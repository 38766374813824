// @flow
import * as R from 'ramda';

import { type AccountHistoryState, type getAccountHistoriesSuccessPayload, } from './types';
import { isPersistActive, storage, } from '../_reduxPersist/persistConfig';

import { AccountHistoryTypes, } from '../actions';
import { createReducer, } from 'reduxsauce';
import { mapTransformer, } from '../_reduxPersist/transformer';
import { persistReducer, } from 'redux-persist';
import { produce, } from 'immer';

export const stateKey = 'accountHistory';

/* ------------- Initial State ------------- */
const INITIAL_STATE: AccountHistoryState = ({
  accountHistories: new Map(),
});

/* ------------- Reducers ------------- */
const getAccountHistories = R.identity;

const getAccountHistoriesSuccess = (state: AccountHistoryState, { payload, }: { payload: getAccountHistoriesSuccessPayload, }) =>
  produce(state, (draft) => {
    Object.assign(draft, payload);
  });

const requestFailure = R.identity;

/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
  [AccountHistoryTypes.GET_ACCOUNT_HISTORIES]: getAccountHistories,
  [AccountHistoryTypes.GET_ACCOUNT_HISTORIES_SUCCESS]: getAccountHistoriesSuccess,

  [AccountHistoryTypes.REQUEST_FAILURE]: requestFailure,
});

const persistConfig = {
  key: stateKey,
  storage,
  transforms: [
    mapTransformer({
      whitelist: 'accountHistories',
    }),
  ],
};

const reducerMap = { [stateKey]: isPersistActive
  ? persistReducer<any, any>(persistConfig, reducer)
  : reducer, };

/* ------------- Selectors ------------- */
const getReducerState: (Object) => AccountHistoryState = (state) => (state[stateKey]);

const selectors = {
  getAccountHistories: ({ accountHistories, }: AccountHistoryState) => (accountHistories),
};

/* ------------- Export ------------- */
export default {
  selectors,

  // default export
  INITIAL_STATE,

  stateKey,
  getReducerState,
  reducerMap,
};
