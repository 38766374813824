import * as R from 'ramda';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Link2PayV from "view/link2pay/Link2PayV";
import { useDispatch, useSelector } from "react-redux";
import { loadScript } from 'utilities/dynamicLoading';
import { API_CONFIG } from 'constants/index';
import { useParams } from 'react-router-dom';
import { UserRedux, PaymentRedux, HeadnoteFirmSettingsRedux } from 'state/reducers';
import { UserActionCreators, PaymentActionCreators, HeadnoteFirmSettingsCreators } from 'state/actions';
import { type PaymentReceivingBankTypePayload } from 'state/payment/types';
import { useHistory, useLocation, } from "react-router-dom";
import { HeadnoteFirmSettings, type FirmInvoiceInfo } from 'domain/firm';
import { type LinkToPayTemplate, LINK2PAY_PAYMENT_METHODS, type HeadnotePayBody, HEADNOTE_BANK_ACCOUNT_TYPES } from 'domain/payment';
import ErrorIcon from '@material-ui/icons/Error';
import Spinner from 'react-bootstrap/Spinner';

type IBankAccountInfo = {
    routingNumber: string;
    accountNumber: string;
    accountClass: string;
    accountName: string;
}

const { VGS_KEY, VGS_ENVIRONMENT, RECAPTCHA_KEY } = API_CONFIG;
const vgsScriptUrl = 'https://js.verygoodvault.com/vgs-collect/2.12.0/vgs-collect.js';
const recaptchaScriptUrl = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_KEY}&badge=bottomleft`;

const useConnect = () => {
    const link2Pay: LinkToPayTemplate = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getLinkToPayTemplate))
    const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
    const headnoteApplication = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getHeadnoteApplication));
    const headnoteFirmSettings: HeadnoteFirmSettings = useSelector(R.pipe(HeadnoteFirmSettingsRedux.getReducerState, HeadnoteFirmSettingsRedux.selectors.getHeadnoteFirmSettings));
    const firmInvoiceInfo: FirmInvoiceInfo = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirmInvoiceInfo));
    const paymentReceivingBankType: HEADNOTE_BANK_ACCOUNT_TYPES = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getPaymentReceivingBankType));

    const mapState = {
        link2Pay,
        firm,
        headnoteApplication,
        headnoteFirmSettings,
        firmInvoiceInfo,
        paymentReceivingBankType
    };

    const dispatch = useDispatch();
    const mapDispatch = useMemo(() => ({
        getLinkToPay: (payload: any) => dispatch(PaymentActionCreators.getLinkToPayTemplate(payload)),
        payByHeadnote: (payload: HeadnotePayBody) => dispatch(PaymentActionCreators.linkToPayHeadnotePay(payload, { thunk: true, })),
        fetchHeadnoteApplication: (): Promise<any> => dispatch(PaymentActionCreators.getHeadnoteApplication({ thunk: true, })),
        getHeadnoteFirmSettings: (): Promise<any> => dispatch(HeadnoteFirmSettingsCreators.getHeadnoteFirmSettings({ thunk: true, })),
        getFirmInvoiceInfo: (): Promise<any> => dispatch(UserActionCreators.getFirmInvoiceInfo({ thunk: true })),
        getPaymentReceivingBankType: (payload: PaymentReceivingBankTypePayload): Promise<any> => dispatch(PaymentActionCreators.getPaymentReceivingBankType(payload, { thunk: true, })),
    }), [dispatch]);
    return {
        ...mapState,
        ...mapDispatch
    };
};

const Link2PayVM = () => {

    const {
        link2Pay,
        firm,
        headnoteApplication,
        headnoteFirmSettings,
        firmInvoiceInfo,
        paymentReceivingBankType,
        getLinkToPay,
        payByHeadnote,
        fetchHeadnoteApplication,
        getHeadnoteFirmSettings,
        getFirmInvoiceInfo,
        getPaymentReceivingBankType
    } = useConnect();

    const history = useHistory();

    const { urlGuid } = useParams();
    const location = useLocation();
    const [vgsForm, setVgsForm] = useState(null);
    const [vgsErrors, setVgsErrors] = useState({});
    const [isVgsScriptLoaded, setIsVgsScriptLoaded] = useState(false);
    const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(LINK2PAY_PAYMENT_METHODS.CARD);
    const [error, setError] = useState(false);
    const [showInvalidTemplateWarning, setShowInvalidTemplateWarning] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    setTimeout(() => {
        setIsLoading(false);
    }, 3000)

    const locationState = useMemo(() => {
        return location.state;
    }, [location.state,]);

    useEffect(() => {
        const fetchPaymentReceivingBankType = async () => {
          const payload: PaymentReceivingBankTypePayload = {
            depositBankAccountId: link2Pay?.bankAccountId
          }
          await getPaymentReceivingBankType(payload);
        }
        fetchPaymentReceivingBankType();
      }, []);

    useEffect(() => {
        const isHeadnoteActive = firm.portalSettings.isHeadnote && headnoteApplication.status === 'VERIFIED';
        setShowInvalidTemplateWarning(!isHeadnoteActive || !link2Pay || link2Pay.isDeleted || !link2Pay.bankAccountId || urlGuid !== link2Pay.urlGuid);
    }, [link2Pay, headnoteApplication, firm]);

    useEffect(() => {
        if (isVgsScriptLoaded) {
            const vgsCollect = window.VGSCollect.create(VGS_KEY, VGS_ENVIRONMENT, function (state) { });
            setVgsForm(vgsCollect);
        }
    }, [isVgsScriptLoaded]);

    useEffect(() => {
        // VGS Script
        const loadVGS = async () => {
            const isLoaded = await loadScript(vgsScriptUrl);
            setIsVgsScriptLoaded(isLoaded);
        }
        if (!isVgsScriptLoaded && selectedPaymentMethod === LINK2PAY_PAYMENT_METHODS.CARD) { loadVGS(); }
        // Google reCAPTCHA
        (async () => {
            const isRecpatchaScriptLoaded = await loadScript(recaptchaScriptUrl);
            setIsRecaptchaLoaded(isRecpatchaScriptLoaded);
        })();
    }, [isVgsScriptLoaded, selectedPaymentMethod]);

    useEffect(() => {
        if (firm && firm.portalSettings.isHeadnote) {
            fetchHeadnoteApplication();
        }

    }, [firm]);

    useEffect(() => {
        const getLink2PayTemplate = async () => {
            if (!urlGuid) { return; }

            try {
                const payload: LinkToPayPayload = {
                    urlGuid
                };
                await getLinkToPay(payload);
            } catch (e) {

            }
        }

        getLink2PayTemplate();
    }, [urlGuid]);

    useEffect(() => {
        const fetchHeadnoteFirmSettings = async () => {
            try {
                await getHeadnoteFirmSettings();
            } catch (e) {
                setError(e.message || e);
            }
        }
        fetchHeadnoteFirmSettings();
    }, [firm]);

    useEffect(() => {
        const fetchFirmInvoiceInfo = async () => {
            try {
                await getFirmInvoiceInfo();
            } catch (error) {
                console.log(error);
            }
        }
        fetchFirmInvoiceInfo();
    }, [firm]);

    const vgsSubmit = () => {
        return new Promise((resolve, reject) => {
            vgsForm.submit(`/api/cards/volatile`, {},
                function (status, data) {
                    const result = {
                        saveCard: false,
                        card_details: {
                            cardholder_name: data.cardholder_name,
                            card_type: vgsForm.state.card_number.cardType,
                            card_number_token: data.card_number,
                            card_zip_code_token: data.zip_code,
                            card_cvv_token: data.cvv_code, // optional
                            card_exp_date: data.exp_date,
                            last4: vgsForm.state.card_number.last4, // optional
                        }
                    }
                    resolve(result);
                },
                function (errors) {
                    reject({ type: 'VGS errors', errors });
                });
        });
    }

    const afterSubmit = useCallback((payment: paymentSuccess) => {
        history.push('/b4t-payment-success', { paymentSuccess: payment, });
    }, [history,]);

    const preProcessLink2PayNotes = (notes: string, paymentMethod: string, clientId: string, projectId: string) => {
        let processedNotes: string;
        switch (paymentMethod) {
            case LINK2PAY_PAYMENT_METHODS.CARD: {
                processedNotes = notes.length > 0 ? notes : 'Payment made via Link2Pay with card.';
                break;
            }
            case LINK2PAY_PAYMENT_METHODS.ARNUMBER:
            case LINK2PAY_PAYMENT_METHODS.PLAID: {
                processedNotes = notes.length > 0 ? notes : 'Payment made via Link2Pay with bank account.';
                break;
            }
        }

        if (clientId) {
            processedNotes += `\nClientId: ${clientId}`
        }
        if (projectId) {
            processedNotes += `\nProjectId: ${projectId}`
        }
        return processedNotes;
    }

    const preProcessLink2PayEmails = (clientEmail: string, receipientEmails: string[]) => {
        let newEmailArr: string[] = [];

        // Append the email to the beginning of the Array, since it is already
        // assumed later that the first email is the one correspondant to the Client.
        newEmailArr.push(clientEmail);
        newEmailArr.push(...receipientEmails);
        return newEmailArr;
    }

    const submitPayment = useCallback(async (values, actions: FormikActions) => {
        try {
            actions.setSubmitting(true);

            const notes = preProcessLink2PayNotes(values.payment_notes, values.paymentMethod, values.client_id, values.project_id);
            const emails = preProcessLink2PayEmails(values.email, values.recipientList);
            const data: HeadnotePayBody = {
                amount: values.isAmountFieldEnabled ? Number(values.amount.replace(/[^0-9.-]+/g, "")) : link2Pay.amount,
                email: emails,
                projectId: values.project_id ? +values.project_id : undefined,
                clientId: values.client_id ? +values.client_id : undefined,
                savePaymentMethod: values.savePaymentMethod,
                saveForFirmUse: values.saveForFirmUse,
                isExistingMethod: false,
                payerFirstName: values.first_name,
                payerLastName: values.last_name,
                cart: [],
                depositBankAccountId: link2Pay.bankAccountId,
                link2PayId: link2Pay.id,
                accountManagerId: link2Pay.acctMgrId,
                notes
            }

            switch (values.paymentMethod) {
                case LINK2PAY_PAYMENT_METHODS.CARD: {
                    const { card_details } = await vgsSubmit();
                    data.card = card_details;
                    data.source = "CC";
                    break;
                }
                case LINK2PAY_PAYMENT_METHODS.ARNUMBER: {
                    const { accountType, routingNumber, accountNumber } = values;
                    const accountNumberlength = accountNumber.length;
                    const accountNumberLast4 = accountNumber.slice(accountNumberlength - 4);
                    const accountName = `${accountType} Account x${accountNumberLast4}`;
                    const bankAccountInfo: IBankAccountInfo = {
                        routingNumber: routingNumber,
                        accountNumber: accountNumber,
                        accountClass: accountType,
                        accountName: accountName
                    };
                    data.bank = bankAccountInfo;
                    data.source = "ACH";
                    break;
                }
                case LINK2PAY_PAYMENT_METHODS.PLAID: {
                    const plaidAccountDetails = R.path(['plaidAccountDetails',], locationState);
                    if (!plaidAccountDetails) {
                        throw Error('Plaid Not Connected');
                    }
                    data.source = "ACH";
                    data.bank = plaidAccountDetails;
                    break;
                }
            }
            const action = 'link_to_pay_payment';
            const captchaToken = await window.grecaptcha.enterprise.execute(RECAPTCHA_KEY, { action: action });
            data.captchaToken = captchaToken;
            data.captchaAction = action;
            const result = await payByHeadnote(data);

            switch (values.paymentMethod) {
                case LINK2PAY_PAYMENT_METHODS.CARD: {
                    result.pmtMethodLabel = `Credit Card x${data.card.last4}`;
                    break;
                }
                case LINK2PAY_PAYMENT_METHODS.ARNUMBER:
                case LINK2PAY_PAYMENT_METHODS.PLAID: {
                    result.pmtMethodLabel = data.bank.accountName;
                    break;
                }
            }

            const afterSubmitBody: PaymentSuccess = {
                ...result,
                amount: data.amount,
                type: data.source,
                emailList: data.email,
                firstName: data.payerFirstName,
                lastName: data.payerLastName,
            }

            afterSubmit(afterSubmitBody)

        } catch (e) {
            if (e && e.type && e.type == 'VGS errors') {
                setVgsErrors(e.errors);
            } else {
                setError({ msg: e.message || e, });
            }
        } finally {
            actions.setSubmitting(false);
        }
    });

    const invalidTemplateWarning = () => {
        return (
            <div style={{ marginTop: '63px', textAlign: 'center' }}>
                <ErrorIcon
                    style={{ fontSize: '63px', color: '#ee6723', marginBottom: '30px' }}
                />
                <h3>This link is no longer active.</h3>
                <br />
                <p>Please contact your attorney to request a new payment link.</p>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div style={{ width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner animation="border" className="mr-2" role="status" />
            </div>
        )
    }

    return (
        <>
            {!showInvalidTemplateWarning &&
                <Link2PayV
                    vgsForm={vgsForm}
                    setVgsForm={setVgsForm}
                    vgsErrors={vgsErrors}
                    error={error}
                    firm={firm}
                    firmInvoiceInfo={firmInvoiceInfo}
                    link2Pay={link2Pay}
                    submitPayment={submitPayment}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    setVgsLoadState={setIsVgsScriptLoaded}
                    headnoteFirmSettings={headnoteFirmSettings}
                    paymentReceivingBankType={paymentReceivingBankType}
                ></Link2PayV>
            }
            {showInvalidTemplateWarning &&
                invalidTemplateWarning()
            }
        </>
    );

};

export default Link2PayVM;