/* @flow */
import { type RetainerRequestPayments, type RetainerRepository, } from 'domain/retainer';
import { ACCT_ISO_TYPE, } from 'domain/client';
import { type WithCurrentUserToken, } from 'domain/user';
import { project } from 'ramda';

type Callbacks = {
    onSuccess: (Array<RetainerRequestPayments>) => void,
    onInvalidPayload: (Error) => void,
    onError: (Error) => void,
  };

  type Dependencies = {
    retainerRepository: RetainerRepository,
  };

  type Payload = {
    clientId: number,
    projectId: ?number,
  };

  export type GetRetainersBehaviour = (
    Payload,
    WithCurrentUserToken,
    Callbacks
  ) => any

  export default ( { retainerRepository, }: Dependencies, ) => {
    /**
    * get list retainers of a client or project behaviour
    * 
    * @param {Payload} payload - client id and project id
    * @param {WithCurrentUserToken} withCurrentUserToken - auth token
    * @param {Callbacks} callbacks - call back for each use case
    */
      const getRetainersBehaviour: GetRetainersBehaviour = async (
        payload,
        withCurrentUserToken,
        { onSuccess, onError, onInvalidPayload, },
      ) => {
          try {
            const { clientId, projectId, acctIsoType, } = payload;
            if (!clientId) {
              return onInvalidPayload(new Error('Invalid client and project id.'));
            }

            let retainers;

            if ((acctIsoType && acctIsoType === ACCT_ISO_TYPE.client) || (projectId && Number(projectId) === -1)) {
              retainers = await retainerRepository.getClientRetainers(clientId, withCurrentUserToken);
            } else if (projectId) {
              retainers = await retainerRepository.getProjectRetainers(clientId, projectId, withCurrentUserToken);
            }
            return onSuccess(retainers);
          } catch(error) {
            return onError(error);
          }
      };
      return getRetainersBehaviour;
};