// @flow
import * as R from 'ramda';

import { type ClientMap, type ClientState, type getAccountBalanceSuccessPayload, type getClientsSuccessPayload, 
         type getFirmClientsSuccessPayload, type getClientHeadnoteInfoSuccessPayload } from './types';
import { ClientTypes, UserTypes, } from '../actions';
import { isPersistActive, storage, } from '../_reduxPersist/persistConfig';

import { createReducer, } from 'reduxsauce';
import { mapTransformer, } from '../_reduxPersist/transformer';
import { persistReducer, type PersistConfig, } from 'redux-persist';
import { produce, } from 'immer';
import { AccountBalance, type Client, type ClientHeadnoteInfo } from 'domain/client';

export const stateKey = 'client';

/* ------------- Initial State ------------- */
const INITIAL_STATE: ClientState = ({
  accountBalance: undefined,
  clients: new Map(),
  firmClients: new Map(),
  selectedClient: undefined,
  clientHeadnoteInfo: undefined
});

/* ------------- Reducers ------------- */
const getClients = R.identity;

const initDataSuccess = (state: UserState) =>
  produce(state, (draft) => {
    Object.assign(draft, INITIAL_STATE);
  });

const getClientsSuccess = (state: ClientState, { payload, }: { payload: getClientsSuccessPayload, }) =>
  produce(state, (draft) => {
    Object.assign(draft, payload);
  });

const getSingleClient = R.identity;

const getSingleClientSuccess = R.identity;

const getAccountBalance = R.identity;

const getAccountBalanceSuccess = (state: ClientState, { payload, }: { payload: getAccountBalanceSuccessPayload, }) =>
  produce(state, (draft) => {
    Object.assign(draft, payload);
  });

const getFirmClients = R.identity;
const getFirmClientsSuccess = (state: ClientState, { payload, }: { payload: getFirmClientsSuccessPayload, }) =>
  produce(state, (draft) => {
    draft.firmClients = payload.clients;
  });

const setSelectedClient = (state: ClientState, { payload, }: { payload: Client, }) =>
  produce(state, (draft) => {
    draft.selectedClient = payload;
  });

const getClientHeadnoteInfo = R.identity;
const getClientHeadnoteInfoSuccess = (state: ClientState, { payload }: { payload: getClientHeadnoteInfoSuccessPayload }) =>
  produce(state, (draft) => {
    Object.assign(draft.clientHeadnoteInfo = payload);
  });

const requestFailure = R.identity;

/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
  [ClientTypes.GET_CLIENTS]: getClients,
  [ClientTypes.GET_CLIENTS_SUCCESS]: getClientsSuccess,

  [ClientTypes.GET_SINGLE_CLIENT]: getSingleClient,
  [ClientTypes.GET_SINGLE_CLIENT_SUCCESS]: getSingleClientSuccess,

  [ClientTypes.GET_ACCOUNT_BALANCE]: getAccountBalance,
  [ClientTypes.GET_ACCOUNT_BALANCE_SUCCESS]: getAccountBalanceSuccess,

  [UserTypes.GET_FIRM_CLIENTS]: getFirmClients,
  [UserTypes.GET_FIRM_CLIENTS_SUCCESS]: getFirmClientsSuccess,

  [UserTypes.LOGOUT_SESSION_SUCCESS]: initDataSuccess,

  [ClientTypes.SET_SELECTED_CLIENT]: setSelectedClient,

  [ClientTypes.REQUEST_FAILURE]: requestFailure,

  [ClientTypes.GET_CLIENT_HEADNOTE_INFO]: getClientHeadnoteInfo,
  [ClientTypes.GET_CLIENT_HEADNOTE_INFO_SUCCESS]: getClientHeadnoteInfoSuccess
});

const persistConfig: PersistConfig = {
  key: stateKey,
  storage,
  transforms: [
    mapTransformer({
      whitelist: 'clients',
    }),
  ],
};

const reducerMap = { [stateKey]: isPersistActive
  ? persistReducer<any, any>(persistConfig, reducer)
  : reducer, };

/* ------------- Selectors ------------- */
const getReducerState: (Object) => ClientState = (state) => (state[stateKey]);

const selectors = {
  getClients: ({ clients, }: ClientState): ClientMap => (clients),
  getFirmClients: ({ firmClients, }: ClientState): ClientMap => (firmClients),
  getAccountBalance: ({ accountBalance, }: ClientState): AccountBalance | undefined => (accountBalance),
  getClientById: (id: number) => ({ clients, }: ClientState) => (clients.get(id)),
  getSelectedClient: ({ selectedClient, }: ClientState): Client | undefined => (selectedClient),
  getClientHeadnoteInfo: ({ clientHeadnoteInfo }: ClientState): ClientHeadnoteInfo | undefined => (clientHeadnoteInfo)
};

/* ------------- Export ------------- */
export default {
  selectors,

  // default export
  INITIAL_STATE,

  stateKey,
  getReducerState,
  reducerMap,
};
