// @flow
import * as R from 'ramda';

import { Alert, Button, Card, Col, Container, Form, Row, Spinner, } from 'react-bootstrap';
import { Field, Formik, type FormikProps, } from 'formik';
import { type InvoicePayCart, type LawpayAddCardInfo, LawpayAddCardInfoSchema, type LawpayPayBody, type PendingPayment, type RequiredFields, } from 'domain/payment';
import { type InvoicePaymentRouteParams, type PaymentSuccess, } from '../../types';
import React, { useCallback, useEffect, useMemo, useRef, } from 'react';

import { API_CONFIG, ERRORS, APP_CONFIG, COUNTRIES, } from 'constants/index';
import { type AffinipayHostedFieldState, } from './types';
import { Colors, } from 'assets/index';
import { FormInput, FormControl, ValidateShading, FormCheckBox, FormSelect, } from 'view/components';
import { type Firm, type ClientPortalSettings, } from 'domain/firm';
import { type LawpayPaymentPayload, } from 'state/payment/types';
import { Link, } from 'react-router-dom';
import { PaymentActionCreators, } from 'state/actions';
import styled from 'styled-components';
import { useDispatch, useSelector, } from 'react-redux';
import { UserRedux, PaymentRedux, } from 'state/reducers';
import { type User, } from 'domain/user';

const StyledCard = styled(Card)`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  background: white;
  border-radius: 0px;
  border: solid 1px ${Colors.gainsBoro};
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
`;

const StyleContainer = styled(Container)`
  flex: 1;
`;

const StyledP = styled.p`
  margin: 0px 0px 5px;
`;

const StyleCreditCard = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${Colors.whiteBlack};
  border-radius: 0.25rem;
  align-items: center;
  &.invalid {
    border-color: ${Colors.shadingInputbg};
    background: ${Colors.shadingInputbg};
  }
  .form-group {
    margin: 0 !important;
    .form-control {
      border: 0px !important;
    }
  }
  input {
    height: 33px !important;
    padding-right: 0px;
    border: 0px;
    outline: none !important;
    box-shadow: none !important;
    background: transparent !important;
  }
  #lawpay_credit_card_id {
    width: 80%;

  }
  .small-input {
    width: 9%;
  }
  #lawpay_card_cvv_id {
    width: 9%;
  }
  @media(max-width: 1200px) {
    #lawpay_card_cvv_id {
      width: 11%;
    }
  }
  @media(max-width: 992px) {
    #lawpay_card_cvv_id {
      width: 18%;
    }
  }
  @media(max-width: 480px) {
    flex-wrap: wrap;
    #lawpay_credit_card_id {
      width: 100%;
    }
    .small-input {
      width: 23%;
      padding: 0 12px;
      text-align: center;
    }
    #lawpay_card_cvv_id {
      width: 41%;
    }
  }
`;

const HostedFieldDiv = styled.div`
  iframe {
    height: 33px;
    border: none;
    width: 100%;
    display: block;
  }
`;

const StyledFormError = styled.div`
  color: ${Colors.redDanger};
  font-size: 11.2px;
`;

type Props = {
  afterSubmit: Function;
  invoicePayment: InvoicePaymentRouteParams,
  currencyCode?: String,
  goBack: Function,
  isUser: boolean,
};

const initialCard: LawpayAddCardInfo = {
  name: '',
  exp_month: '',
  exp_year: '',
  card_cvv: '',
  email: '',
  savePaymentMethod: false,
};

// connect redux
const useConnect = () => {
  // mapState
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const user: User = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const requiredFields: RequiredFields = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getRequiredFields));

  const mapState = {
    user,
    firm,
    requiredFields,
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    payByLawpay: (payload: LawpayPaymentPayload) => dispatch(PaymentActionCreators.lawpayPay(payload, { thunk: true, })),
    getRequiredFields: () => dispatch(PaymentActionCreators.requiredFieldsLawpay({ thunk: true, })),
  }), [dispatch,]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/* ------------- Lawpay hosted fields configs and handling ------------- */
const hostedFieldStyle = {
  'font-size': '1rem',
  'font-weight': '400',
  'line-height': '1.5',
  height: '33px',
  color: Colors.grey,
  width: '100%',
  padding: '.375rem .75rem',
  transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
};

/**
 * handle lawpay hosted fields initialization
 *
 * @param {boolean} isShow - modal show boolean
 */
const useLawpayHostedFields = (isShow: boolean) => {
  const { firm, } = useConnect();

  const lawpayCardHostedFieldsConfig = useMemo(() => {
    // Get publish key from firm
    let publishKey = API_CONFIG.AFFINIPAY_PUBLIC_KEY;
    const portalSettings: ClientPortalSettings = R.path(['portalSettings',], firm);
    if (portalSettings && portalSettings.lawpay && portalSettings.lawpay.publicKey) {
      publishKey = portalSettings.lawpay.publicKey;
    }

    return {
      publicKey: publishKey,
      css: hostedFieldStyle,
      fields: [
        {
          selector: '#lawpay_credit_card_id',
          input: {
            type: 'credit_card_number',
            placeholder: 'Card number',
            css: hostedFieldStyle,
          },
        },
        {
          selector: '#lawpay_card_cvv_id',
          input: {
            type: 'cvv',
            placeholder: 'CVC',
            css: hostedFieldStyle,
          },
        },
      ],
    };
  }, [firm,]);

  // store hosted fields in a ref
  const lawpayHostFieldsRef = useRef();

  const hostedFieldsCallBack = useCallback((state) => {
    // TODO: do we need to do some thing in this call back ?
    // console.log('>>> hosted fiellds', { state })
  }, []);

  useEffect(() => {
    // do nothing if modal is not show
    if (!isShow) { return; }

    // init lawpay hosted fields
    lawpayHostFieldsRef.current = window.AffiniPay.HostedFields.initializeFields(
      lawpayCardHostedFieldsConfig,
      hostedFieldsCallBack
    );

    // clean up
    // remove ref reference to hosted fields
    return () => {
      lawpayHostFieldsRef.current = undefined;
    };
  }, [hostedFieldsCallBack, isShow, lawpayCardHostedFieldsConfig,]);

  return {
    lawpayHostFieldsRef,
  };
};
/* ------------- End ------------- */

/**
 * Main component
 *
 * @param {(Props)} props
 * @returns
 */
const LawpayCard = ({ afterSubmit, invoicePayment, goBack, isUser, }: Props) => {
  const { payByLawpay, user, requiredFields, getRequiredFields, } = useConnect();

  // Load required fields
  useMemo(() => {
    (
      async () => {
        try {
          await getRequiredFields();
        } catch (e) {
          console.log(e);
        }
      }
    )();
  }, [getRequiredFields,]);

  const {
    lawpayHostFieldsRef,
  } = useLawpayHostedFields(true);

  // validate card by required field
  const validate = useCallback(values => {
    let errors = {};

    if (requiredFields.address1 && values.address1 && values.address1.length === 0) {
      errors.address1 = "Billing address is required";
    }

    if (requiredFields.postal_code && values.postal_code && values.postal_code.length === 0) {
      errors.postal_code = "Post Code is required";
    }

    if (requiredFields.city && values.city && values.city.length === 0) {
      errors.city = "City is required";
    }

    if (requiredFields.state && values.state && values.state.length === 0) {
      errors.state = "State is required";
    }

    if (requiredFields.country && values.country && values.country.length === 0) {
      errors.country = "Country is required";
    }

    if (Object.keys(errors).length > 0) {
      return errors;
    }
    return {};
  }, [requiredFields,]);

  // handle stripe card pay
  const onSubmit = useCallback(async (values: LawpayAddCardInfo, actions) => {
    try {
      // throw error if hosted fields not found
      if (!lawpayHostFieldsRef.current) {
        throw new Error('Lawpay hosted field not found.');
      };

      actions.setSubmitting(true);

      // Check if amount is smaller that 50 cents
      if (invoicePayment.amount < 0.5) {
        throw new Error(ERRORS.stripe_amount_at_least);
      }

      // get hosted field state from ref
      const hostedFieldState: AffinipayHostedFieldState = lawpayHostFieldsRef.current.getState();

      // check lawpay hosted fields validation
      let affinipayErrors = {};
      hostedFieldState.fields.forEach((field) => {
        if (field.error) {
          affinipayErrors[field.type] = field.error;
        }
      });

      if (Object.keys(affinipayErrors).length > 0) {
        return actions.setErrors(affinipayErrors);
      }

      // If client check on Save payment method, add reference
      if (values.savePaymentMethod) {
        values.reference = user._id;
      }

      // tokenize lawpay card account
      const result: AffinipayPaymentTokenRes = await lawpayHostFieldsRef.current.getPaymentToken(values);

      // process stripe pay request data
      const payCart: Array<InvoicePayCart> = invoicePayment.cart.map((item) => ({
        invoiceId: item.id,
        invoiceLabel: item.invoiceLabel,
        invoiceBalance: item.invoiceBalance,
        amountApplied: item.userPayment,
      }));

      const payment: LawpayPayBody = {
        cart: payCart.filter(item => item.invoiceBalance !== APP_CONFIG.paymentCart.additionalPayment),
        amount: invoicePayment.amount,
        email: values.email,
        source: "CC",
        projectId: invoicePayment.projectId ? Number(invoicePayment.projectId) : undefined,
        methodIdOrToken: result.id,
        saveCard: values.savePaymentMethod,
      };

      const data: LawpayPaymentPayload = {
        clientId: invoicePayment.clientId,
        payment,
      };

      const res: PendingPayment = await payByLawpay(data);

      // use status to display verification success modal
      actions.setStatus(true);

      // Response doesn't have amount so we can get amount from body
      const afterSubmitBody: PaymentSuccess = {
        ...res,
        amount: invoicePayment.amount,
        type: 'Credit Card',
      };

      afterSubmit(afterSubmitBody);
    } catch (e) {
      // handle error
      actions.setErrors({
        card: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [afterSubmit, invoicePayment, lawpayHostFieldsRef, payByLawpay, user,]);

  return (
    <StyleContainer className="px-0">
      <Formik
        initialValues={{ ...initialCard, requiredFields, }}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={LawpayAddCardInfoSchema}
        onSubmit={onSubmit}
      >
        {
          ({ errors, handleSubmit, isSubmitting, values, }: FormikProps<PaymentCard>) => {
            return (
              <Form onSubmit={handleSubmit}>
                <StyledCard>
                  {errors.payment && (
                    <Row className="justify-content-md-center">
                      <Col>
                        <Alert variant='danger'>{errors.payment}</Alert>
                      </Col>
                    </Row>
                  )}
                  {errors.card && (
                    <Row className="justify-content-md-center">
                      <Col>
                        <Alert variant='danger'>{errors.card}</Alert>
                      </Col>
                    </Row>
                  )}
                  {(
                    errors.email ||
                    errors.name ||
                    errors.credit_card_number
                  ) && (
                      <Row className="justify-content-md-center">
                        <Col>
                          <ValidateShading text={`${ERRORS.invalid_fields}. If you autofilled your payment information, please try manually entering it in again.`} />
                        </Col>
                      </Row>
                    )}

                  <Row className="justify-content-md-center mx-0">
                    <Col className="px-0">
                      <StyledP>{'Pay with credit or debit card'}</StyledP>
                      <Field
                        className={`m-0 mb-2 border-0 payment-validate ${errors.email ? 'invalid' : 'valid'}`}
                        component={FormInput}
                        label={`Send Receipt To:`}
                        maxLength={256}
                        name={'email'}
                        type={'text'}
                      />
                      <Field
                        className={`m-0  mb-2 border-0 payment-validate ${errors.name ? 'invalid' : 'valid'}`}
                        component={FormInput}
                        label={`Name on Card:`}
                        maxLength={26}
                        name={'name'}
                        type={'text'}
                      />
                      {
                        requiredFields.address1 && (
                          <Field
                            className={`m-0  mb-2 border-0 payment-validate ${errors.address1 ? 'invalid' : 'valid'}`}
                            component={FormInput}
                            label={`Billing address:`}
                            maxLength={256}
                            name={'address1'}
                            type={'text'}
                          />
                        )
                      }
                      {
                        requiredFields.postal_code && (
                          <Field
                            className={`m-0 border-0 payment-validate ${errors.postal_code ? 'invalid' : 'valid'}`}
                            component={FormInput}
                            label={`Postal Code:`}
                            maxLength={26}
                            name={'postal_code'}
                            type={'text'}
                          />
                        )
                      }
                      {
                        requiredFields.city && (
                          <Field
                            className={`m-0 border-0 payment-validate ${errors.city ? 'invalid' : 'valid'}`}
                            component={FormInput}
                            label={`City:`}
                            maxLength={26}
                            name={'city'}
                            type={'text'}
                          />
                        )
                      }
                      {
                        requiredFields.state && (
                          <Field
                            className={`m-0 border-0 payment-validate ${errors.state ? 'invalid' : 'valid'}`}
                            component={FormInput}
                            label={`State:`}
                            maxLength={26}
                            name={'state'}
                            type={'text'}
                          />
                        )
                      }
                      {
                        requiredFields.country && (
                          <Field
                            fullWidth
                            required
                            className={`mt-2 payment-validate ${errors.state ? 'invalid' : 'valid'}`}
                            component={FormSelect}
                            defaultValue={COUNTRIES[0].value}
                            inputSize={12}
                            isSearchable={false}
                            label={'Country'}
                            labelClassName={`text-right`}
                            labelSize={12}
                            name={'country'}
                            options={COUNTRIES}
                            placeholder={'Country'}
                          />
                        )
                      }
                      <StyleCreditCard className={`mt-3 ${errors.credit_card_number ? 'invalid' : 'valid'}`}>
                        <HostedFieldDiv id="lawpay_credit_card_id"></HostedFieldDiv>
                        <Field
                          className={`small-input`}
                          component={FormControl}
                          labelClassName={`text-right`}
                          maxLength={2}
                          name={'exp_month'}
                          placeholder={'MM'}
                          type={'text'}
                        />
                        {"/"}
                        <Field
                          className={`small-input`}
                          component={FormControl}
                          labelClassName={`text-right`}
                          maxLength={2}
                          name={'exp_year'}
                          placeholder={'YY'}
                          type={'text'}
                        />
                        <HostedFieldDiv id="lawpay_card_cvv_id"></HostedFieldDiv>
                      </StyleCreditCard>
                      {
                        errors.exp_month && <StyledFormError type="invalid">{errors.exp_month}</StyledFormError>
                      }
                      {
                        errors.exp_year && <StyledFormError type="invalid">{errors.exp_year}</StyledFormError>
                      }
                      {
                        isUser && (
                          <Field
                            className={`mt-3`}
                            component={FormCheckBox}
                            label={'Save payment method'}
                            // labelClassName={`text-right`}
                            name={'savePaymentMethod'}
                          />
                        )
                      }
                    </Col>
                  </Row>
                </StyledCard>

                <StyledCard>
                  <div className="d-flex justify-content-between align-items-center">
                    <Link className="btn" onClick={goBack}>{`Cancel`}</Link>
                    <div className='d-flex flex-row align-items-center'>
                      {
                        isSubmitting && (
                          <Spinner animation="border" className="mr-2" role="status" />
                        )
                      }
                      <Button
                        disabled={isSubmitting}
                        size="lg"
                        type="submit"
                        variant="success"
                      >
                        {`SUBMIT`}
                      </Button>
                    </div>
                  </div>
                </StyledCard>
              </Form>
            );
          }
        }
      </Formik>
    </StyleContainer>
  );
};

export default LawpayCard;
