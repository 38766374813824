// @flow
import * as R from 'ramda';

import { type AcctIsoType, type Client, getAcctIsoType, } from 'domain/client';
import { ClientActionCreators, ProjectActionCreators, } from 'state/actions';
import { ClientRedux, ProjectRedux, UserRedux, } from 'state/reducers';
import { type ProjectMap, type getProjectsPayload, } from 'state/project/types';
import React, { useEffect, useMemo, useState, useCallback, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import { type ClientMap, } from 'state/client/types';
import { type Project, } from 'domain/project';
import styled from 'styled-components';
import { Colors, } from 'assets';
import { APP_CONFIG, } from 'constants/index';

const IDDiv = styled.div`
  color: ${Colors.nyGrey};
`;

const getClientDropDownLabel = (c: Client): string => `Client: ${c.name}`;
const getClientOptionValue = (c: Client): Client => c;
const getClientOptionLabel = (c: Client): JSX.Element => (
  <React.Fragment>
    { c.clientLabel && <IDDiv>{`ID: ${c.clientLabel}`}</IDDiv> }
    { c.name && <div>{`${c.name}`}</div> }
  </React.Fragment>
);

const getProjectDropDownLabel = (p: Project): string => `Project: ${p && (p.name || p.projectLabel)}`;
const getProjectOptionValue = (p: Project): Project => p;
const getProjectOptionLabel = (p: Project): JSX.Element => (
  <React.Fragment>
    { p.projectLabel && <IDDiv>{`ID: ${p.projectLabel}`}</IDDiv> }
    { p.name && <div>{`${p.name}`}</div> }
  </React.Fragment>
);

// connect redux
const useConnect = () => {
  // mapState
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const clientsMap: ClientMap = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getClients));
  const projectsMap: ProjectMap = useSelector(R.pipe(ProjectRedux.getReducerState, ProjectRedux.selectors.getProjects));
  const selectedClient: Client = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getSelectedClient));
  const selectedProject: Project = useSelector(R.pipe(ProjectRedux.getReducerState, ProjectRedux.selectors.getSelectedProjects));

  const mapState = {
    firm,
    clientsMap,
    projectsMap,
    selectedClient,
    selectedProject,
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    fetchClients: (payload) => dispatch(ClientActionCreators.getClients(payload, { thunk: true, })),
    fetchProjects: (payload: getProjectsPayload) => dispatch(ProjectActionCreators.getProjects(payload, { thunk: true, })),
    setSelectedClient: (payload) => dispatch(ClientActionCreators.setSelectedClient(payload)),
    setSelectedProject: (payload) => dispatch(ProjectActionCreators.setSelectedProject(payload)),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

export const useClientProjectList = () => {
  const {
    firm,
    clientsMap,
    projectsMap,
    fetchClients,
    fetchProjects,
    setSelectedClient,
    setSelectedProject,
    selectedClient,
    selectedProject,
  } = useConnect();
  const [ loadingClient, setLoadingClient, ] = useState<boolean>(false);
  const [ loadingProject, setLoadingProject, ] = useState<boolean>(false);

  // get acctIsoType from firm and selected Client
  const acctIsoType = useMemo<AcctIsoType>(() => {
    if (!firm || !selectedClient) {return null;}

    const client = clientsMap.get(selectedClient.id);

    if (!client) {return null;}

    return getAcctIsoType(firm, client);
  }, [ clientsMap, firm, selectedClient, ]);

  // transform client, project map to group array for displaying on select view
  const listClient = useMemo<Array<Client>>(() => {
    return Array.from(clientsMap.values());
  }, [ clientsMap, ]);

  const listProject = useMemo<Array<Project>>(() => {
    let projectArray = R.sortBy(R.compose(R.toLower, R.prop('name')), Array.from(projectsMap.values()));

    if (firm && firm.allowNonMatterRelated) {
      projectArray = [ APP_CONFIG.noneProjectRelated, ...projectArray, ];
    }
    return projectArray;
  }, [ projectsMap, firm, ]);

  // fetch clients at first render
  useEffect(() => {
    const onFetchClients = async () => {
      try {
        setLoadingClient(true);

        const clientResponse = await fetchClients();

        const listClientParse = Array.from(clientResponse.clients.values());
        if (listClientParse.length > 0) {
          await setSelectedClient(listClientParse[0]);
        }

        const projectResponse = await fetchProjects({ clientId: listClientParse[0].id, });
        const listProjectParse = Array.from(projectResponse.projects.values());
        if (listProjectParse.length > 0) {
          await setSelectedProject(listProjectParse[0]);
        }

        if (firm && firm.allowNonMatterRelated) {
          await setSelectedProject(APP_CONFIG.noneProjectRelated);
        }

      } catch (e) {
        console.log('[Load clients error]', { e, });
      } finally {
        setLoadingClient(false);
      }
    };

    // don't load if have selected client
    if (!selectedClient) {
      onFetchClients();
    }
  }, [ fetchClients, fetchProjects, selectedClient, setSelectedClient, setSelectedProject, firm, ]);


  // use callback to save selected client and project in redux state
  const savedSelectedClient = useCallback(value => {
    setSelectedClient(value);

    const onFetchProjects = async () => {
      try {
        setLoadingProject(true);

        const projectResponse = await fetchProjects({ clientId: value.id, });
        let list = Array.from(projectResponse.projects.values());

        if (firm && firm.allowNonMatterRelated) {
          list = [ APP_CONFIG.noneProjectRelated, ...list, ];
        }

        if (list.length > 0) {
          await setSelectedProject(list[0]);
        }
      } catch (e) {
        console.log('[Load projects error]', { e, });
      } finally {
        setLoadingProject(false);
      }
    };

    onFetchProjects();
  }, [ fetchProjects, setSelectedClient, setSelectedProject, firm, ]);

  // If we have state in location, use this callback
  const setSelectedClientWithoutFetching = useCallback(value => {
    setSelectedProject(value);
  }, [ setSelectedProject, ]);

  const savedSelectedProject = useCallback(value => {
    setSelectedProject(value);
  }, [ setSelectedProject, ]);

  return {
    loadingClient,
    loadingProject,
    acctIsoType,
    listClient,
    listProject,
    selectedClient,
    selectedProject,
    setSelectedClient : savedSelectedClient,
    setSelectedClientWithoutFetching: setSelectedClientWithoutFetching,
    setSelectedProject : savedSelectedProject,

    getClientDropDownLabel,
    getClientOptionValue,
    getClientOptionLabel,

    getProjectDropDownLabel,
    getProjectOptionValue,
    getProjectOptionLabel,
  };
};
