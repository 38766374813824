export default {
  // app specific
  QUERY_PAGE_SIZE: 10,
  timerTick: 30, // seconds
  // generic config
  defaultAnimationDelay: 250, // ms
  defaultListEndReachedThreshold: 0.2,
  delayTextInputDebounce: 250,
  longAnimationDelay: 1000, // ms
  minTableHeight: '43.5rem',
  minTableAccountHeight: '40.5rem',
  prefixReducer: 'bill4time',
  dateFormat: 'MM/DD/yyyy',
  defaultCurrency: {
    symbol: '$',
  },
  pdfLink: process.env.REACT_APP_PDF_LINK || '',
  iframeLink: process.env.REACT_APP_IFRAME_LINK || '',
  // stripe
  stripeConfig: {
    country: 'US',
  },
  // env
  isDev: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  invite_status: {
    SENT: 'SENT',
    expired: 'expired',
    accepted: 'accepted',
  },
  // pop up,
  popUpWindowWidth: 500,
  redirectLink: {
    integratePayment: '/integrate-payment',
  },
  paymentCart: {
    additionalPayment: 'Add a payment towards remaining account balance',
  },
  // non project related data
  noneProjectRelated: {
    id: -1,
    name: 'All',
  },
  activeForFreeLink: `${process.env.REACT_APP_B4T_APP_LINK}/b4t2/firmprefs/b4tpayments.aspx`,
};
