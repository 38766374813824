/* @flow */
import { type Firm, type FirmRepository, } from 'domain/firm';
import { type User, type UserRepository, type UserToken, type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (t: UserToken, f: Firm, u: User) => void,
  onError: (e: Error) => void,
};

type Dependencies = {
  firmRepository: FirmRepository,
  userRepository: UserRepository,
};

export type SetTokenFromOutsideBehaviour = (
  token: string,
  Callbacks,
) => any;

export default ( { firmRepository, userRepository, }: Dependencies, ) => {
  /**
   * set token from outside behaviour
   * 
   * @param {string} token - new token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const setTokenFromOutsideBehaviour: SetTokenFromOutsideBehaviour = async ( token, { onSuccess, onError, }, ) => {
    try {
      // construct user token
      const userTokens: UserToken = {
        authToken: token,
      };

      const withUserToken: WithCurrentUserToken = {
        currentUserToken: userTokens,
      };

      // get firm and user info
      const promises = [
        firmRepository.getFirm(withUserToken),
        userRepository.getByToken(withUserToken),
      ];
      const [ firm, user, ]: [ Firm, User, ] = await Promise.all(promises);

      return onSuccess(userTokens, firm, user);
    } catch (error) {
      return onError(error);
    }
  };

  return setTokenFromOutsideBehaviour;
};
