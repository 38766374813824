// @flow
import { type Client, getClientDisplay, } from 'domain/client';
import React, { useEffect, } from 'react';

import { type ClientMap, } from 'state/client/types';
import { type DropDownOption, } from 'view/components/Dropdown/types';
import { type FirmUserMap, } from 'state/user/types';
import { type PermissonFormValues, } from './types';
import { useFormikContext, } from 'formik';

type Props = {
  userMap: FirmUserMap,
  clientMap: ClientMap
};

/**
 * component update formik clients array when selected user changed
 *
 * @returns
 */
const AutoInitClients = ({ clientMap, userMap, }: Props) => {
  const { values, setFieldValue, } = useFormikContext<PermissonFormValues>();

  const { userSelected, } = values;

  useEffect(() => {
    // do nothing if no user selected or no client map / user map
    if (!userSelected || !clientMap || !userMap) {return;}

    // get user object from user map
    const user = userMap.get(userSelected.value._id);

    // do nothing if user doesn't exist
    if (!user) {return;}

    const newDefaultSelectedClient: DropDownOption<Client>[] = user.clients.map(c => {
      // get client object from client map
      const client = clientMap.get(c);
      if (!client) {return;}

      return {
        label: client.name,
        value: client,
      };
    });

    // set clients list to clients selected field
    setFieldValue('clientsSelected', newDefaultSelectedClient);
  }, [ userSelected, clientMap, userMap, setFieldValue, ]);

  return null;
};

export default AutoInitClients;
