import React, { type Node, } from 'react';
import { PersistGate, } from 'redux-persist/integration/react';
import { Provider, } from 'react-redux';
import { type Store, } from 'redux';

export type Props = {
  store: Store,
  persistor: Object,
  loading: ?Node,
  children: ?Node
}

const ProviderFactory = ({ store, persistor, loading, children, }: Props) => {
  // persistor.purge()
  return (
    <Provider store={store}>
      <PersistGate loading={loading} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default ProviderFactory;
