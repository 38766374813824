/* @flow */
import { type PaymentRepository, type VerifyBankInfo, } from 'domain/payment';

import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: () => void,
  onInvalidPayload: (Error) => void,
  onError: (Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
};

type Payload = {
  bankId: string,
  verifyInfo: VerifyBankInfo,
};

export type VerifyAccountBehaviour = (
  payload: Payload,
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export default ( { paymentRepository, }: Dependencies, ) => {
  /**
   * verify user bank account
   * 
   * @param {Payload} payload - 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const verifyAccountBehaviour: VerifyAccountBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { bankId, verifyInfo, } = payload;
      if (!bankId || !verifyInfo) {
        return onInvalidPayload(new Error('Invalid verify info.'));
      };

      // only stripe account need to verify ( lawpay doesn't )
      await paymentRepository.verifyStripeBankAccount(bankId, verifyInfo, withCurrentUserToken);
      
      return onSuccess();
    } catch(error) {
      return onError(error);
    }
  };

  return verifyAccountBehaviour;
};
