import { StatementLink, StatementRepository } from 'domain/statement';
import { WithCurrentUserToken } from 'domain/user';

type Callbacks = {
    onSuccess: (i: StatementLink) => void,
    onError: (e: Error) => void
}

type Dependencies = {
    statementRepository: StatementRepository
}

type Payload = {
    clientId: number, // check
    statementId: number
}

export type GetStatementLinkBehaviour = (
    Payload, WithCurrentUserToken, Callbacks
) => any

export default ({ statementRepository }: Dependencies) => {

    /**
     * Get a Statement PDF Link
     * @param {Payload} payload - statement id and client id
     * @param {WithCurrentUserToken} withCurrentUserToken - auth token
     * @param {Callbacks} callbacks - call back for each use case 
     * @returns 
     */
    const getStatementLink: GetStatementLinkBehaviour = async (
        payload, withCurrentUserToken, { onSuccess, onError }
    ) => {
        try {
            const { statementId, clientId } = payload;

            const statementLink = await statementRepository.getStatementLink(statementId, clientId, withCurrentUserToken);
            return onSuccess(statementLink);
        } catch (e) {
            return onError(e);
        }
    }
    return getStatementLink;
}