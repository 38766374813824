import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Colors, } from 'assets/index';
import Container from 'react-bootstrap/Container';
import React from 'react';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  flex: 1;
  color: ${Colors.charcoal};
  line-height: 20px;
    .privacy-container {
      box-shadow: 1px 1px 1px ${Colors.lightGrey};
    }
    p {
      margin-bottom: .5rem;
    }
`;

const StyledHeader = styled(Card.Header)`
  background-color: rgba(0,0,0,0) !important;
  margin-top: .75rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
`;

const StyledTitle = styled(Card.Title)`
  font-size: 18px !important;
`;

const SecurityStatementPage = () => {
  return (
    <StyledContainer className='my-4'>
      <Row>
        <Col md={{ span: 8, offset: 2, }}>
          <Card className="privacy-container">
            <StyledHeader className="p-0">
              <StyledTitle>
                {`Security Statement`}
              </StyledTitle>
            </StyledHeader>
            <Card.Body>
              <Card.Text className="mb-2">
                {`Secure Socket Layer (SSL) technology protects your information using both server authentication and data encryption, ensuring that your data is safe, secure, and available only to registered Users in your organization. Your data will be completely inaccessible to your competitors.`}
              </Card.Text>
              <Card.Text className="mb-2">
                <a href="http://B4tportal.com">{`B4tportal.com`}</a>{` provides each user with a unique user name and password that must be entered each time a user logs on. `}<a href="http://B4tportal.com">{`B4tportal.com`}</a>{` issues a session "cookie" only to record encrypted authentication information for the duration of a specific session. The session "cookie" does not include the password of the user. B4tportal.com does not use "cookies" to store other confidential user and session information, but instead implements more advanced security methods based on dynamic data and encoded session IDs.`}
              </Card.Text>
              <Card.Text>
                {`In addition, `}<a href="http://B4tportal.com">{`B4tportal.com`}</a>{` is hosted in a world-class secure data center which has full N+1 redundancy. Our network uses firewalls, intrusion prevention systems, and other advanced technology to prevent interference or access from outside intruders. All hardware and systems are continuously tested to ensure maximum data security, performance, and reliability. Full database backups are performed several times per day, and backup sets are stored in a remote location. Our data center is monitored and staffed 24x7x365.`}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default SecurityStatementPage;
