import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Colors } from 'assets/index';
import styled from 'styled-components';
import ApplicationStatus from "./components/ApplicationStatus";
import SupportInfo from "./components/SupportInfo";
import {STATUSES} from "../PaymentsPanel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default () => {
    return (
        <>
            <StyledH5 className="mt-3">{`Congratulations, you've been approved for Bill4Time Payments!`}</StyledH5>
            <ApplicationStatus status={STATUSES.VERIFIED} />
            <p><strong>You now have:</strong></p>
            <StyledList className="mb-4">
                <li className="mb-2">
                    <FontAwesomeIcon
                    className='mr-3'
                    icon={faCheck}
                    style={{ color: Colors.blueButton, }}
                    />{`The ability to accept payments to trust & operating accounts`}
                </li>
                <li className="mb-2">
                    <FontAwesomeIcon
                    className='mr-3'
                    icon={faCheck}
                    style={{ color: Colors.blueButton, }}
                    />{`100% compliant with ABA and IOLTA`}
                </li>
                <li className="mb-2">
                    <FontAwesomeIcon
                    className='mr-3'
                    icon={faCheck}
                    style={{ color: Colors.blueButton, }}
                    />{`Industry-low pricing and no monthly fees`}
                </li>
                <li className="mb-2">
                    <FontAwesomeIcon
                    className='mr-3'
                    icon={faCheck}
                    style={{ color: Colors.blueButton, }}
                    />{`All-in-one functionality, no 3rd party integration`}
                </li>
            </StyledList>
            <SupportInfo />
        </>
    )
}

const StyledList = styled.ul`
  padding: 5px;
  margin-bottom: 0px;
  list-style: none;
`;

const StyledH5 = styled.h5`
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
`;
