// @flow
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import SettingButton from './SettingsButton';

type Props = {
  className?: string;
  isSubmitting: boolean;
  text: string;
  dirty?: boolean;
};

const FormSubmit = ({ isSubmitting, className = '', text, dirty, }: Props) => {
  return (
    <div className={`d-flex flex-row align-items-center justify-content-end ${className}`}>
      {
        isSubmitting && (
          <Spinner animation="border" className="mr-2" role="status" />
        )
      }
      <SettingButton
        disabled={ dirty === false || isSubmitting}
        type="submit"
      >
        {text}
      </SettingButton>
    </div>
  );
};

export default FormSubmit;
