// @flow
import React, { useCallback, useState, } from 'react';
import Select, { type Props as RSProps, components, } from './src';

import { Colors, } from 'assets/index';
import { type DropDownOption, } from './types';

type Props = {
  className: string,
  customSelectStyles?: Object,
  disable: boolean,
  placeholder: string,
  value: any,
  onChange: Function,
  options: Array<DropDownOption>,
  width?: number,
  isSearchable?: boolean,
  fullWidth?: boolean,
  getDropDownLabel?: (v: any) => string,
} & RSProps

const selectStyles = {
  container: (provided) => ({
    ...provided,
    position: 'relative',
  }),
  control: provided => ({
    ...provided,
    margin: 8,
    border: `1px solid ${Colors.greyEmpress} !important`,
    backgroundColor: 'white',
    color: Colors.greyEmpress,
    borderColor: Colors.lightGrey,
    height: '28px',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: 'white',
    borderTop: 1,
    borderTopStyle: 'solid',
    borderColor: Colors.lightGrey,
    marginTop: '4px',
    position: 'absolute',
  }),
};

const selectHideInputStyles = {
  menu: provided => ({
    ...provided,
    backgroundColor: 'white',
    borderTop: 1,
    borderTopStyle: 'solid',
    borderColor: Colors.lightGrey,
  }),
};

const DropdownIndicator = () => (<span className="pr-2">{' ▾'}</span>);

const Dropdown = ({
  className, customSelectStyles,
  disable, placeholder = '',
  value, onChange,
  width = 190, fullWidth, isSearchable,
  getDropDownLabel, ...restProps
}: Props) => {
  const [ isOpen, setIsOpen, ] = useState(null);

  const onToggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [ isOpen, ]
  );

  const onSelectChange = useCallback((item) => {
    onToggleOpen();
    onChange && onChange(item);
  }, [ onToggleOpen, onChange, ]
  );

  // handle select style with isSearchable
  const selectStyle = {
    ...(isSearchable ? selectStyles : selectHideInputStyles),
    ...customSelectStyles,
    control: provided => ({
      ...provided,
      width: fullWidth ? undefined : width,
    }),
    option: provided => ({
      ...provided,
      wordBreak: 'break-word',
    }),
  };

  const SingleValue = props => {
    return (
      <components.SingleValue {...props}>
        <p className="text-dark text-left m-0 overflow-hidden text-truncate">
          {getDropDownLabel ? getDropDownLabel(value) : value.label}
        </p>
      </components.SingleValue>
    );
  };

  return (
    <Select
      autoFocus
      className={className}
      components={{ DropdownIndicator, IndicatorSeparator: null, SingleValue, }}
      isDisabled={disable}
      isSearchable={isSearchable}
      placeholder={placeholder}
      styles={selectStyle}
      value={value}
      onChange={onSelectChange}
      {...restProps}
    />
  );
};

export default Dropdown;
