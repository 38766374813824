/* @flow */
import { ApiResponse, ConduitApiService, } from 'infra/conduit/ApiService';
import { StatementRepository } from 'domain/statement';

type Dependencies = {
    conduitApiService: ConduitApiService
};

export default ({ conduitApiService }: Dependencies): StatementRepository => ({
    async getStatementLink(statementId, clientId, { currentUserToken }) {
        const response: ApiResponse = await conduitApiService.authGet({
            url: `statements/${statementId}/pdfFileLink`,
            userToken: currentUserToken
        });

        if (response.error) { throw response.error; }
        return response.success;
    }
});