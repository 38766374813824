/* @flow */
import { Helmet, } from 'react-helmet';
import React from 'react';

type Props = {
  title?: ?string
};

const Head = ({ title, }: Props) => (
  <Helmet>
    <title>
      {
        title
          ? `${title} — Bill4Time`
          : 'Bill4Time'
      }
    </title>
  </Helmet>
);

export default Head;
