
/** Gets a PDF from a source (url) and converts its contents to a base64 string */
export const getPdfAsBase64 = async (pdfSource: string) => {
    try {
        const response = await fetch(pdfSource);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        // TODO: Do something with the error?
    }
}