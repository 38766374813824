/* eslint-disable no-console */
const getDefaultConfig = () => ({
  name: 'some-store-name', // Used as DB name in IDB or WebSQL, and prefix in LocalStorage
  size: 4980736, // 4.75 MB, WebSQL only
});

export default class Storage {
  constructor(storage, config) {
    this.storage = storage;
    this.config(config);
  }

  setItem(...args) {
    try {
      return this.storage.setItem(...args);
    } catch (error) {
      console.error('setItem - Executing on SSR', { error, });
    }
  }

  getItem(...args) {
    try {
      return this.storage.getItem(...args);
    } catch (error) {
      console.error('getItem - Executing on SSR', { error, });
    }
  }

  removeItem(...args) {
    try {
      return this.storage.removeItem(...args);
    } catch (error) {
      console.error('removeItem - Executing on SSR', { error, });
    }
  }

  length() {
    try {
      return this.storage.length();
    } catch (error) {
      console.error('length - Executing on SSR', { error, });
    }
  }

  async config({ ...restConfig }) {
    const { driver, ...localForageConfig } = {
      ...getDefaultConfig(),
      ...restConfig,
    };

    this.storage.config(localForageConfig);

    if (driver !== undefined) {
      try {
        await this.storage.ready();
        this.storage.setDriver(driver);
      } catch (error) {
        console.error('Storage on SSR Mode', { error, });
      }
    }
  }

  keys() {
    try {
      return this.storage.keys();
    } catch (error) {
      console.error('Keys - Executing on SSR', { error, });
    }
  }

  clean() {
    try {
      return this.storage.clean();
    } catch (error) {
      console.error('Clean - Executing on SSR', { error, });
    }
  }
}
