/* @flow */
import { createActions, } from 'reduxsauce';
import { reducerPrefixFormat, } from '../common';

/* ------------- Retainer ------------- */
export const { Types, Creators, } = createActions({
    getRetainers: [ 'payload', 'meta', ],
    getRetainersSuccess: [ 'payload', 'meta', ],

    getRetainerToken: [ 'payload', 'meta', ],
    getRetainerTokenSuccess: [ 'payload', 'meta', ],

    getRetainerRequestAmount: [ 'payload', 'meta', ],
    getSingleRetainerSuccess: [ 'payload', 'meta', ],

    requestFailure: [ 'error', 'payload' /* error payload */, 'meta', ],
}, {
    prefix: reducerPrefixFormat('retainer'),
});