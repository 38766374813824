// @flow
import React, { useCallback, } from 'react';

import { Field } from 'formik';
// TODO: Refactor this import below so it can be used both for Invoices & Retainers
import PaymentField from 'view/InvoicePayment/components/field/PaymentField';
import { retainerPaymentValidation } from 'domain/retainer';

type Props = {
    className?: string,
    totalRetainerPayments: number
};

const RetainerTotalPaymentField = ({
    totalRetainerPayments, ...restProps
}: Props) => {
    const totalPaymentValidation = useCallback((value: number) => {
        let error;
        const isTotalPaymentInvalid = retainerPaymentValidation(value, totalRetainerPayments);
        if (isTotalPaymentInvalid) {
            error = 'Total payment must be greater than or equal to total requested amount.';
        }
        return error;
    }, [ totalRetainerPayments ]);

    return (
        <Field
            required
            component={PaymentField}
            name={`total`}
            placeholder={'Total payment'}
            style={{
                textAlign: 'right',
            }}
            validate={totalPaymentValidation}
            {...restProps}
            readOnly
        />
    );
};

export default RetainerTotalPaymentField;