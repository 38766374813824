// @flow
import * as R from 'ramda';

import { Tab, } from 'react-bootstrap';
import { type ClientPortalSettings, PAYMENT_OPTIONS, } from 'domain/firm';
import React, { useCallback, useMemo, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import CurrentTab from './components/CurrentTab';
import CustomTab from './components/CustomTab';
import { ERRORS, MESSAGES, } from 'constants/index';
import LawPayTab from './components/LawpayTab';
import PayPalTab from './components/PayPalTab';
import { PaymentActionCreators, UserActionCreators, } from 'state/actions';
import StripeTab from './components/StripeTab';
import ModalDisconnect from '../ModalDisconnect';
import { UserRedux, } from 'state/reducers';
import { getStripeAuthUrl, type ConnectCustomPaymentBody, } from 'domain/payment';
import { openPopupWindow, } from 'utilities/generalUtils';
import { httpString, } from 'utilities/stringUtils';
import { useLocation, } from 'react-router-dom';
import { triggerParentLawpayRegistration as connectLawpay, } from 'iframeMessage';
import { type FormikActions, } from 'formik';

type Props = {
  tabKey?: string,
  setErrors: Function,
  setLoading: Function,
  setMessage: Function,
  onChangePaymentOption: Function,
};

const useConnect = () => {
  // mapState
  const portalSettings: ClientPortalSettings = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getPortalSettings));

  const mapState = {
    portalSettings,
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    connectCustomPayment: (payload: ConnectCustomPaymentBody) => dispatch(PaymentActionCreators.connectCustomPayment(payload, { thunk: true, })),
    disconnectCustomPayment: () => dispatch(PaymentActionCreators.disconnectCustomPayment(undefined, { thunk: true, })),
    disconnectPaypal: () => dispatch(PaymentActionCreators.disconnectPaypal(undefined, { thunk: true, })),
    disconnectLawpay: () => dispatch(PaymentActionCreators.disconnectLawpay(undefined, { thunk: true, })),
    disconnectStripe: () => dispatch(PaymentActionCreators.disconnectStripe(undefined, { thunk: true, })),
    getFirm: () => dispatch(UserActionCreators.getFirm()),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

const PaymentTabs = ({ 
  tabKey, setErrors, setLoading, setMessage,
  onChangePaymentOption
}: Props) => {
  // Get redux state
  const { 
    portalSettings,
    connectCustomPayment,
    disconnectCustomPayment,
    disconnectPaypal,
    disconnectLawpay,
    disconnectStripe,
    getFirm,
  } = useConnect();

  // react router location object
  const location = useLocation();

  const [ showConfirmModal, setShowConfirmModal, ] = useState(false);
  // selected payment portal to stop
  const [ portal, setPortal, ] = useState(null);
  // Show description
  const [ description, setDescription, ] = useState(MESSAGES.setting.remove);
  // Key portal to re-connect after disconnect current payment
  const [ reconnectPortal, setReconnectPortal, ] = useState(undefined);
  // values and actions for re-connect custom payment
  const [ customPaymentFormik, setCustomPaymentFormik, ] = useState(undefined);

  const onModalCancel = useCallback<() => void>(() => {
    setShowConfirmModal(false);
  }, []);


  // stop payment callback to pass to payment tab
  const onStopPaymentClick = useCallback<(name: string, message: string, reconnect: string, customFormik: object, ) => void>(
    (name, message = MESSAGES.setting.remove, reconnect = undefined, customFormik = undefined) => {
      setPortal(name);
      setShowConfirmModal(true);
      setReconnectPortal(reconnect);
      setCustomPaymentFormik(customFormik);
      if (message) {
        setDescription(message);
      }
    }, 
  []);

  /*
   * Handle connection
   */
  // add current location to stripe auth url redirect_uri
  const stripeAuthUrl = useMemo(() => {
    return getStripeAuthUrl();
  }, [ location.pathname, ]);

  // Open window stripe popup
  const connectStripe = useCallback(() => {
    const popup = openPopupWindow(stripeAuthUrl, 'stripe-connect');

    if (!popup) {return;}
    // polling for popup window close
    const timer = setInterval(() => { 
      if(popup.closed) {
        clearInterval(timer);
        // update firm data
        getFirm();
      }
    }, 1000);

  }, [ stripeAuthUrl, getFirm, ]);

  // Handle connect custom payment
  const connectCustom = useCallback(
    async ({values, actions} : {values: ConnectCustomPaymentBody, actions: FormikActions,}) => {
      try {
        actions.setSubmitting(true);
        const data : ConnectCustomPaymentBody = {
          ...values,
          processorUrl: httpString(values.processorUrl),
        };
        const message : string = await connectCustomPayment(data);

        // change to current setting tab
        onChangePaymentOption(PAYMENT_OPTIONS.currentSettings);
        setMessage({ success: message, });
      } catch (e) {
        setErrors({
          auth: ERRORS.disconnect_custom_payment,
        });
      } finally {
        actions.setSubmitting(false);
      }
    }, [ connectCustomPayment, setMessage, setErrors, onChangePaymentOption, ]
  );

  // handle disconnect
  const onDisconnectCP = useCallback(async () => {
    try {
      setLoading(true);

      let message : string;

      switch(portal) {
        case PAYMENT_OPTIONS.paypal.key:
          message = await disconnectPaypal();
          break;
        
        case PAYMENT_OPTIONS.custom.key:
          message = await disconnectCustomPayment();
          break;

        case PAYMENT_OPTIONS.lawPay.key:
          message = await disconnectLawpay();
          break;

        case PAYMENT_OPTIONS.stripe.key:
          message = await disconnectStripe();
          break;
        
        default: 
          break;
      }
      
      // Run reconnect after disconnect if reconnect is not undefined
      if (reconnectPortal) {
        if(reconnectPortal === PAYMENT_OPTIONS.stripe.key) {
          connectStripe();
        }
        if(reconnectPortal === PAYMENT_OPTIONS.lawPay.key) {
          connectLawpay();
        }
        if(reconnectPortal === PAYMENT_OPTIONS.custom.key && customPaymentFormik) {
          connectCustom(customPaymentFormik);
        }
      } else {
        setMessage({ success: message, });
      }      
    } catch (e) {
      setErrors({
        auth: ERRORS.disconnect_custom_payment,
      });
    } finally {
      setLoading(false);
    }
  }, [ setLoading, portal, reconnectPortal, disconnectPaypal, disconnectCustomPayment, disconnectLawpay, disconnectStripe, customPaymentFormik, connectStripe, connectCustom, setMessage, setErrors, ]
  );

  const onRemovePayment = useCallback(() => {
    // TODO: implement remove payment
    onDisconnectCP();
  }, [ onDisconnectCP, ]);

  return (
    <Tab.Container activeKey={tabKey} defaultActiveKey={PAYMENT_OPTIONS.currentSettings.key} id="controlled-tab-example">
      <Tab.Content>
        <Tab.Pane eventKey={PAYMENT_OPTIONS.currentSettings.key}>
          <CurrentTab 
            portalSettings={portalSettings}
            setErrors={setErrors}
            setLoading={setLoading}
            setMessage={setMessage}
            onStopPaymentClick={onStopPaymentClick}
          />
        </Tab.Pane>

        <Tab.Pane eventKey={PAYMENT_OPTIONS.stripe.key}>
          <StripeTab
            portalSettings={portalSettings}
            setErrors={setErrors}
            setMessage={setMessage}
            onConnect={connectStripe}
            onStopPaymentClick={onStopPaymentClick}
          />
        </Tab.Pane>

        <Tab.Pane eventKey={PAYMENT_OPTIONS.lawPay.key}>
          <LawPayTab
            portalSettings={portalSettings}
            setErrors={setErrors}
            setMessage={setMessage}
            onConnect={connectLawpay}
            onStopPaymentClick={onStopPaymentClick}
          />
        </Tab.Pane>

        <Tab.Pane eventKey={PAYMENT_OPTIONS.custom.key}>
          <CustomTab 
            portalSettings={portalSettings}
            setErrors={setErrors}
            setMessage={setMessage}
            onConnect={connectCustom}
            onStopPaymentClick={onStopPaymentClick}
          />
        </Tab.Pane>

        <Tab.Pane eventKey={PAYMENT_OPTIONS.paypal.key}>
          <PayPalTab 
            portalSettings={portalSettings}
            setErrors={setErrors}
            setMessage={setMessage}
            onStopPaymentClick={onStopPaymentClick}
          />
        </Tab.Pane>
        <ModalDisconnect
          description={description}
          show={showConfirmModal}
          onHide={onModalCancel}
          onSubmit={onRemovePayment}
        />
      </Tab.Content>
    </Tab.Container>
  );
};

export default PaymentTabs;
