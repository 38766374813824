/* @flow */
import { type FirmRepository, } from 'domain/firm';
import { type PaymentProcessorType } from 'domain/payment';

import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: () => void,
  onError: (Error) => void,
};

type Dependencies = {
  firmRepository: FirmRepository,
};

export type GetDisconnectedProcessorsBehaviour = (
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export default ( { firmRepository, }: Dependencies, ) => {
  /**
   * get disconnected processor list
   * 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getDisconnectedProcessorsBehaviour: GetDisconnectedProcessorsBehaviour = async (
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      const disconnectedProcessors: PaymentProcessorType[] = await firmRepository.getDisconnectedProcessors(withCurrentUserToken);
      
      return onSuccess(disconnectedProcessors);
    } catch(error) {
      return onError(error);
    }
  };

  return getDisconnectedProcessorsBehaviour;
};
