/* @flow */
import { type Firm, type FirmRepository, } from 'domain/firm';

import { type ConnectLawpayBody, } from 'infra/payment/PaymentRepository'
import { type ConnectPayload, } from 'state/payment/types';
import { type PaymentRepository, } from 'domain/payment';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (data: ConnectPayload) => void,
  onError: (e: Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
  firmRepository: FirmRepository,
};

export type ConnectLawpayBehaviour = (
  payload: ConnectLawpayBody,
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export const makeConnectLawpay = ( { paymentRepository, firmRepository, }: Dependencies, ) => {
  /**
   * Connect lawpay to account
   * 
   * @param {string} code - lawpay auth code
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const connectLawpayBehaviour: ConnectLawpayBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      const message : string = await paymentRepository.connectLawpay(payload, withCurrentUserToken);
      const firm : Firm = await firmRepository.getFirm(withCurrentUserToken);

      const data : ConnectPayload = {
        message,
        firm,
      };

      return onSuccess(data);
    } catch(error) {
      return onError(error);
    }
  };

  return connectLawpayBehaviour;
};

export type DisconnectLawpayBehaviour = (
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export const makeDisconnectLawpay = ( { paymentRepository, firmRepository, }: Dependencies, ) => {
    /**
     * Disconnect lawpay in account
     * 
     * @param {WithCurrentUserToken} withCurrentUserToken - auth token
     * @param {Callbacks} callbacks - call back for each use case
     */
    const disconnectLawpayBehaviour: DisconnectLawpayBehaviour = async (
      withCurrentUserToken,
      { onSuccess, onError, },
    ) => {
      try {
        const message : string = await paymentRepository.disconnectLawpay(withCurrentUserToken);
        const firm : Firm = await firmRepository.getFirm(withCurrentUserToken);

        const data : ConnectPayload = {
          message,
          firm,
        };

        return onSuccess(data);
      } catch(error) {
        return onError(error);
      }
    };
  
    return disconnectLawpayBehaviour;
  };
