// @flow
import { Button, Col, Image, Row, } from 'react-bootstrap';
import { type ClientPortalSettings, PAYMENT_OPTIONS, } from 'domain/firm';
import { Colors, Images, } from 'assets/index';
import React, { useCallback, } from 'react';

import styled from 'styled-components';
import { MESSAGES, } from 'constants/index';

const StyledPaymentButton = styled(Button)`
  background-color: ${Colors.blueButton} !important;
  border: none !important;

  :hover {
    background-color: ${Colors.blueButtonHover} !important;
  }
`;

const StyledRegisterLink = styled.a`
  color: ${Colors.blueCurious};
  cursor: pointer;
  :hover {
    color: ${Colors.blueAstral};
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
`;

type Props = {
  setErrors: Function,
  setMessage: Function,
  portalSettings: ClientPortalSettings,
  onStopPaymentClick: Function,
  onConnect: Function,
};

const LawPayTab = ({ portalSettings, onStopPaymentClick, onConnect, }: Props) => {
  // Open disconnect modal
  const onShowDisconnectModal = useCallback(() => {
    onStopPaymentClick(PAYMENT_OPTIONS.lawPay.key);
  }, [ onStopPaymentClick, ]);

  // trigger lawpay registration window
  const onConnectLawpay = useCallback(() => {
    // Check if stripe or custom payment integrated
    if(portalSettings.isStripe) {
      const message = MESSAGES.setting.removeCurrentPayment(PAYMENT_OPTIONS.stripe.label);
      onStopPaymentClick(PAYMENT_OPTIONS.stripe.key, message, PAYMENT_OPTIONS.lawPay.key);
      return;
    }
    if(portalSettings.isCustom) {
      const message = MESSAGES.setting.removeCurrentPayment(PAYMENT_OPTIONS.custom.label);
      onStopPaymentClick(PAYMENT_OPTIONS.custom.key, message, PAYMENT_OPTIONS.lawPay.key);
      return;
    }
    onConnect();
  }, [ portalSettings.isStripe, portalSettings.isCustom, onConnect, onStopPaymentClick, ]);

  return (
    <div>
      {
        portalSettings && portalSettings.isLawpay
          ? (
            <StyledPaymentButton
              className="font-weight-bold mb-1"
              onClick={onShowDisconnectModal}
            >
              {`Disconnect from Lawpay`}
            </StyledPaymentButton>
          )
          : (
            <React.Fragment>
              <Row>
                <Col xs="5">
                  <p>{`Connect with LawPay to Accept Credit Card and Trust Payments from Your Clients`}</p>
                  <StyledRegisterLink onClick={onConnectLawpay}>
                    <StyledImage src={Images.payment.lawpay.button} />
                  </StyledRegisterLink>
                </Col>
              </Row>
            </React.Fragment>
          )
      }
    </div>
  );
};

export default LawPayTab;
