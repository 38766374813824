/* @flow */
import React from 'react';
import { Redirect, Switch, useRouteMatch, } from 'react-router-dom';

import AccountSettingsPage from './AccountSettings/AccountSettingsVM';
import { Colors, } from 'assets/index';
import Container from 'react-bootstrap/Container';
import InvitesListPage from './InvitesList/InvitesListVM';
import InvitesPage from './Invites/InvitesVM';
import InvitesView from './Invites/InvitesView';
import Nav from 'react-bootstrap/Nav';
import { NavLink, } from 'react-router-dom';
import { TokenPrivateRouteWithoutLayout, } from '../auth/controlledRoute';
import UserPermissionsPage from './UserPermissions/UserPermissionsVM';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const StyledContainerFluid = styled.div`
  background-color: ${Colors.white};
  display: flex;
  flex: 1;
`;

const StyledSetting = styled(Container)`
  flex: 1;

  // remove color to use as an Iframe
  .card {
    background-color: transparent;
    border: none;
  }

  // remove color to use as an Iframe
  .card-header {
    background-color: transparent;
  }
`;

const StlyedNav = styled(Nav)`
  font-size: .875rem;

  .nav-pills {
    margin-left: .5rem;
  }

  .nav-link {
    display: inline;
    color: black;
    padding: .25rem .5rem;
  }

  .active {
    color: ${Colors.blueNavy} !important;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${Colors.charcoal};
  font-size: 1rem;

  :hover {
    color: ${Colors.blueNavy};
    text-decoration: none;
  }

  :active {
    color: ${Colors.blueNavy} !important;
  }
`;

type Props = {
};

const SHOW_LEGACY_KEY = "showLegacy";


const SettingsPage = (props: Props) => {
  // react router current path info
  const { path, url, } = useRouteMatch();

  const location = useLocation();
  const showLegacyPage = location.search.indexOf(`${SHOW_LEGACY_KEY}=True`) !== -1;

  return (
    <StyledContainerFluid>
      <StyledSetting className="py-3">
        <StlyedNav className="flex-row pb-5">
          <Nav.Item>
            <StyledNavLink className="font-weight-bold pr-2" to={`${url}/account-settings`}>{showLegacyPage ? `Account Settings` : `Payments`}</StyledNavLink>
          </Nav.Item>
          <Nav.Item>
            <StyledNavLink className="font-weight-bold px-2" to={`${url}/permissions`}>{`User Permissions`}</StyledNavLink>
          </Nav.Item>
          <Nav.Item>
            <StyledNavLink className="font-weight-bold px-2" to={`${url}/invites`}>{`Invites`}</StyledNavLink>
          </Nav.Item>
        </StlyedNav>

        <Switch>
          <TokenPrivateRouteWithoutLayout exact component={AccountSettingsPage} path={`${path}/account-settings`} />
          <TokenPrivateRouteWithoutLayout exact component={UserPermissionsPage} path={`${path}/permissions`} />
          <TokenPrivateRouteWithoutLayout exact component={InvitesView} path={`${path}/invites`} />
          <TokenPrivateRouteWithoutLayout exact component={InvitesListPage} path={`${path}/invites/list`} />

          <Redirect to={`${path}/account-settings`} />
        </Switch>
      </StyledSetting>
    </StyledContainerFluid>
  );
};

export default SettingsPage;
