import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import { Images } from 'assets/index';
import { LINK2PAY_ACCOUNT_CLASS } from 'domain/payment';
import { useHistory, useLocation, } from "react-router-dom";
import { APP_CONFIG, API_CONFIG, } from 'constants/index';
import { loadScript, } from 'utilities/dynamicLoading';
import { Alert, Spinner, Button } from 'react-bootstrap';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faCheck, faLock, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Colors } from 'assets/index';
import styled from 'styled-components';

const plaidScriptUrl = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
const { PLAID } = API_CONFIG;
const plaidConfig = {
    env: PLAID.ENV,
    key: PLAID.KEY,
    apiVersion: "v2",
    clientName: "Bill4Time",
    products: ["auth"]
};

const StyledErrorAlert = styled(Alert)`
    background: transparent;
    border-color: transparent;
    color: red;
    padding: 0px;
    margin-top: 20px;
`;

const ErrorMsg = ({ error }) => <> {error.msg && <StyledErrorAlert variant='danger'><FontAwesomeIcon icon={faExclamationCircle} /> {error.msg}</StyledErrorAlert>} </>;

const Link2PayECheckPlaidV = ({ error, subtitle, isPlaidConnectionFailed }) => {
    const [isLoaded, scriptLoaded] = useState(false);
    const [plaid, setPlaid] = useState(null);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        (async () => {
            const isLoaded = await loadScript(plaidScriptUrl);
            scriptLoaded(isLoaded);
        })();
    }, []);

    useEffect(() => {
        if (isLoaded) {
            const plaidObject = window.Plaid.create({
                env: plaidConfig.env,
                clientName: plaidConfig.clientName,
                key: plaidConfig.key,
                product: plaidConfig.products,
                apiVersion: plaidConfig.apiVersion,
                onSuccess: onPlaidSuccess,
            });
            setPlaid(plaidObject);
        };
    }, [isLoaded]);

    const onPlaidSuccess = (publicToken, metadata) => {
        const capitalize = (str) => {
            if (typeof str !== 'string') return '';
            return str.charAt(0).toUpperCase() + str.slice(1);
        }

        const locationState = location.state;
        const accountType = capitalize(metadata.account.subtype)
        const accountName = `${accountType} Account x${metadata.account.mask}`;

        locationState.plaidAccountDetails = {
            publicToken: publicToken,
            accountId: metadata.account.id,
            accountName
        };
        history.replace(location.pathname, locationState);
    }

    const openPlaid = () => {
        plaid.open();
    }

    const PlaidSection = () => {
        const isConnected = !!location.state?.plaidAccountDetails;
        return <>
            <Grid container item spacing={2}>
                <Grid container item xs={12} justifyContent="center">
                    <div style={{ lineHeight: '24px', letterSpacing: '0.15px' }}>
                        In order to make an instant eCheck payment directly from your bank account, please securely login to your bank account below. If your bank isn't found in the login list, please change the payment method at the left to "eCheck via Account & Routing Numbers" to pay with the desired bank.
                    </div>
                </Grid>
            </Grid>
            {isConnected ? <PlaidConnectedSection /> : <PlaidLoginSection /> } </>;
    }

    const PlaidConnectedSection = () => {
        return (<>
            <div style={{ margin: '100px 100px' }}>
                <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: '#57b179', float: 'left', marginRight: '10px', marginTop: '3px' }}
                />
                <div style={{ fontSize: '15px', color: '#57b179' }}>
                    <strong>BANK CONNECTED</strong>
                </div>
                <Button variant="link" style={{ marginLeft: '10px', fontWeight: 'bold' }}
                    onClick={openPlaid}> Use a different bank
                </Button>
            </div>
        </>);
    }
    

    const PlaidLoginSection = () => {
        return (
            <>
                {
                    !plaid ? <Spinner animation="border" />
                        : <>
                            <Grid container item spacing={2}>
                                <Grid container item xs={12} justifyContent="center" style={{ marginBottom: '20px' }}>
                                    <div style={{ margin: '20px' }}>
                                        <Button
                                            size="lg"
                                            variant="primary"
                                            onClick={openPlaid}
                                        >
                                            <FontAwesomeIcon
                                                className='inside-button'
                                                icon={faLock}
                                            />
                                            <span style={{ margin: '10px' }}>Bank Login</span>
                                        </Button>
                                        {isPlaidConnectionFailed && <div style={{ color: 'red' }}>
                                            Please click here to securely log in to your bank and make your payment.
                                        </div>}
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                }

            </>
        );
    }

    return (
        <>
            <div className={subtitle}>eCheck Payment via Secure Bank Login</div><br />
            <ErrorMsg error={error} />
            {PlaidSection()}
        </>
    );

};

export default React.memo(Link2PayECheckPlaidV);