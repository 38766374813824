/* @flow */
import { type Firm, type FirmRepository, } from 'domain/firm';

import { type FirmUpdateUserRequestBody, } from 'infra/firm/FirmRepository';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: () => void,
  onInvalidPayload: (e: Error) => void,
  onError: (e: Error) => void,
};

type Dependencies = {
  firmRepository: FirmRepository,
};

type Payload = {
  data: FirmUpdateUserRequestBody,
};

export type UpdateFirmUserBehaviour = (
  payload: Payload,
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export default ( { firmRepository, }: Dependencies, ) => {
  /**
   * update firm settings (except payment processors)
   * 
   * @param {Payload} payload - stripe bank account token
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const updateFirmUserBehaviour: UpdateFirmUserBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { data, } = payload;
      if (!data) {
        return onInvalidPayload(new Error('Invalid user info.'));
      };

      // update firm user
      await firmRepository.updateFirmUser(data, withCurrentUserToken);

      // get updated firm
      // const updatedUser = await firmRepository.getFirm(withCurrentUserToken);
      
      return onSuccess();
    } catch(error) {
      return onError(error);
    }
  };

  return updateFirmUserBehaviour;
};
