// @flow
import * as R from 'ramda';

import { type CellProps, useTable, } from 'react-table';
import { type Firm, convertSystemDateFormat, } from 'domain/firm';
import React, { useMemo, } from 'react';

import { type FieldArrayRenderProps, } from 'formik';
import { type Invoice, } from 'domain/invoice';
import InvoicePaymentField from './InvoicePaymentField';
import InvoiceTotalPaymentField from './InvoiceTotalPaymentField';
import InvoicePaymentAdditionalField from './InvoicePaymentAdditionalField';
import { type InvoiceUserPayment, } from 'domain/invoice';
import { Link, } from 'react-router-dom';
import RemoveInvoiceBtn from './RemoveInvoiceButton';
import Table from 'react-bootstrap/Table';
import { type User, } from 'domain/user';
import { UserRedux, } from 'state/reducers';
import { currencyFormat, getAdjacentUrlParams, } from 'utilities/stringUtils';
import { isLastRow, } from 'utilities/generalUtils';
import styled from 'styled-components';
import { useSelector, } from 'react-redux';
import { Colors, } from 'assets/index';
import { APP_CONFIG, } from 'constants/index';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip, } from 'react-bootstrap';

const trackMixpanelEventInvoice = () => { window.mixpanel.track("b4tp_client_portal_pay_now_invoice_number"); }

const StyledCell = styled.td`
  vertical-align: top !important;
  a {
    color: ${Colors.blueCurious}
  }
`;

const InvoicePaymentArrayDynamicField = ({
  remove,
  form: { values, setFieldValue, },
}: FieldArrayRenderProps) => {
  // get firm and user from redux for currency format
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const user: User = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));

  /**
  * reshape table data whenever formik values invoices update
  * calculate total invoice payment
  */
  const {
    invoiceTotal,
    tableData,
  } = useMemo(() => {
    // calculate total = sum all invoice user payment
    const total = !values.invoices
      ? 0 // 0 if no invoices or all invoices removed
      : values.invoices.reduce((prev: number, curr: InvoiceUserPayment) => {
        return prev + curr.userPayment;
      }, 0);

    // reshape table data whenever formik values udpate
    // add total to end of rows to put it inside react table
    const tableData = [
      ...values.invoices || [], // empty array in case values.invoices = undefined | null
      {
        totalRow: true,
      },
    ];

    setFieldValue('total', total);

    return {
      invoiceTotal: total,
      tableData,
    };
  }, [ values.invoices, setFieldValue, ]);
  
  const COLUMNS = useMemo(() => {
    return (
      [
        {
          Header: 'Invoice Date',
          accessor: 'createdDate',
          Cell: ({ cell: { value, }, }) => {
            if (!value) {return null;}
            const systemDateFormat = user && user.systemDateFormat ? user.systemDateFormat : null;
            return <div className="pt-2">{convertSystemDateFormat(value, systemDateFormat)}</div>;
          },
        },
        {
          Header: 'Invoice',
          accessor: 'id',
          Cell: ({ row: { original, }, cell: { value, }, }) => {
            // link to specific invoice page
            return (
              <div className="pt-2">
                <Link to={`/invoice/${value}${getAdjacentUrlParams(original.clientId)}${getAdjacentUrlParams(original.projectId)}`} onClick={trackMixpanelEventInvoice}>
                  {original.invoiceLabel}
                </Link>
              </div>
            );
          },
        },
        {
          Header: 'Description',
          accessor: 'description',
          Cell: ({ row: { index, }, cell: { value, row, }, }: CellProps<Invoice>) => {
            const history = R.path([ 'original', ], row);

            // don't render if last total row
            if (isLastRow(index, tableData.length)  || history.invoiceBalance === APP_CONFIG.paymentCart.additionalPayment) {
              return null;
            };

            return <div className="pt-2">{value || '___'}</div>;
          },
        },
        {
          Header: () => (
            <div className="text-right">
              {`Due`}
            </div>
          ),
          accessor: 'invoiceBalance',
          Cell: ({ row: { index, }, cell: { value, }, }: CellProps<Invoice>) => {
            // Total label in total row
            if (isLastRow(index, tableData.length)) {
              return (
                <>
                </>
              );
            };

            if (value === APP_CONFIG.paymentCart.additionalPayment) {
              return (
                <div className="text-right pt-2 text-nowrap ">
                  <div className="d-inline">
                  {value}
                  </div>
                  {/*<OverlayTrigger
                    overlay={
                      <Tooltip>{`The payment towards account balance is filled in with the total remaining balance, please make any changes in the field to adjust the amount.`}</Tooltip>
                    }
                  >
                    <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
                  </OverlayTrigger>*/}
                </div>
              );
            }

            return (
              <div className="font-weight-bold text-right pt-2">
                {currencyFormat(value, firm)}
              </div>
            );
          },
        },
        {
          Header: () => (
            <div className="text-right" style={{ width: 250, }}>
              {`Payment`}
            </div>
          ),
          accessor: 'userPayment',
          Cell: ({ row: { index, original, }, cell, }: CellProps<Invoice>) => {
            const history = R.path([ 'row', 'original', ], cell);
            // Total payment in total last row
            if (isLastRow(index, tableData.length)) {
              return (
                <>
                </>
              );
            };

            // normal invoice payment
            const invoice = original;

            if (history.invoiceBalance === APP_CONFIG.paymentCart.additionalPayment) {
              return (
                <InvoicePaymentAdditionalField
                  invoice={invoice}
                  rowIndex={index}
                />
              );
            }

            return (
              <InvoicePaymentField
                invoice={invoice}
                rowIndex={index}
              />
            );
          },
        },
        {
          Header: '',
          accessor: 'removeBtn',
          Cell: ({ row: { index, }, cell, }: CellProps<Invoice>) => {
            const history = R.path([ 'row', 'original', ], cell);

            // don't render if last total row
            if (isLastRow(index, tableData.length) || history.invoiceBalance === APP_CONFIG.paymentCart.additionalPayment) {
              return null;
            };

            return (
              <RemoveInvoiceBtn amount={values.invoices.length} index={index} onClick={onClickRemoveBtn}>
                {`Remove`}
              </RemoveInvoiceBtn>
            );
          },
        },
      ]
    );
  }, [ firm, invoiceTotal, remove, tableData.length, user, values.invoices ]);

  const onClickRemoveBtn = (index) => {
    window.mixpanel.track("b4tp_client_portal_pay_now_remove");
    remove(index);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns: COLUMNS,
      data: tableData,
    },
  );

  return (
    <div className='d-flex' style={{ flex: 1, }}>
      <Table borderless responsive {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          { rows.length < 3 && (
            <tr style={{ height: "50px" }}>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
          {
            rows.map((row, i) =>
              prepareRow(row) || (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <StyledCell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </StyledCell>
                    );
                  })}
                </tr>
              ))
          }
        </tbody>
      </Table>
    </div>
  );
};

export default InvoicePaymentArrayDynamicField;
