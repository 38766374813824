/* @flow */
import { type AccountHistory, type AccountHistoryRepository, getCurrentAccountBalance, } from 'domain/accountHistory';
import { ACCT_ISO_TYPE, } from 'domain/client';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (Array<AccountHistory>) => void,
  onInvalidPayload: (Error) => void,
  onError: (Error) => void,
};

type Dependencies = {
  accountHistoryRepository: AccountHistoryRepository,
};

type Payload = {
  clientId: number,
  projectId: ?number,
};

export type GetAccountHistoriesBehaviour = (
  Payload,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { accountHistoryRepository, }: Dependencies, ) => {
  /**
   * get list account histories of a client or project behaviour
   * 
   * @param {Payload} payload - client id and project id
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getAccountHistoriesBehaviour: GetAccountHistoriesBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { clientId, projectId, acctIsoType, } = payload;
      if (!clientId) {
        return onInvalidPayload(new Error('Invalid client and project id.'));
      }

      let accountHistories;

      if ((acctIsoType && acctIsoType === ACCT_ISO_TYPE.client) || (projectId && Number(projectId) === -1)) {
        accountHistories = await accountHistoryRepository.getClientAccountHistories(clientId, withCurrentUserToken);
      } else if (projectId) {
        accountHistories = await accountHistoryRepository.getProjectAccountHistories(clientId, projectId, withCurrentUserToken);
      }

      const lastIndex = accountHistories.length - 1;
      // iterate account histories array to calculate balance for each history item
      // api return oldest history first so we also need to reverse it
      const reversedCalculatedHistories = accountHistories.reduce((prev, curr, i) => {
        let currentBalance: ?number;

        if (i === 0) {
          // if first item ( oldest history ), get current account balance with default previous balance = 0
          currentBalance = getCurrentAccountBalance(curr);
        } else {
          // previous account history will be reduced array's item at opposite index + 1 since we are reversing the original array
          const prevAccountHistory: AccountHistory = prev[lastIndex - i + 1];
          
          // get current account balance from previous balance
          currentBalance = getCurrentAccountBalance(curr, prevAccountHistory.balance);
        }

        // set the history with balance to opposite index
        prev[lastIndex - i] = {
          ...curr,
          balance: currentBalance,
        };

        // return modified array to continue
        return prev;
      }, []);

      return onSuccess(reversedCalculatedHistories);
    } catch(error) {
      return onError(error);
    }
  };

  return getAccountHistoriesBehaviour;
};
