// @flow
import * as R from 'ramda';

import { type ClientPortalSettings, PAYMENT_OPTIONS, } from 'domain/firm';
import { ConnectCustomPaymentSchema, } from 'domain/payment';
import { Field, Formik, type FormikActions, type FormikState, } from 'formik';
import React, { useCallback, useMemo, } from 'react';
import { type ConnectCustomPaymentBody, } from 'domain/payment';

import { Alert, Button, Spinner, } from 'react-bootstrap';
import { Colors, } from 'assets';
import { FormInput, } from 'view/components/index';
import { MESSAGES, } from 'constants/index';

import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${Colors.blueButton} !important;
  border: none !important;

  :hover {
    background-color: ${Colors.blueButtonHover} !important;
  }
`;

type Props = {
  onConnect: Function,
  portalSettings: ClientPortalSettings,
  onStopPaymentClick: Function,
};

const FormEl = ({ isSubmitting, errors, status, handleSubmit, }: FormikState<CustomPaymentInfo>) => (
  <form onSubmit={handleSubmit}>
    {
      status && status.success && (
        <Alert className="font-weight-bold" variant='success'>{status.success}</Alert>
      )
    }
    {
      errors.auth && (
        <Alert variant='danger'>{errors.auth}</Alert>
      )
    }

    <Field
      required
      component={FormInput}
      name={'title'}
      placeholder={'Payment Site Name'}
      type={'text'}
    />

    <Field
      required
      component={FormInput}
      name={'processorUrl'}
      placeholder={'Link To Payment Site'}
      type={'text'}
    />


    <div className='d-flex flex-row align-items-center'>
      <StyledButton className='font-weight-bold' disabled={isSubmitting} type='submit'>{`Submit`}</StyledButton>
      {
        isSubmitting && (
          <Spinner animation="border" role="status" />
        )
      }
    </div>
  </form>
);

const CustomTab = ({ portalSettings, onConnect, onStopPaymentClick, }: Props) => {
  // Change initial Values when submit form.
  const initialFormValues: ConnectCustomPaymentBody = useMemo(() => ({
    title: portalSettings.custom ? portalSettings.custom.title : '',
    processorUrl: portalSettings.custom ? portalSettings.custom.url : '',
  }), [ portalSettings, ]);

  // On connect
  const onSubmit = useCallback((values: ConnectCustomPaymentBody, actions: FormikActions) => {
    // Check if lawpay or stripe integrated
    const customPaymentFormik = {
      values,
      actions,
    };

    if(portalSettings.isLawpay) {
      const message = MESSAGES.setting.removeCurrentPayment(PAYMENT_OPTIONS.lawPay.label);
      onStopPaymentClick(PAYMENT_OPTIONS.lawPay.key, message, PAYMENT_OPTIONS.custom.key, customPaymentFormik);
      return;
    }
    if(portalSettings.isStripe) {
      const message = MESSAGES.setting.removeCurrentPayment(PAYMENT_OPTIONS.stripe.label);
      onStopPaymentClick(PAYMENT_OPTIONS.stripe.key, message, PAYMENT_OPTIONS.custom.key, customPaymentFormik);
      return;
    }
    onConnect(customPaymentFormik);
  }, [ onConnect, onStopPaymentClick, portalSettings.isLawpay, portalSettings.isStripe, ]);

  return (
    <div>
      <p className="m-0">{`Add a Link to Your Own Payment Site`}</p>
      <small className="text-secondary">
        {`Adding a link to your own payment site will override Stripe or LawPay settings.`}
      </small>
      <div className='custom-payment-form mt-1'>
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          render={FormEl}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={ConnectCustomPaymentSchema}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};

export default CustomTab;
