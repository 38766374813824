/* @flow */
import { createActions, } from 'reduxsauce';
import { reducerPrefixFormat, } from '../common';

/* ------------- Project ------------- */
export const { Types, Creators, } = createActions({
  getProjects: [ 'payload', 'meta', ],

  getProjectsSuccess: [ 'payload' /* GetProjectsResponsePayload */, 'meta', ],

  setSelectedProject: [ 'payload', ],

  requestFailure: [ 'error', 'payload' /* error payload */, 'meta', ],
}, {
  prefix: reducerPrefixFormat('Project'),
});
