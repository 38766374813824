import * as R from 'ramda';
import * as reducers from './reducers';
import * as sagas from './sagas';

import { type Store, combineReducers, } from 'redux';
import { type Persistor, } from 'redux-persist';

import React from 'react';
import ReduxProviderFactory from './_provider/ReduxProviderFactory';
import { all, } from 'redux-saga/effects';
import createStore from './createStore';
import { reducer as thunkReducer, } from 'redux-saga-thunk';

/* ------------- Reducers ------------- */
const allReducers = R.pipe(
  R.values,
  R.map(R.prop('reducerMap')),
  R.append({ thunk: thunkReducer, }),
  R.mergeAll
)(reducers);

const rootReducer = combineReducers(allReducers);

/* ------------- Sagas ------------- */
const allSagas = container => (
  R.pipe(
    R.values,
    R.map(s => s(container)), // inject container to each one
    R.flatten
  )(sagas)
);

const rootSaga = function * (container) {
  yield all(allSagas(container));
};

/* ------------- Create Store ------------- */
export const { store, persistor, }: { store: Store, persistor: Persistor } = createStore(rootReducer, rootSaga);
// persistor.purge();

/* ------------- Create Provider ------------- */
export const ReduxProvider = ({ loading, children, }) => (
  <ReduxProviderFactory loading={loading} persistor={persistor} store={store}>
    {children}
  </ReduxProviderFactory>
);
