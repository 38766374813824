/* @flow */
import { type Firm, } from 'domain/firm';
import { type PaymentRepository, } from 'domain/payment';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: () => void,
  onInvalidPayload: (e: Error) => void,
  onError: (e: Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
};

type Payload = {
  token: string,
};

export type AddCreditCardBehaviour = (
  payload: Payload,
  firm: Firm,
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export default ( { paymentRepository, }: Dependencies, ) => {
  /**
   * Attach a card to the user
   * 
   * @param {Payload} payload - stripe bank account token
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const addCreditCardBehaviour: AddCreditCardBehaviour = async (
    payload,
    firm,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { token, } = payload;
      if (!token) {
        return onInvalidPayload(new Error('Invalid token.'));
      };

      // attach credit card to lawpay or stripe based on user settings
      let request;
      if (firm.portalSettings.isStripe) {
        request = paymentRepository.addStripeCard(token, withCurrentUserToken);
      } else if (firm.portalSettings.isLawpay) {
        request = paymentRepository.addLawpayCard(token, withCurrentUserToken);
      } else {
        throw new Error('Invalid payment type.');
      }

      const message = await request;
      
      return onSuccess();
    } catch(error) {
      return onError(error);
    }
  };

  return addCreditCardBehaviour;
};
