import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { getPdfAsBase64 } from '../../utilities/pdfUtils';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

/** PDF Viewer using react-pdf library */
export default (props: IB4TPDFViewerOptions) => {
    const { pdfLocation } = props;
    const [numPages, setNumPages] = useState();
    const [fileData, setFileData] = useState(null);

    useEffect(() => {
        const fetchPDFData = async () => {
            try {
                const data = await getPdfAsBase64(pdfLocation)
                setFileData(data);
            } catch (error) {
                // TODO: Do something with PDF fetch error?
                console.log(error);
            }
        };
        fetchPDFData();
    }, [pdfLocation]);

    const onDocumentLoadSuccess = (totalNumberOfPages) => {
        console.log('PDF Loaded. Number of pages ' + totalNumberOfPages.numPages);
        setNumPages(totalNumberOfPages.numPages);
    };

    const classes = useStyles();
    return (
        <>
            {fileData && (
                <div className={classes.scrollDiv}>
                    <Document
                        options={{
                            // ...
                        }}
                        file={fileData}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={(error) => console.error('PDF Load error: ', error)}
                        className={classes.pdfDocument}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                className={classes.pdfPage}
                                width={1000}
                            />
                        ))}
                    </Document>
                </div>
            )}
        </>
    );
}

const useStyles = makeStyles({
    scrollDiv: {
        margin: 'auto',
        overflowX: 'hidden',
        overflowY: 'scroll',
        height: '1000px'
    },
    pdfDocument: {
        display: 'grid',
        justifyContent: 'center'
    },
    pdfPage: {
        width: '100%',
        height: 'auto',
        border: '1px solid grey',
        borderRadius: '10px',
        overflow: 'hidden',
        marginTop: '5px',
        marginBottom: '5px'
    }
});

export type IB4TPDFViewerOptions = {
    pdfLocation: string;
}