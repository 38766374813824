/* @flow */
import { createActions, } from 'reduxsauce';
import { reducerPrefixFormat, } from '../common';

/* ------------- Error ------------- */
export const { Types, Creators, } = createActions({
  errorsQueueAppend: [ 'error', ],
  currentErrorFinish: null,

  requestFailure: [ 'error', ],
}, {
  prefix: reducerPrefixFormat('Error'),
});
