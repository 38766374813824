/* @flow */
import { type Invoice, type InvoiceRepository, } from 'domain/invoice';
import { ACCT_ISO_TYPE, } from 'domain/client';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (Array<Invoice>) => void,
  onInvalidPayload: (Error) => void,
  onError: (Error) => void,
};

type Dependencies = {
  invoiceRepository: InvoiceRepository,
};

type Payload = {
  clientId: number,
  projectId: ?number,
};

export type GetInvoicesBehaviour = (
  Payload,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { invoiceRepository, }: Dependencies, ) => {
  /**
   * get list invoices of a client or project behaviour
   * 
   * @param {Payload} payload - client id and project id
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getInvoicesBehaviour: GetInvoicesBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { clientId, projectId, acctIsoType, } = payload;
      if (!clientId) {
        return onInvalidPayload(new Error('Invalid client and project id.'));
      }

      let invoices;

      if ((acctIsoType && acctIsoType === ACCT_ISO_TYPE.client) || (projectId && Number(projectId) === -1)) {
        invoices = await invoiceRepository.getClientInvoices(clientId, withCurrentUserToken);
      } else if (projectId) {
        invoices = await invoiceRepository.getProjectInvoices(clientId, projectId, withCurrentUserToken);
      }

      return onSuccess(invoices);
    } catch(error) {
      return onError(error);
    }
  };

  return getInvoicesBehaviour;
};
