import React from 'react';
import ApplicationStatus from "./components/ApplicationStatus";
import PendingInfo from "./components/PendingInfo";
import SupportInfo from "./components/SupportInfo";
import {STATUSES} from "../PaymentsPanel";
import styled from 'styled-components';
import { APP_CONFIG } from 'constants/index';


const trackMixpanelEvent = () => { window.mixpanel.track("b4tp_update_app") }

export default ({headnoteFirmId}) => {
    return (
        <>
            <StyledH5 className="mt-3">{`Thanks for submitting your application!`}</StyledH5>
            <ApplicationStatus status={STATUSES.PENDING_REVIEW} />
            <PendingInfo />
            <SupportInfo />
            <p className="mt-4 mb-2"><a href={APP_CONFIG.activeForFreeLink} onClick={trackMixpanelEvent} target="_blank">Update Application Information</a></p>
            <p>Application ID: {headnoteFirmId}</p>
        </>
    )
}

const StyledH5 = styled.h5`
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
`;