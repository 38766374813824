// @flow
import { Formik, type FormikActions, type FormikProps, } from 'formik';
import { type PaymentCard, StripeAddCreditCardSchema, } from 'domain/payment';
import React, { useCallback, } from 'react';
import { ReactStripeElements, injectStripe, } from 'react-stripe-elements';

import { type AddCardPayload, } from 'state/payment/types';
import { Button, Alert, Modal, Spinner, } from 'react-bootstrap';
import CardForm from './StripeCardForm';
import FormAlert from '../../../form/FormAlert';
import { PaymentActionCreators, } from 'state/actions';
import SettingButton from '../../../form/SettingsButton';
import styled from 'styled-components';
import { useDispatch, } from 'react-redux';
import { ValidateShading, } from 'view/components';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 700px !important;
  }
`;

type Props = {
  isShow: boolean,
  onClose?: Function,
  onHide: Function,
};

const initialValues: PaymentCard = {
  name: '',
};

const AddCardModal = ({
  isShow = false, onHide, stripe,
}: Props & ReactStripeElements.InjectedStripeProps) => {
  const dispatch = useDispatch();

  const onAddCard = useCallback((payload: AddCardPayload) => {
    return dispatch(PaymentActionCreators.addCard(payload, { thunk: true, }));
  }, [ dispatch, ]);

  // on add card
  // start verifying
  const onVerify = useCallback(async (values: PaymentCard, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);

      // tokenize stripe card
      const { token, error, } = await stripe.createToken(values);
      if (values.name.length === 0) {
        error.name = 'Name is required';
      }
      if (error) {
        throw error;
      }

      // request attach card api
      const payload: AddCardPayload = {
        token: token.id,
      };
      await onAddCard(payload);

      // use status to display verification success modal
      actions.setStatus(true);
    } catch (e) {
      // handle error
      if (e.code || e.name) {
        actions.setErrors(e);
      } else {
        actions.setErrors({
          bank: e.message || e,
        });
      }
    } finally {
      actions.setSubmitting(false);
    }
  }, [ stripe, onAddCard, ]);

  const ErrorStatus = useCallback(errors => {
    if(errors.name || errors.code) {
      return true;
    }
    return false;
  }, []);

  return (
    <StyledModal
      backdrop='static'
      className="p-0"
      show={isShow}
      onHide={onHide}
    >
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={StripeAddCreditCardSchema}
        onSubmit={onVerify}
      >
        {({ errors, handleSubmit, isSubmitting, status, values, }: FormikProps<PaymentCard>) => (
          <>
            <Modal.Header closeButton className="border-bottom-0">
              <h2>
                {
                  status ? `Verification` : `Credit Card`}
              </h2>

              
            </Modal.Header>
            <Modal.Body>
              { ErrorStatus(errors) && (
                <ValidateShading 
                  className="w-100" 
                  text={ `All fields in blue are required.` }
                />
                )
              }
              <FormAlert alert={errors.bank} />
              {
                status ? (
                  <>
                    <p>
                      {`Success!`}
                    </p>
                    <p>
                      {`Credit Card added.`}
                    </p>
                  </>
                ) : (  
                  <CardForm errors={errors} values={values} />
                )
              }
            </Modal.Body>
            <Modal.Footer className="border-top-0">
              {
                isSubmitting && (
                  <Spinner animation="border" className="mr-2" role="status" />
                )
              }
              <Button
                className="font-weight-bold border-0"
                disabled={isSubmitting}
                variant="light"
                onClick={onHide}
              >
                {`Close`}
              </Button>
              {
                !status && (
                  <SettingButton
                    className="font-weight-bold"
                    disabled={isSubmitting}
                    type='submit'
                    onClick={handleSubmit}
                  >
                    {`Verify`}
                  </SettingButton>
                )
              }
            </Modal.Footer>
          </>
        )}
      </Formik>
    </StyledModal>
  );
};

export default injectStripe(AddCardModal);
