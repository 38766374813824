// @flow
import * as R from 'ramda';

import { Colors, Images, } from 'assets';
import { Field, Formik, } from 'formik';
import { FormSelectCreatable, FormSelectMulti, } from 'view/components';
import React, { useMemo, } from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import { type PostInviteBody, type Contact, } from 'domain/invite';
import { type Client, } from 'domain/client';
import { components, } from 'react-select';
import styled from 'styled-components';
import AutoInitClients from './AutoInitClients';
import { NavLink, useRouteMatch, } from 'react-router-dom';

type Props = {
  initialFormValues: PostInviteBody,
  onSubmit: Function,
  clients: Array<Contact>,
  users: Array<Client>,
  firmUsers: Map<Contact>,
  firmclientsMap: Map<Client>,
}

const StyledButton = styled(Button)`
  background-color: ${Colors.blueButton} !important;
  border: none !important;
  width: 150px;

  :hover {
    background-color: ${Colors.blueButtonHover} !important;
  }
`;

const StyledText = styled.p`
  font-size: .75rem;
  color: ${Colors.greyEmpress};
`;

const StyledEmailField = styled(Field)`
  max-width: 400px;
  label {
    font-weight: bold;
  }
`;

const StyledClientField = styled(Field)`
  label {
    font-weight: bold;
  }
`;

const StyledImage = styled(Image)`
  width: 12px;
  height: 12px;
  filter: invert(1);

  :hover {
    filter: invert(0.7);
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${Colors.blueButton};

  :hover {
    color: ${Colors.blueButtonHover};
  }
`;

const StyledSelectOption = styled.span`
  color: ${Colors.lightGrey};
`;

const Input = props => (
  <components.Input {...props} placeholder={'Add Client'} />
);

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <StyledImage src={Images.common.removeIcon} />
  </components.MultiValueRemove>
);

const Option = props => (
  <components.Option {...props}>
    {props.value.name} <StyledSelectOption>(Id: {props.value.clientLabel})</StyledSelectOption>
  </components.Option>
);

const MultiValueLabel = props => (
  <components.MultiValueLabel {...props}>
    {props.data.value.name} <StyledSelectOption>(Id: {props.data.value.clientLabel})</StyledSelectOption>
  </components.MultiValueLabel>
);

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>{children}</components.SingleValue>
  );
};

const InvitesForm = ({ initialFormValues, onSubmit, clients, users, firmUsers, firmclientsMap, }: Props) => {
  // Filter user: remove blank label elements.
  const userList = useMemo(() => {
    if(!users) {
      return [];
    }
    return users.filter(item => item.label);
  }, [ users, ]);

  // react router current path info
  const { url, } = useRouteMatch();

  return (
    <div className='invites-form'>
      <Formik
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
      {
        ({ isSubmitting, values, errors, handleSubmit, status, setStatus, }: FormikState<UserInvitesInfo>) => (
          <form onSubmit={handleSubmit}>
              {
                status && status.success && (
                  <Alert 
                    dismissible
                    className="font-weight-bold"
                    variant='success'
                    onClose={() => { setStatus(null); }}
                  >
                    {status.success}
                  </Alert>
                )
              }

              {
                errors.auth && (
                  <Alert className="font-weight-bold" variant='warning'>
                    {R.includes('User has been invited already', errors.auth) ? (
                      <>
                        {`User entered has already been sent an invite. You can view the status`} <StyledNavLink to={`${url}/list`}>{`here`}</StyledNavLink> {`or enter a new user.`}
                      </>
                    ) : errors.auth}
                  </Alert>
                )
              }

              <AutoInitClients
                clientMap={firmclientsMap}
                userMap={firmUsers}
              />

              <StyledEmailField
                isClearable
                className="mb-0"
                component={FormSelectCreatable}
                components={{ DropdownIndicator: null, IndicatorSeparator: null, SingleValue, }}
                label={'User'}
                name={'email'}
                noOptionsMessage={() => null}
                options={userList}
                placeholder={'Select a User'}
                styles={{
                  control: (base) => ({
                    ...base,
                    width: 400,
                  }),
                }}
              />

              <StyledText className="text-secondary pt-3">
                {`This user will be associated with the following Bill4Time client account(s). This user will have access to invoices and payment history for the selected client(s), and will receive automatic email notifications when a new invoice is finalized for the selected client(s).`}
              </StyledText>

              <StyledClientField
                isMulti
                component={FormSelectMulti}
                components={{ IndicatorSeparator: null, Input: Input, MultiValueRemove: MultiValueRemove, Option, MultiValueLabel, }}
                defaultValue={values.clientIds}
                key={values.clientIds}
                label={'Client(s)'}
                name={'clientIds'}
                options={clients}
                placeholder={''}
                styles={{
                  multiValueLabel: base => ({
                    ...base,
                    backgroundColor: Colors.charcoal,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    color: 'white',
                    fontWeight: 'bold',
                  }),
                  multiValueRemove: base => ({
                    ...base,
                    backgroundColor: Colors.charcoal,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,

                    '&:hover': {
                      backgroundColor: Colors.charcoal,
                    },
                  }),
                }}
              />

              <div className="d-flex justify-content-end px-0 py-2">
                {
                  isSubmitting && (
                    <Spinner animation="border" className="mr-2" role="status" />
                  )
                }
                <StyledButton 
                  className="font-weight-bold"
                  type="submit"
                >
                  {`Send Invite`}
                </StyledButton>
              </div>
          </form>
        )
      }
      </Formik>
    </div>
  );
};

export default InvitesForm;
