import * as R from 'ramda';
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, useParams, useLocation, } from 'react-router-dom';
import { Firm } from 'domain/firm';
import { ClientMap } from 'state/client/types';
import { UserRedux, StatementRedux } from 'state/reducers';
import { convertEpocToDate } from 'utilities/stringUtils';
import StatementV from './StatementV';
import { StatementActionCreators } from 'state/actions';

const useConnect = () => {
    const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
    const statementLink = useSelector(R.pipe(StatementRedux.getReducerState, StatementRedux.selectors.getStatementLink));
    const mapState = { firm, statementLink };

    const dispatch = useDispatch();
    const mapDispatch = useMemo(() => ({
        getStatementLink: (payload: any): Promise<any> => dispatch(StatementActionCreators.getStatementLink(payload))
    }), [dispatch]);

    return {
        ...mapState,
        ...mapDispatch
    }
}

const StatementVM = () => {
    const { firm, statementLink, getStatementLink } = useConnect();
    const { id: statementId, clientId } = useParams();
    const location = useLocation();

    const epocStatementCreatedDate: number = location.state && location.state.statementCreatedDate;
    const statementCreatedDate = new Date(epocStatementCreatedDate * 1000).toString();

    const statementDateFormat = firm?.systemDateFormat || null;

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchStatementData = async () => {
            try {
                setIsLoading(true);
                const payload = {
                    clientId, statementId
                }
                await getStatementLink(payload);
            } catch (e) {
                setError(e.message || e);
            } finally {
                setIsLoading(false);
            }
        }
        fetchStatementData();
    }, [statementId, clientId])

    return (
        <StatementV
            dateFormat={statementDateFormat}
            statementDate={statementCreatedDate}
            statementLink={statementLink}
            isLoading={isLoading}
            error={error}
        />
    )
}

export default StatementVM;