// @flow
import { type Options, } from 'ky';
import { type UserToken, } from 'domain/user';
import { getFirmName, } from 'utilities/stringUtils';

/**
 * add firm name to request url
 * 
 * @param {string} url - url endpoint
 */
export const withFirmName = (url: string, manualFirmName?: string, connector?: string = '/'): string => {
  // use manualfirmname instead of getting from host url if present
  const subdomain: string = manualFirmName || getFirmName();
  // don't add connector if url empty
  const adjacent = url ? `${connector}${url}` : '';
  return `${subdomain}${adjacent}`;
};

/**
 * add user token to request header
 * 
 * @param {Options} options - url endpoint
 * @param {UserToken} userToken - user token string
 */
export const withUserToken = (options: Options, userToken?: UserToken): Options => {
  if(!userToken) {
    return options;
  }

  const token = userToken.authToken;

  return {
    ...options,
    headers: {
      ...options ? options.headers : undefined,
      authorization: `Bearer ${token}`,
    },
    refreshToken: userToken.refreshToken,
  };
};
