/* @flow */
import { type Firm, type FirmRepository, } from 'domain/firm';
import { type User, type UserAuthInfo, type UserRepository, type UserToken, type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (UserToken, Firm, User) => void,
  onError: (Error) => void,
};

type Dependencies = {
  firmRepository: FirmRepository,
  userRepository: UserRepository,
};

export type SignInUserBehaviour = (
  UserAuthInfo,
  Callbacks,
) => any;

export default ( { firmRepository, userRepository, }: Dependencies, ) => {
  /**
   * login behaviour
   * 
   * @param {UserAuthInfo} userAuthInfo - user auth info
   * @param {Callbacks} callbacks - call back for each use case
   */
  const signInUserBehaviour: SignInUserBehaviour = async ( userAuthInfo: UserAuthInfo, { onSuccess, onError, }: Callbacks, ) => {
    try {
      // sign in to get auth token
      const userTokens: UserToken = await userRepository.byAuthInfo(userAuthInfo);

      const withUserToken: WithCurrentUserToken = {
        currentUserToken: userTokens,
      };

      // get firm and user info
      const promises = [
        firmRepository.getFirm(withUserToken),
        userRepository.getByToken(withUserToken),
      ];
      const [ firm, user, ]: [ Firm, User, ] = await Promise.all(promises);

      return onSuccess(userTokens, firm, user);
    } catch (error) {
      return onError(error);
    }
  };

  return signInUserBehaviour;
};
