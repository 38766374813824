/* @flow */
import { type ApiResponse, type ConduitApiService, } from 'infra/conduit/ApiService';

import { type RetainerRepository, type  RetainerRequestPayments, } from 'domain/retainer';
import GetSingleRetainer from 'app/retainer/GetSingleRetainer';

type Dependencies = {
    conduitApiService: ConduitApiService
  };

  export default ({ conduitApiService, }: Dependencies): RetainerRepository => ({
      /**
       * Request a single retainer
       */
      async getRetainerAmounts( retainerId, clientId, { currentUserToken }) {
          const res: ApiResponse = await conduitApiService.authGet({
              url: `clients/${clientId}/retainer/${retainerId}`,
              userToken: currentUserToken,
          });
          
          if (res.error) {throw res.error;}
          return res.success;
      },

      async getClientRetainers(clientId, { currentUserToken }) {
          const res: ApiResponse = await conduitApiService.authGet({
              url: `clients/${clientId}/retainers`,
              userToken: currentUserToken
          });

          if (res.error) { throw res.error; }

          return res.success;
      },
      async getProjectRetainers(clientId, projectId, { currentUserToken }) {
        const res: ApiResponse = await conduitApiService.authGet({
            url: `clients/${clientId}/projects/${projectId}/retainers`,
            userToken: currentUserToken
        });

        if (res.error) {throw res.error;}

        return res.success;
      }
  });