/* @flow */
import { type ApiResponse, type ConduitApiService, } from 'infra/conduit/ApiService';

import { type ProjectRepository, } from 'domain/project';

type Dependencies = {
  conduitApiService: ConduitApiService
};

export default ({ conduitApiService, }: Dependencies): ProjectRepository => ({
  /**
   * request projects list of a client with auth credentials
   * 
   * @param {number} clientId - client id
   * @param {WithCurrentUserToken} params - user token
   */
  async getProjectsOfClient(clientId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}/projects`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * request specific project with auth credentials
   * 
   * @param {WithCurrentUserToken} params - user token
   */
  async getSingleProject(clientId, projectId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}/projects/${projectId}`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success[0];
  },

  /**
   * request billed client balance with auth credentials
   * 
   * @param {WithCurrentUserToken} params - user token
   */
  async getBilledProjectBalances(clientId, projectId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}/projects/${projectId}/billedBalance`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },
});
