/* @flow */
import { type InviteRepository, } from 'domain/invite';

import { type WithCurrentUserToken, } from 'domain/user';

type Payload = string;

type Callbacks = {
  onSuccess: (string) => void,
  onError: (Error) => void,
};

type Dependencies = {
  inviteRepository: InviteRepository,
};

export type DeleteClientBehaviour = (
  Payload,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { inviteRepository, }: Dependencies, ) => {
  /**
   * revoke invite, stop access behaviour
   * 
   * @param {Payload} payload - token ID of invite element
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const deleteClientBehaviour: DeleteClientBehaviour = async (
    payload,
    withCurrentUserToken: WithCurrentUserToken,
    { onSuccess, onError, }: Callbacks,
  ) => {
    try {
      const message: string = await inviteRepository.deleteInvite(payload, withCurrentUserToken);

      return onSuccess(message);
    } catch(error) {
      return onError(error);
    }
  };

  return deleteClientBehaviour;
};
