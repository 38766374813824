/* @flow */
import { type BankAccountData, type Payment, type PaymentRepository, } from 'domain/payment';

import { type Firm, } from 'domain/firm';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (bankAccounts: Array<BankAccountData>) => void,
  onError: (e: Error) => void,
  onInvalidPayload: (e: Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
};

export type GetAllBankAccountsBehaviour = (
  firm: Firm,
  tokens: WithCurrentUserToken,
  cb: Callbacks
) => any

export default ( { paymentRepository, }: Dependencies, ) => {
  /**
   * get list bank accounts of user ( stripe or lawpay )
   * 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getAllBankAccountsBehaviour: GetAllBankAccountsBehaviour = async (
    firm,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      let request: Promise<Array<BankAccountData>>;

      // request paypal / stripe / headnote bank accounts based on user settings
      if (firm.portalSettings.isHeadnote) {
        request = paymentRepository.getHeadnoteBankAccounts(withCurrentUserToken);
      } else if (firm.portalSettings.isStripe) {
        request = paymentRepository.getStripeBankAccounts(withCurrentUserToken);
      } else if (firm.portalSettings.isLawpay) {
        request = paymentRepository.getLawpayBankAccounts(withCurrentUserToken);
      } else {
        // return invalid error if firm portal settings doesn't
        // have stripe, lawpay or headnote integrated
        return onInvalidPayload(new Error('Invalid payment type.'));
      }

      const bankAccounts = await request;

      return onSuccess(bankAccounts);
    } catch(error) {
      return onError(error);
    }
  };

  return getAllBankAccountsBehaviour;
};
