import React, { useCallback, } from 'react';

import { Colors, } from 'assets';
import Feedback from 'react-bootstrap/Feedback';
import { type FieldProps, } from 'formik';
import Form from 'react-bootstrap/Form';
import CreatableSelect  from 'react-select/creatable';
import styled from 'styled-components';

const StyledForm = styled(Form.Group)`
  .is-invalid {
    &::placeholder {
      color: ${Colors.redDanger} !important;
    }
  }
`;

const StyledFormError = styled(Feedback)`
  color: ${Colors.redDanger};
`;

const StyledDropdown = styled(CreatableSelect)`
  font-size: .875rem;
`;

type OptionItem = {
  value: any,
  label: string,
};

type Props = {
  className: String,
  defaultValue: Object,
  label: String,
  options: Array<OptionItem>,
};

const FormSelectMulti = ({
  field, form,
  label, className, ...restProps
}: FieldProps<any> & Props) => {

  const { errors, } = form;
  // field states:
  const _error = errors[field.name];
  // const _touched = touched[field.name];
  const _onSelect = useCallback((item) => {
    // formik handleChange will look for an input's id attribute
    // https://jaredpalmer.com/formik/docs/api/formik#handlechange-e-reactchangeevent-any-void
    form.setFieldValue(field.name, item);
  }, [ field.name, form, ]);

  const onInputChange = useCallback((inputValue, action) => {
    if (action.action === "input-change") {
      const inputValueFormat = {
        label: inputValue,
        value: inputValue,
        __isNew__: true,
      };
      form.setFieldValue(field.name, inputValueFormat);
    }
    
  }, [ field.name, form, ]);

  return (
    <StyledForm className={className}>
      {
        label && <Form.Label>{label}</Form.Label>
      }
      <StyledDropdown
        {...restProps}
        value={field.value}
        onBlurResetsInput={false}
        onChange={_onSelect}
        onInputChange={onInputChange}
      />
      {
        _error && <StyledFormError type="invalid">{_error}</StyledFormError>
      }
    </StyledForm>
  );
};

export default FormSelectMulti;
