/* @flow */
import ky, { type Options as KyOptions, } from 'ky';
import { withFirmName, withUserToken, } from './utilities';
import { jwtDecoded, compareEpochDate, } from 'utilities/stringUtils';

import { API_CONFIG, } from 'constants/index';
import { UserActionCreators, } from 'state/actions';
import { type UserToken, } from 'domain/user';
import { store, } from 'state';

// optional settings outside of ky options
interface Options extends KyOptions {
  firmCode?: string;
}

type RequestParams = {
  url: string,
  options?: Options,
  data?: mixed,
}

type AuthRequestParams = {
  userToken: UserToken,
} & RequestParams;

type Request = (params: RequestParams) => Promise<any>;
type AuthRequest = (params: AuthRequestParams) => Promise<any>;

type SuccessResponse = {
  success: any,
  message: ?string,
};

type FailureResponse = {
  error: any,
};

export type ApiResponse = SuccessResponse | FailureResponse;

export type ConduitApiService = {
  post: Request,
  get: Request,
  getNoHook: Request,
  del: Request,
  put: Request,
  authGet: AuthRequest,
  authPost: AuthRequest,
  authDel: AuthRequest,
  authPut: AuthRequest,
}


const PREFIX_URL = API_CONFIG.HOST + API_CONFIG.API_ENDPOINT;

// Api service factory
export default () => {
  const apiConfigNoHook: Options = {
    prefixUrl: PREFIX_URL,
    timeout: API_CONFIG.timeout,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    throwHttpErrors: false,
    retry: 0,
  };

  const apiConfig: Options = {
    prefixUrl: PREFIX_URL,
    timeout: API_CONFIG.timeout,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    throwHttpErrors: false,
    retry: 0,
    hooks: {
      beforeRequest: [
        async () => {
          const { user: { tokens, }, } : { user: { tokens: UserToken, } } = store.getState();
          let expired_time = 0;

          if (tokens && tokens.authToken) {
            const { exp, } = jwtDecoded(tokens.authToken);
            expired_time = exp;
          }

          if (tokens && tokens.refreshToken) {
            const { exp, } = jwtDecoded(tokens.refreshToken);
            expired_time = exp;
          }

          if (expired_time && expired_time !== 0) {
            // compare with current time
            if (!compareEpochDate(expired_time)) {
              store.dispatch(UserActionCreators.logout());
            }
          }
        },
      ],
      afterResponse: [
        // refresh token hook
        async (input, options, response) => {
          // auth refresh and reject flow
          const { refreshToken, } = options;

          // check if user logged in
          if (!refreshToken) {return;}

          // refresh token when API request gets rejected by backend because of an expired token
          if (response.status === 401 && refreshToken) {
            try {
              // request new auth token with refresh token
              // use a distinct ky instance so this hook doesn't get rerun with refresh request
              const res = await ky.post(PREFIX_URL + '/' + withFirmName('tokens/session/user/refresh'), {
                headers: {
                  Authorization: `Bearer ${refreshToken}`,
                },
              }).json();

              // if refresh request get rejected
              // then log out
              if (res.error) {
                throw new Error('Invalid refresh.');
              }

              // This route verifies a refresh token and returns a new user JWT if it is valid.
              // | 200 | {"success": { accessToken: [token] }} |
              const authToken = res.success.accessToken;

              const newTokens: UserToken = {
                authToken,
                refreshToken,
              };

              // set new tokens to app state
              store.dispatch(UserActionCreators.setTokens(newTokens));

              // temporary work around
              // ky options from hook are normalized so passing it to ky again will throw errors ( https://github.com/sindresorhus/ky/issues/167 )
              // TODO: remove work around when issue get updated and fixed
              const newOptions = withUserToken(options, newTokens);
              newOptions.retry = 0;
              newOptions.prefixUrl = '';

              // retry previous request with new token
              return ky(input, newOptions);
            } catch (e) {
              console.log('[Refresh token error]', { e, }); // eslint-disable-line
              store.dispatch(UserActionCreators.logout());
              throw e;
            }
          }
        },
      ],
    },
  };
  
  const api = ky.extend(apiConfig);

  const apiNoHook = ky.extend(apiConfigNoHook);

  const post: Request = ({ url, data, options, }) => {
    // https://github.com/sindresorhus/ky#json
    if (data) {
      options = {
        ...options,
        json: data,
      };
    }
  
    return api.post(withFirmName(url, options.firmCode), options).json();
  };
  const get: Request = ({ url, data, options, }) => {
    // https://github.com/sindresorhus/ky#searchparams
    if (data) {
      options = {
        ...options,
        searchParams: data,
      };
    }
  
    return api.get(withFirmName(url, options.firmCode), options).json();
  };

  const getNoHook: Request = ({ url, data, options, }) => {
    // https://github.com/sindresorhus/ky#searchparams
    if (data) {
      options = {
        ...options,
        searchParams: data,
      };
    }
  
    return apiNoHook.get(withFirmName(url, options.firmCode), options).json();
  };

  const del: Request = ({ url, data, options, }) => {
    if (data) {
      options = {
        ...options,
        json: data,
      };
    }
    return api.delete(withFirmName(url, options.firmCode), options).json();
  };

  const put: Request = ({ url, data, options, }) => {
    // https://github.com/sindresorhus/ky#json
    if (data) {
      options = {
        ...options,
        json: data,
      };
    }
  
    return api.put(withFirmName(url, options.firmCode), options).json();
  };
  
  const authGet: AuthRequest = ({ url, userToken, data, options = {}, }) => get({ url, data, options: withUserToken(options, userToken), });
  
  const authPost: AuthRequest = ({ url, userToken, data, options = {}, }) => post({ url, data, options: withUserToken(options, userToken), });
  
  const authDel: AuthRequest = ({ url, userToken, data, options = {} }) =>
    del({ url, data, options: withUserToken(options, userToken), });
  
  const authPut: AuthRequest = ({ url, userToken, data, options = {}, }) => put({ url, data, options: withUserToken(options, userToken), });

  return {
    post,
    get,
    getNoHook,
    del,
    put,
    authGet,
    authPost,
    authDel,
    authPut,
  };
};
