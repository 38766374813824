// @flow
import { Field, Formik, type FormikState, } from 'formik';
import { type RegisterCredentials, RegisterSchema, } from 'domain/user/index';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { Colors, } from 'assets/index';
import { FormInput, } from 'view/components/index';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${Colors.blueButton} !important;
  border: none !important;
  font-weight: bold !important;
  width: 140px;

  :hover {
    background-color: ${Colors.blueButtonHover} !important;
  }
`;

type Props = {
  onSubmit: Function,
  initialFormValues: RegisterCredentials,
  email: string,
}

const FormEl = ({ isSubmitting, values, errors, touched, handleChange, handleBlur, handleSubmit, }: FormikState<RegisterCredentials>) => (
  <form onSubmit={handleSubmit}>
    {
      errors.auth && (
        <Alert variant='danger'>{errors.auth}</Alert>
      )
    }

    <Field
      required
      component={FormInput}
      name={'firstName'}
      placeholder={'First name'}
      type={'text'}
    />

    <Field
      required
      component={FormInput}
      name={'lastName'}
      placeholder={'Last name'}
      type={'text'}
    />

    <Field
      required
      component={FormInput}
      name={'password'}
      placeholder={'Password'}
      type={'password'}
    />

    <Field
      required
      component={FormInput}
      name={'confirmPassword'}
      placeholder={'Confirm New Password'}
      type={'password'}
    />

    <div className='d-flex flex-row justify-content-end'>
      {
        isSubmitting && (
          <Spinner
            animation="border"
            className='mr-2'
            role="status"
          />
        )
      }
      <div className='d-flex flex-column'>
        <StyledButton className='border-0 mb-3' disabled={isSubmitting} type='submit'>
          {`Register`}
        </StyledButton>
      </div>
    </div>
  </form>
);

const RegisterForm = ({ initialFormValues, onSubmit, email, }: Props) => (
  <div className='login-form'>
    <Formik
      initialValues={initialFormValues}
      // eslint-disable-next-line
      render={FormEl}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={RegisterSchema(email)}
      // eslint-disable-next-line
      onSubmit={onSubmit}
    />
  </div>
);

export default RegisterForm;
