/* @flow */
import { createActions, } from 'reduxsauce';
import { reducerPrefixFormat, } from '../common';

/* ------------- Payment ------------- */
export const { Types, Creators, } = createActions({
  getPayments: [ 'payload', 'meta', ],
  getPaymentsSuccess: [ 'payload' /* GetPaymentsResponsePayload */, 'meta', ],

  getInvoicePayments: [ 'payload', 'meta', ],
  getInvoicePaymentsSuccess: [ 'payload' /* GetInvoicePaymentsResponsePayload */, 'meta', ],

  stripePay: [ 'payload', 'meta', ],
  stripePaySuccess: [ 'payload' /* StripePaymentProcessorPayload */, 'meta', ],

  lawpayPay: [ 'payload', 'meta', ],
  lawpayPaySuccess: [ 'payload', 'meta', ],

  headnotePay: [ 'payload', 'meta', ],
  headnotePaySuccess: [ 'payload', 'meta', ],

  linkToPayHeadnotePay: [ 'payload', 'meta', ],
  linkToPayHeadnotePaySuccess: [ 'payload', 'meta', ],

  paypalCreateOrder: [ 'payload', 'meta', ],
  paypalCreateOrderSuccess: [ 'payload', 'meta', ],

  paypalPay: [ 'payload', 'meta', ],
  paypalPaySuccess: [ 'payload', 'meta', ],

  stripePaymentMethods: [ 'meta', ],
  stripePaymentMethodsSuccess: [ 'payload' /* StripePaymentMethodsPayload */, 'meta', ],

  getUserBankAccounts: [ 'meta', ],
  getUserBankAccountsSuccess: [ 'payload' /* GetUserBankAccountsSuccessPayload */, 'meta', ],

  addBankAccount: [ 'payload', 'meta', ],
  addBankAccountSuccess: [ 'payload', 'meta', ],

  verifyBankAccount: [ 'payload', 'meta', ],
  verifyBankAccountSuccess: [ 'payload', 'meta', ],

  removeBankAccount: [ 'payload', 'meta', ],
  removeBankAccountSuccess: [ 'payload', 'meta', ],

  getUserCards: [ 'meta', ],
  getUserCardsSuccess: [ 'payload' /* GetUserBankAccountsSuccessPayload */, 'meta', ],

  addCard: [ 'payload', 'meta', ],
  addCardSuccess: [ 'payload', 'meta', ],

  removeCard: [ 'payload', 'meta', ],
  removeCardSuccess: [ 'payload', 'meta', ],

  connectCustomPayment: [ 'payload', 'meta', ],
  connectCustomPaymentSuccess: [ 'payload', 'meta', ],

  disconnectCustomPayment: [ 'payload', 'meta', ],
  disconnectCustomPaymentSuccess: [ 'payload', 'meta', ],

  paypalGetSignupLink: [ 'payload', 'meta', ],
  paypalGetSignupLinkSuccess: [ 'payload', 'meta', ],

  connectPaypal: [ 'payload', 'meta', ],
  connectPaypalSuccess: [ 'payload', 'meta', ],

  disconnectPaypal: [ 'payload', 'meta', ],
  disconnectPaypalSuccess: [ 'payload', 'meta', ],

  connectLawpay: [ 'payload', 'meta', ],
  connectLawpaySuccess: [ 'payload', 'meta', ],

  disconnectLawpay: [ 'payload', 'meta', ],
  disconnectLawpaySuccess: [ 'payload', 'meta', ],

  connectStripe: [ 'payload' /* ConnectUserStripePayload */, 'meta', ],
  connectStripeSuccess: [ 'payload' /* ConnectUserStripeSuccessPayload */, 'meta', ],

  disconnectStripe: [ 'payload' /* DisconnectUserStripePayload */, 'meta', ],
  disconnectStripeSuccess: [ 'payload' /* DisconnectUserStripeSuccessPayload */, 'meta', ],

  requiredFieldsLawpay: [ 'payload' /* requiredFieldsLawpayPayload */, 'meta', ],
  requiredFieldsLawpaySuccess: [ 'payload' /* requiredFieldsLawpayPayloadSuccess */, 'meta', ],

  getHeadnoteApplication: [ 'meta', ],
  getHeadnoteApplicationSuccess: [ 'payload', 'meta', ],

  getDisconnectedProcessors: ['meta'],
  getDisconnectedProcessorsSuccess: ['payload', 'meta'],

  getLinkToPayTemplate: ['payload', 'meta'],
  getLinkToPayTemplateSuccess: ['payload', 'meta'],

  submitPaymentMethodHeadnote: ['payload', 'meta'],
  submitPaymentMethodHeadnoteSuccess: ['payload', 'meta'],

  getPaymentReceivingBankType: ['payload', 'meta'],
  getPaymentReceivingBankTypeSuccess: ['payload', 'meta'],

  requestFailure: [ 'error', 'payload' /* error payload */, 'meta', ],
}, {
  prefix: reducerPrefixFormat('payment'),
});
