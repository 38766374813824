// @flow
import { FormInput, FormSelect, } from 'view/components';
import { Colors, } from 'assets';
import { FormGroup as CustomFormGroup, } from 'view/components';
import { Field, } from 'formik';
import Form from 'react-bootstrap/Form';
import React from 'react';
import styled from 'styled-components';
import { type RequiredFields, } from 'domain/payment';
import { COUNTRIES, } from 'constants/index';

const HostedFieldDiv = styled.div`
  border: 1px solid ${Colors.whiteBlack};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  iframe {
    height: 35px;
    border: none;
    width: 100%;
  }
  &.isValid {
    background: ${Colors.shadingInputbg};
  }
`;

const StyledFormInput = styled(FormInput)`
  && {
    .is-invalid {
      background: ${Colors.shadingInputbg};
    }
    .invalid-feedback {
      display: none;
    }
  }
`;

type Props = {
  errors: { [string]: string },
  values: object,
  requiredFields: RequiredFields,
};

const CardForm = ({ errors = {}, values, requiredFields, }: Props) => {
  return (
    <Form>

      <Field
        isRow
        className={`payment-validate ${errors.name ? 'invalid' : 'valid'}`}
        component={StyledFormInput}
        inputSize={9}
        label={'Name'}
        labelClassName={`text-right font-weight-bold`}
        labelSize={3}
        maxLength={26}
        name={'name'}
        type={'text'}
      />

      {/* Additional Fields  */}
      { requiredFields.address1 && (
          <Field
            isRow
            className={`mt-3 payment-validate ${errors.address1 ? 'invalid' : 'valid'}`}
            component={StyledFormInput}
            inputSize={9}
            label={'Billing address'}
            labelClassName={`text-right font-weight-bold`}
            labelSize={3}
            name={'address1'}
            type={'text'}
          />
        )
      }

      { requiredFields.postal_code && (
          <Field
            isRow
            className={`mt-3 payment-validate ${errors.postal_code ? 'invalid' : 'valid'}`}
            component={StyledFormInput}
            inputSize={9}
            label={'Postal Code'}
            labelClassName={`text-right font-weight-bold`}
            labelSize={3}
            maxLength={7}
            name={'postal_code'}
            type={'text'}
          />
        )
      }
      { requiredFields.city && (
          <Field
            isRow
            className={`mt-3 payment-validate ${errors.city ? 'invalid' : 'valid'}`}
            component={StyledFormInput}
            inputSize={9}
            label={'City'}
            labelClassName={`text-right font-weight-bold`}
            labelSize={3}
            maxLength={7}
            name={'city'}
            type={'text'}
          />
        )
      }
      { requiredFields.state && (
          <Field
            isRow
            className={`mt-3 payment-validate ${errors.state ? 'invalid' : 'valid'}`}
            component={StyledFormInput}
            inputSize={9}
            label={'State'}
            labelClassName={`text-right font-weight-bold`}
            labelSize={3}
            maxLength={7}
            name={'state'}
            type={'text'}
          />
        )
      }
      { requiredFields.country && (
          <Field
            fullWidth
            isRow
            required
            className={`mt-3 payment-validate ${errors.state ? 'invalid' : 'valid'}`}
            component={FormSelect}
            defaultValue={COUNTRIES[0].value}
            inputSize={9}
            isSearchable={false}
            label={'Country'}
            labelClassName={`text-right font-weight-bold`}
            labelSize={3}
            name={'country'}
            options={COUNTRIES}
            placeholder={'Country'}
          />
        )
      }
      
      {/* affinipay hosted fields */}
      <CustomFormGroup
        isRow
        className={`mt-3`}
        inputSize={9}
        label={`Credit Card`}
        labelClassName={`text-right font-weight-bold`}
        labelSize={3}
      >
        <HostedFieldDiv className={errors.credit_card_number ? 'isValid' : 'valid'} id="lawpay_credit_card_id" />
      </CustomFormGroup>

      <CustomFormGroup
        isRow
        className={`mt-3`}
        inputSize={9}
        label={`CVC`}
        labelClassName={`text-right font-weight-bold`}
        labelSize={3}
      >
        <HostedFieldDiv className={errors.cvv ? 'isValid' : 'valid'} id="lawpay_card_cvv_id" />
      </CustomFormGroup>

      <Field
        isRow
        className={`mt-3 payment-validate ${errors.exp_month ? 'invalid' : 'valid'}`}
        component={StyledFormInput}
        inputSize={9}
        label={'Exp Month'}
        labelClassName={`text-right font-weight-bold`}
        labelSize={3}
        maxLength="2"
        name={'exp_month'}
        placeholder="MM"
        type={'number'}
      />

      <Field
        isRow
        className={`mt-3 payment-validate ${errors.exp_year ? 'invalid' : 'valid'}`}
        component={StyledFormInput}
        inputSize={9}
        label={'Exp Year'}
        labelClassName={`text-right font-weight-bold`}
        labelSize={3}
        maxLength="4"
        name={'exp_year'}
        placeholder="YYYY"
        type={'number'}
      />
      
    </Form>
  );
};

export default CardForm;
