// @flow
import * as R from 'ramda';

import { type ErrorState, } from './types';
import { ErrorTypes, } from '../actions';
import { createReducer, } from 'reduxsauce';
import { produce, } from 'immer';

const stateKey = 'error';

/* ------------- Initial State ------------- */
const INITIAL_STATE: ErrorState = ({
  errorsQueue: [],
  currentError: null,
});

/* ------------- Reducers ------------- */
const errorsQueueAppend = (state: ErrorState, { error, }: { error: Error, }) =>
  produce(state, (draft) => {
    let { errorsQueue = [], currentError, } = draft;
    if (currentError && currentError.message === error.message) {
      // do nothing to reduce duplicate error
    } else if (errorsQueue.findIndex((item) => (item.message === error.message)) > -1) {
      // do nothing to reduce duplicate error
    } else {
      errorsQueue = errorsQueue.concat(error);
    }

    if (currentError) {
      draft.errorsQueue = errorsQueue;
    } else {
      draft.errorsQueue = errorsQueue.slice(1);
      draft.currentError = errorsQueue[0];
    }
  });

const currentErrorFinish = (state: ErrorState) =>
  produce(state, (draft) => {
    const errorsQueue = draft.errorsQueue;
    if (errorsQueue.length > 0) {
      draft.errorsQueue = errorsQueue.slice(1);
      draft.currentError = errorsQueue[0];
    } else {
      Object.assign(draft, INITIAL_STATE);
    }
  });

const requestFailure = R.identity;

/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
  [ErrorTypes.ERRORS_QUEUE_APPEND]: errorsQueueAppend,
  [ErrorTypes.CURRENT_ERROR_FINISH]: currentErrorFinish,

  [ErrorTypes.REQUEST_FAILURE]: requestFailure,
});

const reducerMap = { [stateKey]: reducer, };

/* ------------- Selectors ------------- */
const getReducerState = (state): ErrorState => (state[stateKey]);

const selectors = {
  getCurrentError: ({ currentError, }: ErrorState): ?Error => {
    return currentError;
  },
};

/* ------------- Export ------------- */
export default {
  selectors,

  // default export
  INITIAL_STATE,

  stateKey,
  getReducerState,
  reducerMap,
};
