// @flow
import * as R from 'ramda';

import { type CellProps, useTable } from 'react-table';
import { type Firm, convertSystemDateFormat, } from 'domain/firm';
import React, { useMemo, } from 'react';

import { type FieldArrayRenderProps } from 'formik';
import { type RetainerRequestPayments, RetainerUserPayment } from 'domain/retainer';
import RetainerTotalPaymentField from './RetainerTotalPaymentField';
import Table from 'react-bootstrap/Table';
import { type User } from 'domain/user';
import { UserRedux, } from 'state/reducers';
import { currencyFormat, } from 'utilities/stringUtils';
import { isLastRow, } from 'utilities/generalUtils';
import styled from 'styled-components';
import { useSelector, } from 'react-redux';
import { Colors, } from 'assets/index';
import RetainerPaymentField from './RetainerPaymentField';

const StyledCell = styled.td`
  vertical-align: top !important;
  a {
    color: ${Colors.blueCurious}
  }
`;

const RetainerPaymentArrayDynamicField = ({
    form: { values, setFieldValue, }
}: FieldArrayRenderProps) => {
    const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
    const user: User = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));

    const {
        retainerTotal,
        tableData,
    } = useMemo(() => {

        const total = !values.retainers ? 0
            : values.retainers.reduce((prev: number, curr: RetainerUserPayment) => {
                return prev + curr.userPayment;
            }, 0);

        const tableData = [
            ...values.retainers || [], { totalRow: true }
        ];

        setFieldValue('total', total);

        return {
            retainerTotal: total,
            tableData
        };

    }, [values.retainers, setFieldValue]);

    const COLUMNS = useMemo(() => {
        return ([
            {
                Header: 'Request Date',
                accessor: 'createdDate',
                Cell: ({ cell: { value, }, }) => {
                    if (!value) { return null; }
                    const systemDateFormat = user && user.systemDateFormat ? user.systemDateFormat : null;
                    return <div className="pt-2">{convertSystemDateFormat(value, systemDateFormat)}</div>;
                }
            },
            {
                Header: 'Due Date',
                accessor: 'dueDate',
                Cell: ({ cell: { value, }, }) => {
                    if (!value) { return null; }
                    const systemDateFormat = user && user.systemDateFormat ? user.systemDateFormat : null;
                    return <div className="pt-2">{convertSystemDateFormat(value, systemDateFormat)}</div>;
                }
            },
            {
                Header: 'Request ID',
                accessor: 'retainerRequestId',
                Cell: ({ row: { original, }, cell: { value, }, }) => {
                    return (
                        <div className="pt-2">
                            {value}
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className="text-right">
                        {`Amount Requested`}
                    </div>
                ),
                accessor: 'requestAmount',
                Cell: ({ row: { index, }, cell: { value, }, }: CellProps<RetainerRequestPayments>) => {
                    return (
                        <div className="font-weight-bold text-right pt-2">
                            {currencyFormat(value, firm)}
                        </div>
                    );
                }
            },
            {
                Header: () => (
                    <div className="text-right">
                        {`Balance`}
                    </div>
                ),
                accessor: 'balance',
                Cell: ({ row: { index, }, cell: { value, }, }: CellProps<RetainerRequestPayments>) => {
                    // Total label in total row
                    if (isLastRow(index, tableData.length)) {
                        return (
                            <div className="text-right pt-2">
                                {`Total`}
                            </div>
                        );
                    };

                    return (
                        <div className="font-weight-bold text-right pt-2">
                            {currencyFormat(value, firm)}
                        </div>
                    );
                }
            },
            {
                Header: () => (
                    <div className="text-right" style={{ width: 250, }}>
                        {`Payment`}
                    </div>
                ),
                accessor: 'retainerTotal',
                Cell: ({ row: { index, original, }, cell, }: CellProps<RetainerRequestPayments>) => {
                    //const history = R.path(['row', 'original',], cell);
                    // Total payment in total last row
                    if (isLastRow(index, tableData.length)) {
                        return (
                            <RetainerTotalPaymentField
                                totalRetainerPayments={retainerTotal}
                            />
                        );
                    };

                    const retainer = original;

                    return (
                        <RetainerPaymentField
                            retainer={retainer}
                            rowIndex={index}
                        />
                    );
                }
            }
        ]);
    }, [user, firm, tableData.length, retainerTotal]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows
    } = useTable({
        columns: COLUMNS,
        data: tableData,
    });

    return (
        <div className='d-flex' style={{ flex: 1, }}>
            <Table borderless responsive {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.length < 3 && (
                        <tr style={{ height: "50px" }}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    )}
                    {
                        rows.map((row, i) =>
                            prepareRow(row) || (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <StyledCell {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </StyledCell>
                                        );
                                    })}
                                </tr>
                            ))
                    }
                </tbody>
            </Table>
        </div>
    );
};

export default RetainerPaymentArrayDynamicField;