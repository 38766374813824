/* @flow */

import { APP_CONFIG, } from 'constants/index';
import { type ApiResponse, type ConduitApiService, } from 'infra/conduit/ApiService';
import { type User, type UserAuthInfo, type UserNewPasswordCredentials, type UserRepository, type UserResetCredentials, RegisterCredentials } from 'domain/user';

type Dependencies = {
  conduitApiService: ConduitApiService
};

type signInRequestData = {
  credentials: {
    username: string,
    password: string,
  },
};

type resetRequestData = {
  email: string
};

type resetPasswordData = {
  credentials: {
    password: string
  },
};

type UpdateUserInfoBody = {
  userId?: string, // Only needed if an admin is updating user
  user: {
      email?: string,
      password?: string, // Provide if updating to a new password
      clients?: number[], // Ids of clients user has access to
      firstName?: string,
      lastName?: string,
      systemDateFormat?: string,
      invoiceNotificationsEnabled?: boolean,
  },
  currentPassword?: string, // Only needed if updating password
}

export default ({ conduitApiService, }: Dependencies): UserRepository => ({
  /**
   * Register new account when get invite token
   *
   * @param {*} { currentUserToken, }
   * @returns
   */
  async register(data: RegisterCredentials, { currentUserToken, }) {
    const res = await conduitApiService.authPost({
      url: 'users',
      userToken: currentUserToken,
      data: data,
    });
    
    if (res.error) {throw res.error;}
    
    // Return message when calling request is successed
    return res.success;
  },
  /**
   * request login with auth credentials
   * 
   * @param {UserAuthInfo} userAuthInfo - user auth info
   */
  async byAuthInfo(userAuthInfo: UserAuthInfo) {
    const data: signInRequestData = {
      credentials: {
        ...userAuthInfo,
      },
    };

    const res: ApiResponse = await conduitApiService.post({
      url: 'tokens/session/user/login',
      data,
    });


    if (res.error) {throw new Error(res.error);}

    // login api will return auth and refresh token
    // | 200 | {"success": {accessToken, refreshToken}} |
    const { success: { accessToken, refreshToken, }, } = res;
    return {
      authToken: accessToken,
      refreshToken,
    };
  },

  add(user) {
    return this._authUser(user, 'users');
  },

  resetRequest(resetCredential: UserResetCredentials) {
    const data: resetRequestData = {
      ...resetCredential,
    };

    return this._resetCredential(data, 'tokens/session/user/emailResetPasswordRequest');
  },

  resetPassword(credentials: UserNewPasswordCredentials) {
    const data: resetPasswordData = {
      credentials: {
        password: credentials.password,
      },
    };

    const options: any = {
      headers: {
        Authorization: `Bearer ${credentials.token}`,
      },
    };

    return this._resetCredential(data, 'tokens/session/user/resetPassword', options);
  },

  async update(editingUser, { currentUserToken, }) {
    const data: UpdateUserInfoBody = {
      user: {
        ...editingUser,
      },
    };

    // add currentPassword if update user password
    if (editingUser.currentPassword) {
      data.currentPassword = editingUser.currentPassword;

      // remove current password field from editingUser
      delete data.user.currentPassword;
    }

    const res = await conduitApiService.authPut({
      url: 'users',
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw new Error(res.error);}

    // | PUT | 200 | { "success": "User successfully updated" } |
    const { success, } = res;
    return success;
  },

  /**
   * get current account user ( auth token )
   *
   * @param {*} { currentUserToken, }
   * @returns
   */
  async getByToken({ currentUserToken, }) {
    const res = await conduitApiService.authGet({
      url: 'users',
      userToken: currentUserToken,
    });
    
    if (res.error) {throw res.error;}
    
    // get current auth user only need current user
    // but api returning array of 1 element
    return res.success[0];
  },

  async _authUser(user, url) {
    const res: ApiResponse = await conduitApiService.post({
      url,
      data: user,
    });

    if (res.error) {throw new Error(res.error);}

    return res.success;
  },

  async _resetCredential(user, url, options) {
    const content = {
      url,
      data: user,
      options,
    };

    const res: ApiResponse = await conduitApiService.post(content);
    
    if (res.error) {throw new Error(res.error);}

    if(res.message) { res.success = res.message; }

    return res.success;
  },

  _serializeUserData({ email, username, bio, image, password, }) {
    return {
      email,
      username,
      bio,
      image,
      password,
    };
  },
});
