// @flow
import * as R from 'ramda';

import React, { useCallback, useMemo, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import { type FormikActions, } from 'formik';
import { type ResetPasswordResponsePayload, } from 'state/user/types';
import ResetPasswordV from "./ResetPasswordV";
import { UserActionCreators, } from 'state/actions';
import { type UserNewPasswordCredentials, } from 'domain/user';
import { UserRedux, } from 'state/reducers';
import { useHistory, useParams, } from "react-router-dom";
import { jwtDecoded, } from 'utilities/stringUtils';

const useConnect = () => {
  // mapState
  const user = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const mapState = {
    user,
  };

  // mapDispatch
  const dispatch = useDispatch();
  
  const mapDispatch = useMemo(() => ({
    onResetPassword: (userReset: UserNewPasswordCredentials) => dispatch(UserActionCreators.resetPassword(userReset, { thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

const initialFormValues: UserNewPasswordCredentials = {
  password: '',
  confirmPassword: '',
  token: '',
};

const ResetCredentialsVM = () => {
  // react redux connect
  const rdx = useConnect();
  const { onResetPassword, } = rdx;

  // get token from url parameters
  const { token, } = useParams();
  
  // History for redirect
  const history = useHistory();

  /**
   * on login form submit
   * request reset password then nav to login screen with success message
   * 
   * @param {UserNewPasswordCredentials} values - form values
   * @param {FormikActions} actions - formikbag actions
   */
  const onSubmit = useCallback(async (values: UserNewPasswordCredentials, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);

      values.token = token;
      const { message, }: ResetPasswordResponsePayload = await onResetPassword(values);
      history.push('/login', { message, });
    } catch (e) {
      // handle error
      actions.setStatus(null);
      actions.setErrors({
        auth: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ onResetPassword, token, history, ]);

  const username = useMemo(() => {
    const { username, } : { username: string } = jwtDecoded(token);
    if (username) {
      return username;
    }
    return '';
  }, [ token, ]);
  
  return (
    <ResetPasswordV
      {...rdx}
      initialFormValues={initialFormValues}
      username={username}
      onSubmit={onSubmit}
    />
  );
};

export default ResetCredentialsVM;
