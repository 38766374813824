import * as R from 'ramda';

// @flow
import { type Firm, convertSystemDateFormat, } from 'domain/firm';
import React, { useMemo, } from 'react';
import { usePagination, useTable, } from 'react-table';
import { type Payment, HEADNOTE_PAYMENT_STATUS } from 'domain/payment';
import Table from 'react-bootstrap/Table';
import { type User, } from 'domain/user';
import { currencyFormatWithoutParenthesis, } from 'utilities/stringUtils';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;

type Props = {
  className?: string,
  data: Array<Payment>,
  firm: Firm,
  user: User,
};

const PaymentTable = ({ data, firm, user, }: Props) => {
  // remove balance adjustments from payment history
  const paymentFilter = useMemo(() => {
    return data.filter(payment => !payment.isBalanceAdjustment).sort((a, b) => {
      return new Date(b.paymentDate) - new Date(a.paymentDate);
    });
  }, [data,]);

  const COLUMNS = useMemo(() => (
    [
      {
        Header: 'Date & Payment',
        Cell: ({ row, cell: { value, }, }) => {
          return (
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <span>{convertSystemDateFormat(row.original.paymentDate, user.systemDateFormat)}</span>
              <span className='font-weight-bold'>{` ${currencyFormatWithoutParenthesis(row.original.amount, firm)} `}</span>
            </div>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ cell: { value, row }, }) => {
          const desc = value || '';
          const hnPmtStatus = R.path([  'original', 'hnPmtStatus' ], row);
          if(hnPmtStatus === HEADNOTE_PAYMENT_STATUS.PENDING){
            return (<div>(Pending) {desc}</div>);
          }
          return (<div>{desc}</div>);
        },
      },
    ]
  ), [firm, user.systemDateFormat,]);

  // Use the state and functions returned from useTable to build your UI
  const {
    className,
    getTableProps,
    getTableBodyProps,
    // headerGroups,
    rows,
    prepareRow,
    // which has only the rows for the active page
  } = useTable(
    {
      columns: COLUMNS,
      data: paymentFilter,
      // initialState: { pageIndex: 0, pageSize: APP_CONFIG.QUERY_PAGE_SIZE, },
    },
    usePagination,
  );

  return (
    <StyledWrapper className={className}>

      <Table borderless {...getTableProps()}>
        {/* <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead> */}
        <tbody {...getTableBodyProps()}>
          {
            rows.map((row, i) =>
              prepareRow(row) || (
                <tr {...row.getRowProps()}>
                  <td>
                    {
                      row.cells.map((cell, i) => {
                        return (
                          <React.Fragment key={`cell-${i}`}>
                            {cell.render('Cell')}
                          </React.Fragment>
                        );
                      })
                    }
                  </td>
                </tr>
              ))
          }
        </tbody>
      </Table>
    </StyledWrapper>
  );
};

export default PaymentTable;
