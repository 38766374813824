// @flow
import * as R from 'ramda';

import { ACCT_ISO_TYPE, type AcctIsoType, type Client, } from 'domain/client';
import { type Project, } from 'domain/project';
import { Card, Container, Media, } from "react-bootstrap";

import { APP_CONFIG, } from 'constants/index';
import { type AccountHistory, } from 'domain/accountHistory';
import AccountHistoryTable from './components/AccountHistoryTable';
import { Colors, } from 'assets/index';
import { DropDownOption, } from 'view/components/Dropdown/types';
import Dropdown from 'view/components/DropdownCustom';
import { type Firm, } from 'domain/firm';
import React from "react";
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import styled from "styled-components";

const trackMixpanelEventClientDropdown = () => { window.mixpanel.track("b4tp_client_portal_client_dropdown") }
const trackMixpanelEventProjectDropdown = () => { window.mixpanel.track("b4tp_client_portal_project_dropdown") }

type Props = {
  acctIsoType: ?AcctIsoType,
  firm: Firm,
  groupClient: Array<DropDownOption>,
  groupProject: Array<DropDownOption>,
  accountHistory: Array<AccountHistory>,
  loadingClient: boolean,
  loadingProject: boolean,
  selectedClient: Client,
  selectedProject: Project,
  onSelectClient: Function,
  onSelectProject: Function,

  getClientDropDownLabel: Function,
  getClientOptionValue: Function,
  getClientOptionLabel: Function,

  getProjectDropDownLabel: Function,
  getProjectOptionValue: Function,
  getProjectOptionLabel: Function,
}

const StyledContainer = styled(Container)`
  flex: 1;
`;

const StyledCard = styled(Card)`
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
`;

const StyledCardBody = styled(Card.Body)`
  min-height: ${APP_CONFIG.minTableAccountHeight};
`;

const AccountingV = ({
  firm,
  acctIsoType,
  accountHistory, 
  groupClient, 
  groupProject,
  loadingClient,
  loadingProject,
  selectedClient,
  selectedProject,
  onSelectClient, 
  onSelectProject,

  getClientDropDownLabel,
  getClientOptionValue,
  getClientOptionLabel,

  getProjectDropDownLabel,
  getProjectOptionValue,
  getProjectOptionLabel,
}: Props) => {
  const isProjectDropDownDisabled = acctIsoType !== ACCT_ISO_TYPE.project;

  const clientId = R.path([ 'id', ], selectedClient);
  const projectId = R.path([ 'id', ], selectedProject);

  return (
    <StyledContainer className="my-4">
      <div className="container">
        <Media className="mb-1">
          <Media.Body>
            <h4>{`${firm ? firm.firmName : '___'}`}</h4>
          </Media.Body>
        </Media>

        <Row className='mx-0 mb-1'>
          <Dropdown
            className="mr-1"
            getDropDownLabel={getClientDropDownLabel}
            getOptionLabel={getClientOptionLabel}
            getOptionValue={getClientOptionValue}
            options={groupClient}
            placeholder="Client"
            value={selectedClient}
            onChange={onSelectClient}
            onMenuOpen={trackMixpanelEventClientDropdown}
          />
          {
            loadingClient || loadingProject ? (
              <Spinner animation="border" className="ml-2" role="status" />
            ) : !isProjectDropDownDisabled && (
              <Dropdown
                className="mr-1"
                disable={isProjectDropDownDisabled}
                getDropDownLabel={getProjectDropDownLabel}
                getOptionLabel={getProjectOptionLabel}
                getOptionValue={getProjectOptionValue}
                options={groupProject}
                placeholder="Project"
                value={selectedProject}
                onChange={onSelectProject}
                onMenuOpen={trackMixpanelEventProjectDropdown}
              />
            )
          }
        </Row>

        <StyledCard>
          <Card.Body className="pb-0">
            <Card.Title><h3>{`Account History`}</h3></Card.Title>
            <hr />
          </Card.Body>
          <StyledCardBody className="h-100 pt-0 d-flex flex-column">
            <div className="h-100 flex-grow-1 d-flex flex-column">
              {loadingClient || loadingProject ? (
                <Spinner animation="border" className="ml-2" role="status" />
              ) : (
                <AccountHistoryTable
                  clientId={clientId}
                  data={accountHistory}
                  projectId={projectId}
                />
              )}
            </div>
          </StyledCardBody>
        </StyledCard>
      </div>
    </StyledContainer>
  );
};

export default AccountingV;
