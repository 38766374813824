/* @flow */

// most common styles
export const defaultTextColor: string = "#333333";
export const defaultFontSize: number = 16; // about 0.95 rem
export const defaultFontREM: string = "1.1rem";
export const defaultFontWeight: number = 500;
export const defaultFontFamilies: string = ["ProximaNova", "Arial", "sans-serif"].join(',');
export const defaultContrastColor: string = "#ffffff";
export const defaultMainBackgroundColor: string = "#3774D9";
export const defaultHeaderBackgroundColor: string = "#27489e";
export const defaultMainBackgroundHoverColor: string = "#27489E";
export const defaultMainBackgroundDisabledColor: string = "#75B7F9";
export const defaultSecondaryBackgroundColor: string = "#ededed";
export const defaultDisabledButtonBackgroundColor: string = "#a5c5e0";
export const defaultSecondaryButtonBackgroundColor: string = "#AFAAB5";
export const defaultSecondaryButtonHoverColor: string = "#818799";
export const defaultBorderStyling: string = "1px solid #dedede";
export const defaultLinkColor: string = "#3685C7";
export const defaultLinkHoverColor: string = "#0056b3";