// @flow
import * as R from 'ramda';

import { type AcctIsoType, getAcctIsoType, ACCT_ISO_TYPE, } from 'domain/client';
import { ClientActionCreators, InvoiceActionCreators, PaymentActionCreators } from 'state/actions';
import { type ClientMap, type getAccountBalancePayload, } from 'state/client/types';
import { ClientRedux, InvoiceRedux, UserRedux, } from 'state/reducers';
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, useParams, useLocation, } from 'react-router-dom';
import { getAdjacentUrlParams, } from 'utilities/stringUtils';

import { type Firm, } from 'domain/firm';
import { type Invoice, } from 'domain/invoice';
import { type InvoiceMap, type GetSingleInvoicePayload, } from 'state/invoice/types';
import { type getSingleClientPayload, } from 'state/client/types';
import InvoiceV from './InvoiceV';

// connect redux
const useConnect = () => {
  // mapState
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const clientsMap: ClientMap = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getClients));
  const invoicesMap: InvoiceMap = useSelector(R.pipe(InvoiceRedux.getReducerState, InvoiceRedux.selectors.getInvoices));

  const mapState = {
    firm,
    clientsMap,
    invoicesMap,
  };

  // mapDispatch
  const dispatch = useDispatch();

  const mapDispatch = useMemo(() => ({
    getSingleInvoice: (payload: GetSingleInvoicePayload) => dispatch(InvoiceActionCreators.getSingleInvoice(payload, { thunk: true, })),
    getSingleClient: (payload: getSingleClientPayload) => dispatch(ClientActionCreators.getSingleClient(payload, { thunk: true, })),
    fetchAccountBalance: (payload: getAccountBalancePayload) => dispatch(ClientActionCreators.getAccountBalance(payload)),
    fetchHeadnoteApplication: (): Promise<any> => dispatch(PaymentActionCreators.getHeadnoteApplication({ thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

const InvoiceVM = () => {
  // Get state in store
  const {
    firm,
    clientsMap, invoicesMap,
    getSingleInvoice, fetchAccountBalance,
    getSingleClient, fetchHeadnoteApplication
  } = useConnect();

  // route params and state
  const { id: invoiceId, clientId, projectId, } = useParams();
  const location = useLocation();

  // state single client to check
  const [ client, setClient, ] = useState(undefined);

  // fetch account balance from client and project
  useEffect(() => {
    (
      async () => {
        try {
          const getSingleClientPayload : getSingleClientPayload = {
            clientId,
          };
      
          const response = await getSingleClient(getSingleClientPayload);
          
          setClient(response);

          const accountBalancePayload: getAccountBalancePayload = {
            clientId,
            projectId,
            acctIsoType: getAcctIsoType(firm, response),
          };
      
          
          await fetchAccountBalance(accountBalancePayload);
        } catch (e) {
          console.log('>>> fetch signle client >>> fetch acocunt balance');
        }
      }
    )();
  }, [ clientId, projectId, fetchAccountBalance, getSingleClient, firm, ]);

  useEffect(() => {
    if (firm && firm.portalSettings.isHeadnote) {
      fetchHeadnoteApplication();
    }
  }, [firm])

  const invoiceItem = useMemo<Invoice | undefined>(
    () => invoicesMap.get(parseInt(invoiceId)),
    [ invoicesMap, invoiceId, ]
  );
    
  const acctIsoType = useMemo<AcctIsoType | undefined>(() => {
    if (!invoiceItem) {return undefined;}
    
    if (!firm || !invoiceItem.clientId || !client) {return null;}

    return getAcctIsoType(firm, client);
  }, [ firm, invoiceItem, client, ]);
  
  // get single invoice
  const [ error, setError, ] = useState(null);
  const [ loading, setLoading, ] = useState(false);

  useEffect(() => {
    setError(null);
    const getInvoiceItem = async () => {
      try {
        setLoading(true);
        
        const payload: GetSingleInvoicePayload = {
          clientId,
          invoiceId,
        };
        await getSingleInvoice(payload);
      } catch(e) {
        setError(e.message || e);
      } finally {
        setLoading(false);
      }
    };

    getInvoiceItem();
  }, [ clientId, getSingleInvoice, invoiceId, location, ]);

  // react router history object
  const history = useHistory();

  /**
   * handle pay now button press
   * nav to invoice payment screen
   */
  const onPayNow = useCallback(() => {
    // do nothing if no invoiceId
    if (!invoiceId) {return;}

    // nav to invoice payment page with selected invoiceId
    // TODO: invoice payment with invoice id instead of client and project id ?
    let url = `/invoice-payment-single/${invoiceId}${getAdjacentUrlParams(clientId)}`;

    if (acctIsoType === ACCT_ISO_TYPE.project) {
      url += `${getAdjacentUrlParams(projectId)}`;
    }

    history.push(url);
  }, [ history, invoiceId, clientId, acctIsoType, projectId, ]);

  return (
    <InvoiceV
      error={error}
      firm={firm}
      invoiceId={invoiceId}
      invoiceItem={invoiceItem}
      isolated={acctIsoType}
      loading={loading}
      onPayNow={onPayNow}
    />
  );
};

export default InvoiceVM;
