// @flow
import React, { type Node, } from 'react';

import { Colors, } from 'assets';
import Feedback from 'react-bootstrap/Feedback';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import styled from 'styled-components';

const StyledForm = styled(FormGroup)`
  .is-invalid {
    &::placeholder {
      color: ${Colors.redDanger} !important;
    }
  }
`;

const StyledFormError = styled(Feedback)`
  color: ${Colors.redDanger};
`;

type Props = {
  value?: any,
  error?: string,
  touched?: string,
  className?: string,
  label?: string,
  labelClassName?: string,
  children: Node,
};

const FormInput = ({
  value, error, touched,
  label, labelClassName, className,
  children,
}: Props) => {
  return (
    <StyledForm className={className}>
      {
        label && <FormLabel className={labelClassName}>{label}</FormLabel>
      }
      {children}
      {
        error && <StyledFormError type="invalid">{error}</StyledFormError>
      }
    </StyledForm>
  );
};

export default FormInput;
