// @flow
import * as R from 'ramda';
import parseDomain, { type ParsedDomain, } from 'parse-domain';

import { APP_CONFIG, ERRORS, ERROR_MESSAGE_MAPPING, } from 'constants/index';
import { format, fromUnixTime, isAfter, } from 'date-fns';
import jwt from 'jsonwebtoken';
import queryString from 'query-string';

export type CurrencyOption = {
  currencyCode?: string,
  currencySymbol?: string,
};

const getCurrency = (
  value: string | number,
  intlOptions: ? Intl.NumberFormatOptions = {},
): string => {
  const currency = new Intl.NumberFormat('us-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...intlOptions,
  }).format(Math.abs(Number(value)));
  return currency;
}

/**
 * get currency formatted number
 *
 * @param {*} value number value
 * @param {*} [currencyOption={}] currency option from firm config
 * @param {*} [intlOptions={}] additional intl option
 * @returns {string} currency formatted number
 */
export const currencyFormat = (
  value: string | number,
  currencyOption: ?CurrencyOption = {},
  intlOptions: ?Intl.NumberFormatOptions = {},
): string => {
  // 0 is falsy value so !0 return true, check with null instead
  if (value == null || isNaN(value)) { return '-'; }

  const currency = getCurrency(value, intlOptions);
  return value < 0 ? `(${currencyOption.currencySymbol || ''}${currency})` : `${currencyOption.currencySymbol || ''}${currency}`;
};

/**
 * get currency formatted number without parenthesis for negative number
 *
 * @param {*} value number value
 * @param {*} [currencyOption={}] currency option from firm config
 * @param {*} [intlOptions={}] additional intl option
 * @returns {string} currency formatted number
 */
export const currencyFormatWithoutParenthesis = (
  value: string | number,
  currencyOption: ?CurrencyOption = {},
  intlOptions: ?Intl.NumberFormatOptions = {},
): string => {
  // 0 is falsy value so !0 return true, check with null instead
  if (value == null || isNaN(value)) { return '-'; }

  const currency = getCurrency(value, intlOptions);
  return `${currencyOption.currencySymbol || ''}${currency}`;
};

/**
 * get subdomain string from location host url
 *
 * @returns {string} subdomain string
 */
export const getFirmName = (): string => {
  const parsed: ParsedDomain = parseDomain(window.location.host);

  // host url is localhost in development so we need to set it for testing
  if (APP_CONFIG.isDev) { return process.env.REACT_APP_API_DEFAULT_DEV_FIRMNAME || 'dem'; }

  // subdomain could be for ex `dem.clientportalv2.development.corp`
  // firm name will be `dem` ( first part )
  const parts = parsed && parsed.subdomain ? parsed.subdomain.split('.') : [];
  const firmName = parts[0];

  return firmName;
};

/**
 * get account balance string to display to text
 *
 * @param {*} balance balance value
 * @param {*} [currencyOption={}] currency options
 * @returns {string} formatted account balance
 */
export const getDisplayAccountText = (balance?: number): string => {
  if (balance == null || isNaN(balance)) { return ''; }

  // When the account balance is positive (meaning the client has a credit on their account), text is Account Balance 
  // When account balance is negative, text is Account Credit.
  const numberBalance = Number(balance);

  if (numberBalance > 0) {
    return `Account Credit`;
  } else {
    return `Account Balance`;
  }
};

/**
 * get account balance string to display to user
 *
 * @param {*} balance balance value
 * @param {*} [currencyOption={}] currency options
 * @returns {string} formatted account balance
 */
export const getDisplayAccountBalanceText = (balance?: number, currencyOption: CurrencyOption = {}): string => {
  if (balance == null || isNaN(balance)) { return '-'; }

  // When the account balance is positive (meaning the client has a credit on their account), surround the balance with parentheses.
  // When account balance is negative, show the balance as a positive number.
  const numberBalance = Number(balance);

  if (numberBalance > 0) {
    return `(${currencyFormat(numberBalance, currencyOption)})`;
  } else {
    return `${currencyFormat(Math.abs(numberBalance), currencyOption)}`;
  }
};

/**
 * get number value from masked currency string
 *
 * @param {string} value currency string value
 * @returns {number} number value
 */
export const unmaskCurrencyToNumber = (value: string): number => {
  // remove all non-digit and not "." character
  return Number(value.replace(/[^\d.]/g, ''));
};

/**
 * get query params from url search string
 *
 * @param {string} search: url search string
 * @returns {Object} query params object
 */
export const getLocationQueryString: (string) => Object = (search) => {
  return queryString.parse(search);
};

/**
 * get boolean value from string boolean
 *
 * @param {string} text
 * @returns {bool}
 */
export const getBoolFromString = (text: string): boolean => {
  return text === 'true';
};

/**
 * convert epoc date to mm/dd/yyyy
 *
 * @param {string} text
 * @returns {bool}
 */
export const convertEpocToDate = (date: Date): string | null => {
  if (!date) {
    return null;
  }

  const internalDate = new Date(date);

  return internalDate.getTime() ? format(internalDate, 'MM/dd/yyyy') : null; // Check if date type
};

/**
 * Check and return link with http://
 *
 * @param {string} text
 * @returns {bool}
 */
export const httpString = (str: string): string => {
  const symbolHttp = str.substring(0, 7);
  const symbolHttps = str.substring(0, 8);
  const http = 'http://';
  const https = 'https://';
  return symbolHttp === http || symbolHttps === https ? str : http + str;
};

/**
 * get adjacent router url params
 *
 * @param {(number | string)} item
 * @returns {string}
 */
export const getAdjacentUrlParams = (item: number | string | undefined): string => {
  return item ? `/${String(item)}` : '';
};

/**
 * Decode token
 *
 * @param {string} search: url search string
 * @returns {Object} query params object
 */
export const jwtDecoded: (string) => Object = (token) => {
  return jwt.decode(token);
};

/**
 * Return site URL
 *
 * @returns {String} query params string
 */
export const site_url = (): string => {
  const hostName = window.location.href.split("/");
  return hostName[0] + "//" + hostName[2];
};

/**
 * Return Format number with decimal
 *
 * @returns {Number} number with decimal
 */
export const formatNumber = (num: number, decimal: number = 2): number => {
  return (Math.round(num * 100) / 100).toFixed(decimal);
};

/**
 * Mapping error message
 *
 * @returns {Number} number with decimal
 */
export const changeErrorToMessage = (error: string): { key: string, message: string, } => {
  if (R.includes(ERROR_MESSAGE_MAPPING.invalid_password, error)) {
    return { key: 'currentPassword', message: ERRORS.invalid_password, };
  }

  return { key: 'password', message: ERRORS.invalid_password, };
};

/**
 * Compare Epoch date with Current date
 *
 * @returns {Number} number with integer
 */
export const compareEpochDate = (date: string): number => {
  const dateLeft: string = fromUnixTime(date);
  return isAfter(dateLeft, new Date());
};

/**
 * Title format
 *
 * @returns {Number} number with integer
 */
export const titleFormat = (pageTitle: string): string => {
  return `${pageTitle} | Client Portal`;
};