// @flow
import React, { type Node, useCallback, } from 'react';

import Button from 'react-bootstrap/Button';
import { Colors, } from 'assets/index';
import styled from 'styled-components';

const StyledRemoveButton = styled(Button)`
  background-color: ${Colors.redRemove} !important;
  border: none !important;
  font-weight: bold !important;
  box-shadow: none !important;
  text-transform: uppercase !important;
  &:hover {
    background-color: ${Colors.redRemoveHover} !important;
  }
`;

type Props = {
  className?: string,
  children: Node,
  index: number,
  onClick?: (number) => any,
  amount: number,
};

const RemoveInvoiceBtn = ({
  className, children, index, onClick, amount,
}: Props) => {
  const onBtnClick = useCallback(() => {
    if (amount > 1) {
      onClick && onClick(index);
    }
  }, [ index, onClick, amount, ]);

  return (
    <StyledRemoveButton className={`${className || ''}`} onClick={onBtnClick}>
      {
       children 
      }
    </StyledRemoveButton>
  );
};

export default RemoveInvoiceBtn;
