/* @flow */
import { type PaymentRepository, } from 'domain/payment';

import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: () => void,
  onError: (Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
};

export type GetHeadnoteApplicationBehaviour = (
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export default ( { paymentRepository, }: Dependencies, ) => {
  /**
   * get headnote application
   * 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getHeadnoteApplicationBehaviour: GetHeadnoteApplicationBehaviour = async (
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      const application = await paymentRepository.getHeadnoteApplication(withCurrentUserToken);
      
      return onSuccess(application);
    } catch(error) {
      return onError(error);
    }
  };

  return getHeadnoteApplicationBehaviour;
};
