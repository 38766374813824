// @flow
import React, { type Node, } from 'react';

import { Colors, } from 'assets';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

type Props = {
  label: string | Node;
  className?: string;
};

const StyledRow = styled(Row)`
  border-bottom: 0.5px solid ${Colors.lightGrey};
`;

const FormLabel = ({ label, className = '', }: Props) => {
  return (
    <StyledRow className={`mx-0 ${className}`}>
      <h4>
        {label}
      </h4>
    </StyledRow>
  );
};

export default FormLabel;
