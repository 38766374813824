/* @flow */
import { createActions } from 'reduxsauce';
import { reducerPrefixFormat } from 'state/common';

/* ------------- Statement ------------- */
export const { Types, Creators } = createActions({
    getStatementLink: ['payload', 'meta',],
    getStatementLinkSuccess: ['payload', 'meta',],

    requestFailure: ['error', 'payload' /* error payload */, 'meta',]
}, {
    prefix: reducerPrefixFormat('statement'),
});