export default {
  // app specific
  HOST: process.env.REACT_APP_API_URL || 'https://dev.com',
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || '',
  AVATAR_URL: 'https://files.s3.amazonaws.com/avatar',
  // generic config
  timeout: 25000,
  unauthorizedErrorCode: 401,
  // stripe
  STRIPE_CLIENT_ID: process.env.REACT_APP_STRIPE_CLIENT_ID,
  STRIPE_CONNECT_AUTH: `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write`,
  stripeApiKey: process.env.REACT_APP_STRIPE_APIKEY || 'pk_test_b53a4fnwo9w6OKucehzH9zhZ',
  // affinipay
  AFFINIPAY_PUBLIC_KEY: process.env.REACT_APP_AFFINIPAY_PUBLIC_KEY,
  // paypal
  PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  PAYPAL_PARTNER_ATTRIBUTION_ID: 'Bill4Time-PCP',
  // Plaid
  PLAID: {
    KEY: process.env.REACT_APP_CLIENT_PLAID_KEY,
    ENV: process.env.REACT_APP_CLIENT_PLAID_ENV || 'sandbox',
  },
  // VGS
  VGS_KEY: process.env.REACT_APP_CLIENT_VGS_KEY || 'tntmrhnuvtw',
  VGS_ENVIRONMENT: process.env.REACT_APP_VGS_ENVIRONMENT || 'live',

  //Google Enterprise reCAPTCHA V3
  RECAPTCHA_KEY: '6LeHGbYmAAAAAMiNISN0Qi6Q2hjDne1aE7kqTt4i'
};
