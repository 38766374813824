/* @flow */
import { type Firm, type FirmRepository, } from 'domain/firm';

import { type ConnectPayload, } from 'state/payment/types';
import { type PaymentRepository, } from 'domain/payment';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (successRes: ConnectPayload) => void,
  onInvalidPayload: (e: Error) => void,
  onError: (e: Error) => void,
};

type Dependencies = {
  firmRepository: FirmRepository,
  paymentRepository: PaymentRepository,
};

export type ConnectStripeBehaviour = (
  string,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { firmRepository, paymentRepository, }: Dependencies, ) => {
  /**
   * connect user stripe account to B4T's
   * 
   * @param {number} authCode - stripe Oauth code
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const connectStripeBehaviour: ConnectStripeBehaviour = async (
    authCode,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      if (!authCode) {
        return onInvalidPayload(new Error('Invalid stripe auth code.'));
      }

      // request stripe integrate api
      const successMsg = await paymentRepository.connectStripePayment(authCode, withCurrentUserToken);

      // load new firm after stripe integration success
      const firm: Firm = await firmRepository.getFirm(withCurrentUserToken);

      const successRes: ConnectPayload = {
        message: successMsg,
        firm,
      };

      return onSuccess(successRes);
    } catch(error) {
      return onError(error);
    }
  };

  return connectStripeBehaviour;
};
