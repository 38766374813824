/* @flow */
import * as R from 'ramda';

import { Colors, Images, } from 'assets/index';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { type Firm, } from 'domain/firm';
import { Link, } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import React from 'react';
import Row from 'react-bootstrap/Row';
import { type UserAuthInfo, } from 'domain/user';
import styled from 'styled-components';

const StyledLogin = styled(Container)`
  flex: 1;
`;

const StyledContainer = styled(Card)`
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
  .card-text {
    padding-bottom: 1rem;
  }
`;

const StyledAlertSuccess = styled(Alert)`
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
  font-size: 14px;
`;

const StyledCardImg = styled(Card.Img)`
  max-height: 150px !important;
  width: auto;
  max-width: 270px !important;
`;

type Props = {
  onClose: Function,
  onSubmit: Function,
  initialFormValues: UserAuthInfo,
  message: ?string,
  firm: Firm
}

const LoginPageV = ({ firm, initialFormValues, onSubmit, message, onClose, }: Props) => {
  const dashboardLogo = R.path(['dashboardLogoFile'], firm);

  return (
    <StyledLogin className="d-flex align-items-center justify-content-center">
      <Row className="w-100">
        <Col className="my-5" md={{ span: 4, offset: 4, }}>
          <StyledContainer>
            <Link className="d-block mx-5 mt-5 mb-4" style={{textAlign: "center"}} to={'/'}>
              <StyledCardImg src={ dashboardLogo || Images.common.defaultLogo}></StyledCardImg>
            </Link>
            <Card.Body className="pb-5">
              <Card.Text>
                {`Welcome, please log in.`}
              </Card.Text>
              {
                message && (
                  <StyledAlertSuccess dismissible variant='success' onClose={onClose}>
                    {message}
                  </StyledAlertSuccess>
                )
              }
              <LoginForm
                initialFormValues={initialFormValues}
                message={message}
                onClose={onClose}
                onSubmit={onSubmit}
              />
            </Card.Body>
          </StyledContainer>
        </Col>
      </Row>
    </StyledLogin>
  );
};

export default LoginPageV;
