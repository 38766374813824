// @flow
import * as R from 'ramda';

import { type AccountBalance, type Client, type ClientHeadnoteInfo } from 'domain/client';
import { ClientActionCreators, ClientTypes, UserTypes, UserActionCreators, } from '../actions';
import { type ClientMap, type getAccountBalancePayload, type getAccountBalanceSuccessPayload, type getClientsSuccessPayload, type getFirmClientsSuccessPayload, 
         type getSingleClientPayload, type getClientHeadnoteInfoPayload, type getClientHeadnoteInfoSuccessPayload } from './types';
import { UserRedux, } from 'state/reducers';
import { END, eventChannel, } from 'redux-saga';
import { cancelled, put, select, take, takeLatest, } from 'redux-saga/effects';

import { type AwilixContainer, } from 'awilix';
import { type GetAccountBalanceBehaviour, } from 'app/accountBalance/GetAccountBalance';
import { type GetClientsBehaviour, } from 'app/client/GetClients';
import { type GetSingleClientBehaviour, } from 'app/client/GetSingleClient';
import { type WithCurrentUserToken, } from 'domain/user';
import { type GetFirmClientsBehaviour, } from 'app/firm/GetFirmClients';
import { type GetClientHeadnoteInfoBehaviour } from 'app/client/GetClientHeadnoteInfo'

/**
 * get clients redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const getClients = function * (container: AwilixContainer, { payload, meta, }) {
  try {
    // resolve get clients behaviour from awilix container
    const getClientsBehaviour: GetClientsBehaviour = container.resolve('getClients');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getClientsBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (clients) => {
            emitter({ data: clients, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data: clients, error, }: { data: Array<Client>, error: Object | string, } = yield take(channel);
        if (!clients && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (clients) {
          // process client list to js Map ['key', 'value']
          const clientTuple = clients.map((c: Client) => [ c.id, c, ]);
          const clientMap: ClientMap = new Map(clientTuple);

          const payload: getClientsSuccessPayload = { clients: clientMap, };
          yield put(ClientActionCreators.getClientsSuccess(payload, meta));
        }
    }
  } catch (e) {
    yield put(ClientActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(ClientActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * get Single client redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const getSingleClient = function * (container: AwilixContainer, { payload, meta, }) {
  try {
    // resolve get clients behaviour from awilix container
    const getSingleClientBehaviour: GetSingleClientBehaviour = container.resolve('getSingleClient');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getSingleClientBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (clients) => {
            emitter({ data: clients, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data: client, error, }: { data: Array<Client>, error: Object | string, } = yield take(channel);
        if (!client && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (client) {
          // process client list to js Map ['key', 'value']
          const payload: Client = client;
          yield put(ClientActionCreators.getSingleClientSuccess(payload, meta));
        }
    }
  } catch (e) {
    yield put(ClientActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(ClientActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * get account balance from client, project redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const getAccountBalance = function * (container: AwilixContainer, { payload, meta, }: { payload: getAccountBalancePayload, meta: ?Object, }) {
  try {
    // resolve get clients behaviour from awilix container
    const getAccountBalanceBehaviour: GetAccountBalanceBehaviour = container.resolve('getAccountBalance');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getAccountBalanceBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (accountBalance) => {
            emitter({ data: { accountBalance, }, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data, error, }: { data: { accountBalance: AccountBalance }, error: Object | string, } = yield take(channel);
        if (!data && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        const { accountBalance, } = data;
        const payload: getAccountBalanceSuccessPayload = { accountBalance, };
        yield put(ClientActionCreators.getAccountBalanceSuccess(payload));
    }
  } catch (e) {
    yield put(ClientActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(ClientActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * get firm clients redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const getFirmClients = function * (container: AwilixContainer, { payload, meta, }) {
  try {
    // resolve get firm clients behaviour from awilix container
    const getFirmClientsBehaviour: GetFirmClientsBehaviour = container.resolve('getFirmClients');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getFirmClientsBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (clients) => {
            emitter({ data: clients, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data: clients, error, }: { data: Array<Client>, error: Object | string, } = yield take(channel);
      if (!clients && !error) {
          break;
      }
      // Handle the data...
      if (error) {throw error;}
      
      if (clients) {
        // process client list to js Map ['key', 'value']
        const clientTuple = clients.map((c: Client) => [ c.id, c, ]);
        const clientMap: ClientMap = new Map(clientTuple);

        const payload: getFirmClientsSuccessPayload = { clients: clientMap, };
        yield put(UserActionCreators.getFirmClientsSuccess(payload, meta));
      }
    }
  } catch (e) {
    yield put(ClientActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(ClientActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * Get the client Headnote info redux saga side effect
 * @param {AwilixContainer} container 
 * @param {Object} data 
 */
const getClientHeadnoteInfo = function * (container: AwilixContainer, { payload, meta}: { payload: getClientHeadnoteInfoPayload, meta: ?Object }) {
  try {
    const getClientHeadnoteInfoBehaviour: GetClientHeadnoteInfoBehaviour = container.resolve('getClientHeadnoteInfo');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
        currentUserToken: token,
    };

    const channel = eventChannel(emitter => {
        getClientHeadnoteInfoBehaviour(
            payload,
            withCurrentUserToken,
            {
                onSuccess: (data) => {
                    emitter({ data, });
                    emitter(END);
                },
                onInvalidPayload: (error) => {
                    emitter({ data: {}, error, });
                    emitter(END);
                },
                onError: (error) => {
                    emitter({ data: {}, error, });
                    emitter(END);
                },
            }
        );

        return () => {
            // clean up here, if needed
        };
    });

    // Process events until operation completes
    while (true) {
        const { data: clientHeadnoteInfo, error }: { data: ClientHeadnoteInfo, error: Object | string } = yield take(channel);
        if (!clientHeadnoteInfo && !error) {
            break;
        }

        if (error) { throw error; }

        if (clientHeadnoteInfo) {
            yield put(ClientActionCreators.getClientHeadnoteInfoSuccess(clientHeadnoteInfo));
        }
    }
  } catch (e) {
      yield put(ClientActionCreators.requestFailure(true, e, meta));
  } finally {
      if (yield cancelled()) {
          yield put(ClientActionCreators.requestFailure(true, null, meta));
      }
  }
};

export default (container: AwilixContainer) => ([
  takeLatest(ClientTypes.GET_CLIENTS, getClients, container),
  takeLatest(ClientTypes.GET_SINGLE_CLIENT, getSingleClient, container), 
  takeLatest(ClientTypes.GET_ACCOUNT_BALANCE, getAccountBalance, container),
  takeLatest(UserTypes.GET_FIRM_CLIENTS, getFirmClients, container),
  takeLatest(ClientTypes.GET_CLIENT_HEADNOTE_INFO, getClientHeadnoteInfo, container)
]);
