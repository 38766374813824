// @flow
import Button from 'react-bootstrap/Button';
import { Colors, } from 'assets';
import styled from 'styled-components';

export const StyleContainer = styled.div`
  flex: 1;
`;

export const StyledSpan = styled.span`
  color: ${Colors.blueCurious};
`;

export const ButtonWrapper = styled.div`
  padding-right: 15px;
`;

export const CancelButton = styled(Button)`
  background-color: ${Colors.yellowInfo} !important;
  color: white !important;
`;
