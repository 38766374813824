// @flow
import { Formik, type FormikActions, type FormikProps, } from 'formik';
import React, { useCallback, } from 'react';
import { ReactStripeElements, injectStripe, } from 'react-stripe-elements';
import { STRIPE_ACCOUNT_HOLDER_TYPE, type StripeAddBankAccountInfo, StripeAddBankAccountInfoSchema, } from 'domain/payment';

import { APP_CONFIG, } from 'constants/index';
import { type AddBankAccountPayload, } from 'state/payment/types';
import BankForm from './StripeBankForm';
import { Button, Alert, Modal, Spinner, } from 'react-bootstrap';
import { PaymentActionCreators, } from 'state/actions';
import SettingButton from '../../../form/SettingsButton';
import styled from 'styled-components';
import { useDispatch, } from 'react-redux';
import FormAlert from '../../../form/FormAlert';
import { ValidateShading, } from 'view/components';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 700px !important;
  }
`;

type Props = {
  isShow: boolean,
  onClose?: Function,
  onHide: Function,
};

const initialValues: StripeAddBankAccountInfo = {
  account_holder_name: '',
  account_holder_type: STRIPE_ACCOUNT_HOLDER_TYPE[0].value,
  account_number: '',
  routing_number: '',
};

const AddBankModal = ({
  isShow = false, onHide, stripe,
}: Props & ReactStripeElements.InjectedStripeProps) => {
  const dispatch = useDispatch();

  const onAddBankAccount = useCallback((payload: AddBankAccountPayload) => {
    return dispatch(PaymentActionCreators.addBankAccount(payload, { thunk: true, }));
  }, [ dispatch, ]);

  // on add bank submit
  // start verifying
  const onVerify = useCallback(async (values: StripeAddBankAccountInfo, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);

      // tokenize stripe bank account
      const { token, error, } = await stripe.createToken(
        'bank_account',
        {
          ...values,
          country: APP_CONFIG.stripeConfig.country,
        }
      );

      if (error) {throw new Error(error.message);}

      // request attach bank account api
      const payload: AddBankAccountPayload = {
        token: token.id,
      };
      await onAddBankAccount(payload);

      // use status to display verification success modal
      actions.setStatus(true);
    } catch (e) {
      // handle error
      actions.setErrors({
        bank: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ stripe, onAddBankAccount, ]);

  // Check error in formik and show message
  const ErrorStatus = useCallback(errors => {
    if(errors.given_name || errors.business_name || errors.bank_account_number || errors.routing_number) {
      return true;
    }
    return false;
  }, []);

  return (
    <StyledModal
      backdrop='static'
      className="p-0"
      show={isShow}
      onHide={onHide}
    >
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={StripeAddBankAccountInfoSchema}
        onSubmit={onVerify}
      >
        {({ errors, handleSubmit, isSubmitting, status, values, }: FormikProps<StripeAddBankAccountInfo>) => (
          <>
            <Modal.Header closeButton className="border-bottom-0">
              <h2>
                {
                  status ? `Verification` : `Bank Account (ACH)`}
              </h2>
            </Modal.Header>
            <Modal.Body>
              <FormAlert alert={errors.bank} />
              { 
                ErrorStatus(errors) &&  (
                  <ValidateShading 
                    className="w-100" 
                    text={ `All fields in blue are required.` }
                  />
                ) 
              }
              {
                status ? (
                  <>
                    <p>
                      {`Success!`}
                    </p>
                    <p>
                      {`In a couple of days you should see two small deposits labelled VERIFICATION on your bank statement. When you have these deposits return here and enter the amounts to finish verifying your account.`}
                    </p>
                  </>
                ) : (  
                  <BankForm errors={errors} values={values} />
                )
              }
            </Modal.Body>
            <Modal.Footer className="border-top-0">
              {
                isSubmitting && (
                  <Spinner animation="border" className="mr-2" role="status" />
                )
              }
              <Button
                className="font-weight-bold border-0"
                disabled={isSubmitting}
                variant="light"
                onClick={onHide}
              >
                {`Close`}
              </Button>
              {
                !status && (
                  <SettingButton
                    className="font-weight-bold"
                    disabled={isSubmitting}
                    type='submit'
                    onClick={handleSubmit}
                  >
                    {`Verify`}
                  </SettingButton>
                )
              }
            </Modal.Footer>
          </>
        )}
      </Formik>
    </StyledModal>
  );
};

export default injectStripe(AddBankModal);
