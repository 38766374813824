// @flow
import * as R from 'ramda';

import { type Firm, convertSystemDateFormat, } from 'domain/firm';
import React, { useMemo, } from 'react';
import { currencyFormat, getAdjacentUrlParams, } from 'utilities/stringUtils';
import { usePagination, useTable, } from 'react-table';

import APP_CONFIG from 'constants/APP_CONFIG';
import { Colors, } from 'assets';
import { type Invoice, } from 'domain/invoice';
import { Link, } from 'react-router-dom';
import { Pagination, } from 'view/components';
import Table from 'react-bootstrap/Table';
import { type User, } from 'domain/user';
import styled from 'styled-components';

const trackMixpanelEvent = () => { window.mixpanel.track("b4tp_client_portal_invoice"); }

const StyledLink = styled(Link)`
  color: ${Colors.blueCurious};
`;

const StyledTableWrapper = styled.div`
  overflow-y: auto !important;
`;

type Props = {
  className?: string,
  data: Array<Invoice>,
  user: User,
  firm: Firm,
  isPaid?: ?boolean,
  clientId?: number,
  projectId?: number,
};

const InvoiceTable = ({ data, isPaid, clientId, projectId, firm, user, }: Props) => {
  const COLUMNS = useMemo(() => (
    [
      {
        Header: 'Date',
        accessor: 'invoiceDate',
        Cell: ({ cell: { value, }, }) => {
          return convertSystemDateFormat(value, user.systemDateFormat);
        },
      },
      {
        Header: 'Invoice',
        accessor: 'id',
        Cell: ({ cell, }) => {
          const history: Invoice = R.path([ 'row', 'original', ], cell);
          
          // link to specific invoice page
          return (
            <StyledLink to={`/invoice/${cell.value}${getAdjacentUrlParams(clientId)}${getAdjacentUrlParams(projectId)}`} onClick={trackMixpanelEvent}>
              {history.invoiceLabel}
            </StyledLink>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ cell: { value, }, }) => value || '___',
      },
      {
        Header: () => (
          <div className="text-right">
            {`Balance`}
          </div>
        ),
        accessor: 'invoiceBalance',
        Cell: ({ cell: { value, }, }) => {
          return (
            <div className="font-weight-bold text-right">
              {currencyFormat(value, firm)}
            </div>
          );
        },
      },
    ]
  ), [ user.systemDateFormat, clientId, projectId, firm, ]);

  // filter invoice base on paid status
  const filteredPaidData = useMemo(() => {
    if (isPaid != null) {
      return data.filter(i => {
        return i.isPaid === isPaid;
      });
    }

    return data;
  }, [ data, isPaid, ]);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // pagination props
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, },
  } = useTable(
    {
      columns: COLUMNS,
      data: filteredPaidData,
      initialState: { pageIndex: 0, pageSize: APP_CONFIG.QUERY_PAGE_SIZE, },
    },
    usePagination,
  );

  return (
    <StyledTableWrapper className="h-100 d-flex flex-column">

      <div className='d-flex' style={{ flex: 1, }}>
        <Table borderless responsive {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              page.map((row, i) =>
              prepareRow(row) || (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>

      <Pagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        onNext={nextPage}
        onPrev={previousPage}
      />

    </StyledTableWrapper>
  );
};

export default InvoiceTable;
