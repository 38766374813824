// @flow
import React from 'react';

import UserPermissionsV from './UserPermissionsV';

const UserPermissionsVM = () => {
  return (
    <UserPermissionsV />
  );
};

export default UserPermissionsVM;
