// @flow
import { BANK_ACCOUNT_STATUS, type BankAccountData, } from 'domain/payment';
import { ButtonWrapper, CancelButton, } from '../../UI';
import React, { useCallback, useMemo, useState, } from 'react';

import ConfirmRemoveModal from './ConfirmRemoveModal';
import { PaymentActionCreators, } from 'state/actions';
import { type RemoveBankAccountPayload, } from 'state/payment/types';
import SettingButton from '../../form/SettingsButton';
import Spinner from 'react-bootstrap/Spinner';
import VerifiyBankModal from './VerifyBankModal';
import { useDispatch, } from 'react-redux';

type Props = {
  bankAccount: BankAccountData,
};

// connect redux
const useConnect = () => {
  const dispatch = useDispatch();

  const mapDispatch = useMemo(() => ({
    removeBankAccount: (payload: RemoveBankAccountPayload) => dispatch(PaymentActionCreators.removeBankAccount(payload, { thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapDispatch,
  };
};

/**
 * custom hook handle verify bank account
 *
 * @returns
 */
const useVerfifyBank = () => {
  const [ isShowVerifyBank, setIsShowVerifyBank, ] = useState<boolean>(false);

  const onVerifyBankAccountClick = useCallback(() => {
    setIsShowVerifyBank(true);
  }, []);

  const onVerifyBankModalHide = useCallback(() => {
    setIsShowVerifyBank(false);
  }, []);

  return {
    isShowVerifyBank,
    onVerifyBankAccountClick,
    onVerifyBankModalHide,
  };
};

const useRemoveBank = (bankAccount: BankAccountData, removeBankAccount: Function) => {
  const [ isShowRemoveModal, setIsShowRemoveModal, ] = useState<boolean>(false);
  const [ isRemoving, setIsRemoving, ] = useState<boolean>(false);

  const onRemoveModalHide = useCallback(() => {
    setIsShowRemoveModal(false);
  }, []);

  const onPressRemove = useCallback(() => {
    setIsShowRemoveModal(true);
  }, []);

  const onRemoveBank = useCallback(async () => {
    // request remove bank
    try {
      // turn off remove confirm remove modal
      setIsShowRemoveModal(false);
      // start remove loading indicator
      setIsRemoving(true);
      const data: RemoveBankAccountPayload = {
        bankId: bankAccount.id,
      };

      await removeBankAccount(data);
    } catch (e) {
      console.log('[Remove Bank Account Error]', { e, });
    } finally {
      setIsRemoving(false);
    }
  }, [ bankAccount.id, removeBankAccount, ]);

  return {
    isRemoving,
    isShowRemoveModal,
    onRemoveModalHide,
    onPressRemove,
    onRemoveBank,
  };
};

const BankAccountRow = ({ bankAccount, }: Props) => {
  const { removeBankAccount, } = useConnect();

  const {
    isShowVerifyBank,
    onVerifyBankAccountClick,
    onVerifyBankModalHide,
  } = useVerfifyBank();

  const {
    isRemoving,
    isShowRemoveModal,
    onRemoveModalHide,
    onPressRemove,
    onRemoveBank,
  } = useRemoveBank(bankAccount, removeBankAccount);

  return (
    <React.Fragment>
      <ButtonWrapper className='d-flex flex-row justify-content-between pt-4'>
        <div className={`d-flex flex-column justify-content-center`}>
          <span>
            {`Account ending in ${bankAccount.last4}`}
          </span>
        </div>

        <div className={`d-flex flex-row justify-content-center`}>
          {
            isRemoving && (
              <Spinner animation="border" className="mr-2" role="status" />
            )
          }
          {
            bankAccount.status === BANK_ACCOUNT_STATUS.unverified
            ? (
              <div>
                <SettingButton className={`mr-2`} onClick={onVerifyBankAccountClick}>
                  {`Verify Account`}
                </SettingButton>

                <CancelButton className={`border-0`} onClick={onPressRemove}>
                  {`Cancel Verification`}
                </CancelButton>
              </div>
            ) : (
              <div>
                <CancelButton className={`border-0`} onClick={onPressRemove}>
                  {`Remove Bank Account`}
                </CancelButton>
              </div>
            )
          }
        </div>
      </ButtonWrapper>

      <VerifiyBankModal
        bankAccountId={bankAccount.id}
        isShow={isShowVerifyBank}
        onHide={onVerifyBankModalHide}
      />
      <ConfirmRemoveModal
        isShow={isShowRemoveModal}
        onHide={onRemoveModalHide}
        onModalAccept={onRemoveBank}
      />
    </React.Fragment>
  );
};

export default BankAccountRow;
