import { PaymentRepository, HEADNOTE_BANK_ACCOUNT_TYPES } from 'domain/payment';
import { WithCurrentUserToken } from 'domain/user';

type Callbacks = {
    onSucces: (receivingBankType: HEADNOTE_BANK_ACCOUNT_TYPES) => void;
    onError: (e: Error) => void;
}

type Dependencies = {
    paymentRepository: PaymentRepository
}

export type GetPaymentReceivingBankTypeBehaviour = (
    Payload, WithCurrentUserToken, Callbacks
) => any

export default ({ paymentRepository }: Dependencies) => {
    /**
     * Gets the Headnote Payment Deposit Bank Account Type
     * @param {Payload} payload - retainerId, depositBankAccountId
     * @param {WithCurrentUserToken} withCurrentUserToken  - auth token
     * @param {Callbacks} callbacks - call back for each use case 
     * @returns 
     */
    const getPaymentReceivingBankType: GetPaymentReceivingBankTypeBehaviour = async (
        payload, withCurrentUserToken, { onSuccess, onError }
    ) => {
        try {
            const receivingBankType: HEADNOTE_BANK_ACCOUNT_TYPES = await paymentRepository.getPaymentReceivingBankType(payload, withCurrentUserToken);
            return onSuccess(receivingBankType);
        } catch (e) {
            return onError(e);
        }
    }
    return getPaymentReceivingBankType;
}