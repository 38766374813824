/* @flow */
import { type Firm, type FirmRepository, } from 'domain/firm';
import { type PaymentRepository, type ConnectCustomPaymentBody, } from 'domain/payment';
import { type WithCurrentUserToken, } from 'domain/user';
import { type ConnectPayload, } from 'state/payment/types';

type Callbacks = {
  onSuccess: (data: ConnectPayload) => void,
  onError: (e: Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
  firmRepository: FirmRepository,
};

type Payload = ConnectCustomPaymentBody;

export type ConnectCusttomPaymentBehaviour = (
  payload: Payload,
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export const makeConnectCustomPayment = ( { paymentRepository, firmRepository, }: Dependencies, ) => {
  /**
   * Connect custom payment to account
   * 
   * @param {Payload} payload - title and url of link payment
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const connectCustomPaymentBody: ConnectCustomPaymentBody = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      const message : string = await paymentRepository.connectCustomPayment(payload, withCurrentUserToken);
      const firm : Firm = await firmRepository.getFirm(withCurrentUserToken);

      const data : ConnectPayload = {
        message,
        firm,
      };

      return onSuccess(data);
    } catch(error) {
      return onError(error);
    }
  };

  return connectCustomPaymentBody;
};

export type DisconnectCusttomPaymentBehaviour = (
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export const makeDisconnectCustomPayment = ( { paymentRepository, firmRepository, }: Dependencies, ) => {
    /**
     * Attach a card to the user
     * 
     * @param {WithCurrentUserToken} withCurrentUserToken - auth token
     * @param {Callbacks} callbacks - call back for each use case
     */
    const disconnectCusttomPaymentBehaviour: DisconnectCusttomPaymentBehaviour = async (
      withCurrentUserToken,
      { onSuccess, onError, },
    ) => {
      try {
        const message : string = await paymentRepository.disconnectCustomPayment(withCurrentUserToken);
        const firm : Firm = await firmRepository.getFirm(withCurrentUserToken);

        const data : ConnectPayload = {
          message,
          firm,
        };

        return onSuccess(data);
      } catch(error) {
        return onError(error);
      }
    };
  
    return disconnectCusttomPaymentBehaviour;
  };