/* @flow */
import { type WithCurrentUserToken, } from 'domain/user';
import { type Client, } from 'domain/client';

import { type FirmRepository, type Feature } from 'domain/firm';

type Callbacks = {
  onSuccess: (clients: Feature[]) => void,
  onError: (e: Error) => void,
};

type Dependencies = {
  firmRepository: FirmRepository,
};

export type GetFirmFeaturesBehaviour = (
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export default ( { firmRepository, }: Dependencies, ) => {
  /**
   * get firm features
   * 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getFirmFeaturesBehaviour: GetFirmFeaturesBehaviour = async (
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      // get firm features
      const featureList = await firmRepository.getFirmFeatures(withCurrentUserToken);

      return onSuccess(featureList);
    } catch(error) {
      return onError(error);
    }
  };

  return getFirmFeaturesBehaviour;
};
