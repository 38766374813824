// @flow
import * as R from 'ramda';

import { FIRM_DATE_FORMAT, type FirmDateFormatInfo, FirmDateFormatInfoSchema, } from 'domain/firm';
import { Field, Formik, type FormikActions, type FormikProps, } from 'formik';
import { FormRadioButton, FormRadioGroup, } from 'view/components/FormRadio';
import React, { useCallback, useMemo, } from 'react';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormAlert from './form/FormAlert';
import FormLabel from './form/FormLabel';
import FormSubmit from './form/FormSubmit';
import { MESSAGES, } from 'constants/index';
import Row from 'react-bootstrap/Row';
import { type UpdateUserPayload, } from 'state/user/types';
import { UserRedux, } from 'state/reducers';
import styled from 'styled-components';
import { useSelector, } from 'react-redux';
import { useUpdateUserInfo, } from '../hooks/updateUser';

const trackMixPanelEventDateFormat = () => { window.mixpanel.track("b4tp_client_portal_change_date_settings") }

const StyleContainer = styled.div`
  flex: 1;
`;

type Props = {
  setSettingStatus: Function;
}

const useConnect = () => {
  // mapState
  const user = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const mapState = {
    user,
  };

  // mapDispatch
  // const dispatch = useDispatch();
  // const mapDispatch = useMemo(() => ({
  // }), [ dispatch, ]);

  return {
    ...mapState,
    // ...mapDispatch,
  };
};

const INITIAL_FORM_VALUES: FirmDateFormatInfo = {
  systemDateFormat: '',
};

const DateFormatForm = ({ setSettingStatus, }: Props) => {
  // connect redux
  const { user, } = useConnect();

  // update user dispatch action
  const { updateUserInfo, } = useUpdateUserInfo();

  // get initial email from user
  const initialValues = useMemo<FirmDateFormatInfo>(() => {
    if (!user) {return INITIAL_FORM_VALUES;}

    return {
      systemDateFormat: user.systemDateFormat,
    };
  }, [ user, ]);

  /**
   * on change date format
   * 
   * @param {FirmDateFormatInfo} values - date format info values from form
   * @param {FormikActions} actions - formik bag actions
   */
  const onSubmit = useCallback(async (values: FirmDateFormatInfo, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);
      // Update firm system date format
      const payload: UpdateUserPayload = {
        info: {
          systemDateFormat: values.systemDateFormat,
        },
      };

      await updateUserInfo(payload);

      // update page status success
      setSettingStatus(MESSAGES.accountSettings.dateFormatUpdated);
    } catch (e) {
      // handle error
      actions.setErrors({
        systemDateFormat: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ setSettingStatus, updateUserInfo, ]);

  return (
    <StyleContainer>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={FirmDateFormatInfoSchema}
        // eslint-disable-next-line
        onSubmit={onSubmit}
      >
        {({ errors, handleSubmit, isSubmitting, touched, values, }: FormikProps<FirmDateFormatInfo>) => {
          return (
            <Form onSubmit={handleSubmit}>

              <FormLabel label='Date Format' />

              <FormAlert alert={errors.systemDateFormat} />

              <Row className='mx-0 pt-4'>
                <Col md={5}>
                  <FormRadioGroup>
                    <Field
                      component={FormRadioButton}
                      id={FIRM_DATE_FORMAT.mmddyyyy}
                      label={FIRM_DATE_FORMAT.mmddyyyy}
                      name="systemDateFormat"
                      value={FIRM_DATE_FORMAT.mmddyyyy}
                    />
                    <Field
                      component={FormRadioButton}
                      id={FIRM_DATE_FORMAT.ddmmyyyy}
                      label={FIRM_DATE_FORMAT.ddmmyyyy}
                      name="systemDateFormat"
                      value={FIRM_DATE_FORMAT.ddmmyyyy}
                    />
                  </FormRadioGroup>
                </Col>

                <Col md={7} onClick={trackMixPanelEventDateFormat}>
                  <FormSubmit
                    isSubmitting={isSubmitting}
                    text={`Change Date Settings`}
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </StyleContainer>
  );
};

export default DateFormatForm;
