// @flow
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

type Props = {
  onSubmit: Function,
  onHide: Function,
  show: boolean,
  description: string,
};

export default ({ show, onHide, onSubmit, description, }: Props) => (
  <Modal className="p-0" show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <h2 className="pt-4 w-100">{`Are You Sure?`}</h2>
    </Modal.Header>
    <Modal.Body>
      <p>{description}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button
        className="font-weight-bold border-0"
        variant="light"
        onClick={onHide}>
        {`Cancel`}
      </Button>
      <Button
        className="font-weight-bold"
        variant="danger"
        onClick={onSubmit}>
        {`Remove`}
      </Button>
    </Modal.Footer>
  </Modal>
);
