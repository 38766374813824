import { ClientHeadnoteInfo, ClientRepository } from "domain/client";
import { WithCurrentUserToken } from 'domain/user';

type Callbacks = {
    onSuccess: (i: ClientHeadnoteInfo) => void;
    onError: (e: Error) => void;
}

type Dependencies = {
    clientRepository: ClientRepository;
}

type Payload = {
    clientId: number
}

export type GetClientHeadnoteInfoBehaviour = (
    Payload, WithCurrentUserToken, Callbacks
) => any

export default ({ clientRepository }: Dependencies) => {

    /**
     * Get the client's Headnote settings
     * @param {Payload} payload
     * @param {WithCurrentUserToken} withCurrentUserToken - auth token
     * @param {Callbacks} callbacks - call back for each use case 
     * @returns 
     */
    const getClientHeadnoteInfoBehaviour: GetClientHeadnoteInfoBehaviour = async (
        payload, withCurrentUserToken, { onSuccess, onError }
    ) => {
        try {
            const { clientId } = payload;
            const clientHeadnoteInfo: ClientHeadnoteInfo = await clientRepository.getClientHeadnoteInfo(clientId, withCurrentUserToken);
            return onSuccess(clientHeadnoteInfo);
        } catch (e) {
            return onError(e);
        }
    }
    return getClientHeadnoteInfoBehaviour;
}