/* @flow */
import { type Payment, type PaymentRepository, } from 'domain/payment';

import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (url: string) => void,
  onError: (Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
};

type Payload = string;

export type PaypalGetSignupLinkBehaviour = (
  Payload,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { paymentRepository, }: Dependencies, ) => {
  /**
   * get paypal signup link to redirect behaviour
   * 
   * @param {Payload} payload - return url
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const paypalGetSignupLinkBehaviour: PaypalGetSignupLinkBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      const url = await paymentRepository.paypalGetSignupLink(payload, withCurrentUserToken);

      return onSuccess(url);
    } catch(error) {
      return onError(error);
    }
  };

  return paypalGetSignupLinkBehaviour;
};
