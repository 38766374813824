// @flow
import * as R from 'ramda';
import { type AwilixContainer, } from 'awilix';
import { END, eventChannel, } from 'redux-saga';
import { GetHeadnoteFirmSettingsBehaviour } from 'app/firm/GetHeadnoteFirmSettings';
import { HeadnoteFirmSettingsCreators, HeadnoteFirmSettingsTypes } from '../actions';
import { cancelled, put, select, take, takeLatest, } from 'redux-saga/effects';
import { type WithCurrentUserToken, } from 'domain/user';
import { UserRedux } from 'state/reducers';

const getHeadnoteFirmSettings = function* (container: AwilixContainer, { payload, meta }: { payload: Object, meta: ?Object }) {
    try {
        const getHeadnoteFirmSettingsBehaviour: GetHeadnoteFirmSettingsBehaviour = container.resolve('getHeadnoteFirmSettings');

        // get data from redux state
        const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

        const withCurrentUserToken: WithCurrentUserToken = {
            currentUserToken: token,
        };

        const channel = eventChannel(emitter => {
            getHeadnoteFirmSettingsBehaviour(
                payload,
                withCurrentUserToken,
                {
                    onSuccess: (data) => {
                        emitter({ data, });
                        emitter(END);
                    },
                    onInvalidPayload: (error) => {
                        emitter({ data: {}, error, });
                        emitter(END);
                    },
                    onError: (error) => {
                        emitter({ data: {}, error, });
                        emitter(END);
                    },
                }
            );

            return () => {
                // clean up here, if needed
            };
        });

        // Process events until operation completes
        while (true) {
            const { data: headnoteFirmSettings, error }: {  data: Object, error: ?Error } = yield take(channel);
            if (!headnoteFirmSettings && !error) {
                break;
            }

            if (error) { throw error; }

            if (headnoteFirmSettings) {
                yield put(HeadnoteFirmSettingsCreators.getHeadnoteFirmSettingsSuccess(headnoteFirmSettings));
            }
        }
    } catch (e) {
        yield put(HeadnoteFirmSettingsCreators.requestFailure(true, e, meta));
    } finally {
        if (yield cancelled()) {
            yield put(HeadnoteFirmSettingsCreators.requestFailure(true, null, meta));
        }
    }
};

export default (container: AwilixContainer) => ([
    takeLatest(HeadnoteFirmSettingsTypes.GET_HEADNOTE_FIRM_SETTINGS, getHeadnoteFirmSettings, container),
    // ...
])