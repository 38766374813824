import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Colors } from 'assets/index';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default () => {
    return (
        <StyledList className="mb-4">
            <li className="mb-2">
                <FontAwesomeIcon
                className='mr-3'
                icon={faCheck}
                style={{ color: Colors.blueButton, }}
                />{`Accept payments to trust & operating accounts`}
            </li>
            <li className="mb-2">
                <FontAwesomeIcon
                className='mr-3'
                icon={faCheck}
                style={{ color: Colors.blueButton, }}
                />{`ABA, IOLTA & PCI compliant`}
            </li>
            <li className="mb-2">
                <FontAwesomeIcon
                className='mr-3'
                icon={faCheck}
                style={{ color: Colors.blueButton, }}
                />{`Industry-low pricing and no monthly fees`}
            </li>
            <li className="mb-2">
                <FontAwesomeIcon
                className='mr-3'
                icon={faCheck}
                style={{ color: Colors.blueButton, }}
                />{`All-in-one functionality, no 3rd party integration`}
            </li>
        </StyledList>
    )
}

const StyledList = styled.ul`
  padding: 0px;
  margin-bottom: 0px;
  list-style: none;
`;