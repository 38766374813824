/* @flow */
import { type InviteRepository, type PostInviteBody, type Invite, } from 'domain/invite';

import { type WithCurrentUserToken, } from 'domain/user';

type Payload = PostInviteBody;

type Callbacks = {
  onSuccess: (string) => void,
  onError: (Error) => void,
};

type Dependencies = {
  inviteRepository: InviteRepository,
};

export type PostInviteBehaviour = (
  Payload,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { inviteRepository, }: Dependencies, ) => {
  /**
   * send invite to client behaviour
   * 
   * @param {Payload} payload - list client to send invite
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const postInviteBehaviour: PostInviteBehaviour = async (
    payload,
    withCurrentUserToken: WithCurrentUserToken,
    { onSuccess, onError, }: Callbacks,
  ) => {
    try {
      const invite = await inviteRepository.postInvite(payload, withCurrentUserToken);

      return onSuccess(invite);
    } catch(error) {
      return onError(error);
    }
  };

  return postInviteBehaviour;
};
