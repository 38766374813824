// @flow
import * as R from 'ramda';

import { type ClientPortalSettings, type Firm, type FirmStatusInfo, type FirmInvoiceInfo } from 'domain/firm';
import { type FirmUserMap, type GetFirmUsersSuccess, type LogInSuccessPayload,
  type UpdateFirmUserSuccessPayload, type UpdateUserSuccessPayload, type UserState,
  type GetFeatureListSuccessPayload, type GetFirmStatusInfoSuccessPayload, type GetFirmInvoiceInfoSuccessPayload, } from './types';
import { type PersistConfig, persistReducer, } from 'redux-persist';
import { type User, type UserToken, } from 'domain/user';
import { isPersistActive, storage, } from '../_reduxPersist/persistConfig';

import { UserTypes, } from '../actions';
import { createReducer, } from 'reduxsauce';
import { mapTransformer, } from '../_reduxPersist/transformer';
import { produce, } from 'immer';

export const stateKey = 'user';

export const UserStatuses = {
  INIT: 'INIT',
  SIGNING: 'SIGNING',
  SIGNED_IN: 'SIGNED_IN',
  SIGNED_OUT: 'SIGNED_OUT',
  RESET_CREDENTIALS: 'RESET_CREDENTIALS',
};

export type UserStatus = $Keys<typeof UserStatuses>;

/* ------------- Initial State ------------- */
const INITIAL_STATE: UserState = ({
  tokens: null,
  user: null,
  firm: null,
  firmStatusInfo: null,
  firmInvoiceInfo: null,
  firmUsers: new Map(),
  status: UserStatuses.INIT,
  featureList: []
});

/* ------------- Reducers ------------- */
const login = R.identity;

const loginSessionSuccess = (state: UserState, { payload, }: { payload: LogInSuccessPayload, }) =>
  produce(state, (draft) => {
    draft.tokens = payload.tokens;
    draft.user = payload.user;
    draft.firm = payload.firm;
    draft.firmStatusInfo = payload.firmStatusInfo;
    draft.firmInvoiceInfo = payload.firmInvoiceInfo;
  });

const setTokenFromOutSide = R.identity;

const setTokenFromOutsideSuccess = (state: UserState, { payload, }: { payload: LogInSuccessPayload, }) =>
  produce(state, (draft) => {
    draft.tokens = payload.tokens;
    draft.firm = payload.firm;
    draft.firmStatusInfo = payload.firmStatusInfo;
    draft.firmInvoiceInfo = payload.firmInvoiceInfo;
  });

const logoutSessionSuccess = (state: UserState) =>
  produce(state, (draft) => {
    Object.assign(draft, {
      ...INITIAL_STATE,
      firm: draft.firm // keep firm data to show logo when user logout
    });
  });

// set user tokens
const setTokens = (state: UserState, { payload, }: { payload: UserToken, }) =>
  produce(state, (draft) => {
    draft.tokens = {
      ...draft.tokens,
      ...payload,
    };
  });

const updateFirm = (state: UserState, { payload, }: { payload: Firm, }) =>
  produce(state, (draft) => {
    draft.firm = {
      ...draft.firm,
      ...payload,
    };
  });

const updateFirmSettingsSuccess = (state: UserState, { payload, }: { payload: Firm, }) =>
  produce(state, (draft) => {
    draft.firm = payload;
  });

const updateFirmUserSuccess = (state: UserState, { payload, }: { payload: UpdateFirmUserSuccessPayload, }) =>
  produce(state, (draft) => {
    draft.firmUsers.set(payload.user._id, payload.user);
  });

const updateUserSuccess = (state: UserState, { payload, }: { payload: UpdateUserSuccessPayload, }) =>
  produce(state, (draft) => {
    draft.user = payload.user;
  });

const getFirmUsersSuccess = (state: UserState, { payload, }: { payload: GetFirmUsersSuccessPayload, }) =>
  produce(state, (draft) => {
    draft.firmUsers = payload.usersMap;
  });

const getByTokenSuccess = (state: UserState, { payload, }: { payload: User, }) =>
  produce(state, (draft) => {
    draft.user = payload;
  });

const getFeatureList = R.identity;

const getFeatureListSuccess = (state: UserState, { payload }: { payload: GetFeatureListSuccessPayload, }) =>
  produce(state, (draft) => {
    draft.featureList = payload.featureList;
  });

const getFirmStatusInfo = R.identity;

const getFirmStatusInfoSuccess = (state: UserState, { payload }: { payload: GetFirmStatusInfoSuccessPayload }) =>
  produce(state, (draft) => {
    draft.firmStatusInfo = payload.firmStatusInfo;
  });

  const getFirmInvoiceInfo = R.identity;

  const getFirmInvoiceInfoSuccess = (state: UserState, { payload }: { payload: GetFirmInvoiceInfoSuccessPayload }) =>
  produce(state, (draft) => {
    draft.firmInvoiceInfo = payload.firmInvoiceInfo;
  });

/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
  [UserTypes.LOGIN]: login,
  [UserTypes.LOGIN_SESSION_SUCCESS]: loginSessionSuccess,

  [UserTypes.SET_TOKEN_FROM_OUT_SIDE]: setTokenFromOutSide,
  [UserTypes.SET_TOKEN_FROM_OUTSIDE_SUCCESS]: setTokenFromOutsideSuccess,

  [UserTypes.LOGOUT_SESSION_SUCCESS]: logoutSessionSuccess,

  [UserTypes.SET_TOKENS]: setTokens,

  [UserTypes.UPDATE_FIRM]: updateFirm,

  [UserTypes.UPDATE_FIRM_SETTINGS_SUCCESS]: updateFirmSettingsSuccess,

  [UserTypes.UPDATE_FIRM_USER_SUCCESS]: updateFirmUserSuccess,

  [UserTypes.UPDATE_USER_SUCCESS]: updateUserSuccess,

  [UserTypes.GET_FIRM_USERS_SUCCESS]: getFirmUsersSuccess,

  [UserTypes.GET_BY_TOKEN_SUCCESS]: getByTokenSuccess,

  [UserTypes.GET_FEATURE_LIST]: getFeatureList,
  [UserTypes.GET_FEATURE_LIST_SUCCESS]: getFeatureListSuccess,
  
  [UserTypes.GET_FIRM_STATUS_INFO]: getFirmStatusInfo,
  [UserTypes.GET_FIRM_STATUS_INFO_SUCCESS]: getFirmStatusInfoSuccess,

  [UserTypes.GET_FIRM_INVOICE_INFO]: getFirmInvoiceInfo,
  [UserTypes.GET_FIRM_INVOICE_INFO_SUCCESS]: getFirmInvoiceInfoSuccess,
});

const persistConfig: PersistConfig = {
  key: stateKey,
  storage,
  transforms: [
    mapTransformer({
      whitelist: [ 'firmUsers', 'firmStatusInfo'],
    }),
  ],
  blacklist: [ 'firmUsers', ],
};

const reducerMap = { [stateKey]: isPersistActive
  ? persistReducer<any, any>(persistConfig, reducer)
  : reducer, };

/* ------------- Selectors ------------- */
const getReducerState: (Object) => UserState = (state) => (state[stateKey]);

const selectors = {
  getToken: ({ tokens, }: UserState): UserToken  => (tokens),
  getUser: ({ user, }: UserState): User => (user),
  getFirm: ({ firm, }: UserState): Firm => (firm),
  getFirmUsersMap: ({ firmUsers, }: UserState): FirmUserMap => (firmUsers),
  getPortalSettings: (state: UserState): ClientPortalSettings => (R.path([ 'firm', 'portalSettings', ], state)),
  getCurrencySymbol: (state: UserState): ?string => (R.path([ 'firm', 'currencySymbol', ], state)),
  getFeatureList: ({ featureList }: UserState): Array<FeatureList> => (featureList),
  getFirmStatusInfo: ({ firmStatusInfo, }: UserState): FirmStatusInfo => (firmStatusInfo),
  getFirmInvoiceInfo: ({ firmInvoiceInfo, }: UserState): FirmInvoiceInfo => (firmInvoiceInfo),
};

/* ------------- Export ------------- */
export default {
  selectors,

  // default export
  INITIAL_STATE,

  stateKey,
  getReducerState,
  reducerMap,
};
