// @flow
import * as R from 'ramda';

import { type GetSingleInvoiceSuccessPayload, type InvoiceState, type getInvoicesSuccessPayload, } from './types';
import { isPersistActive, storage, } from '../_reduxPersist/persistConfig';

import { InvoiceTypes, } from '../actions';
import { createReducer, } from 'reduxsauce';
import { mapTransformer, } from '../_reduxPersist/transformer';
import { persistReducer, type PersistConfig, } from 'redux-persist';
import { produce, } from 'immer';

export const stateKey = 'invoice';

/* ------------- Initial State ------------- */
const INITIAL_STATE: InvoiceState = ({
  invoices: new Map(),
});

/* ------------- Reducers ------------- */
const getInvoices = R.identity;

const getInvoicesSuccess = (state: InvoiceState, { payload, }: { payload: getInvoicesSuccessPayload, }) =>
  produce(state, (draft) => {
    Object.assign(draft, payload);
  });

const getInvoiceToken = R.identity;
const getInvoiceTokenSuccess = R.identity;

const getSingleInvoice = R.identity;
const getSingleInvoiceSuccess = (state: InvoiceState, { payload, }: { payload: GetSingleInvoiceSuccessPayload, }) =>
  produce(state, (draft) => {
    draft.invoices.set(payload.invoice.id, payload.invoice);
  });

const requestFailure = R.identity;

/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
  [InvoiceTypes.GET_INVOICES]: getInvoices,
  [InvoiceTypes.GET_INVOICES_SUCCESS]: getInvoicesSuccess,

  [InvoiceTypes.GET_INVOICE_TOKEN]: getInvoiceToken,
  [InvoiceTypes.GET_INVOICE_TOKEN_SUCCESS]: getInvoiceTokenSuccess,

  [InvoiceTypes.GET_SINGLE_INVOICE]: getSingleInvoice,
  [InvoiceTypes.GET_SINGLE_INVOICE_SUCCESS]: getSingleInvoiceSuccess,

  [InvoiceTypes.REQUEST_FAILURE]: requestFailure,
});

const persistConfig: PersistConfig = {
  key: stateKey,
  storage,
  transforms: [
    mapTransformer({
      whitelist: 'invoices',
    }),
  ],
  whitelist: [],
};

const reducerMap = { [stateKey]: isPersistActive
  ? persistReducer<any, any>(persistConfig, reducer)
  : reducer, };

/* ------------- Selectors ------------- */
const getReducerState: (Object) => InvoiceState = (state) => (state[stateKey]);

const selectors = {
  getInvoices: ({ invoices, }: InvoiceState) => (invoices),
};

/* ------------- Export ------------- */
export default {
  selectors,

  // default export
  INITIAL_STATE,

  stateKey,
  getReducerState,
  reducerMap,
};
