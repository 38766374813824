// @flow
import * as R from 'ramda';

import { END, eventChannel, } from 'redux-saga';
import { type GetSingleRetainerPayload, type GetSingleRetainerSuccessPayload, type RetainerMap, type GetRetainersPayload, type GetRetainersSuccessPayload, } from './types';
import { RetainerActionCreators, RetainerTypes, } from '../actions';
import { RetainerRedux, UserRedux, } from 'state/reducers';
import { cancelled, put, select, take, takeLatest, } from 'redux-saga/effects';

import { type AwilixContainer, } from 'awilix';
import { type GetRetainerTokenBehaviour, } from 'app/retainer/GetRetainerToken';
import { type GetRetainersBehaviour, } from 'app/retainer/GetRetainers';
import { type GetSingleRetainerBehaviour, } from 'app/retainer/GetSingleRetainer';
import { type RetainerRequestPayments, } from 'domain/retainer';
import { type WithCurrentUserToken, } from 'domain/user';
import { compareDesc, eachQuarterOfInterval } from 'date-fns';

/**
 * Get single retainer request amount
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const getRetainerRequestAmount = function * (container: AwilixContainer, { payload, meta, }: { payload: GetSingleRetainerPayload, meta: ?Object, }) {
    try {
        const getSingleRetainerBehaviour: GetSingleRetainerBehaviour = container.resolve('getRetainerRequestAmount');

        const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

        const withCurrentUserToken: WithCurrentUserToken = {
            currentUserToken: token,
        };

        const channel = eventChannel(emitter => {
            getSingleRetainerBehaviour(
                payload,
                withCurrentUserToken,
                {
                    onSuccess: (token) => {
                        emitter({ data: token, });
                        emitter(END);
                    },
                    onError: (error) => {
                        emitter({ data: {}, error, });
                        emitter(END);
                    },
                    onInvalidPayload: (invalidError) => {
                        emitter({ data: {}, error: invalidError, });
                        emitter(END);
                    },
                }
            );

            return () => {
                // Perform any cleanup you need here
            };
        });

        // Process events until operation completes
        while (true) {
            const { data: retainer, error }: { data: GetSingleRetainerSuccessPayload, error: ?Error } = yield take(channel);
            if (!retainer && !error) {
                break;
            }

            if (error) { throw error; }

            if (retainer) {
                yield put(RetainerActionCreators.getSingleRetainerSuccess(retainer));
            }
        }
    } catch (e) {
        yield put(RetainerActionCreators.requestFailure(true, e, meta));
    } finally {
        if (yield cancelled()) {
            yield put(RetainerActionCreators.requestFailure(true, null, meta));
        }
    }
};

/**
 * Get multiple retainer requests amounts
 * @param {AwilixContainer} container - awilix container
 *  @param {Object} data - action params
 */
const getRetainers = function * (container: AwilixContainer, { payload, meta }: { payload: GetRetainersPayload, meta: ?Object }) {
    try {
        const getRetainersBehaviour: GetRetainersBehaviour = container.resolve('getRetainers');

        const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

        const withCurrentUserToken: WithCurrentUserToken = {
            currentUserToken: token
        };
        const channel = eventChannel(emitter => {
            getRetainersBehaviour(
                payload,
                withCurrentUserToken,
                {
                    onSuccess: (retainers) => {
                        emitter({ data: retainers, });
                        emitter(END);
                    },
                    onError: (error) => {
                        emitter({ data: {}, error, });
                        emitter(END);
                    },
                    onInvalidPayload: (invalidError) => {
                        emitter({ data: {}, error: invalidError, });
                        emitter(END);
                    },
                }
            );

            // Return an unsubscribe method
            return () => {
                // Perform any cleanup you need here
            };
        });

        while (true) {
            const { data: retainers, error }: { data: Array<RetainerRequestPayments>, error: ?Error } = yield take(channel);
            if (!retainers && !error) {
                break;
            }
            if (error) { throw error; }

            if (retainers) {
                const retainerSort = retainers.sort((a, b) => compareDesc(new Date(a.createdDate), new Date(b.createdDate)));

                const retainerTuple = retainerSort.map((r: RetainerRequestPayments) => [ r.retainerRequestId, { ...r, isPaid: r.requestAmount === r.pmtAmounts }]);
                const retainerMap: RetainerMap = new Map(retainerTuple);

                const payload: GetRetainersSuccessPayload = { retainers: retainerMap };
                yield put(RetainerActionCreators.getRetainersSuccess(payload));
            }
        }
    } catch (e) {
        yield put(RetainerActionCreators.requestFailure(true, e, meta));
    } finally {
        if (yield cancelled()) {
            yield put(RetainerActionCreators.requestFailure(true, null, meta));
        }
    }
};

const getRetainerToken = function* (container: AwilixContainer, { payload, meta, }: { payload: Retainer, meta: ?Object, }) {
    try {
        const getRetainerTokenBehaviour: GetRetainerTokenBehaviour = container.resolve('getRetainerToken');

        const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

        const withCurrentUserToken: WithCurrentUserToken = {
            currentUserToken: token,
        };

        const channel = eventChannel(emitter => {
            getRetainerTokenBehaviour(
                payload,
                withCurrentUserToken,
                {
                    onSuccess: (token) => {
                        emitter({ data: token, });
                        emitter(END);
                    },
                    onError: (error) => {
                        emitter({ data: {}, error, });
                        emitter(END);
                    },
                    onInvalidPayload: (invalidError) => {
                        emitter({ data: {}, error: invalidError, });
                        emitter(END);
                    },
                }
            );

            return () => {
                // Perform any cleanup you need here
            };
        });

        while (true) {
            const { data: retainer, error, }: { data: string, error: ?Error, } = yield take(channel);
            if (!retainer && !error) {
                break;
            }

            if (error) { throw error; }

            if (retainer) {
                yield put(RetainerActionCreators.getRetainerTokenSuccess(retainer));
            }
        }

    } catch (e) {
        yield put(RetainerActionCreators.requestFailure(true, e, meta))
    } finally {
        yield put(RetainerActionCreators.requestFailure(true, null, meta))
    }
};

export default (container: AwilixContainer) => ([
    takeLatest(RetainerTypes.GET_RETAINER_REQUEST_AMOUNT, getRetainerRequestAmount, container),
    takeLatest(RetainerTypes.GET_RETAINERS, getRetainers, container),
    takeLatest(RetainerTypes.GET_RETAINER_TOKEN, getRetainerToken, container)
]);
