// @flow
import { type FieldProps, } from 'formik';
import Form from 'react-bootstrap/Form';
import React from 'react';

type Props = {
  value: any,
};

const FormRadioButton = ({
  field: { value: fieldValue, name, onChange, }, form: { errors, touched, },
  value,
  ...restProps
}: FieldProps & Props) => {
  // field states:
  // field states:
  const _error = errors[name];
  const _touched = touched[name];

  return (
    <Form.Check
      custom
      inline
      checked={value === fieldValue}
      isInvalid={_error && _touched}
      name={name}
      type='radio'
      value={value}
      onChange={onChange}
      {...restProps}
    />
  );
};

export default FormRadioButton;
