// @flow
import * as R from 'ramda';

import { FormInput, } from 'view/components/index';
import { FieldArray, Form, Formik, type FormikProps, Field, isObject } from 'formik';
import { type RetainerRequestPayments, isRetainerCanPay, RetainerPayment } from 'domain/retainer';
import React, { useCallback, useMemo, useEffect } from 'react';
import { Alert, Button, Card, Col, Container, Row, Spinner, } from 'react-bootstrap';
import { APP_CONFIG, } from 'constants/index';
import { Colors, } from 'assets';
import RetainerPaymentArrayField from './RetainerPaymentArrayField';
import RetainersFormTotalSection from './RetainersFormTotalSection';
import styled from 'styled-components';
import { useHistory, } from 'react-router-dom';
import { PaymentActionCreators, } from 'state/actions';
import { ClientRedux, UserRedux, PaymentRedux } from 'state/reducers';
import { useDispatch, useSelector, } from 'react-redux';
import { type AccountBalance, } from 'domain/client';
import { type UserToken, } from 'domain/user';
import { type PaymentReceivingBankTypePayload } from 'state/payment/types';
import { useParams, } from 'react-router-dom';

const minNameLength = 2;
const StyledCard = styled(Card)`
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
`;

const StyledCardBody = styled(Card.Body)`
  /* min-height: ${APP_CONFIG.minTableHeight}; */
     label {
     display: flex;
   }
`;

const CancelButton = styled(Button)`
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  color: ${Colors.charcoal} !important;
`;

const ContinueButton = styled(Button)`
  box-shadow: none !important;
  border: none !important;
`;

type Props = {
    onSubmit: Function,
    retainers: Array<RetainerRequestPayments>,
}

// connect redux
const useConnect = () => {
    // mapState
    const accountBalance: AccountBalance = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getAccountBalance));
    const tokens: UserToken = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
    const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
    const user: User = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
    const paymentReceivingBankType: HEADNOTE_BANK_ACCOUNT_TYPES = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getPaymentReceivingBankType));

    const mapState = {
        accountBalance,
        tokens,
        firm,
        user,
        paymentReceivingBankType
    };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    getPaymentReceivingBankType: (payload: PaymentReceivingBankTypePayload): Promise<any> => dispatch(PaymentActionCreators.getPaymentReceivingBankType(payload, { thunk: true, })),
  }), [dispatch,]);

    return {
        ...mapState,
        ...mapDispatch,
    };
};

const isValidName = (name) => {
    return name && name.length >= minNameLength;
}

const hasValidLastAndFirstNames = (user: User): Boolean => {
    if (!user) { return false; }
    const isLastAndFirstNameValid = isValidName(user.lastName) && isValidName(user.firstName);
    return isLastAndFirstNameValid;
}

const NameField = ({ label, name }) => {
    const validateName = (value) => {
        const nameErrorMessage = `Must be no less than ${minNameLength} characters`;
        let error;
        if (!value) {
            error = 'Required'
        } else if (!isValidName(value)) {
            error = nameErrorMessage;
        }
        return error;
    }

    return <Field
        required
        component={FormInput}
        isRow={true}
        label={label}
        name={name}
        type={'text'}
        inputSize={7}
        labelSize={4}
        validate={validateName}
    />
}

const RetainerPaymentForm = ({ retainers, onSubmit }: Props) => {
    const { accountBalance, firm, user, paymentReceivingBankType, getPaymentReceivingBankType } = useConnect();
    const isHeadnote: Boolean = R.path(['portalSettings', 'isHeadnote'], firm);
    const isNameValid: Boolean = hasValidLastAndFirstNames(user);
    const showPayerInfoCard: Boolean = isHeadnote && !isNameValid;

    const { retainerId, } = useParams();

    // if only single retainer
    const retainerItem = useMemo(() => {
        if (!retainerId || !retainers || !retainers[0]) {
            return;
        }
        return R.find(R.propEq('retainerRequestId', Number(retainerId)))(retainers);
    }, [retainerId, retainers,]);

    useEffect(() => {
      if (!isHeadnote) { return; }
      const fetchPaymentReceivingBankType = async () => {
        const payload: PaymentReceivingBankTypePayload = {
          retainerId
        }
        await getPaymentReceivingBankType(payload);
      }
      fetchPaymentReceivingBankType();
    }, [isHeadnote, retainerId]);

    const initialValues: RetainerPayment = useMemo(() => {
        let total = 0;
        let retainersWithPayment = [];

        retainers.forEach(i => {
            if (!isRetainerCanPay(i)) { return; }

            total += (i.requestAmount - i.pmtAmounts);
            retainersWithPayment.push({
                ...i,
                userPayment: total,
                balance: total
            });
        });

        //additional payments not included in this form, yet...
        const initialValues = {
            retainers: retainersWithPayment,
            total
        };
        if (isHeadnote) {
            initialValues.lastName = R.path(['lastName'], user);
            initialValues.firstName = R.path(['firstName'], user);
        }

        return initialValues;

    }, [ retainers, retainerItem ]);

    // react router history object
    const history = useHistory();

    /**
     * On cancel button press
    * back to previous screen
    */
  const onCancel = useCallback(() => {
    history.goBack();
  }, [history,]);

  return (
    <div className='retainers-form'>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // eslint-disable-next-line
        onSubmit={onSubmit}
      >
        {({ errors, values, handleSubmit, }: FormikProps<RetainerPayment>) => (
          <Form onSubmit={handleSubmit}>
            {
              errors.auth && (
                <Alert variant='danger'>{errors.auth}</Alert>
              )
            }

            <StyledCard className='mb-3'>
              <StyledCardBody className="h-100">
                <FieldArray
                  component={RetainerPaymentArrayField}
                  name="retainers"
                />
              </StyledCardBody>
            </StyledCard>

            {showPayerInfoCard && <StyledCard className='mb-3'>
              <StyledCardBody className="h-100">
                <b>Payer Information</b><br /><br />
                <Row>
                  <Col>
                    <NameField label='• First Name:' name={'firstName'} />
                  </Col>
                  <Col>
                    <NameField label='• Last Name:' name={'lastName'} />
                  </Col>
                  <Col></Col>
                </Row>
              </StyledCardBody>
            </StyledCard>}

            <StyledCard>
              <Card.Body className='d-flex justify-content-between'>
                <RetainersFormTotalSection
                  balance={accountBalance ? accountBalance.balance : 0}
                  total={values.total}
                  firm={firm}
                  onCancel={onCancel}
                  paymentReceivingBankType={paymentReceivingBankType}
                  >
                </RetainersFormTotalSection>
              </Card.Body>
            </StyledCard>

          </Form>
        )}
      </Formik>
    </div>
  );

};

export default RetainerPaymentForm;