// @flow
import { Alert, Button, Card, Col, Container, Form, Row, Spinner, } from 'react-bootstrap';
import { CardElement, type InjectedStripeProps, injectStripe, } from 'react-stripe-elements';
import { Field, Formik, type FormikProps, } from 'formik';
import { FormCheckBox, FormInput, ValidateShading, } from 'view/components/index';
import { type InvoicePayCart, type PaymentCard, PaymentCardSchema, type PendingPayment, type StripePayBody, } from 'domain/payment';
import { type InvoicePaymentRouteParams, type PaymentSuccess, } from '../types';
import React, { useCallback, useMemo, } from 'react';

import { Colors, } from 'assets/index';
import { type Firm, } from 'domain/firm';
import { Link, } from 'react-router-dom';
import { PaymentActionCreators, } from 'state/actions';
import { type StripePaymentPayload, } from 'state/payment/types';
import styled from 'styled-components';
import { useDispatch, } from 'react-redux';
import { ERRORS, APP_CONFIG, } from 'constants/index';

const StyledCard = styled(Card)`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  background: white;
  border-radius: 0px;
  border: solid 1px ${Colors.gainsBoro};
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
`;

const StyleContainer = styled(Container)`
  flex: 1;
`;

const StyledP = styled.p`
  margin: 0px 0px 5px;
`;

const StyledFormInput = styled.div`
  padding: 0.5rem 0.75rem;
  color: ${Colors.grey};
  border: 1px solid ${Colors.whiteBlack};
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  &.isValid {
    background: ${Colors.shadingInputbg};
    border-color: ${Colors.shadingInputbg};
  }
`;

type Props = {
  afterSubmit: Function;
  invoicePayment: InvoicePaymentRouteParams;
  goBack: Function;
  currencyCode?: string,
  isUser: boolean,
};

const initialCard: PaymentCard = {
  email: '',
  name: '',
  savePaymentMethod: false,
};

// connect redux
const useConnect = () => {
  // mapState
  const mapState = {
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    payByStripe: (payload: StripePaymentPayload) => dispatch(PaymentActionCreators.stripePay(payload, { thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/**
 * Main component
 *
 * @param {(Props & InjectedStripeProps)} props
 * @returns
 */
const StripeCard = ({ afterSubmit, stripe, invoicePayment, goBack, isUser, }: Props & InjectedStripeProps) => {
  const { payByStripe, } = useConnect();

  // handle stripe card pay
  const onSubmit = useCallback(async (values, actions) => {
    try {
      actions.setSubmitting(true);

      // Check if amount is smaller that 50 cents
      if (invoicePayment.amount < 0.5) {
        throw new Error(ERRORS.stripe_amount_at_least);
      }
      // get stripe token
      const { token, error, } = await stripe.createToken(values);

      if(error) {
        throw error;
      }

      // process stripe pay request data
      const stripePayCart: Array<InvoicePayCart> = invoicePayment.cart.map((item) => ({
        invoiceId: item.id,
        invoiceLabel: item.invoiceLabel,
        invoiceBalance: item.invoiceBalance,
        amountApplied: item.userPayment,
      }));

      const payment: StripePayBody = {
        cart: stripePayCart.filter(item => item.invoiceBalance !== APP_CONFIG.paymentCart.additionalPayment),
        amount: invoicePayment.amount,
        methodIdOrToken: token.id,
        email: values.email,
        source: "CC",
        projectId: invoicePayment.projectId ? Number(invoicePayment.projectId) : undefined,
        saveCard: values.savePaymentMethod,
      };

      const data: StripePaymentPayload = {
        clientId: invoicePayment.clientId,
        payment,
      };

      // send data to stripe pay api
      const res: PendingPayment = await payByStripe(data);

      // Response doesn't have amount so we can get amount from body
      const afterSubmitBody : PaymentSuccess = {
        ...res,
        amount: invoicePayment.amount,
        type: 'Credit Card',
      };

      afterSubmit(afterSubmitBody);
    } catch (e) {
      if (e.code && e.code === "incomplete_number") {
        actions.setErrors({
          bankNumber: e.message || e,
        });
      } else {
        // handle error
        actions.setErrors({
          payment: e.message || e,
        });
      }
      
    } finally {
      actions.setSubmitting(false);
    }
  }, [ afterSubmit, stripe, invoicePayment, payByStripe, ]);

  return (
    <StyleContainer className="px-0">
      <Formik
        initialValues={initialCard}
        validationSchema={PaymentCardSchema}
        onSubmit={onSubmit}
      >
        {({ errors, handleSubmit, isSubmitting, values, }: FormikProps<PaymentCard>) => (
          <Form onSubmit={handleSubmit}>
            <StyledCard>
              {errors.payment && (
                <Row className="justify-content-md-center">
                  <Col>
                    <Alert variant='danger'>{errors.payment}</Alert>
                  </Col>
                </Row>
              )}

              {(
                  errors.email ||
                  errors.name ||
                  errors.bankNumber
                ) && (
                <Row className="justify-content-md-center">
                  <Col>
                    <ValidateShading text={ERRORS.invalid_fields} />
                  </Col>
                </Row>
              )}

              <Row className="justify-content-md-center mx-0">
                <Col className="px-0">
                    <StyledP>{'Pay with credit or debit card'}</StyledP>
                      <Field
                        className={`payment-validate ${errors.email ? 'invalid' : 'valid'}`}
                        component={FormInput}
                        label={`Send Receipt To:`}
                        maxLength={256}
                        name={'email'}
                        type={'text'}
                      />
                      <Field
                        className={`payment-validate ${errors.name ? 'invalid' : 'valid'}`}
                        component={FormInput}
                        label={`Name on Card:`}
                        maxLength={26}
                        name={'name'}
                        type={'text'}
                      />
                    <Form.Group>
                      <StyledFormInput className={errors.bankNumber ? 'isValid' : 'valid'}>
                        <CardElement />
                      </StyledFormInput>
                    </Form.Group>
                    { 
                      isUser && (
                        <Field
                          className={`mt-3`}
                          component={FormCheckBox}
                          label={'Save payment method'}
                          // labelClassName={`text-right`}
                          name={'savePaymentMethod'}
                        />
                      )
                    }
                </Col>
              </Row>
            </StyledCard>

            <StyledCard>
              <div className="d-flex justify-content-between align-items-center">
                <Link className="btn" onClick={goBack}>{`Cancel`}</Link>
                <div className='d-flex flex-row align-items-center'>
                  {
                    isSubmitting && (
                      <Spinner animation="border" className="mr-2" role="status" />
                    )
                  }
                  <Button
                    disabled={isSubmitting}
                    size="lg"
                    type="submit"
                    variant="success"
                  >
                    {`SUBMIT`}
                  </Button>
                </div>
              </div>
            </StyledCard>
          </Form>
        )}
      </Formik>
    </StyleContainer>
  );
};

export default injectStripe(StripeCard);
