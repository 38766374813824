
export const loadScript = (scriptSrc: string) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = scriptSrc;
      script.async = true;
      script.onload = () => {
        resolve(true);
      }
      document.body.appendChild(script);
    });
  }  