// @flow
import CustomFormGroup, { type Props as CustomGroupProps, } from '../FormGroup';
import React, { useCallback, } from 'react';

import { Colors, } from 'assets';
import { type DropDownOption, } from '../Dropdown/types';
import Dropdown from '../Dropdown';
import Feedback from 'react-bootstrap/Feedback';
import { type FieldProps, } from 'formik';
import styled from 'styled-components';

const StyledFormError = styled(Feedback)`
  color: ${Colors.redDanger};
  display: block !important;
`;

const StyledDropdown = styled(Dropdown)`
  font-size: .875rem;
`;

type Props = {
  selectClassName?: string,
  defaultValue: Object,
  isMulti?: boolean,
  options: Array<DropDownOption>,
  labelSize: number,
  inputSize: number,
};

const FormSelect = ({
  field, form,
  label, className, labelClassName, isRow,
  controlWrapperClass,
  selectClassName, isMulti,
  labelSize, inputSize, ...restProps
}: FieldProps<any> & CustomGroupProps & Props) => {
  const { errors, } = form;
  // field states:
  const _error = errors[field.name];
  // const _touched = touched[field.name];

  // get value from option item
  const getItemValue = useCallback((i: any): any => {
    return restProps.getOptionValue ? restProps.getOptionValue(i) : i.value;
  }, [ restProps.getOptionValue, ]);

  // on dropdown select
  const _onSelect = useCallback((item: DropDownOption | DropDownOption[]) => {
    // formik handleChange will look for an input's id attribute
    // https://jaredpalmer.com/formik/docs/api/formik#handlechange-e-reactchangeevent-any-void
    
    // handle multiple and single case
    let value;
    if (isMulti) {
      value = item.map(getItemValue);
    } else {
      value = getItemValue(item);
    }

    form.setFieldValue(field.name, value);
  }, [ field.name, form, isMulti, getItemValue, ]);

  return (
    <CustomFormGroup
      className={className}
      controlWrapperClass={controlWrapperClass}
      inputSize={inputSize}
      isRow={isRow}
      label={label}
      labelClassName={labelClassName}
      labelSize={labelSize}
    >
      <StyledDropdown
        className={selectClassName}
        isMulti={isMulti}
        value={field.value}
        {...restProps}
        onChange={_onSelect}
      />
      {
        _error && <StyledFormError type="invalid">{_error}</StyledFormError>
      }
    </CustomFormGroup>
  );
};

export default FormSelect;
