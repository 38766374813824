/* @flow */
import { createActions, } from 'reduxsauce';
import { reducerPrefixFormat, } from '../common';

/* ------------- Invite ------------- */
export const { Types, Creators, } = createActions({
  getInvite: [ 'payload', 'meta', ],
  getInviteSuccess: [ 'payload' /* GetInvitesResponsePayload */, 'meta', ],

  postInvite: [ 'payload', 'meta', ],
  postInviteSuccess: [ 'payload' /* SendInviteSuccessResponsePayload */, 'meta', ],

  deleteInvite: [ 'payload', 'meta', ],
  deleteInviteSuccess: [ 'payload' /* RevokeInviteSuccessResponsePayload */, 'meta', ],

  getContact: [ 'payload', 'meta', ],
  getContactSuccess: [ 'payload' /* GetContactsResponsePayload */, 'meta', ],

  requestFailure: [ 'error', 'payload' /* error payload */, 'meta', ],
}, {
  prefix: reducerPrefixFormat('invite'),
});
