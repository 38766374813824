/* @flow */
import { APP_CONFIG, } from 'constants/index';
import Storage from 'infra/cache';
import * as localforage from 'localforage';

const storage = new Storage(localforage.createInstance(), {
  name: APP_CONFIG.name,
  version     : 1.0,
  storeName   : 'bill4time_redux',
  description : 'bill4time_redux_persist_store',
});


export {
  storage,
};

export const isPersistActive = true;

export const persistConfig = {
  key: 'root',
  // update reducerVersion each time reducer configuration changes to purge redux persisted storage
  version: 0,
  storage,
  // reducer keys that you do NOT want stored to persistence here
  blacklist: [
    // default blacklist
    'app',
    'error',
    'fetching',
    // nested persist config
    'user',
    'invoice',
    'retainer',
    'payment',
    'linkToPay',
    'accountHistory',
  ],
  /*
  // Optionally, just specify the keys you DO want stored to persistence.
  // An empty array means 'don't store any reducers' -> infinitered/ignite#409
  whitelist: [
  ],
  stateReconciler: autoMergeLevel1, // default
  debug
  */
};
