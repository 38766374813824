/* @flow */
import React, { useEffect, useMemo, useState, } from 'react';
import { getLocationQueryString, jwtDecoded, } from 'utilities/stringUtils';
import { useLocation, useHistory, } from "react-router-dom";
import { Container, Alert, } from 'react-bootstrap';
import { useDispatch, } from 'react-redux';
import { UserActionCreators, } from 'state/actions';
import { type UserToken, } from 'domain/user';
import { Loading, } from 'view/components/Loading';
import { ERRORS, } from 'constants/index';

// connect redux
const useConnect = () => {

    // mapDispatch
    const dispatch = useDispatch();

    const mapDispatch = useMemo(() => ({
        setTokens: (payload: UserToken) => dispatch(UserActionCreators.setTokens(payload)),
        loadFirm: () => dispatch(UserActionCreators.getFirm({ thunk: true, })),
    }), [dispatch,]);

    return {
        ...mapDispatch,
    };
};

type DecodeData = {
    retainerId: string,
    projectId: string,
    accessibleClients: number[]
}

const RetainerFromEmailVM = () => {
    // connect redux
    const { setTokens, loadFirm } = useConnect();

    const location = useLocation();
    const history = useHistory();
    const [error, setError] = useState(null);

    useEffect(() => {
        (
            async function checkRetainerGuest() {
                try {
                    const { retainerToken }: { retainerToken: string } = getLocationQueryString(location.search);
                    const { retainerId, accessibleClients } : DecodeData = jwtDecoded(retainerToken);

                    // save token and re-use
                    const data: UserToken = {
                        authToken: retainerToken
                    };
                    await setTokens(data);

                    // Load firm to get guest setting
                    const firm: Form = await loadFirm();

                    if (firm.portalSettings.allowGuests) {
                        const url = {
                            pathname: `/retainer-payment/${retainerId}/${accessibleClients[0]}`,
                        };
                        history.push(url);
                    } else {
                        history.push('/login');
                    }
                } catch (e) {
                    setError(ERRORS.retainer_token);
                }
            }
        )();

    }, [location, history, setTokens, loadFirm]);

    return (
        <Container>
            { error ? (
                <Alert className="mt-4" variant="warning" >
                    {error}
                </Alert>
            ) : <Loading />}
        </Container>
    );
};

export default RetainerFromEmailVM;