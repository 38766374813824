/* @flow */
import { INewHeadnoteCard, INewBankInfo, IPlaidInfo } from '../payment';

export type IB4TClientPmtMethodRequestBody  = {
    userEmail: string;
    userFName: string;
    userLName: string;
    card?: INewHeadnoteCard;
    bank?: INewBankInfo | IPlaidInfo;
}

export type PaymentSource = 'ACH' | 'CC';

export const PAYMENT_METHOD_REQUEST_TYPES = {
    CARD: 'CARD',
    ARNUMBER: 'ARNUMBER',
    PLAID: 'PLAID',
};