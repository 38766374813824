// @flow
import * as R from 'ramda';

import React, { FC, useMemo, } from 'react';
import { Redirect, useLocation, } from 'react-router-dom';

import { Container, Card, Row, Col, } from 'react-bootstrap';
import { type PaymentSuccess, } from '../methods/types';
import styled from 'styled-components';
import { useSelector, } from 'react-redux';
import { UserRedux, } from 'state/reducers';
import { currencyFormat, } from 'utilities/stringUtils';
import { PAYMENT_METHOD, } from 'domain/payment';

const StyledCard = styled(Card)`
  padding: 15px 20px 15px;
`;

const StyledCardHeader = styled(Card.Header)`
  background: transparent;
  padding: 0px;
`;

// connect redux
const useConnect = () => {
  // mapState
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const mapState = {
    firm,
  };

  return {
    ...mapState,
  };
};

const AccessPage: FC = () => {
  const { firm, } = useConnect();

  // get query params from url
  const location = useLocation();
  const paymentSuccess: PaymentSuccess | undefined = location.state && location.state.paymentSuccess;

  // last row
  const LastRowRender = useMemo(() => {
    if (paymentSuccess.type === 'Bank Account' || paymentSuccess.type === 'Credit Card') {
      return <>{`Payment Method: ${paymentSuccess.type}`} <b>{`****-****-****-${paymentSuccess.last4.substring(paymentSuccess.last4.length - 4)}`}</b></>;
    }

    return <>{`Payment Method:`} <b>{paymentSuccess.type}</b></>;
  }, [ paymentSuccess, ]);

  // redirect to home if no pendingPayment provided in nav params
  if (!paymentSuccess)  {
    return <Redirect to='/' />;
  }
  
  return (
    <Container className="my-3">
      <Row className="justify-content-center">
        <Col md="5">
          <StyledCard>
            <StyledCardHeader>
              <Card.Title className="my-1">
                <h3>{`Payment Successful`}</h3>
              </Card.Title>
              <hr />
              <Card.Subtitle className="my-3">
                {`Thank you for your payment! Once the charge has finished processing, it will appear in the portal. A receipt has been emailed to the email address you provided.`}
              </Card.Subtitle>
            </StyledCardHeader>
            <Card.Body className="p-0">
              <Card.Text className="my-3 px-2 d-flex flex-row justify-content-between align-items-center">
                {`Transaction #`} <b>{paymentSuccess.transaction}</b>
              </Card.Text>
              <hr />
              {
                paymentSuccess.type === PAYMENT_METHOD.paypal && (
                  <>
                    <Card.Text className="my-3 px-2 d-flex flex-row justify-content-between align-items-center">
                      {`PayPal #`} <b>{paymentSuccess.paypalPmtId}</b>
                    </Card.Text>
                    <hr />
                  </>
                )
              }
              <Card.Text className="my-3 px-2 d-flex flex-row justify-content-between align-items-center">
                {`Total Paid`} <b>{currencyFormat(paymentSuccess.amount.toFixed(2), firm)}</b>
              </Card.Text>
              <hr />
              <Card.Text className="my-3 px-2 d-flex flex-row justify-content-between align-items-center">
                {LastRowRender}
              </Card.Text>
              <Card.Text className="d-flex justify-content-end mt-2">
                <Card.Link href="/">{`Done`}</Card.Link>
              </Card.Text>
            </Card.Body>
          </StyledCard>
        </Col>
      </Row>
    </Container>
  );
};

export default AccessPage;
