/* @flow */
import { type UserRepository, type RegisterCredentials, } from 'domain/user';

import { type WithCurrentUserToken, } from 'domain/user';

type Payload = RegisterCredentials;

type Callbacks = {
  onSuccess: (message) => void,
  onError: (Error) => void,
};

type Dependencies = {
  userRepository: UserRepository,
};

export type RegisterBehaviour = (
  Payload,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { userRepository, }: Dependencies, ) => {
  /**
   * Submit register from invite token
   * 
   * @param {Payload} payload - first name, last name and password
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const registerBehaviour: RegisterBehaviour = async (
    payload,
    withCurrentUserToken: WithCurrentUserToken,
    { onSuccess, onError, }: Callbacks,
  ) => {
    try {
      const message = await userRepository.register(payload, withCurrentUserToken);

      return onSuccess(message);
    } catch(error) {
      return onError(error);
    }
  };

  return registerBehaviour;
};
