// @flow
import * as R from 'ramda';
import { type GetSingleRetainerPayload, type RetainerMap, } from 'state/retainer/types';
import { type RetainerRequestPayments, type RetainerPayment, } from 'domain/retainer';

import { type Client, getAcctIsoType, } from 'domain/client';
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, useParams, } from 'react-router-dom';
import { type Firm, } from 'domain/firm';

import { type FormikActions, } from 'formik';
import { RetainerActionCreators, ClientActionCreators } from 'state/actions';
import { type RetainerPaymentRouteParams, } from '../payment/methods/types';
import { type ClientMap, } from 'state/client/types';
import RetainerPaymentV from './RetainerPaymentV';
import { PaymentActionCreators } from 'state/actions';
import { RetainerRedux, ClientRedux, UserRedux, PaymentRedux } from 'state/reducers';
import ErrorIcon from '@material-ui/icons/Error';


// connect redux
const useConnect = () => {
    const retainerRequestAmountMap: RetainerMap = useSelector(R.pipe(RetainerRedux.getReducerState, RetainerRedux.selectors.getRetainerRequestAmount));
    const clientsMap: ClientMap = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getClients));
    const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
    const headnoteApplication = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getHeadnoteApplication));

    const mapState = {
      retainerRequestAmountMap,
      clientsMap,
      firm,
      headnoteApplication
    };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    getRetainerRequestAmount: (payload: GetSingleRetainerPayload) => dispatch(RetainerActionCreators.getRetainerRequestAmount(payload, { thunk: true, })),
    fetchHeadnoteApplication: (): Promise<any> => dispatch(PaymentActionCreators.getHeadnoteApplication({ thunk: true, })),
    fetchAccountBalance: (payload: getAccountBalancePayload) => dispatch(ClientActionCreators.getAccountBalance(payload)),
    getSingleClient: (payload: getSingleClientPayload) => dispatch(ClientActionCreators.getSingleClient(payload, { thunk: true, })),
  }), [dispatch,]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/**
 * hook handle loading single retainer
 *
 */
 const useSingleRetainer = ({
    retainerId,
    clientId,
    retainerRequestAmountMap,
    getRetainerRequestAmount,
    setError,
    setLoading,
  }: {
    retainerId: number,
    clientId: number,
    retainerRequestAmountMap: RetainerMap,
    getRetainerRequestAmount: Function,
    setError: Function,
    setLoading: Function,
  }): {
    retainerItem?: RetainerRequestPayments,
  } => {
    // load single retainer
    useEffect(() => {
      setError(undefined);
  
      const getRetainerItem = async () => {
        // don't request anything if no retainer id
        if (!retainerId) { return; }
  
        try {
          setLoading(true);
  
          const payload: GetSingleRetainerPayload = {
            clientId,
            retainerId,
          };
          await getRetainerRequestAmount(payload);
        } catch (e) {
          setError(e.message || e);
        } finally {
          setLoading(false);
        }
      };
  
      getRetainerItem();
    }, [ clientId, getRetainerRequestAmount, retainerId, setError, setLoading ]);
  
    // get retainer from retainer map
    const retainerItem = useMemo<RetainerRequestPayments | undefined>(
      () => { return retainerRequestAmountMap.get(parseInt(retainerId)) },
      [ retainerRequestAmountMap, retainerId ]
    );
  
    // return undefined if no retainer found
    if (!retainerId || !retainerItem) {
      return {
        retainerItem: undefined,
      };
    }
  
    return {
      retainerItem
    };
  };


/**
 * Main component
 *
 * @returns
 */
const RetainerPaymentVM = () => {
  const {
    retainerRequestAmountMap,
    getRetainerRequestAmount,
    firm,
    headnoteApplication,
    fetchHeadnoteApplication,
    fetchAccountBalance,
    getSingleClient
  } = useConnect();

  // get url params
  const { clientId, retainerId } = useParams();

  // error and loading state
  const [error, setError,] = useState<string | undefined>(undefined);
  const [loading, setLoading,] = useState<boolean>(false);
  const [showInvalidTemplateWarning, setShowInvalidTemplateWarning] = useState(true);

  const { retainerItem } = useSingleRetainer({
      retainerId,
      clientId,
      retainerRequestAmountMap,
      getRetainerRequestAmount,
      setError,
      setLoading
  });

  //Get projectId from retanerRequest Item
  const projectId = retainerItem?.projectId;

  // TODO: Currently brining only one retainer at a time to the screen.
  const retainers = [ retainerItem ]

  const history = useHistory();

    /**
     * on pay form submit
     * 
     * @param {UserAuthInfo} values - auth info values from form
     * @param {FormikActions} actions - formik bag actions
     */
  const onSubmit = useCallback(async (values: RetainerPayment, actions: FormikActions) => {
      try {
          actions.setSubmitting(true);

          const params: RetainerPaymentRouteParams = {
              clientId: Number(clientId),
              retainerRequestId: Number(retainerId),
              projectId: Number(projectId),
              cart: values.retainers,
              amount: values.total
          };
          const isHeadnote: boolean = R.path(['portalSettings', 'isHeadnote'], firm);
          if (isHeadnote) {
              params.firstName = values.firstName;
              params.lastName = values.lastName;
          }
          // nav to payment method screen with user edited retainer payments
          history.push({
              pathname: '/payment-methods',
              state: params,
          });

      } catch (e) {
          // handle error
          actions.setErrors({
          auth: e.message || e,
        });
      } finally {
          actions.setSubmitting(false);
      }
  }, [history, projectId,]);

  useEffect(() => {
    if (firm && firm.portalSettings.isHeadnote) {
      fetchHeadnoteApplication();
    }

  }, [firm]);

  useEffect(() => {
    const isHeadnoteActive = firm.portalSettings.isHeadnote && headnoteApplication.status === 'VERIFIED';
    setShowInvalidTemplateWarning(!isHeadnoteActive || !retainerItem || !retainerItem.bankAccountId || retainerItem.isVoided);
  }, [headnoteApplication, firm, retainerItem]);

  // fetch account balance from client and project
  useEffect(() => {
    (
      async () => {
        try {
          const getSingleClientPayload : getSingleClientPayload = {
            clientId,
          };
      
          const response = await getSingleClient(getSingleClientPayload);

          const accountBalancePayload: getAccountBalancePayload = {
            clientId,
            projectId,
            acctIsoType: getAcctIsoType(firm, response),
          };
      
          
          await fetchAccountBalance(accountBalancePayload);
        } catch (e) {
          console.log('Failed to fetch account balance');
        }
      }
    )();
  }, [ clientId, projectId, fetchAccountBalance, getSingleClient, firm, ]);

  if (showInvalidTemplateWarning) {
    return (
      <div style={{marginTop: '63px', textAlign: 'center'}}>
        <ErrorIcon 
            style={{fontSize: '63px', color: '#ee6723', marginBottom: '30px'}}
        />
        <h3>This link is no longer active.</h3>
        <br />
        <p>Please contact your attorney to request a new payment link.</p>
      </div>
    )
  } else {
    return (
        <RetainerPaymentV
            error={error}
            retainers={retainers}
            loading={loading}
            onSubmit={onSubmit}
        />
    );
  }

    
};

export default RetainerPaymentVM;