// @flow
import CustomFormGroup, { type Props as CustomGroupProps, } from '../FormGroup';
import React, { type Node, } from 'react';

import { Colors, } from 'assets';
import Feedback from 'react-bootstrap/Feedback';
import { type FieldProps, } from 'formik';
import FormControl from 'react-bootstrap/FormControl';
import styled from 'styled-components';

const StyledFormError = styled(Feedback)`
  color: ${Colors.redDanger};
`;

type Props = {
};

const FormInput = ({
  field, form: { touched, errors, },
  isRow, label, className, labelClassName,
  inputSize, labelSize, controlWrapperClass,
  ...restProps
}: FieldProps<any> & CustomGroupProps) => {
  // field states:
  const _error = errors[field.name];
  const _touched = touched[field.name];

  return (
    <CustomFormGroup
      className={className}
      inputSize={inputSize}
      isRow={isRow}
      label={label}
      labelClassName={labelClassName}
      labelSize={labelSize}
      controlWrapperClass={controlWrapperClass}
    >
      <FormControl
        {...field}
        {...restProps}
        isInvalid={_error && _touched}
      />
      {
        _error && <StyledFormError type="invalid">{_error}</StyledFormError>
      }
    </CustomFormGroup>
  );
};

FormInput.defaultProps = {
  labelSize: 4,
  inputSize: 8
};

export default FormInput;
