import styled from 'styled-components';
import React from 'react';
import {triggerChatWindow} from "../../../../../../../iframeMessage";

export default ({ message }) => {

    return (
        <p>{message} Please <StyledLink onClick={triggerChatWindow}>click here</StyledLink> to contact support or email support at <a style={{display: "inline-block"}} href="mailto:support@bill4time.com">support@bill4time.com</a> to find out why.</p>
    )
}

const StyledLink = styled.span`
    &:hover {
        color: #0056b3;
    };
    cursor: pointer;
    color: #007bff;
`;