// @flow
import * as R from 'ramda';

import { Col, Form, Row, } from 'react-bootstrap';
import { Field, Formik, type FormikActions, type FormikProps, } from 'formik';
import React, { useCallback, useMemo, } from 'react';
import { type UserEmailInfo, UserEmailInfoSchema, } from 'domain/user';

import FormAlert from './form/FormAlert';
import { FormInput, } from 'view/components/index';
import FormLabel from './form/FormLabel';
import FormSubmit from './form/FormSubmit';
import { MESSAGES, } from 'constants/index';
import { type UpdateUserPayload, } from 'state/user/types';
import { UserRedux, } from 'state/reducers';
import styled from 'styled-components';
import { useSelector, } from 'react-redux';
import { useUpdateUserInfo, } from '../hooks/updateUser';

const StyleContainer = styled.div`
  flex: 1;
`;

type Props = {
  setSettingStatus: Function;
}

const useConnect = () => {
  // mapState
  const user = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const mapState = {
    user,
  };

  // mapDispatch
  // const dispatch = useDispatch();
  // const mapDispatch = useMemo(() => ({
  // }), [ dispatch, ]);

  return {
    ...mapState,
    // ...mapDispatch,
  };
};

const INITIAL_FORM_VALUES: UserEmailInfo = {
  email: '',
};

/**
 * Main Component
 *
 * @returns
 */
const SettingsForm = ({ setSettingStatus, }: Props) => {
  // connect redux
  const { user, } = useConnect();

  // update user dispatch action
  const { updateUserInfo, } = useUpdateUserInfo();

  // get initial email from user
  const initialValues = useMemo<UserEmailInfo>(() => {
    if (!user) {return INITIAL_FORM_VALUES;}

    return {
      email: user.email,
    };
  }, [ user, ]);

  /**
   * on change email
   *
   * @param {UserEmailInfo} values - auth info values from form
   * @param {FormikActions} actions - formik bag actions
   */
  const onSubmit = useCallback(async (values: UserEmailInfo, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);

      // Update user email
      const payload: UpdateUserPayload = {
        info: {
          email: values.email,
        },
      };

      await updateUserInfo(payload);

      // update page status success
      setSettingStatus(MESSAGES.accountSettings.emailUpdated);
    } catch (e) {
      // handle error
      actions.setErrors({
        emailSubmit: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ setSettingStatus, updateUserInfo, ]);

  return (
    <StyleContainer>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={UserEmailInfoSchema}
        // eslint-disable-next-line
        onSubmit={onSubmit}
      >
        {({ errors, handleSubmit, isSubmitting, dirty }: FormikProps<UserEmailInfo>) => (
          <Form onSubmit={handleSubmit}>

            <FormLabel label='Email' />

            <FormAlert alert={errors.emailSubmit} />

            <Row className='mx-0 pt-4'>
              <Col md={5}>
                <Field
                  required
                  component={FormInput}
                  name={'email'}
                  placeholder='Email'
                  type={'email'}
                  disabled
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </StyleContainer>
  );
};

export default SettingsForm;
