/* @flow */

import VirtualizedSelector, { IVirtualizedOptions, IDropDownOption } from "./B4TVirtualizedSelector";
import React from "react";
import { IClientSelectorResult } from "./ClientSelectorHook";

/**
 * Selector designed to handle data fetching of client list. Should be used in conjunction with client selector hook.
 * Built on top of the virtualized selector.
 */
export default (props: IClientSelectorOptions) => {

    const { options, filterCallback, placeholder, ...others } = props;

    // apply filtering
    let filteredData: IClientSelectorResult[] = options;
    if (filterCallback) {
        filteredData = filteredData.filter(filterCallback);
    }

    // transform data to selector format
    const modifiedOptions: IClientDropdownOption[] = filteredData.map((client: IClientSelectorResult) => ({
        id: client.id,
        name: client.clientName,
        ...client
    }));

    return (
        <VirtualizedSelector
            options={modifiedOptions}
            label={!placeholder ? "Client(s)" : null}
            placeholder={placeholder}
            textWidth="100%"
            {...others}
        />
    );
}

export type IClientSelectorOptions = ISpecificOptions & Omit<IVirtualizedOptions, "label" | "options">;
export type IClientDropdownOption = IDropDownOption & IClientSelectorResult;

interface ISpecificOptions {
    filterCallback?: (client: IClientSelectorResult) => boolean; // pass to filter clients shown - this does not prevent them from being retrieved from API
    options: IClientSelectorResult[];
    selectedOption: IClientDropdownOption | IClientDropdownOption[];
}