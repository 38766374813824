// @flow
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import styled from 'styled-components';
import { Colors, } from 'assets';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 700px !important;
  }
`;

const StyledNoButton = styled(Button)`
  && {
    background-color: ${Colors.blueButton};
    border-color: ${Colors.blueButton};
    &:hover {
      background-color: ${Colors.blueButtonHover};
      border-color: ${Colors.blueButtonHover};
    }
  }
`;

type Props = {
  isShow: boolean,
  onClose?: Function,
  onHide: Function,
  onModalAccept: Function,
};

const VerifyBankModal = ({ isShow = false, onHide, onModalAccept, }: Props) => {
  return (
    <StyledModal
      backdrop='static'
      className="p-0"
      show={isShow}
      onHide={onHide}
    >
      <Modal.Header closeButton className="border-bottom-0">
        <h2>{`Confirm Account Removal`}</h2>
      </Modal.Header>
      <Modal.Body>
        {`If you remove this account, you will need to re-verify a new bank account. This can take several days`}
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <Button
          className="font-weight-bold"
          variant="danger"
          onClick={onModalAccept}
        >
          {`Yes`}
        </Button>
        <StyledNoButton
          className="font-weight-bold"
          onClick={onHide}
        >
          {`No`}
        </StyledNoButton>
      </Modal.Footer>
    </StyledModal>
  );
};

export default VerifyBankModal;
