
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Alert, } from 'react-bootstrap';
import React, { useCallback, useEffect, useRef, useMemo, } from 'react';

const StyledAlert = styled(Alert)`
    background: transparent;
    border-color: transparent;
    color: red;
    padding: 0px;
    margin-top: 20px;
`;

export default ({ error, }) => <> {error.msg && <StyledAlert variant='danger'><FontAwesomeIcon icon={faExclamationCircle} /> {error.msg}</StyledAlert>} </>;


