/* @flow */

import React from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { defaultFontFamilies, defaultFontREM, defaultFontWeight } from '../../styles/styles';
import getSize from 'calculate-size';
import { ISelectorLoadState } from './virtualizedSelectorHook';
import { ThemeProvider, TextField, CircularProgress, makeStyles } from '@material-ui/core';
import { mainTheme } from '../../styles/themes';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';

const filter = createFilterOptions();

/*
 * Selector built on top of the standard selector and designed to be used with large datasets.
 * Will intelligently determine whether to virtualize list.
 */
export default function(props: IVirtualizedOptions) {

    const { style, selectedOption, onChange, setSelectedOption, selectorLoadState,
          setSelectorLoadState, isOpen, setIsOpen, renderOption, classes, label, options,
          placeholder, loading, textWidth, sort, error, helperText, openOnFocus, setOptionById, autoComplete, setData, ...others } = props;

    // will set the selectedOption and also invoke the optional onChange handler
    const modifiedOnChange = (ev, value) => {
      let finalValue = value;
      if (typeof value === 'string') {
        setSelectedOption({
          name: value,
        });
        finalValue = {
          name: value,
        }
      } else if (value && value.inputValue) {
        // Create a new value from the user input
        setSelectedOption({
          name: value.inputValue,
        });
        finalValue = {
          name: value.inputValue,
        }
      } else {
        setSelectedOption(value || {});
        finalValue = value || {};
      }

      // invoke optional handler
      if (onChange) {
        onChange(ev, finalValue);
      }
    }

    let onOpenHandler;
    let onCloseHandler;

    // opens the dropdown and loads data if it hasn't been loaded already
    onOpenHandler = (ev) => {
        if (!isOpen) {
            setIsOpen(true);

            // fetch data if it hasn't been loaded yet & isn't currently in the process of retrieval
            if (!selectorLoadState.hasLoaded && !selectorLoadState.isLoading) {
              setSelectorLoadState({...selectorLoadState, isLoading: true});
            }
        }
    };

    // closes the dropdown
    onCloseHandler = (ev) => {
        if (isOpen) {
            setIsOpen(false);
        }
    }

    // Adapter for react-window
    function ListboxComponentFunction(listboxProps, listBoxRef) {
      const { children, ...listBoxOther } = listboxProps;
      const itemData = React.Children.toArray(children);
      const itemCount = itemData.length;

      // gets the total height of the row as it would appear in the DOM
      const getRowHeight = (child: React.ReactNode) => {
        let totalTextHeight: number = LISTBOX_PADDING;

        if (renderOption) {
          // @ts-ignore
          child.props.children.props.children.props.children.forEach((subchild: React.ReactNode) => {
              // @ts-ignore
            totalTextHeight += getTextHeight(subchild.props.children);
          })
        } else {
          // @ts-ignore
          totalTextHeight += getTextHeight(child.props.children);
        }

        return totalTextHeight;
      };

      // gets the height of the text as it would appear in the DOM
      const getTextHeight = (text: string) => {
        return getSize(text, {
          font: defaultFontFamilies.split(',')[0],
          fontSize: `${defaultFontREM}`,
          fontWeight: `${defaultFontWeight}`,
          width: textWidth ?? '250px',
        }).height
      }

      // gets total height of list container
      const getListBoxHeight = () => {
        let totalHeight = 0;

        // max number of items in listbox should not exceed 15
        const length = itemData.length > 15 ? 15 : itemData.length;
        for (let i = 0; i < length; i++) {
          totalHeight += getRowHeight(itemData[i]);
        }
        return totalHeight;
      };

      const gridRef = useResetCache(itemCount);

      // return listbox component
      return (
        <div ref={listBoxRef}>
          <OuterElementContext.Provider value={listBoxOther}>
            <VariableSizeList
              itemData={itemData}
              height={getListBoxHeight() + 2 * LISTBOX_PADDING}
              width='100%'
              ref={gridRef}
              outerElementType={OuterElementType}
              innerElementType='ul'
              itemSize={(index) => getRowHeight(itemData[index])}
              overscanCount={5}
              itemCount={itemCount}
            >
              {renderRow}
            </VariableSizeList>
          </OuterElementContext.Provider>
        </div>
      );
    }

    const ListboxComponent = React.forwardRef(ListboxComponentFunction);

    // styling classes for selector
    const styles = useSelectorStyling();

    // return standard selector that uses virtualized listbox
    return (
      <ThemeProvider theme={mainTheme}>
          <Autocomplete
          open={isOpen}
          onOpen={onOpenHandler}
          onClose={onCloseHandler}
          onChange={modifiedOnChange}
          ListboxComponent={ListboxComponent}
          style= {style}
          size='medium'
          value={selectedOption}
          classes={{root: styles.root, option: styles.option, ...classes}}
          options={sort ? options.sort((a, b) => -b.name.localeCompare(a.name)) : options}
          loading={selectorLoadState.isLoading}
          getOptionSelected={(option: IDropDownOption, value: IDropDownOption) => {
            if(!value){
              return false;
            }
            return option.id === value.id && (option.name.localeCompare(value.name) === 0);
          }}
          renderInput={(params) => <TextField {...params}
              label={label} classes={{root: styles.inputField}}
              autoComplete={autoComplete}
              InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                  <React.Fragment>
                      {selectorLoadState.isLoading ? <CircularProgress style={{position: 'relative', left: '23px'}} color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                  </React.Fragment>
                  ),
              }}
              error={error}
              helperText={helperText}
            placeholder={placeholder ? placeholder : undefined} variant='outlined'
          />}
          getOptionLabel={(option: any) => option.name || ''}
          renderOption={renderOption}
          openOnFocus={openOnFocus ?? true}
          // provide filterOptions if freeSolo
          filterOptions={others.freeSolo ? (options, params) => {
            const filtered = filter(options, params);
    
            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              });
            }
    
            return filtered;
          } : undefined}
          selectOnFocus={others.freeSolo}
          clearOnBlur={others.freeSolo}
          handleHomeEndKeys={others.freeSolo}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
            ))
          }
          {...others}
          />
      </ThemeProvider>
    )
};

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      letterSpacing: '0.15px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 500,
      top: (style.top) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

export interface IVirtualizedOptions {
  isOpen: boolean; // whether selector dropdown is open or not
  setIsOpen: (open: boolean) => void; // shows/hides dropdown
  selectedOption?: IDropDownOption | IDropDownOption[]; // currently selected option
  setSelectedOption: (option: IDropDownOption | IDropDownOption[]) => void; // sets the currently selected option
  options: IDropDownOption[]; // the list of dropdown options to choose from
  onChange?: (ev, value: IDropDownOption | IDropDownOption[]) => void; // handler invoked upon a change in the selection
  setSelectorLoadState: (newState: ISelectorLoadState) => any;
  selectorLoadState: ISelectorLoadState;
  renderOption?: (option: IDropDownOption, state: any) => React.ReactFragment;
  label?: string; // label will populate placeholder text and will also show in the upper left corner when something is selected
  placeholder?: string;
  loading?: boolean;
  disableClearable?: boolean;
  getOptionSelected?: (option: IDropDownOption, value: IDropDownOption) => boolean;
  classes?: any;
  className?: string;
  disabled?: boolean;
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  required?: boolean;
  style?: any;
  textWidth?: string;
  sort?: boolean; // whether to sort the options or show them in the order they are given
  error?: boolean; // use for validation
  helperText?: string; // shown if 'error' is true
  openOnFocus? :boolean;
  setOptionById?: any; // not needed by this component, but it's passed around often enough and people forget not to pass it down to us
  autoComplete?: string;
  setData?: any; // doesn't do anything with it
}

export interface IDropDownOption {
  id: number | string;
  name: string;
  [x: string]: any;
}

const useSelectorStyling = makeStyles({
  option: {
      fontSize: `${defaultFontREM}`,
      fontWeight: 500,
      fontFamily: defaultFontFamilies.split(',')[0],
      overflow: 'hidden',
  },
  inputField: {
      '& input': {
          fontSize: `${defaultFontREM}`,
          fontWeight: 500,
          fontFamily: defaultFontFamilies.split(',')[0],
          letterSpacing: '0.15px',
      },
      '& label': {
          fontSize: `${defaultFontREM}`,
          fontWeight: 500,
          fontFamily: defaultFontFamilies.split(',')[0],
      },
      "& .MuiInputLabel-shrink": {
        fontSize: "20px",
        letterSpacing: '0.15px',
      },
      "& .MuiChip-label": {
        letterSpacing: '0.15px',
      },
      '& fieldset': {
          background: 'transparent',
          "& legend": {
            fontSize: "14px"
          }
      },
  },
  root: {
    "& .Mui-disabled": {
        backgroundColor: "#eeeeee"
    },
  }

});
