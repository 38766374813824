/* @flow */
import { type PaymentRepository, type RequiredFields, type StripePayBody, } from 'domain/payment';

import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (res: RequiredFields) => void,
  onError: (Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
};

export type RequiredFieldsLawpayBehaviour = (
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { paymentRepository, }: Dependencies, ) => {
  /**
   * Lawpay payment processors: get required fields
   * 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const requiredFieldsLawpayBehaviour: RequiredFieldsLawpayBehaviour = async (
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      const requiredFields = await paymentRepository.requiredFieldsLawpay(withCurrentUserToken);
      
      return onSuccess(requiredFields);
    } catch(error) {
      return onError(error);
    }
  };

  return requiredFieldsLawpayBehaviour;
};
