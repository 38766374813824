// @flow
import * as R from 'ramda';

import { APP_CONFIG, } from 'constants/index';
import Col from 'react-bootstrap/Col';
import { Colors, } from 'assets/index';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { type Invoice, } from 'domain/invoice';
import PaymentTotal from './components/PaymentTotal';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { faFilePdf, } from '@fortawesome/free-regular-svg-icons';
import { faPrint, } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const trackMixpanelEventPdf = () => { window.mixpanel.track("b4tp_client_portal_invoice_pdf"); }
const trackMixpanelEventPrint = () => { window.mixpanel.track("b4tp_client_portal_invoice_print"); }

const StyledContainer = styled(Container)`
  flex: 1;
`;

const StyledH2 = styled.h2`
  font-size: 30px;
`;

const StyledPrintButton = styled.a`
  padding-left: 5px;
  padding-right: 5px;
  color: ${Colors.greyLight};
  font-size: 15pt;
  text-decoration: none;
  &:hover {
    color: ${Colors.charcoal};
  }
`;

const StyldIframe = styled.iframe`
  background: ${Colors.white};
`;

type Props = {
  invoiceItem?: Invoice,
  invoiceId: number,
  onPayNow: Function,
  firm: ?Object,
  isolated: ?string,
  error: ?string,
  loading: boolean,
}

const InvoiceV = ({
  invoiceId, invoiceItem,
  onPayNow,
  firm, isolated,
  loading, error,
}: Props) => {
  const token = R.path([ 'encryptedInvoiceReference', ], invoiceItem) || '';

  return (
    <StyledContainer className="py-3 d-flex">
      <div className="d-flex flex-column" style={{ flex: 1, }}>
        <div className="py-3">
          <StyledH2>{`Invoice ${invoiceItem && invoiceItem.invoiceLabel ? invoiceItem.invoiceLabel : ''}`}</StyledH2>
        </div>

        <Row style={{ flex: 1, }}>
          <Col lg={9}>
            {
              loading ? <Spinner animation="border" role="status" /> : (
                <>
                <div className="text-right">
                  <StyledPrintButton href={`${APP_CONFIG.pdfLink}${token}`} title="Download PDF" onClick={trackMixpanelEventPdf}>
                    <FontAwesomeIcon icon={faFilePdf} />
                  </StyledPrintButton>
                  <StyledPrintButton href={`${APP_CONFIG.iframeLink}${token}`} target="_blank" title="Printable HTML" onClick={trackMixpanelEventPrint}>
                    <FontAwesomeIcon icon={faPrint} />
                  </StyledPrintButton>
                </div>
                {
                  error ? error : <StyldIframe height="850" src={`${APP_CONFIG.iframeLink}${token}`} title={invoiceId} width="100%" />
                }
                </>
              )
            }
            
          </Col>
          <Col lg={3}>
            <PaymentTotal
              currencyOption={firm}
              invoice={invoiceItem}
              isolated={isolated}
              onPayNow={onPayNow}
            />
          </Col>
        </Row>
      </div>
    </StyledContainer>
  );
};

export default InvoiceV;
