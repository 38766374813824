// @flow
import { ErrorActionCreators, ErrorTypes, } from '../actions';
import { put, takeEvery, } from 'redux-saga/effects';

import { type AwilixContainer, } from 'awilix';

const requestFailure = function * (
  _container: AwilixContainer,
  { error, }: { error: Error | string, }
) {
  if (!error) { return; };

  if (typeof error === 'string') {
    yield put(ErrorActionCreators.errorsQueueAppend(new Error(error)));
  } else if (typeof error.message === 'string') {
    yield put(ErrorActionCreators.errorsQueueAppend(error));
  }
};

export default (container: AwilixContainer) => ([
  takeEvery(ErrorTypes.REQUEST_FAILURE, requestFailure, container),
]);
