/* @flow */
import { type Invite, type InviteRepository, } from 'domain/invite';

import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (Array<Invite>) => void,
  onError: (Error) => void,
};

type Dependencies = {
  inviteRepository: InviteRepository,
};

export type GetInviteBehaviour = (
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { inviteRepository, }: Dependencies, ) => {
  /**
   * get list invite behaviour
   * 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  
  const getInviteBehaviour: GetInviteBehaviour = async (
    withCurrentUserToken: WithCurrentUserToken,
    { onSuccess, onError, }: Callbacks,
  ) => {
    try {
      const invites: Array<Invite> = await inviteRepository.getInvite(withCurrentUserToken);

      return onSuccess(invites);
    } catch(error) {
      return onError(error);
    }
  };

  return getInviteBehaviour;
};
