import { UserActionCreators, } from 'state/actions';
import { store, } from 'state';

// listen to message from parent app
window.addEventListener("message", function (e) {
    if (e.data === "height") {
        // return height message to parent
        window.parent.postMessage({
            //Minimum page height when embedded should be 640 to accomodate user permissions and invites
            height: Math.max(document.getElementById("root").offsetHeight, 1062)
        }, "*");
    }
});

/**
 *  Get iframe's parent's url
 *
 * @returns {string}
 */
export const getParentUrl = (): string => {
    return (window.location != window.parent.location)
    ? document.referrer
    : document.location.href;
};

/**
 *  Post message to parent window and trigger lawpay
 *  registration wizard
 *
 */
export const triggerParentLawpayRegistration = (): void => {
    let parentDomain = getParentUrl();

    // check if development parent site
    if (parentDomain.includes("development")) {
        parentDomain = "*";
    }

    window.parent && window.parent.postMessage({
        event: "portal:lawpay:registration",
        type: "trigger"
    }, parentDomain);
};

window.addEventListener("message", function (event) {
    if (event.data.event === "portal:lawpay:closed" && event.data.type === "trigger") {
        store.dispatch(UserActionCreators.getFirm());
    }
});


/**
 *  Post message to parent window and trigger chat window open.
 *
 */
export const triggerChatWindow = (): void => {
    let parentDomain = getParentUrl();

    // check if development parent site
    if (parentDomain.includes("development")) {
        parentDomain = "*";
    }

    window.parent && window.parent.postMessage({
        event: "portal:chat:open",
        type: "trigger"
    }, parentDomain);
};

/**
 * Post message to parent window and change to the Subscription Tab
 * 
 */
export const triggerParentChangeSubscriptionTab = () : void => {
    let parentDomain = getParentUrl();

    // check if development parent site
    if (parentDomain.includes("development")) {
        parentDomain = "*";
    }

    window.parent && window.parent.postMessage({
        event: "portal:tabSettings:subscription",
        type: "trigger"
    }, parentDomain);

}