/* @flow */

import * as R from 'ramda';

import { ClientRedux } from '../../../state/reducers';
import useVirtualizedSelectorHook, { IVirtualizedSelectorReturnValues } from "./virtualizedSelectorHook";
import { IClientDropdownOption } from "./B4TClientSelector";
import { useDispatch, useSelector, } from 'react-redux';
import { UserActionCreators } from '../../../state/actions';

import { useMemo } from 'react';

const useConnect = () => {
    // mapState
    const clientsMap = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getFirmClients));
  
    const mapState = {
      clientsMap
    };
  
    // mapDispatch
    const dispatch = useDispatch();
    const mapDispatch = useMemo(() => ({
      getFirmClients: () => dispatch(UserActionCreators.getFirmClients(undefined, { thunk: true, })),
    }), [ dispatch, ]);
  
    return {
      ...mapState,
      ...mapDispatch,
    };
};

export default function(props: IClientSelectorHookOptions): IVirtualizedSelectorReturnValues<IClientSelectorResult> {
    const { selectedClient } = props;

    const { clientsMap, getFirmClients, } = useConnect();

    const clientArray: IClientSelectorResult[] = Array.from(clientsMap).map(([ key, value, ]) => ({
        clientName: value.name,
        id: value.id,
        ...value
    }));

    // fetches clients and populates local data
    const fetchClients = async () => {
        await getFirmClients();
    };

    // invoke virtual selector hook that'll handle common selector logic
    const virtualizedHookValues: IVirtualizedSelectorReturnValues<IClientSelectorResult> =
        useVirtualizedSelectorHook<IClientSelectorResult>({fetchData: fetchClients, selectedOption: selectedClient, data: clientArray, ...props});

    return virtualizedHookValues;
}

export interface IClientSelectorResult {
    id: number;
    clientName: string;
}

export interface IClientSelectorHookOptions {
    fetchErrorHandler?: (error: Error) => void;
    selectedClient: IClientDropdownOption | IClientDropdownOption[];
    isLoading?: boolean; // pass if clients are to be loaded as soon as selector renders
    hasLoaded?: boolean; // pass if clients have been loaded outside of the hook
}