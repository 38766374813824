/* @flow */
import { Colors, Images, } from 'assets/index';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { NavLink, } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

const currentTime = new Date();
const copyrightYear = currentTime.getFullYear(); // get current year to this part

const StyledFooter = styled.footer`
  height: 120px;
  padding-top: 20px;
  background-color: ${Colors.whiteSmoke};
  border-top: 1px solid ${Colors.gainsBoro};
  margin-top: auto;
  
  .bill4time-logo {
    width: 157px;
    height: 27px;
  }

  .nav {
    .footer-link {
      display: block;
      line-height: 1rem;
      color: ${Colors.greyEmpress};
      cursor: pointer;
      padding: 0 5px;
      border-right: 1px solid ${Colors.greyEmpress};
      &.no-link {
        cursor: auto;
        &:hover {
          text-decoration: none
        }
        
      }
      :hover {
        text-decoration: underline;
      }
    }

    .footer-link:last-child {
      border-right: none !important;
    }
  }

  .bill4time-copyright {
    color: ${Colors.greyEmpress}
    line-height: 1.7rem;
  }
`;

const Footer = () => (
  <StyledFooter className='style-footer'>
    <Container>
      <div className='d-flex flex-column justify-content-center align-items-center'>
      <a href="https://www.bill4time.com">
        <Image alt='bill4time-logo' className='mb-3 bill4time-logo' src={Images.common.bill4timeLogo} />
      </a>
        <Nav className='justify-content-center mb-2'>
          <Nav.Item className='nav-link footer-link no-link'>{`All Rights Reserved`}</Nav.Item>
          <NavLink activeClassName='footer-selected-link' className='nav-link footer-link' to={'/privacy'}>{`Privacy Policy`}</NavLink>
          <NavLink activeClassName='footer-selected-link' className='nav-link footer-link' to={'/security'}>{`Security Statement`}</NavLink>
          <NavLink activeClassName='footer-selected-link' className='nav-link footer-link' to={'/terms'}>{`Terms of Service`}</NavLink>
        </Nav>
        <p className='mb-0 bill4time-copyright'>{`©${copyrightYear} Bill4Time.`}</p>
      </div>
    </Container>
  </StyledFooter>
);

export default Footer;
