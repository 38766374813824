/* @flow */
import * as R from 'ramda';

import { ACCT_ISO_TYPE, type AccountBalance, type AcctIsoType, type Client, } from 'domain/client';

import { APP_CONFIG, } from 'constants/index';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Colors, } from 'assets/index';
import Container from 'react-bootstrap/Container';
import { type DropDownOption, } from 'view/components/DropdownCustom/types';
import Dropdown from 'view/components/DropdownCustom';
import { type Firm, } from 'domain/firm';
import { type Invoice, } from 'domain/invoice';
import { type RetainerRequestPayments, } from 'domain/retainer';
import InvoiceTable from './components/InvoiceTable';
import RetainerTable from './components/RetainerTable';
import { Link, } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { type Payment, } from 'domain/payment';
import PaymentTable from './components/PaymentTable';
import { type Project, } from 'domain/project';
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import { type User, } from 'domain/user';
import styled from 'styled-components';

const trackMixpanelEventPaid = () => { window.mixpanel.track("b4tp_client_portal_paid_tab") }
const trackMixpanelEventUnpaid = () => { window.mixpanel.track("b4tp_client_portal_unpaid_tab") }
const trackMixpanelEventAll = () => { window.mixpanel.track("b4tp_client_portal_all_tab") }
const trackMixpanelEventClientDropdown = () => { window.mixpanel.track("b4tp_client_portal_client_dropdown") }
const trackMixpanelEventProjectDropdown = () => { window.mixpanel.track("b4tp_client_portal_project_dropdown") }

type Props = {
  acctIsoType: ?AcctIsoType,
  accountBalance: AccountBalance,
  firm: Firm,
  user: User,
  invoices: Array<Invoice>,
  isHideAccounting: boolean,
  payments: Array<Payment>,
  groupClient: Array<Client>,
  groupProject: Array<Project>,
  hasUnpaidInvoice: boolean,
  loadingClient: boolean,
  loadingProject: boolean,
  noPayment: boolean,
  selectedClient?: Client,
  selectedProject?: Project,
  onSelectClient: Function,
  onSelectProject: Function,
  onPayNow: Function,

  getClientDropDownLabel: Function,
  getClientOptionValue: Function,
  getClientOptionLabel: Function,

  getProjectDropDownLabel: Function,
  getProjectOptionValue: Function,
  getProjectOptionLabel: Function,
};

const StyledHome = styled(Container)`
  flex: 1;

  .box-header {
    min-height: 85px;
  }

  .table-wrapper {
    // same height for both invoice and payment tables
    height: ${APP_CONFIG.minTableHeight};
  }

  .wrapper-payment-table {
    max-height: ${APP_CONFIG.minTableHeight};
  }
`;

const StyledCard = styled(Card)`
  position: static !important; 
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
`;

const StyledHeaderLeft = styled(Card.Header)`
  background-color: ${Colors.white}  !important;
`;

const StlyedNav = styled(Nav)`
  .nav-pills {
  margin-left: .5rem;
  }
  .nav-link {
    display: inline;
    color: ${Colors.greyEmpress};
    padding: .25rem .5rem;
  }
  .active {
    background-color: #343a40 !important;
  }
`;

const StyledLink = styled(Link)`
  color: ${Colors.greyEmpress};
  font-size: 0.85rem;

  :hover {
    color: ${Colors.greyEmpress} !important;
  }
`;

const StyledDropdownContainer = styled(Container)`
  margin-bottom: 7px;
  .first-dropdown {
    margin-right: 5px;
  }
`;

const StyledTitle = styled(Nav)`
  .nav-pills {
    margin-left: .5rem;
    }
  .nav-link {
    display: inline;
    color: ${Colors.blackGrey};    
    padding: .25rem .5rem;
  }
  .header {
    border-bottom: 2px solid #377BD1;
    padding-bottom: 2px;
  }
`;

/**
 * main component
 *
 * @param {Props} props
 * @returns
 */
const HomePage = (props: Props) => {
  const { acctIsoType, } = props;
  const isProjectDropDownDisabled = acctIsoType !== ACCT_ISO_TYPE.project;

  const clientId = R.path([ 'id', ], props.selectedClient);
  const projectId = R.path([ 'id', ], props.selectedProject);

  // true for Invoices, false for Retainers
  const [showFiltersFlag, setShowFiltersFlag] = useState(true);
  
  const invoicesOnClickEvent = () => {
    setShowFiltersFlag(true);
  }
  
  const retainersOnClickEvent = () => {
    setShowFiltersFlag(false);
  };

  return (
    <StyledHome className="my-4">

      <Container className="d-flex pb-1">
        <div className='d-flex flex-column w-100'>
          <h4>{`${props.firm ? props.firm.firmName : '___'}`}</h4>
        </div>
      </Container>

      <StyledDropdownContainer className="d-flex">
        <Row className='mx-0'>
          <Dropdown
            className="first-dropdown"
            getDropDownLabel={props.getClientDropDownLabel}
            getOptionLabel={props.getClientOptionLabel}
            getOptionValue={props.getClientOptionValue}
            options={props.groupClient}
            placeholder="Client"
            value={props.selectedClient}
            width="220px"
            onChange={props.onSelectClient}
            onMenuOpen={trackMixpanelEventClientDropdown}
          />
          {
            !isProjectDropDownDisabled && (
              <Dropdown
                className="mr-1"
                disable={isProjectDropDownDisabled}
                getDropDownLabel={props.getProjectDropDownLabel}
                getOptionLabel={props.getProjectOptionLabel}
                getOptionValue={props.getProjectOptionValue}
                options={props.groupProject}
                placeholder="Project"
                value={props.selectedProject}
                width="220px"
                onChange={props.onSelectProject}
                onMenuOpen={trackMixpanelEventProjectDropdown}
              />
            )
          }
          {
            (props.loadingClient || props.loadingProject) && (
              <Spinner animation="border" className="ml-2" role="status" />
            )
          }
        </Row>
      </StyledDropdownContainer>

      <Container className="d-xl-flex flex-xs-column px-0">

        <Col className="pb-3 position-static table-wrapper" lg={12} xl={7}>
          <StyledCard className="px-4 pt-3 h-100">
            <Tab.Container defaultActiveKey="invoice-unpaid">
              <StyledHeaderLeft
                className="d-md-flex flex-xs-column align-items-center justify-content-between p-1"
              >
                <StyledTitle>
                  <Card.Title className="my-1">
                    <Nav.Item>
                      <Nav.Link eventKey="invoice-unpaid" onClick={invoicesOnClickEvent}> <h5 className={ showFiltersFlag ? 'header' : null }>{`Invoices`}</h5> </Nav.Link>
                    </Nav.Item>
                  </Card.Title>
                </StyledTitle>

                <StyledTitle>
                  <Card.Title className="my-1">
                    <Nav.Item>
                      <Nav.Link eventKey="retainer-unpaid" onClick={retainersOnClickEvent}> <h5 className={ !showFiltersFlag ? 'header' : null }>{`Payment Requests`}</h5> </Nav.Link>
                    </Nav.Item>
                  </Card.Title>
                </StyledTitle>
                {
                  (showFiltersFlag) && (
                    <StlyedNav className="flex-row justify-content-between mr-1" variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="invoice-unpaid" onClick={trackMixpanelEventUnpaid}>{`Unpaid`}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="invoice-paid" onClick={trackMixpanelEventPaid}>{`Paid`}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="invoice-all" onClick={trackMixpanelEventAll}>{`All`}</Nav.Link>
                      </Nav.Item>
                    </StlyedNav>
                  )
                }
                {
                  (!showFiltersFlag) && (
                    <StlyedNav className="flex-row justify-content-between mr-1" variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="retainer-unpaid" >{`Unpaid`}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="retainer-paid" >{`Paid`}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="retainer-all" >{`All`}</Nav.Link>
                      </Nav.Item>
                    </StlyedNav>
                  )
                }
              </StyledHeaderLeft>

              <Card.Body
                className="h-100 overflow-hidden px-0"
              >
                <Tab.Content className="h-100 overflow-hidden">
                  <Tab.Pane className="h-100" eventKey="invoice-unpaid">
                    <InvoiceTable
                      clientId={clientId}
                      data={props.invoices}
                      firm={props.firm}
                      isPaid={false}
                      projectId={projectId}
                      user={props.user}
                    />
                  </Tab.Pane>
                  <Tab.Pane className="h-100" eventKey="invoice-paid">
                    <InvoiceTable
                      clientId={clientId}
                      data={props.invoices}
                      firm={props.firm}
                      isPaid={true}
                      projectId={projectId}
                      user={props.user}
                    />
                  </Tab.Pane>
                  <Tab.Pane className="h-100" eventKey="invoice-all">
                    <InvoiceTable
                      clientId={clientId}
                      data={props.invoices}
                      firm={props.firm}
                      projectId={projectId}
                      user={props.user}
                    />
                  </Tab.Pane>
                  <Tab.Pane className="h-100" eventKey="retainer-unpaid">
                    <RetainerTable
                      clientId={clientId}
                      data={props.retainers}
                      firm={props.firm}
                      isPaid={false}
                      projectId={projectId}
                      user={props.user}
                    />
                  </Tab.Pane>
                  <Tab.Pane className="h-100" eventKey="retainer-paid">
                    <RetainerTable
                      clientId={clientId}
                      data={props.retainers}
                      firm={props.firm}
                      isPaid={true}
                      projectId={projectId}
                      user={props.user}
                    />
                  </Tab.Pane>
                  <Tab.Pane className="h-100" eventKey="retainer-all">
                    <RetainerTable
                      clientId={clientId}
                      data={props.retainers}
                      firm={props.firm}
                      projectId={projectId}
                      user={props.user}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </StyledCard>
        </Col>

        <Col className="position-static table-wrapper pb-3" lg={12} xl={5}>
          <StyledCard className="h-100 px-4 pt-3 ">

            <Card.Body className="px-0 d-flex flex-column overflow-hidden">
              <Card.Title>
                <h3>{`Payment History`}</h3>
              </Card.Title>
              <div
                className="mb-2 overflow-auto h-100"
              >
                <PaymentTable data={props.payments} firm={props.firm} user={props.user} />
              </div>
              {
                !props.isHideAccounting && (
                  <StyledLink 
                    className="pb-3"
                    to={{
                      pathname: '/accounting',
                      state: {
                        selectedClient: props.selectedClient,
                        selectedProject: props.selectedProject,
                      },
                    }}
                  >
                    {`View full account history`}
                  </StyledLink>
                )
              }
              
            </Card.Body>
          </StyledCard>
        </Col>

      </Container>

    </StyledHome>
  );
};

export default HomePage;
