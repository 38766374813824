import React from 'react';
import styled from 'styled-components';

export default () => {
    return (
        <div>
            <p>Your application is currently pending review. Here's what you can expect next:</p>
            <StyledList className="mb-4">
                <li className="mb-2">
                    {`Most applications are approved in 7 - 10 business days`}
                </li>
                <li className="mb-2">
                    {`We'll contact you if we need any additional information`}
                </li>
                <li className="mb-2">
                    {`You'll receive a notification once your account is approved and you have access to Bill4Time Payments`}
                </li>
            </StyledList>
        </div>
    )
}

const StyledList = styled.ul`
  padding-left: 20px;
  margin-bottom: 0px;
`;