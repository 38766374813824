/* @flow */
import * as awilix from 'awilix';

import { makeConnectCustomPayment, makeDisconnectCustomPayment, } from 'app/payment/CustomPayment';
import { makeConnectLawpay, makeDisconnectLawpay } from 'app/payment/LawpayPayment';
import { makeConnectPaypal, makeDisconnectPaypal, } from 'app/payment/PaypalPayment';
import { makeResetPassword, makeResetRequest, } from 'app/user/ResetCredentialsUser';

import makeAccountHistoryRepository from 'infra/accountHistory/AccountHistoryRepository';
import makeAddBankAccount from 'app/payment/AddBankAccount';
import makeAddCreditCard from 'app/payment/AddCreditCard';
import makeClientRepository from 'infra/client/ClientRepository';
import makeConduitApiService from 'infra/conduit/ApiService';
import makeConnectStripe from 'app/payment/ConnectStripe';
import makeDeleteInvite from 'app/invite/DeleteInvite';
import makeDisconnectStripe from 'app/payment/DisconnectStripe';
import makeFirmRepository from 'infra/firm/FirmRepository';
import makeGetAccountBalance from 'app/accountBalance/GetAccountBalance';
import makeGetAccountHistories from 'app/accountHistory/GetAccountHistories';
import makeGetAllBankAccounts from 'app/payment/GetAllBankAccounts';
import makeGetAllCreditCards from 'app/payment/GetAllCreditCards';
import makeGetHeadnoteApplication from 'app/payment/getHeadnoteApplication';
import makeGetByToken from 'app/user/GetByToken';
import makeGetClients from 'app/client/GetClients';
import makeGetSingleClient from 'app/client/GetSingleClient';
import makeGetContact from 'app/invite/GetContact';
import makeGetFirmClients from 'app/firm/GetFirmClients';
import makeGetFirmUsers from 'app/firm/GetFirmUsers';
import makeGetInvite from 'app/invite/GetInvite';
import makeGetInvoiceToken from 'app/invoice/GetInvoiceToken';
import makeGetInvoices from 'app/invoice/GetInvoices';
import makeGetPayments from 'app/payment/GetPayments';
import makeGetInvoicePayments from 'app/payment/GetInvoicePayment';
import makeGetProjectsOfClient from 'app/project/GetProjectsOfClient';
import makeGetSingleInvoice from 'app/invoice/GetSingleInvoice';
import makeGetSingleRetainer from 'app/retainer/GetSingleRetainer';
import makeGetStatementLink from 'app/statement/GetStatementLink';
import makeGetRetainers from 'app/retainer/GetRetainers';
import makeInviteRepository from 'infra/invite/InviteRepository';
import makeInvoiceRepository from 'infra/invoice/InvoiceRepository';
import makeRetainerRepository from 'infra/retainer/RetainerRepository';
import makeStatementRepository from 'infra/statement/StatementRepository';
import makePaymentRepository from 'infra/payment/PaymentRepository';
import makePaypalGetSignupLink from 'app/payment/PaypalGetSignupLink';
import makePostInvite from 'app/invite/PostInvite';
import makeProjectRepository from 'infra/project/ProjectRepository';
import makeRegisterUser from 'app/user/RegisterUser';
import makeRemoveBankAccount from 'app/payment/RemoveBankAccount';
import makeRemoveCreditCard from 'app/payment/RemoveCreditCard';
import makeSetTokenFromOutsideBehaviour from 'app/user/SetTokenFromOutside';
import makeSignInUser from 'app/user/SignInUser';
import makeSignOutUser from 'app/user/SignOutUser';
import makeStripePay from 'app/payment/StripePayment';
import makeStripePaymentMethods from 'app/payment/StripePaymentMethods';
import makeUpdateFirmSettings from 'app/firm/UpdateFirmSettings';
import makeUpdateFirmUser from 'app/firm/UpdateFirmUser';
import makeUpdateUserBehaviour from 'app/user/UpdateUser';
import makeUserRepository from 'infra/user/UserRepository';
import makeVerifyBankAccount from 'app/payment/VerifyBankAccount';
import makeRequiredFieldsLawpay from 'app/payment/RequiredFieldsLawpay';
import makeGetFirmFeatures from 'app/firm/GetFeatureList';
import makeGetFirmStatusInfo from 'app/firm/GetFirmStatusInfo';
import makeGetFirmInvoiceInfo from 'app/firm/GetFirmInvoiceInfo';
import makeGetFirmHNSettings from 'app/firm/GetHeadnoteFirmSettings';
import makeGetDisconnectedProcessors from 'app/payment/getDisconnectedProcessors';
import makeGetLinkToPayTemplate from 'app/payment/GetLinkToPayTemplate';
import makeGetPaymentReceivingBankType from 'app/payment/GetPaymentReceivingBankType';
import makeGetClientHeadnoteInfo from 'app/client/GetClientHeadnoteInfo';

// Create the container and set the injectionMode to PROXY (which is also the default).
const container = awilix.createContainer();

/* ------------- Infra ------------- */
container
  // services
  .register({
    conduitApiService: awilix.asFunction(makeConduitApiService).singleton(),
  })
  // repositories
  .register({
    accountHistoryRepository: awilix.asFunction(makeAccountHistoryRepository).singleton(),
    clientRepository: awilix.asFunction(makeClientRepository).singleton(),
    firmRepository: awilix.asFunction(makeFirmRepository).singleton(),
    invoiceRepository: awilix.asFunction(makeInvoiceRepository).singleton(),
    retainerRepository: awilix.asFunction(makeRetainerRepository).singleton(),
    paymentRepository: awilix.asFunction(makePaymentRepository).singleton(),
    projectRepository: awilix.asFunction(makeProjectRepository).singleton(),
    userRepository: awilix.asFunction(makeUserRepository).singleton(),
    inviteRepository: awilix.asFunction(makeInviteRepository).singleton(),
    statementRepository: awilix.asFunction(makeStatementRepository).singleton(),
  });

/* ------------- App ------------- */
container
  // user behaviours
  .register({
    signInUser: awilix.asFunction(makeSignInUser).singleton(),
    signOutUser: awilix.asFunction(makeSignOutUser).singleton(),
    resetRequestUser: awilix.asFunction(makeResetRequest).singleton(),
    resetPasswordUser: awilix.asFunction(makeResetPassword).singleton(),
    getByToken: awilix.asFunction(makeGetByToken).singleton(),
    updateUser: awilix.asFunction(makeUpdateUserBehaviour).singleton(),
    setTokenFromOutside: awilix.asFunction(makeSetTokenFromOutsideBehaviour).singleton(),
    registerUser: awilix.asFunction(makeRegisterUser).singleton(),
  })
  // firm behaviours
  .register({
    updateFirmSettings: awilix.asFunction(makeUpdateFirmSettings).singleton(),
    updateFirmUser: awilix.asFunction(makeUpdateFirmUser).singleton(),
    getFirmUsers: awilix.asFunction(makeGetFirmUsers).singleton(),
    getFirmClients: awilix.asFunction(makeGetFirmClients).singleton(),
    getFeatureList: awilix.asFunction(makeGetFirmFeatures).singleton(),
    getFirmStatusInfo: awilix.asFunction(makeGetFirmStatusInfo).singleton(),
    getFirmInvoiceInfo: awilix.asFunction(makeGetFirmInvoiceInfo).singleton(),
    getHeadnoteFirmSettings: awilix.asFunction(makeGetFirmHNSettings).singleton(),
  })
  // client behaviours
  .register({
    getClients: awilix.asFunction(makeGetClients).singleton(),
    getSingleClient: awilix.asFunction(makeGetSingleClient).singleton(),
    getClientHeadnoteInfo: awilix.asFunction(makeGetClientHeadnoteInfo).singleton()
  })
  // project behaviours
  .register({
    getProjectsOfClient: awilix.asFunction(makeGetProjectsOfClient).singleton(),
  })
  // invoice behaviours
  .register({
    getInvoices: awilix.asFunction(makeGetInvoices).singleton(),
    getSingleInvoice: awilix.asFunction(makeGetSingleInvoice).singleton(),
    getInvoiceToken: awilix.asFunction(makeGetInvoiceToken).singleton(),
  })
  // retainer behaviours
  .register({
    getRetainerRequestAmount: awilix.asFunction(makeGetSingleRetainer).singleton(),
    getRetainers: awilix.asFunction(makeGetRetainers).singleton(),
  })
  // statement behaviours
  .register({
    getStatementLink: awilix.asFunction(makeGetStatementLink).singleton()
  })
  // payment behaviours
  .register({
    getPayments: awilix.asFunction(makeGetPayments).singleton(),
    getInvoicePayments: awilix.asFunction(makeGetInvoicePayments).singleton(),
    stripePay: awilix.asFunction(makeStripePay).singleton(),
    stripePaymentMethods: awilix.asFunction(makeStripePaymentMethods).singleton(),

    getAllBankAccounts: awilix.asFunction(makeGetAllBankAccounts).singleton(),
    addBankAccount: awilix.asFunction(makeAddBankAccount).singleton(),
    verifyBankAccount: awilix.asFunction(makeVerifyBankAccount).singleton(),
    removeBankAccount: awilix.asFunction(makeRemoveBankAccount).singleton(),

    addCreditCard: awilix.asFunction(makeAddCreditCard).singleton(),
    getAllCreditCards: awilix.asFunction(makeGetAllCreditCards).singleton(),
    removeCreditCard: awilix.asFunction(makeRemoveCreditCard).singleton(),

    // custom payment behaviours
    connectCustomPayment: awilix.asFunction(makeConnectCustomPayment).singleton(),
    disconnectCustomPayment: awilix.asFunction(makeDisconnectCustomPayment).singleton(),

    // paypal behaviours
    paypalGetSignupLink: awilix.asFunction(makePaypalGetSignupLink).singleton(),
    connectPaypal: awilix.asFunction(makeConnectPaypal).singleton(),
    disconnectPaypal: awilix.asFunction(makeDisconnectPaypal).singleton(),

    // lawpay behaviours
    connectLawpay: awilix.asFunction(makeConnectLawpay).singleton(),
    disconnectLawpay: awilix.asFunction(makeDisconnectLawpay).singleton(),
    requiredFieldsLawpay: awilix.asFunction(makeRequiredFieldsLawpay).singleton(),

    // stripe
    connectStripe: awilix.asFunction(makeConnectStripe).singleton(),
    disconnectStripe: awilix.asFunction(makeDisconnectStripe).singleton(),

    getHeadnoteApplication: awilix.asFunction(makeGetHeadnoteApplication).singleton(),
    getDisconnectedProcessors: awilix.asFunction(makeGetDisconnectedProcessors).singleton(),
    getPaymentReceivingBankType: awilix.asFunction(makeGetPaymentReceivingBankType).singleton(),

    //link2pay
    getLinkToPayTemplate: awilix.asFunction(makeGetLinkToPayTemplate).singleton(),
  })
  // account balance
  .register({
    getAccountBalance: awilix.asFunction(makeGetAccountBalance).singleton(),
  })
  // account history
  .register({
    getAccountHistories: awilix.asFunction(makeGetAccountHistories).singleton(),
  })
  // invite
  .register({
    getInvite: awilix.asFunction(makeGetInvite).singleton(),
    postInvite: awilix.asFunction(makePostInvite).singleton(),
    deleteInvite: awilix.asFunction(makeDeleteInvite).singleton(),
    getContact: awilix.asFunction(makeGetContact).singleton(),
  });

export default container;
