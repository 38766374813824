// @flow
import * as R from 'ramda';

import { AccountHistoryActionCreators, AccountHistoryTypes, } from '../actions';
import { type AccountHistoryMap, type getAccountHistoriesPayload, type getAccountHistoriesSuccessPayload, } from './types';
import { END, eventChannel, } from 'redux-saga';
import { cancelled, put, select, take, takeLatest, } from 'redux-saga/effects';

import { type AccountHistory, } from 'domain/accountHistory';
import { type AwilixContainer, } from 'awilix';
import { type GetAccountHistoriesBehaviour, } from 'app/accountHistory/GetAccountHistories';
import { UserRedux, } from 'state/reducers';
import { type WithCurrentUserToken, } from 'domain/user';

/**
   * get account histories of a client, project redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
const getAccountHistories = function * (container: AwilixContainer, { payload, meta, }: { payload: getAccountHistoriesPayload, meta: ?Object, }) {
  try {
    // resolve get invoices of a client, project behaviour from awilix container
    const getAccountHistoriesBehaviour: GetAccountHistoriesBehaviour = container.resolve('getAccountHistories');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getAccountHistoriesBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (invoices) => {
            emitter({ data: invoices, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data: accountHistories, error, }: { data: Array<AccountHistory>, error: Object | string, } = yield take(channel);
        if (!accountHistories && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (accountHistories) {
          // process account histories list to js Map ['key', 'value']
          const accountHistoriesTuple = accountHistories.map((i: AccountHistory, index: number) => [ index, i, ]);
          const accountHistoriesMap: AccountHistoryMap = new Map(accountHistoriesTuple);

          const payload: getAccountHistoriesSuccessPayload = { accountHistories: accountHistoriesMap, };
          yield put(AccountHistoryActionCreators.getAccountHistoriesSuccess(payload));
        }
    }
  } catch (e) {
    yield put(AccountHistoryActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(AccountHistoryActionCreators.requestFailure(true, null, meta));
    }
  }
};

export default (container: AwilixContainer) => ([
  takeLatest(AccountHistoryTypes.GET_ACCOUNT_HISTORIES, getAccountHistories, container),
]);
