// @flow
import * as R from 'ramda';

import { type Client, getClientDisplay, } from 'domain/client';
import { ClientRedux, UserRedux, } from 'state/reducers';
import { Colors, Images, } from 'assets/index';
import { Field, Formik, type FormikBag, type FormikProps, } from 'formik';
import React, { useCallback, useEffect, useMemo, } from 'react';
import { type User, type UserPermissionsInfo, } from 'domain/user';
import { useDispatch, useSelector, } from 'react-redux';

import Alert from 'react-bootstrap/Alert';
import AutoInitClients from './AutoInitClients';
import Button from 'react-bootstrap/Button';
import { type ClientMap, } from 'state/client/types';
import { type DropDownOption, } from 'view/components/Dropdown/types';
import { type FirmUserMap, type UpdateFirmUserPayload, } from 'state/user/types';
import { FormSelectMulti, } from 'view/components';
import Image from 'react-bootstrap/Image';
import { type PermissonFormValues, } from './types';
import Spinner from 'react-bootstrap/Spinner';
import { UserActionCreators, } from 'state/actions';
import { components, } from 'react-select';
import styled from 'styled-components';

type Props = {
}

const StyledButton = styled(Button)`
  background-color: ${Colors.blueButton} !important;
  border: none !important;
  width: 150px;

  :hover {
    background-color: ${Colors.blueButtonHover} !important;
  }
`;

const StyledText = styled.p`
  font-size: .75rem;
  color: ${Colors.greyEmpress};
`;

const StyledEmailField = styled(Field)`
  label {
  font-weight: bold;
  }
`;

const StyledClientField = styled(Field)`
  label {
  font-weight: bold;
  }
`;

const StyledImage = styled(Image)`
  width: 12px;
  height: 12px;
  filter: invert(1);

  :hover {
    filter: invert(0.7);
  }
`;

const StyledSelectOption = styled.span`
  color: ${Colors.lightGrey};
`;

const Input = props => (
  <components.Input {...props} placeholder={'Add Client'} />
);

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <StyledImage src={Images.common.removeIcon} />
  </components.MultiValueRemove>
);

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>{children}</components.SingleValue>
  );
};

const Option = props => (
  <components.Option {...props}>
    {props.value.name} <StyledSelectOption>(Id: {props.value.clientLabel})</StyledSelectOption>
  </components.Option>
);

const MultiValueLabel = props => (
  <components.MultiValueLabel {...props}>
    {props.data.value.name} <StyledSelectOption>(Id: {props.data.value.clientLabel})</StyledSelectOption>
  </components.MultiValueLabel>
);

const useConnect = () => {
  // mapState
  const firmUsers = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirmUsersMap));
  const firmclientsMap = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getFirmClients));

  const mapState = {
    firmUsers,
    firmclientsMap,
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    getFirmUsers: () => dispatch(UserActionCreators.getFirmUsers(undefined, { thunk: true, })),
    getFirmClients: () => dispatch(UserActionCreators.getFirmClients(undefined, { thunk: true, })),
    updateFirmUser: (payload: UpdateFirmUserPayload) => dispatch(UserActionCreators.updateFirmUser(payload, { thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/**
 * custom hooks handle getting firm users and clients
 *
 * @param {{
 *   firmUsers: FirmUserMap,
 *   getFirmUsers: Function,
 * }}
 */
const useFirmUsersClients = ({
  firmUsers,
  firmclientsMap,
  getFirmUsers,
  getFirmClients,
}: {
  firmUsers: FirmUserMap,
  firmclientsMap: ClientMap,
  getFirmUsers: Function,
  getFirmClients: Function,
}): any => {
  // transform users map to group array for displaying on select view
  const listUser = useMemo<DropDownOption<User>[]>(() => {
    if(!firmUsers) {
      return [];
    }

    return Array.from(firmUsers).map(([ key, value, ]) => ({
      label: value.email,
      value,
    }));
  }, [ firmUsers, ]);

  // transform client map to group array for displaying on select view
  const listClient = useMemo<DropDownOption<Client>[]>(() => {
    if(!firmclientsMap) {
      return [];
    }

    return Array.from(firmclientsMap).map(([ key, value, ]) => ({
      label: value.name,
      value,
    }));
  }, [ firmclientsMap, ]);

  // load firm users and clients when adminToken available
  useEffect(() => {
    getFirmUsers();
    getFirmClients();
  }, [ getFirmUsers, getFirmClients, ]);

  return {
    listClient,
    listUser,
  };
};

const initialFormValues: PermissonFormValues = {
  userSelected: undefined,
  clientsSelected: [],
};

/**
 * Main Component
 *
 * @param {Props} {}
 * @returns
 */
const ChangePermissionsForm = ({}: Props) => {
  const rdx = useConnect();
  const { updateFirmUser, } = rdx;

  const {
    listUser,
    listClient,
  } = useFirmUsersClients({
    ...rdx,
  });

  const onSubmit = useCallback(async (values: PermissonFormValues, actions: FormikBag<any, PermissonFormValues>) => {
    try {
      actions.setSubmitting(true);

      const user = values.userSelected ? values.userSelected.value : null;

      if (!user) {throw new Error('Please select user.');}

      if (!values.clientsSelected) {
        values.clientsSelected = [];
      }

      const clientIds = values.clientsSelected.map(d => d.value.id);
      // TODO: update firm user clients permisson

      const payload: UpdateFirmUserPayload = {
        data: {
          userId: user._id,
          user: {
            clients: clientIds,
          },
        },
      };

      await updateFirmUser(payload);

      actions.setStatus({
        success: 'User permission updated.',
      });
    } catch (e) {
      actions.setErrors({
        permission: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ updateFirmUser, ]);

  return (
    <div className='change-permissions-form'>
      <Formik
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {
          ({ isSubmitting, values, errors, handleSubmit, status, setStatus, }: FormikProps<PermissonFormValues>) => (
            <form onSubmit={handleSubmit}>
              {
                status && status.success && (
                  <Alert
                    dismissible
                    className="font-weight-bold"
                    variant='success'
                    onClose={() => { setStatus(null); }}
                  >
                    {status.success}
                  </Alert>
                )
              }
          
              {
                errors.permission && (
                  <Alert className="font-weight-bold" variant='warning'>{errors.permission}</Alert>
                )
              }

              <AutoInitClients
                clientMap={rdx.firmclientsMap}
                userMap={rdx.firmUsers}
              />
          
              <StyledEmailField
                isClearable
                className="mb-0"
                component={FormSelectMulti}
                components={{ DropdownIndicator: null, IndicatorSeparator: null, SingleValue, }}
                label={'User'}
                name={'userSelected'}
                noOptionsMessage={() => null}
                options={listUser}
                placeholder={'Select a User'}
                styles={{
                  control: (base) => ({
                    ...base,
                    width: 400,
                  }),
                }}
              />
          
              <StyledText className="text-secondary pt-3">
                {`This user will be associated with the following Bill4Time client account(s). This user will have access to invoices and payment history for the selected client(s), and will receive automatic email notifications when a new invoice is finalized for the selected client(s).`}
              </StyledText>
          
              <StyledClientField
                fullWidth
                isMulti
                component={FormSelectMulti}
                components={{ IndicatorSeparator: null, Input: Input, MultiValueRemove: MultiValueRemove, Option, MultiValueLabel, }}
                defaultValue={values.clientSelected}
                label={'Client(s)'}
                name={'clientsSelected'}
                options={listClient}
                placeholder={``}
                styles={{
                  multiValueLabel: base => ({
                    ...base,
                    backgroundColor: Colors.charcoal,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    color: 'white',
                    fontWeight: 'bold',
                  }),
                  multiValueRemove: base => ({
                    ...base,
                    backgroundColor: Colors.charcoal,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
          
                    '&:hover': {
                      backgroundColor: Colors.charcoal,
                    },
                  }),
                }}
              />
          
              <div className="d-flex justify-content-end px-0 py-2">
                {
                  isSubmitting && (
                    <Spinner animation="border" className="mr-2" role="status" />
                  )
                }
                <StyledButton className="font-weight-bold" type="submit">{`Save`}</StyledButton>
              </div>
            </form>
          )
        }
      </Formik>
    </div>
  );
};

export default ChangePermissionsForm;
