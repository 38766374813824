// @flow
import {Container, Col, Row, Card, Alert, Button,} from 'react-bootstrap';
import React from 'react';
import { Colors, } from 'assets/index';
import styled from 'styled-components';
import {Link,} from 'react-router-dom';
import Cart from './components/Cart';
import Confirm from './components/Confirm';

const StyledCard = styled(Card)`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  background: white;
  border-radius: 0px;
  border: solid 1px ${Colors.gainsBoro};
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
`;
const StyleContainer = styled(Container)`
  flex: 1;
  padding-top: 40px;
`;

type Props = {
  setStep: Function,
  step: number,
  amount: number,
  setAmount: Function,
};

const PayCartPageV = ({setStep, step, amount, setAmount, }: Props) => {
  return (
    <StyleContainer>
      <Row className="justify-content-md-center">
        <Col md="8">
          <h3>{`Pay Now`}</h3>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md="8">
          <Alert variant="info">
            {`Did you know you can pay directly with your bank account? `}
            <Alert.Link href="#">{`Click here to sign up`}</Alert.Link>
          </Alert>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md="8">
          <StyledCard>
            {step === 0 && <Cart amount={amount} setAmount={setAmount} />}
            {step === 1 && <Confirm amount={amount} setAmount={setAmount} />}
          </StyledCard>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md="8">
          <StyledCard>
            <div className="d-flex justify-content-between align-items-center">
              <Link className="btn" to="#">{`Cancel`}</Link>
              <Button size="lg" variant="success" onClick={setStep}>
                {`Continue`}
              </Button>
            </div>
          </StyledCard>
        </Col>
      </Row>
    </StyleContainer>
  );
};

export default PayCartPageV;
