// @flow
import { FormInput, FormSelect, } from 'view/components';
import { FormRadioButton, FormRadioGroup, } from 'view/components/FormRadio';
import { LAWPAY_ACCOUNT_HOLDER_TYPE, LAWPAY_ACCOUNT_TYPE, } from 'domain/payment';

import { Colors, } from 'assets';
import { FormGroup as CustomFormGroup, } from 'view/components';
import { Field, } from 'formik';
import Form from 'react-bootstrap/Form';
import React from 'react';
import styled from 'styled-components';

const HostedFieldDiv = styled.div`
  border: 1px solid ${Colors.whiteBlack};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  iframe {
    height: 35px;
    border: none;
    width: 100%;
  }
  &.isValid {
    background: ${Colors.shadingInputbg};
  }
`;

const StyledFormInput = styled(FormInput)`
  && {
    .is-invalid {
      background: ${Colors.shadingInputbg};
    }
    .invalid-feedback {
      display: none;
    }
  }
`;

type Props = {
  errors: { [string]: string },
  values: object,
};

const BankForm = ({ errors = {}, values, }: Props) => {
  return (
    <Form>

      <Field
        isRow
        required
        className={`payment-validate ${errors.given_name ? 'invalid' : 'valid'}`}
        component={StyledFormInput}
        label={'Given Name'}
        labelClassName={`text-right font-weight-bold`}
        maxlength={"35"}
        name={'given_name'}
        type={'text'}
      />

      <Field
        isRow
        className={`mt-3 payment-validate ${errors.surname ? 'invalid' : 'valid'}`}
        component={StyledFormInput}
        label={'Surname'}
        labelClassName={`text-right font-weight-bold`}
        maxlength={"35"}
        name={'surname'}
        type={'text'}
      />

      <Field
        isRow
        required
        className={`mt-3 payment-validate ${errors.business_name ? 'invalid' : 'valid'}`}
        component={StyledFormInput}
        label={'Business Name'}
        labelClassName={`text-right font-weight-bold`}
        maxlength={"70"}
        name={'business_name'}
        type={'text'}
      />

      <Field
        fullWidth
        isRow
        required
        className={`mt-3`}
        component={FormSelect}
        defaultValue={LAWPAY_ACCOUNT_HOLDER_TYPE[0].value}
        isSearchable={false}
        label={'Account Holder Type'}
        labelClassName={`text-right font-weight-bold`}
        name={'account_holder_type'}
        options={LAWPAY_ACCOUNT_HOLDER_TYPE}
        placeholder={'Account Holder Type'}
      />

      <CustomFormGroup
        isRow
        className={`mt-3`} // empty label to push children to the right
        label={` `}
      >
        <FormRadioGroup>
          <Field
            component={FormRadioButton}
            id={LAWPAY_ACCOUNT_TYPE.checking}
            label={`Checking`}
            name="account_type"
            value={LAWPAY_ACCOUNT_TYPE.checking}
          />
          <Field
            component={FormRadioButton}
            id={LAWPAY_ACCOUNT_TYPE.savings}
            label={`Savings`}
            name="account_type"
            value={LAWPAY_ACCOUNT_TYPE.savings}
          />
        </FormRadioGroup>
      </CustomFormGroup>

      {/* affinipay hosted fields */}
      <CustomFormGroup
        isRow
        className={`mt-3`}
        label={`Routing Number`}
        labelClassName={`text-right font-weight-bold`}
      >
        <HostedFieldDiv className={errors.routing_number || values.routing_number === '' ? 'isValid' : 'valid'} id="lawpay_routing_number_id" />
      </CustomFormGroup>

      <CustomFormGroup
        isRow
        className={`mt-3`}
        label={`Account number`}
        labelClassName={`text-right font-weight-bold`}
      >
        <HostedFieldDiv className={errors.bank_account_number || values.bank_account_number === '' ? 'isValid' : 'valid'} id="lawpay_bank_account_number_id" />
      </CustomFormGroup>
      
    </Form>
  );
};

export default BankForm;
