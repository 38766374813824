/* @flow */
import * as R from 'ramda';

import { Alert, Card, Col, Container, Row, Spinner, Image, Button, } from 'react-bootstrap';
import { type ClientPortalSettings, PAYMENT_OPTIONS, } from 'domain/firm';
import { Colors } from 'assets/index';
import { type DropDownOption, } from 'view/components/Dropdown/types';
import { Dropdown, } from 'view/components';
import { type Firm, } from 'domain/firm';
import FirmLogoUpload from './components/FirmLogoUpload';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import PaymentTabs from './components/PaymentTabs';
import React from 'react';
import SettingsGroup from './components/SettingsGroup';
import { faCheck, } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import PaymentsPanel from "./components/PaymentsApplication/PaymentsPanel";
import { type HeadnoteApplication } from "domain/payment";
import { useLocation } from 'react-router-dom';

type Props = {
  firm: Firm,
  featureList: Array<Feature>,
  hasDisconnectedProcessors: boolean,
  headnote: HeadnoteApplication,
  selectedPaymentOption: DropDownOption,
  onChangePaymentOption: Function,
  updateFirmSettings: Function,
  paymentDropdownOptions: Array<DropDownOption>,
  errors: object,
  errorModal: object,
  loading: boolean,
  message: object,
  showModal: boolean,
  onModalOkClick: Function,
  onCloseMessage: Function,
  onCloseError: Function,
  setErrors: Function,
  setLoading: Function,
  setMessage: Function,
};

const StyledContainer = styled(Container)`
  color: ${Colors.charcoal};
  flex: 1;
`;

const StyledDropdown = styled(Dropdown)`
  background-color: transparent;
  border: 0px;
  button {
    background-color: transparent !important;
    font-size: 14px;
    position: relative;
    &::after {
      content: ' ';
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      position: absolute;
      right: 6px;
      top: 45%;
    }
  }
`;

export const HIDDEN_PAGE_PARAM_KEY = 'Xksl';
export const HIDDEN_PAGE_PARAM_VALUE = 'Xndutw';
export const B4T_PAYMENT_FEATURE_ID = 16;

const AccountSettingsV = ({
  firm, featureList, hasDisconnectedProcessors, paymentDropdownOptions, headnote,
  selectedPaymentOption, onChangePaymentOption, updateFirmSettings,
  errors, loading, message,
  setErrors, setLoading, setMessage,
  onCloseMessage, onCloseError
}: Props) => {
  const portalSettings: ClientPortalSettings = R.path([ 'portalSettings', ], firm);

  const formatOptionLabel = ({ label, }) => {
    let key = label;
    if (key === PAYMENT_OPTIONS.lawPay.label) {
      key = 'Lawpay';
    }
    if (key === 'PayPal') {
      key = 'Paypal';
    }
    return (
      <div className="d-flex">
        <span>{label}</span>
        { portalSettings[`is${key}`] && (
            <div className="ml-2">
              <FontAwesomeIcon
                className='pl-1'
                icon={faCheck}
                style={{ color: Colors.greenCheck, }}
              />
            </div>
          )
        }
      </div>
    );
  };

  const location = useLocation();
  const showProcessorOverride = location.search.indexOf(`${HIDDEN_PAGE_PARAM_KEY}=${HIDDEN_PAGE_PARAM_VALUE}`) !== -1;

  // if feature is found, then payments is enabled for this firm
  const paymentsIsEnabled = !!featureList.find((feature) => {
    return feature.featureId === B4T_PAYMENT_FEATURE_ID;
  });

  // whether dropdown should be shown or not on the b4tpayments screen
  const showDropdown = showProcessorOverride || (hasDisconnectedProcessors && !firm.portalSettings.isHeadnote);
  const hasCurrentProcessor = firm.portalSettings.isStripe || firm.portalSettings.isCustom || firm.portalSettings.isLawpay || firm.portalSettings.isPaypal;

  // determines whether payment processor section is shown or not
  // if will be shown if firm has a payment processor other than headnote enabled, if the override query string is provided, or if the firm
  // isn't currently using headnote and they've disconnected from their previous processors
  const showPmtProcessorSection = hasCurrentProcessor || showDropdown;
      
  const newPage = (
    <StyledContainer>
      <Row>
        <Col>
          {
            message && message.success && (
              <Alert dismissible className="font-weight-bold" variant='success' onClose={onCloseMessage}>{message.success}</Alert>
            )
          }

          {
            errors.auth && (
              <Alert dismissible className="font-weight-bold" variant='warning' onClose={onCloseError}>{errors.auth}</Alert>
            )
          }
        </Col>
      </Row>
      <Row>
          <Card
            as={Col}
            border="light"
            className='pl-0'
            md={6}
            ms={12}
          >

            <Card.Header
              className="font-weight-bold px-0"
            >
              {'Bill4Time Payments'}
            </Card.Header>

            <Card.Body className='pl-0'>
              <PaymentsPanel {...headnote} headnoteIsConnected={firm.portalSettings.isHeadnote} />
            </Card.Body>

          </Card>

          <Col className='px-0' md={6} ms={12}>
            {
              portalSettings && (
                <SettingsGroup setErrors={setErrors} settings={portalSettings} updateFirmSettings={updateFirmSettings} />
              )
            }
            { showPmtProcessorSection  && (
              <Card border="light">
                <Card.Header className="font-weight-bold px-0">
                  {showProcessorOverride ? `Other Processors` : `Payment Setup`}
                </Card.Header>

                <Card.Body className="px-0">
                  {                
                      showDropdown && <StyledDropdown
                        className="pb-4"
                        customSelectStyles={{
                          group: provided => ({
                            ...provided,
                            borderBottom: `1px solid ${Colors.lightBorderGrey} !important`,
                          }),
                          option: (styles, { isFocused, isSelected, }) => {
                            if (isSelected && !isFocused) {
                              return {
                                ...styles,
                                backgroundColor: 'transparent',
                                color: 'inherit',
                              };
                            }
                            if (isFocused) {
                              return {
                                ...styles,
                                backgroundColor: '#f5f5f5',
                                color: 'inherit',
                              };
                            }

                            return styles;
                          },
                        }}
                        formatOptionLabel={formatOptionLabel}
                        isSearchable={false}
                        options={paymentDropdownOptions}
                        placeholder="Payment option"
                        value={selectedPaymentOption}
                        onChange={onChangePaymentOption}
                      />
                  }
                  {
                    loading ? 
                        <Spinner animation="border" role="status" /> 
                      :
                        <PaymentTabs 
                          setErrors={setErrors}
                          setLoading={setLoading}
                          setMessage={setMessage}
                          tabKey={selectedPaymentOption.key}
                          onChangePaymentOption={onChangePaymentOption}
                        />
                  }
                </Card.Body>
              </Card>
            )}
          </Col>  
      </Row>
    </StyledContainer>)

  const Content = newPage;

  return Content;
};

export default AccountSettingsV;
