/* @flow */
import { type ApiResponse, type ConduitApiService, } from 'infra/conduit/ApiService';

import { type InviteRepository, type PostInviteBody, } from 'domain/invite';

type Dependencies = {
  conduitApiService: ConduitApiService
};

type PostInviteData = {
  invite : PostInviteBody
};

type DeleteInviteData = {
  tokenId: string
}

export default ({ conduitApiService, }: Dependencies): InviteRepository => ({
  /**
   * request invites list with auth credentials
   * 
   * @param {WithCurrentUserToken} params - user token
   */
  async getInvite({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: 'tokens/invite',
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * request send invites with auth credentials
   * 
   * @param {WithCurrentUserToken} params - user token
   */
  async postInvite(body: PostInviteBody, { currentUserToken, }) {
    const data : PostInviteData = {
      invite: {
        ...body,
      },
    };

    const res: ApiResponse = await conduitApiService.authPost({
      url: `tokens/invite`,
      userToken: currentUserToken,
      data: data,
    });

    if (res.error) {throw res.error;}

    // api return balance in an array
    return res.success;
  },

  /**
   * request revoke invites with auth credentials
   * 
   * @param {WithCurrentUserToken} params - user token
   */
  async deleteInvite(tokenId: string, { currentUserToken, }) {
    const data : DeleteInviteData = {
      tokenId: tokenId,
    };

    const res: ApiResponse = await conduitApiService.authDel({
      url: `tokens/invite`,
      userToken: currentUserToken,
      data: data,
    });

    if (res.error) {throw res.error;}

    // api return balance in an array
    return res.success;
  },

  /**
   * request contact list with auth credentials
   * 
   * @param {WithCurrentUserToken} params - user token
   */
  async getContact({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: 'contacts',
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },
});
