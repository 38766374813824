// @flow
import React, { useCallback, } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { Colors, } from 'assets';
import Feedback from 'react-bootstrap/Feedback';
import { type FieldProps, } from 'formik';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

const StyledForm = styled(FormGroup)`
  .is-invalid {
    &::placeholder {
      color: ${Colors.redDanger} !important;
    }
  }

  .boolean-button {
    border: none !important;
    font-weight: bold !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .active {
    background-color: ${Colors.blueButton} !important;

    :hover {
      background-color: ${Colors.blueButtonHover} !important;
    }
  }

  .inactive {
    color: ${Colors.blackGrey};
    background-color: ${Colors.greyWhite} !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    :hover {
      color: ${Colors.blackGrey}
    }
  }
`;

const StyledFormError = styled(Feedback)`
  color: ${Colors.redDanger};
`;

type Props = {
  className: String,
  label: String,
  disabled?: boolean,
};

const FormBoolean = ({
  field: { value, name, },
  form: { errors, setFieldValue, },
  label, className, disabled,
  ...restProps
}: FieldProps<any> & Props) => {
  // field states:
  const _error = errors[name];

  const onTrue = useCallback(() => {
    // formik handleChange will look for an input's id attribute
    // https://jaredpalmer.com/formik/docs/api/formik#handlechange-e-reactchangeevent-any-void
    setFieldValue(name, true);
  }, [ setFieldValue, name, ]);

  const onFalse = useCallback(() => {
    // formik handleChange will look for an input's id attribute
    // https://jaredpalmer.com/formik/docs/api/formik#handlechange-e-reactchangeevent-any-void
    setFieldValue(name, false);
  }, [ setFieldValue, name, ]);

  return (
    <StyledForm as={Row} className={`mx-0 ${className}`}>
      {
        _error && <StyledFormError type="invalid">{_error}</StyledFormError>
      }
      {
        label && (
          <FormLabel column className="pl-0 pb-1" md={9} sm={12}>
            {label}
          </FormLabel>
        )
      }

      <Col className="pr-0" md={3} sm={12}>
        <div className={`pt-0 text-right`}>
          <Button
            className={`mr-1 boolean-button ${value ? 'active' : 'inactive'}`}
            disabled={disabled}
            onClick={onTrue}
          >
            {`Yes`}
          </Button>
          <Button
            className={`boolean-button ${value ? 'inactive' : 'active'}`}
            disabled={disabled}
            onClick={onFalse}
          >
            {`No`}
          </Button>
        </div>
      </Col>
    </StyledForm>
  );
};

export default FormBoolean;
