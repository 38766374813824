// @flow
import * as R from 'ramda';

import { ErrorActionCreators, UserActionCreators, PaymentActionCreators, } from 'state/actions';
import { type Firm, PAYMENT_OPTIONS, } from 'domain/firm';
import { type PaymentProcessorType, PAYMENT_PROCESSOR_TYPES } from 'domain/payment';
import React, { useCallback, useMemo, useState, useEffect, } from 'react';
import { type StripeConnectRedirectError, type StripeConnectRedirectSuccess, } from 'infra/payment/PaymentRepository';
import { useDispatch, useSelector, } from 'react-redux';

import AccountSettingsV, {HIDDEN_PAGE_PARAM_KEY, HIDDEN_PAGE_PARAM_VALUE, B4T_PAYMENT_FEATURE_ID} from './AccountSettingsV';
import { type ConnectCustomPaymentBody, } from 'domain/payment';
import { type ConnectUserStripePayload, } from 'state/payment/types';
import { type DropDownOption, } from 'view/components/Dropdown/types';
import { type FormikActions, } from 'formik';
import { type UpdateFirmSettingsPayload, } from 'state/user/types';
import { UserRedux, PaymentRedux, } from 'state/reducers';
import { useLocation } from 'react-router-dom';

const useConnect = () => {
  // mapState
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const headnote = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getHeadnoteApplication));
  const featureList = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFeatureList));
  const disconnectedProcessors = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getDisconnectedProcessers));

  const mapState = {
    firm,
    headnote,
    featureList,
    disconnectedProcessors
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    showError: (error: Error) => dispatch(ErrorActionCreators.requestFailure(error)),
    updateFirmSettings: (payload: UpdateFirmSettingsPayload): Promise<any> => dispatch(UserActionCreators.updateFirmSettings(payload, { thunk: true, })),
    getHeadnoteApplication: (): Promise<any> => dispatch(PaymentActionCreators.getHeadnoteApplication({ thunk: true, })),
    getFeatureList: (): Promise<any> => dispatch(UserActionCreators.getFeatureList({ thunk: true })),
    getDisconnectedProcessors: (): Promise<any> => dispatch(PaymentActionCreators.getDisconnectedProcessors({thunk: true}))
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/**
 * hook handle payment settings flow
 * 
 */
const usePaymentSetting = (firm: Firm) => {
  // selected payment opiton, default to current setting
  const [ selectedPaymentOption, setSelectedPaymentOption, ] = useState(PAYMENT_OPTIONS.currentSettings);

  // on payment option dropdown change
  const onChangePaymentOption = useCallback((option: DropDownOption) => {
    setSelectedPaymentOption(option);
  }, []);

  return {
    selectedPaymentOption,
    onChangePaymentOption,
  };
};

const AccountSettingsVM = () => {
  // connect redux
  const rdx = useConnect();
  const { firm, headnote, featureList, disconnectedProcessors, getHeadnoteApplication, getFeatureList, getDisconnectedProcessors } = rdx;

  useEffect(() => {
    (async () => {
      try {
        await getHeadnoteApplication();
      } catch (err) {
        console.log(err);
      }
    })();
  }, [ getHeadnoteApplication, ]);

  useEffect(() => {
    (async () => {
      try {
        await getFeatureList();
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await getDisconnectedProcessors();
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const {
    selectedPaymentOption,
    onChangePaymentOption,
  } = usePaymentSetting(firm);

  // loading, message, error state
  const [ loading, setLoading, ] = useState(false);
  const [ errors, setErrors, ] = useState(false);
  const [ message, setMessage, ] = useState(false);

  const onCloseError = useCallback(() => {
    setErrors(false);
  }, [ setErrors, ]);

  const onCloseMessage = useCallback(() => {
    setMessage(false);
  }, [ setMessage, ]);

  const location = useLocation();
  const showAllOptions = location.search.indexOf(`${HIDDEN_PAGE_PARAM_KEY}=${HIDDEN_PAGE_PARAM_VALUE}`) !== -1;

  const paymentsIsEnabled = !!featureList.find((feature) => {
    return feature.featureId === B4T_PAYMENT_FEATURE_ID;
  });

  const hasDisconnectedProcessors = disconnectedProcessors.length > 0;
  
  const paymentDropdownOptions = getPaymentsDropdownOptions(paymentsIsEnabled, showAllOptions, disconnectedProcessors);

  return (
    <AccountSettingsV
      {...rdx}
      hasDisconnectedProcessors={hasDisconnectedProcessors}
      errors={errors}
      headnote={headnote}
      loading={loading}
      message={message}
      paymentDropdownOptions={paymentDropdownOptions}
      selectedPaymentOption={selectedPaymentOption}
      setErrors={setErrors}
      setLoading={setLoading}
      setMessage={setMessage}
      onChangePaymentOption={onChangePaymentOption}
      onCloseError={onCloseError}
      onCloseMessage={onCloseMessage}
    />
  );
};

function getPaymentsDropdownOptions(paymentsIsEnabled: boolean, showAllOptions: Boolean, disconnectedProcessors: PaymentProcessorType[]) {
  const paymentDropdownOptions = [
    {
      options: [
        PAYMENT_OPTIONS.currentSettings,
      ],
    }
  ];

  // if firm has b4t payments enabled, then only show processors that the firm had
  // been previously connected to
  if (paymentsIsEnabled && !showAllOptions) {

    // arrays for the 'sections' in the dropdown
    const optionsSection2 = [];
    const optionsSection3 = [];

    // add each disconnected processor as an option
    disconnectedProcessors.forEach((disconnectedProcessorItem: PaymentProcessorType) => {
      switch (disconnectedProcessorItem) {
        case PAYMENT_PROCESSOR_TYPES.LAWPAY:
          optionsSection2.push(PAYMENT_OPTIONS.lawPay);
          break;
        case PAYMENT_PROCESSOR_TYPES.STRIPE:
          optionsSection2.push(PAYMENT_OPTIONS.stripe);
          break;
        case PAYMENT_PROCESSOR_TYPES.CUSTOM:
          optionsSection2.push(PAYMENT_OPTIONS.custom);
          break;
        case PAYMENT_PROCESSOR_TYPES.PAYPAL:
          optionsSection3.push(PAYMENT_OPTIONS.paypal);
          break;
      }
    });

    // Push the sections onto the dropdown options array - must go in order (i.e 2 and then 3)
    if (optionsSection2.length) {
      paymentDropdownOptions.push({options: optionsSection2});
    }

    if (optionsSection3.length) {
      paymentDropdownOptions.push({options: optionsSection3});
    }

  } else {
    paymentDropdownOptions.push({
      options: [
        PAYMENT_OPTIONS.lawPay,
        PAYMENT_OPTIONS.stripe,
        PAYMENT_OPTIONS.custom,
      ],
    },
    {
      options: [
        PAYMENT_OPTIONS.paypal,
      ],
    })
  }

  return paymentDropdownOptions;
}

export default AccountSettingsVM;
