import React from 'react';
import ApplicationStatus from "./components/ApplicationStatus";
import PendingInfo from "./components/PendingInfo";
import SupportInfo from "./components/SupportInfo";
import {STATUSES} from "../PaymentsPanel";
import styled from 'styled-components';

export default () => {
    return (
        <>
            <StyledH5 className="mt-3">{`Thanks for submitting your application!`}</StyledH5>
            <ApplicationStatus status={STATUSES.UNDER_REVIEW} />
            <PendingInfo />
            <SupportInfo />
        </>
    )
}

const StyledH5 = styled.h5`
font-size: 15px;
font-weight: bold;
  line-height: 24px;
`;