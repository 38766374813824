// @flow
import * as R from 'ramda';

import { Field, Formik, type FormikActions, type FormikProps, } from 'formik';
import { FormRadioButton, FormRadioGroup, } from 'view/components/FormRadio';
import React, { useCallback, useMemo, } from 'react';
import { type UserEmailNotiInfo, UserEmailNotiInfoSchema, } from 'domain/user';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormAlert from './form/FormAlert';
import FormLabel from './form/FormLabel';
import FormSubmit from './form/FormSubmit';
import { MESSAGES, } from 'constants/index';
import Row from 'react-bootstrap/Row';
import { type UpdateUserPayload, } from 'state/user/types';
import { UserRedux, } from 'state/reducers';
import { getBoolFromString, } from 'utilities/stringUtils';
import styled from 'styled-components';
import { useSelector, } from 'react-redux';
import { useUpdateUserInfo, } from '../hooks/updateUser';

const trackMixpanelEventChangeEmail = () => { window.mixpanel.track("b4tp_client_portal_change_email_settings") }

const StyleContainer = styled.div`
  flex: 1;
`;

type Props = {
  setSettingStatus: Function;
}

const useConnect = () => {
  // mapState
  const user = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const mapState = {
    user,
  };

  // mapDispatch
  // const dispatch = useDispatch();
  // const mapDispatch = useMemo(() => ({
  // }), [ dispatch, ]);

  return {
    ...mapState,
    // ...mapDispatch,
  };
};

const INITIAL_FORM_VALUES: UserEmailNotiInfo = {
  invoiceNotificationsEnabled: undefined,
};

/**
 * Main Component
 *
 * @returns
 */
const NotificationEmailForm = ({ setSettingStatus, }: Props) => {
  // connect redux
  const { user, } = useConnect();

  // update user dispatch action
  const { updateUserInfo, } = useUpdateUserInfo();

  // get initial email from user
  const initialValues = useMemo<UserEmailNotiInfo>(() => {
    if (!user) {return INITIAL_FORM_VALUES;}

    // convert boolean value to string ( from radio button use string only )
    return {
      invoiceNotificationsEnabled: String(user.invoiceNotificationsEnabled),
    };
  }, [ user, ]);

  /**
   * on email notification settings
   * 
   * @param {UserEmailNotiInfo} values - notification email info values from form
   * @param {FormikActions} actions - formik bag actions
   */
  const onSubmit = useCallback(async (values: UserEmailNotiInfo, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);
      // Update email notification settings

      // form value from radio field will be string, need to convert it to boolean
      const isSendEmail = getBoolFromString(values.invoiceNotificationsEnabled);

      const payload: UpdateUserPayload = {
        info: {
          invoiceNotificationsEnabled: isSendEmail,
        },
      };

      await updateUserInfo(payload);

      // update page status success
      setSettingStatus(MESSAGES.accountSettings.emailNotficationUpdated);
    } catch (e) {
      // handle error
      actions.setErrors({
        sendInvoiceEmails: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ setSettingStatus, updateUserInfo, ]);

  return (
    <StyleContainer>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={UserEmailNotiInfoSchema}
        // eslint-disable-next-line
        onSubmit={onSubmit}
      >
        {({ errors, handleSubmit, isSubmitting, touched, values, }: FormikProps<UserEmailNotiInfo>) => {
          return (
            <Form onSubmit={handleSubmit}>

              <FormLabel label='Receive Invoice Notification Emails' />

              <FormAlert alert={errors.invoiceNotificationsEnabled} />

              <Row className='mx-0 pt-4'>
                <Col md={5}>
                  <FormRadioGroup>
                    <Field
                      component={FormRadioButton}
                      id={'email-yes'}
                      label={`Yes`}
                      name="invoiceNotificationsEnabled"
                      value={'true'}
                    />
                    <Field
                      component={FormRadioButton}
                      id={'email-no'}
                      label={`No`}
                      name="invoiceNotificationsEnabled"
                      value={'false'}
                    />
                  </FormRadioGroup>
                </Col>

                <Col md={7} onClick={trackMixpanelEventChangeEmail}>
                  <FormSubmit
                    isSubmitting={isSubmitting}
                    text={`Change Email Settings`}
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </StyleContainer>
  );
};

export default NotificationEmailForm;
