// @flow
import { Formik, type FormikActions, type FormikProps, } from 'formik';
import React, { useCallback, } from 'react';
import { type VerifyBankInfo, VerifyBankInfoSchema, } from 'domain/payment';

import { Button, Modal, Alert, Spinner, } from 'react-bootstrap';
import { PaymentActionCreators, } from 'state/actions';
import SettingButton from '../../form/SettingsButton';
import VerificationForm from './VerificationForm';
import { type VerifyBankAccountPayload, } from 'state/payment/types';
import styled from 'styled-components';
import { useDispatch, } from 'react-redux';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 700px !important;
  }
`;

type Props = {
  isShow: boolean,
  onClose?: Function,
  onHide: Function,
  bankAccountId: string,
};

const initialValues: VerifyBankInfo = {
  amount1: '',
  amount2: '',
};

const VerifyBankModal = ({ isShow = false, onHide, bankAccountId, }: Props) => {
  const dispatch = useDispatch();

  const onVerifyBankAccount = useCallback((payload: VerifyBankAccountPayload) => {
    return dispatch(PaymentActionCreators.verifyBankAccount(payload, { thunk: true, }));
  }, [ dispatch, ]);

  // on add bank submit
  // start verifying
  const onVerify = useCallback(async (values: VerifyBankInfo, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);

      // only stripe need to verify bank account
      const payload: VerifyBankAccountPayload = {
        bankId: bankAccountId,
        verifyInfo: values,
      };
      await onVerifyBankAccount(payload);

      // use status to display verification success modal
      actions.setStatus(true);
    } catch (e) {
      // handle error
      actions.setErrors({
        bank: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ bankAccountId, onVerifyBankAccount, ]);

  return (
    <StyledModal
      backdrop='static'
      className="p-0"
      show={isShow}
      onHide={onHide}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={VerifyBankInfoSchema}
        onSubmit={onVerify}
      >
        {({ errors, handleSubmit, isSubmitting, status, }: FormikProps<VerifyBankInfo>) => (
          <>
            <Modal.Header closeButton className="border-bottom-0">
              <h2>
                {`Verification`}
              </h2>
            </Modal.Header>
            <Modal.Body>
              {
                errors.bank && (
                  <Alert variant='danger'>{errors.bank}</Alert>
                )
              }
              {
                status ? (
                  <>
                    <p>
                      {`Verification successful, you can now make payments with ACH.`}
                    </p>
                  </>
                ) : (  
                  <VerificationForm />
                )
              }
            </Modal.Body>
            <Modal.Footer className="border-top-0">
              {
                isSubmitting && (
                  <Spinner animation="border" className="mr-2" role="status" />
                )
              }
              <Button
                className="font-weight-bold border-0"
                disabled={isSubmitting}
                variant="light"
                onClick={onHide}
              >
                {`Close`}
              </Button>
              {
                !status && (
                  <SettingButton
                    className="font-weight-bold"
                    disabled={isSubmitting}
                    type='submit'
                    onClick={handleSubmit}
                  >
                    {`Verify`}
                  </SettingButton>
                )
              }
            </Modal.Footer>
          </>
        )}
      </Formik>
    </StyledModal>
  );
};

export default VerifyBankModal;
