// @flow
import * as R from 'ramda';

import { ButtonWrapper, StyleContainer, StyledSpan, } from '../UI';
import { Elements, StripeProvider, } from 'react-stripe-elements';
import { PaymentRedux, UserRedux, } from 'state/reducers';
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import { API_CONFIG, } from 'constants/index';
import { type ClientPortalSettings, } from 'domain/firm';
import CreditCardRow from './components/CreditCardRow';
import FormLabel from '../form/FormLabel';
import LawpayAddCardModal from './components/lawpay/LawpayAddCardModal';
import { PaymentActionCreators, } from 'state/actions';
import SettingButton from '../form/SettingsButton';
import StripeAddCardModal from './components/stripe/StripeAddCardModal';
import { isMaxCreditCards, } from 'domain/payment';

const useConnect = () => {
  // mapState
  const firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const cards = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getCreditCards));

  const mapState = {
    firm,
    cards,
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    getUserCards: () => dispatch(PaymentActionCreators.getUserCards(
      // {
      //   thunk: true,
      // }
    )),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/**
 * custom hook handle add credit card
 *
 * @returns
 */
const useAddCard = () => {
  const [ isShowAddCard, setIsShowAddBank, ] = useState<boolean>(false);

  const onAddCardClick = useCallback(() => {
    setIsShowAddBank(true);
  }, []);

  const onAddCardModalHide = useCallback(() => {
    setIsShowAddBank(false);
  }, []);

  return {
    isShowAddCard,
    onAddCardClick,
    onAddCardModalHide,
  };
};

const Cards = () => {
  // connect redux
  const {
    firm, cards,
    getUserCards,
  } = useConnect();

  // firm data selectors
  const portalSettings: ClientPortalSettings = R.path([ 'portalSettings', ], firm) || {};

  // load user credit cards on mount
  useEffect(() => {
    (
      async function fetchDatas() {
        try {
          await getUserCards();
        } catch (e) {
          console.log(e);
        }
      }
    )();
    
  }, [ getUserCards, ]);

  const {
    isShowAddCard,
    onAddCardClick,
    onAddCardModalHide,
  } = useAddCard();

  // only render add card button if max cards has not exceeded
  const isAllowAddCard = !isMaxCreditCards(cards.length);

  // Get publish key from firm
  const publishKey = useMemo(() => {
    const portalSettings: ClientPortalSettings = R.path([ 'portalSettings', ], firm);
    if (portalSettings && portalSettings.stripe && portalSettings.stripe.stripePublishableKey) {
      return portalSettings.stripe.stripePublishableKey;
    }
    return API_CONFIG.stripeApiKey;
  }, [ firm, ]);

  return (
    <StyleContainer>
      <FormLabel
        label={(
          <>
            {`Saved Cards`}
          </>
        )}
      />
      {
        // only render add bank button if max bank accounts has not exceeded
        isAllowAddCard && (
          <ButtonWrapper className='d-flex flex-row justify-content-end pt-4'>
            <SettingButton onClick={onAddCardClick}>
              {`Add a Card`}
            </SettingButton>
          </ButtonWrapper>
        )
      }

      {
        cards.map((c, key) => {
          return (
            <CreditCardRow card={c} key={key} />
          );
        })
      }

      {
        portalSettings.isStripe && (
          <StripeProvider apiKey={publishKey}>
            <Elements>
              <StripeAddCardModal
                isShow={isShowAddCard}
                onHide={onAddCardModalHide}
              />
            </Elements>
          </StripeProvider>
        )
      }
      {
        portalSettings.isLawpay && (
          <LawpayAddCardModal
            isShow={isShowAddCard}
            onHide={onAddCardModalHide}
          />
        )
      }

    </StyleContainer>
  );
};

export default Cards;
