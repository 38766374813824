// @flow
import * as R from 'ramda';

import { type ContactMap, type InviteMap, type InviteState, type getContactSuccessPayload, type getInviteSuccessPayload, } from './types';
import { isPersistActive, storage, } from '../_reduxPersist/persistConfig';

import { type Contact, } from 'domain/invite'
import { InviteTypes, } from '../actions';
import { createReducer, } from 'reduxsauce';
import { mapTransformer, } from '../_reduxPersist/transformer';
import { persistReducer, } from 'redux-persist';
import { produce, } from 'immer';

export const stateKey = 'invite';

/* ------------- Initial State ------------- */
const INITIAL_STATE: InviteState = ({
  invites: new Map(),
  contacts: new Map(),
});

/* ------------- Reducers ------------- */
const getInvite = R.identity;

const getInviteSuccess = (state: InviteState, { payload, }: { payload: getInviteSuccessPayload, }) =>
  produce(state, (draft) => {
    draft.invites = payload.invites;
  });

const postInvite = R.identity;

const postInviteSuccess = R.identity;

const deleteInvite = R.identity;

const deleteInviteSuccess = (state: InviteState, { payload : { id, }, }: { payload: string, }) => 
  produce(state, (draft) => {
    draft.invites.delete(id);
  });

const getContact = R.identity;

const getContactSuccess = (state: InviteState, { payload, }: { payload: getContactSuccessPayload, }) =>
  produce(state, (draft) => {
    draft.contacts = payload.contacts;
  });

const requestFailure = R.identity;

/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
  [InviteTypes.GET_INVITE]: getInvite,
  [InviteTypes.GET_INVITE_SUCCESS]: getInviteSuccess,

  [InviteTypes.POST_INVITE]: postInvite,
  [InviteTypes.POST_INVITE_SUCCESS]: postInviteSuccess,

  [InviteTypes.DELETE_INVITE]: deleteInvite,
  [InviteTypes.DELETE_INVITE_SUCCESS]: deleteInviteSuccess,

  [InviteTypes.GET_CONTACT]: getContact,
  [InviteTypes.GET_CONTACT_SUCCESS]: getContactSuccess,

  [InviteTypes.REQUEST_FAILURE]: requestFailure,
});

const persistConfig = {
  key: stateKey,
  storage,
  transforms: [
    mapTransformer({
      whitelist: ['invites', 'contacts'],
    }),
  ],
};

const reducerMap = { [stateKey]: isPersistActive
  ? persistReducer<any, any>(persistConfig, reducer)
  : reducer, };

/* ------------- Selectors ------------- */
const getReducerState: (Object) => InviteState = (state) => (state[stateKey]);

const selectors = {
  getInvite: ({ invites, }: InviteState): InviteMap => (invites),
  getInviteById: (id: number) => ({ invites, }: InviteState) => (invites.get(id)),
  getContact: ({ contacts, }: InviteState): ContactMap => (contacts),
};

/* ------------- Export ------------- */
export default {
  selectors,

  // default export
  INITIAL_STATE,

  stateKey,
  getReducerState,
  reducerMap,
};
