/* @flow */

import { useState, useEffect } from "react";
import { IDropDownOption } from "./B4TVirtualizedSelector";

/**
 * Contains logic associated with the virtualized selector component.
 * NOTE: DO NOT MODIFY THIS IF IT'S NOT DOING SOMETHING YOU'D LIKE - CREATE A NEW HOOK AND WRAP THIS ONE (for an example, see industrySelector hook)
 * IF YOU MODIFY THIS YOU'RE COMPLICATING THE LOGIC, POSSIBLY BREAKING THE MANY SELECTORS BUILT ON THIS, AND MAKING IT HARDER TO MAINTAIN
 */
export default function(props: IVirtualizedSelectorHookOptions): IVirtualizedSelectorReturnValues {
    const { fetchData, fetchDataById, fetchErrorHandler, data } = props;

    const [selectedOption, setSelectedOption] = useState(props.selectedOption);
    const [selectorLoadState, setSelectorLoadState] = useState({isLoading: props.isLoading, hasLoaded: props.hasLoaded});
    const [isOpen, setIsOpen] = useState(false);

    // fetches and populates local data
    const fetch = async () => {
        try {
            await fetchData();
            setSelectorLoadState({isLoading: false, hasLoaded: true});
        } catch (e) {
            setSelectorLoadState({isLoading: false, hasLoaded: false});
            // invoke error handler
            if (fetchErrorHandler) {
                fetchErrorHandler(e);
            }
            setIsOpen(false);
        }
    };

    const setOptionById = async (id: string | number= '') => {
        if(!id){
            return;
        }

        const result = await fetchDataById(id);
        setSelectedOption(result);
    };

    // fetch data when selector is in a loading state and data hasn't been loaded yet
    useEffect(() => {
        if (selectorLoadState.isLoading && !selectorLoadState.hasLoaded && fetch) {
            fetch();
        }
    }, [selectorLoadState]);

    return {
        options: data || [], selectedOption, setSelectedOption, selectorLoadState, setSelectorLoadState, isOpen, setIsOpen, setOptionById
    }
}

interface IVirtualizedSelectorHookOptions<T> {
    data?: T[];
    fetchData?: (...args) => Promise<void>;
    fetchErrorHandler?: (error: Error) => void;
    filterCallback?: (item: T) => boolean; // pass to filter data
    selectedOption?: IDropDownOption & T | (IDropDownOption & T)[]; // is an array if multiple is true
    isLoading?: boolean; // pass if data are to be loaded as soon as selector renders
    hasLoaded?: boolean; // pass if data has been loaded outside of the hook
    fetchDataById?: any;
}

export interface ISelectorLoadState {
    isLoading: boolean;
    hasLoaded: boolean;
}

export interface IVirtualizedSelectorReturnValues<T> {
    options: T[];
    setData: (data: T[]) => void;
    selectedOption: IDropDownOption & T | (IDropDownOption & T)[];
    setSelectedOption: (newOption: IDropDownOption & T | (IDropDownOption & T)[]) => void;
    selectorLoadState: ISelectorLoadState;
    setSelectorLoadState: (newState: ISelectorLoadState) => void;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    setOptionById?: (id: number | string) => void;
}
