// @flow
import * as R from 'ramda';

import { type AffinipayHostedFieldState, type AffinipayPaymentTokenRes, } from './types';
import { Formik, type FormikActions, type FormikProps, } from 'formik';
import { type LawpayAddCardInfo, type RequiredFields, } from 'domain/payment';
import React, { useCallback, useEffect, useRef, useMemo, } from 'react';

import { API_CONFIG, } from 'constants/index';
import { type AddCardPayload, } from 'state/payment/types';
import { Button, Modal, Spinner, } from 'react-bootstrap';
import CardForm from './LawpayCardForm';
import { Colors, } from 'assets';
import FormAlert from '../../../form/FormAlert';
import { PaymentActionCreators, } from 'state/actions';
import SettingButton from '../../../form/SettingsButton';
import styled from 'styled-components';
import { useDispatch, useSelector, } from 'react-redux';
import { UserRedux, PaymentRedux } from 'state/reducers';
import { type User, } from 'domain/user';
import { type Firm, type ClientPortalSettings, } from 'domain/firm';
import { ValidateShading, } from 'view/components';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 550px !important;
  }
`;

/* ------------- connect redux ------------- */
const useConnect = () => {
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const user: User  = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const requiredFields: RequiredFields = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getRequiredFields));

  const dispatch = useDispatch();

  const mapDispatch = useMemo(() => ({
    onAddCard: (payload: AddCardPayload) => dispatch(PaymentActionCreators.addCard(payload, { thunk: true, })),
    getRequiredFields: () => dispatch(PaymentActionCreators.requiredFieldsLawpay({ thunk: true, })),
  }), [ dispatch, ]);

  return {
    user,
    firm,
    requiredFields,
    ...mapDispatch,
  };
};
/* ------------- End ------------- */

/* ------------- Lawpay hosted fields configs and handling ------------- */
const hostedFieldStyle = {
  'font-size': '1rem',
  'font-weight': '400',
  'line-height': '1.5',
  color: Colors.grey,
  width: '100%',
  padding: '.375rem .75rem',
  transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
};

/**
 * handle lawpay hosted fields initialization
 *
 * @param {boolean} isShow - modal show boolean
 */
const useLawpayHostedFields = (isShow: boolean): Object => {
  const { firm, } = useConnect();

  const lawpayCardHostedFieldsConfig = useMemo(() => {
    // Get publish key from firm
    let publishKey = API_CONFIG.AFFINIPAY_PUBLIC_KEY;
    const portalSettings: ClientPortalSettings = R.path([ 'portalSettings', ], firm);
    if (portalSettings && portalSettings.lawpay && portalSettings.lawpay.publicKey) {
      publishKey = portalSettings.lawpay.publicKey;
    }

    return {
      publicKey: publishKey,
      css: hostedFieldStyle,
      fields: [
        {
          selector: '#lawpay_credit_card_id',
          placeholder: 'Credit Card',
          input: {
            type: 'credit_card_number',
            css: hostedFieldStyle,
          },
        },
        {
          selector: '#lawpay_card_cvv_id',
          placeholder: 'CVV',
          input: {
            type: 'cvv',
            css: hostedFieldStyle,
          },
        },
      ],
    };
  }, [ firm, ]);

  // store hosted fields in a ref
  const lawpayHostFieldsRef = useRef();

  const hostedFieldsCallBack = useCallback((state) => {
    // TODO: do we need to do some thing in this call back ?
    // console.log('>>> hosted fiellds', { state })
  }, []);

  useEffect(() => {
    // do nothing if modal is not show
    if (!isShow) {return;}

    // init lawpay hosted fields
    lawpayHostFieldsRef.current = window.AffiniPay.HostedFields.initializeFields(
      lawpayCardHostedFieldsConfig,
      hostedFieldsCallBack
    );    

    // clean up
    // remove ref reference to hosted fields
    return () => {
      lawpayHostFieldsRef.current = undefined;
    };
  }, [ hostedFieldsCallBack, isShow, lawpayCardHostedFieldsConfig, ]);

  return {
    lawpayHostFieldsRef,
  };
};
/* ------------- End ------------- */

type Props = {
  isShow: boolean,
  onClose?: Function,
  onHide: Function,
};

/* ------------- Component ------------- */
const LawpayAddCardModal = ({ isShow = false, onHide, }: Props) => {
  const { onAddCard, user, requiredFields, getRequiredFields, } = useConnect();

  // Load required fields
  useMemo(() => {
    (
      async () => {
        try {
          await getRequiredFields();
        } catch (e) {
          console.log(e);
        }
      }
    )();
  }, [ getRequiredFields, ]);

  // Init value including reference
  const initialValues: LawpayAddBankAccoutnInfo = useMemo(() => ({
    name: '',
    exp_month: '',
    exp_year: '',
    card_cvv: '',
    reference: user._id,
  }), [ user, ]);

  const {
    lawpayHostFieldsRef,
  } = useLawpayHostedFields(isShow);

  // on add card submit
  // Custom validate form
  const validate = useCallback(values => {
    if (!lawpayHostFieldsRef.current) {
      return {};
    };

    // get hosted field state from ref
    const hostedFieldState: AffinipayHostedFieldState = lawpayHostFieldsRef.current.getState();
    // check lawpay hosted fields validation
    let errors = {};
    hostedFieldState.fields.forEach((field) => {
      if (field.error) {
        errors[field.type] = field.error;
      }
    });

    if (values.name.length === 0) {
      errors.name = "Name is required";
    }

    if (requiredFields.address1 && values.address1 && values.address1.length === 0) {
      errors.address1 = "Billing address is required";
    }

    if (requiredFields.postal_code && values.postal_code && values.postal_code.length === 0) {
      errors.postal_code = "Post Code is required";
    }

    if (requiredFields.city && values.city && values.city.length === 0) {
      errors.city = "City is required";
    }

    if (requiredFields.state && values.state && values.state.length === 0) {
      errors.state = "State is required";
    }

    if (requiredFields.country && values.country && values.country.length === 0) {
      errors.country = "Country is required";
    }

    if (values.exp_month.length === 0) {
      errors.exp_month = "Exp month is required";
    }

    if (values.exp_year.length === 0) {
      errors.exp_year = "Exp year is required";
    }

    if (Object.keys(errors).length > 0 || values.name.length === 0 || values.exp_month.length === 0 || values.exp_year.length === 0) {
      return errors;
    }
    return {};
  }, [ lawpayHostFieldsRef, requiredFields, ]);
  // start verifying
  const onVerify = useCallback(async (values: LawpayAddCardInfo, actions: FormikActions) => {
    try {
      // throw error if hosted fields not found
      if (!lawpayHostFieldsRef.current) {
        throw new Error('Lawpay hosted field not found.');
      };

      actions.setSubmitting(true);

      // tokenize lawpay card account
      const result: AffinipayPaymentTokenRes = await lawpayHostFieldsRef.current.getPaymentToken(values);

      // request attach card api
      const payload: AddCardPayload = {
        token: result.id,
      };

      await onAddCard(payload);

      // use status to display verification success modal
      actions.setStatus(true);
    } catch (e) {
      // handle error
      actions.setErrors({
        card: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ onAddCard, lawpayHostFieldsRef, ]);

  // Check error in formik and show message
  const ErrorStatus = useCallback(errors => {
    if(errors.name || errors.exp_month || errors.exp_year || errors.credit_card_number || errors.cvv) {
      return true;
    }
    return false;
  }, []);

  const getErrorMsg = (errors,touched)=>{
    if(errors.credit_card_number && errors.credit_card_number !== 'Input field is empty' ){
      return  errors.credit_card_number;
    }

    return `All fields in blue are required.`;
  };

  return (
    <StyledModal
      backdrop='static'
      className="p-0"
      show={isShow}
      onHide={onHide}
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onVerify}
      >
        {({ errors, handleSubmit, isSubmitting, status, touched, values, }: FormikProps<LawpayAddCardInfo>) => (
          <>
            <Modal.Header closeButton className="border-bottom-0">
              <h2>
                {
                  status ? `Verification` : `Credit Card`}
              </h2>

              
            </Modal.Header>
            <Modal.Body>
              <FormAlert alert={errors.card} />
              { ErrorStatus(errors) && (
                <ValidateShading 
                  className="w-100" 
                  text={getErrorMsg(errors,touched)}
                  variant="danger"
                />
                )}
              {
                status ? (
                  <>
                    <p>
                      {`Success!`}
                    </p>
                    <p>
                      {`Credit Card Added.`}
                    </p>
                  </>
                ) : (  
                  <CardForm errors={errors} requiredFields={requiredFields} values={values} />
                )
              }
            </Modal.Body>
            <Modal.Footer className="border-top-0">
              {
                isSubmitting && (
                  <Spinner animation="border" className="mr-2" role="status" />
                )
              }
              <Button
                className="font-weight-bold border-0"
                disabled={isSubmitting}
                variant="light"
                onClick={onHide}
              >
                {`Close`}
              </Button>
              {
                !status && (
                  <SettingButton
                    className="font-weight-bold"
                    disabled={isSubmitting}
                    type='submit'
                    onClick={handleSubmit}
                  >
                    {`Verify`}
                  </SettingButton>
                )
              }
            </Modal.Footer>
          </>
        )}
      </Formik>
    </StyledModal>
  );
};

export default LawpayAddCardModal;
