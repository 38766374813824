/* @flow */
import { type WithCurrentUserToken, } from '../user';

export const ACCOUNT_HISTORY_TYPE = {
  invoice: "I", // An invoice is always going to be a debit
  payment: "P", // A payment will always be a credit
  balanceAdjustment: "BA", // A balance adjustment may be either - if the amount is negative, then it is a debit, if positive, then a credit.
};

export type AccountHistoryType = "P" | "I" | "BA";

export type AccountHistory = {
  date: string,
  id: number,
  desc: string,
  amount: number,
  project: number,
  invoiceIsPaid: number,
  type: AccountHistoryType,
  balance: ?number, // will be calculated from amount, type, and previous balance
};

export type AccountHistoryRepository = {
  getClientAccountHistories: (clientId: number, WithCurrentUserToken) => Promise<Array<AccountHistory>>,
  getProjectAccountHistories: (clientId: number, projectId: number, WithCurrentUserToken) => Promise<Array<AccountHistory>>,
};

/**
 * Calculate current account history balance from previous account history balance
 *
 * @param {AccountHistory} accountHistory account history item
 * @param {?number} prevBalance previous balance
 * @returns {number} the calculated current balance
 */
export const getCurrentAccountBalance: (accountHistory: AccountHistory, prevBalance?: ?number) => ?number = (accountHistory, prevBalance = 0) => {
  let newBalance: number;

  switch (accountHistory.type) {
    case ACCOUNT_HISTORY_TYPE.invoice:
      // invoice -> debit
      newBalance = Number(prevBalance) - Number(accountHistory.amount);
      break;
  
    case ACCOUNT_HISTORY_TYPE.payment:
      // payment -> credit
      newBalance = Number(prevBalance) + Number(accountHistory.amount);
      break;

    case ACCOUNT_HISTORY_TYPE.balanceAdjustment:
      // balance adjustment: if negative -> debit, if positive -> credit. So we let js convert it to number and add it to prevBalance
      newBalance = Number(prevBalance) + Number(accountHistory.amount);
      break;

    default:
      break;
  }

  return newBalance;
};
