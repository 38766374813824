import { type PaymentBody, type PendingPayment, PAYMENT_METHOD, } from 'domain/payment';
import { type PaypalCreateOrderPayload, type PaypalPaymentPayload, } from 'state/payment/types';
import React, { useCallback, useMemo, useState, useEffect, } from 'react';
import { Alert, Button, Card, Col, Container, Row, Spinner, } from 'react-bootstrap';
import { type InvoicePaymentRouteParams, type PaymentSuccess, } from '../../types';
import * as Yup from 'yup';

import Image from 'react-bootstrap/Image'
import { Field, Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import { FormInput, } from 'view/components/index';
import { Dropdown, } from 'view/components';
import { Colors, Images, } from 'assets/index';
import { RequiredLabel } from './RequiredLabel';
import ErrorMsg from './ErrorMsg';

const getBankCardOptionValue = (c: PaymentData): PaymentData => c;
const maxRoutingNumDigits = 9;
const maxAccountNumDigits = 17;
const minAccountNumDigits = 3;
export const HEADNOTE_ACCOUNT_CLASS = {
  savings: 'savings',
  checking: 'checking',
};

const accountTypeOptions = [{
  label: 'Checking',
  value: HEADNOTE_ACCOUNT_CLASS.checking
}, {
  label: 'Savings',
  value: HEADNOTE_ACCOUNT_CLASS.savings
}];
const defaultAccountType = accountTypeOptions[0];
const REGEX = {
  ALPHANUMERIC: /^[a-zA-Z0-9 ]*$/,
  NUMBER: /^[0-9]*$/
}

const BankAccountInfoSchema = Yup.object().shape({
  routing_number: Yup.string().matches(
    REGEX.NUMBER, 'Must be a number'
  ).test('customLength', `Must be ${maxRoutingNumDigits} digits`, (value) => {
    return value ? value.length === maxRoutingNumDigits : true;
  }).required('Required'),
  account_number: Yup.string().matches(
    REGEX.NUMBER, 'Must be a number'
  ).test('customLength', `Please enter between ${minAccountNumDigits} and ${maxAccountNumDigits} digits`, (value) => {
    return value ? (value.length >= 3 && value.length <= maxAccountNumDigits) : true;
  }).required('Required'),
  account_number_reenter: Yup.string()
    .oneOf([Yup.ref('account_number'), null], 'Account numbers must match').required('Required'),
});

const HeadnoteECheckARNumbers = ({ headnoteARNumberFormRef, error }) => {
  const initialValues = {
    routing_number: '',
    account_number: '',
    account_number_reenter: '',
    account_class: defaultAccountType,
  }

  const FormSection = () => {
    return <>
      <Formik
        initialValues={initialValues}
        validationSchema={BankAccountInfoSchema}
        innerRef={headnoteARNumberFormRef}
      >
        {({ errors, values, setFieldValue, }) => {
          return (
            <Form>
              <Row className='pb-3'>
                <Col >
                  <div class="form-group">
                    <label><RequiredLabel>Account Type</RequiredLabel></label>
                    <Dropdown
                      getOptionValue={getBankCardOptionValue}
                      options={accountTypeOptions}
                      value={values.account_class.value}
                      fullWidth={true}
                      onChange={(e) => {
                        setFieldValue('account_class', e);
                      }}
                    />
                  </div>
                </Col>
                <Col >
                  <div>
                    <Field name="routing_number" component={FormInput} type={'text'}
                      label={<RequiredLabel>Routing Number</RequiredLabel>}
                      maxLength={maxRoutingNumDigits}
                    />
                  </div>
                </Col>
              </Row>
              <div >
                <Field required name="account_number" component={FormInput} type={'text'}
                  label={<RequiredLabel>Account Number</RequiredLabel>}
                  maxLength={maxAccountNumDigits}
                />
              </div>
              <div>
                <Field name="account_number_reenter" component={FormInput} type={'text'}
                  label={<RequiredLabel>Re-Enter Account Number</RequiredLabel>}
                  maxLength={maxAccountNumDigits} />
              </div>
            </Form>
          )
        }}
      </Formik>
    </>;
  }

  return (
    <>
      <h4> eCheck Payment via Account & Routing Numbers </h4>
      <ErrorMsg error={error} />
      <Image src={Images.payment.check} style={{ width: '80%', margin: 'auto', display: 'block' }} />
      <FormSection />
    </>
  );
};

export default React.memo(HeadnoteECheckARNumbers);
