// @flow
import React, { useCallback, } from 'react';

import { Field } from 'formik';
// TODO: Refactor this import below so it can be used both for Invoices & Retainers
import PaymentField from 'view/InvoicePayment/components/field/PaymentField';
import { retainerPaymentValidation } from 'domain/retainer';

type Props = {
    className?: string,
    retainer: Retainer,
    rowIndex: number,
  };

  const RetainerPaymentField = ({
      retainer, rowIndex, ...restProps
  }: Props) => {
      const paymentValidation = useCallback((value: number) => {
          let error;
          const isRetainerPaymentInvalid = retainerPaymentValidation(value, retainer);  
          if (isRetainerPaymentInvalid) {
              error = 'Payment must be greater than 0 and less than or equal to requested amount.';
          }

          return error;
      }, [ retainer ]);

      return (
        <Field
          required
          component={PaymentField}
          name={`retainers[${rowIndex}].userPayment`}
          placeholder={'Payment'}
          style={{
            textAlign: 'right',
          }}
          validate={paymentValidation}
          {...restProps}
        />
      );
  };

  export default RetainerPaymentField;