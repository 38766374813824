/* @flow */
import { ACCT_ISO_TYPE, type AccountBalance, type Client, type ClientRepository, } from 'domain/client';
import { type Project, type ProjectRepository, } from 'domain/project';

import { type Firm, } from 'domain/firm';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (?AccountBalance) => void,
  onInvalidPayload: (Error) => void,
  onError: (Error) => void,
};

type Dependencies = {
  clientRepository: ClientRepository,
  projectRepository: ProjectRepository,
};

type Payload = {
  firm: Firm,
  clientId: number,
  projectId?: number,
};

export type GetAccountBalanceBehaviour = (
  Payload,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { clientRepository, projectRepository, }: Dependencies, ) => {
  /**
   * get list invoices of a client or project behaviour
   * 
   * @param {Payload} payload - firm, client and project
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getAccountBalanceBehaviour: GetAccountBalanceBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { acctIsoType, clientId, projectId, } = payload;

      if (!clientId) {
        return onInvalidPayload(new Error('Invalid client or project.'));
      }

      let balance: ?AccountBalance;
      if (acctIsoType) {
        switch (acctIsoType) {
          case ACCT_ISO_TYPE.client:
            balance = await clientRepository.getBilledClientBalances(clientId, withCurrentUserToken);
            break;
        
          case ACCT_ISO_TYPE.project:
            if (projectId) {
              if (Number(projectId) === -1) {
                balance = await clientRepository.getBilledClientBalances(clientId, withCurrentUserToken);
              } else {
                balance = await projectRepository.getBilledProjectBalances(clientId, projectId, withCurrentUserToken);
              }
            }
            break;

          default:
            break;
        }
      }

      return onSuccess(balance);
    } catch(error) {
      return onError(error);
    }
  };

  return getAccountBalanceBehaviour;
};
