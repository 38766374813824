/* @flow */
import { type Payment, type PaymentRepository, } from 'domain/payment';
import { ACCT_ISO_TYPE, } from 'domain/client';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (Array<Payment>) => void,
  onInvalidPayload: (Error) => void,
  onError: (Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
};

type Payload = {
  clientId: number,
  projectId: ?number,
};

export type GetPaymentsBehaviour = (
  Payload,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { paymentRepository, }: Dependencies, ) => {
  /**
   * get list payments of a client or project behaviour
   * 
   * @param {Payload} payload - client id and project id
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getPaymentsBehaviour: GetPaymentsBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { clientId, projectId, acctIsoType, } = payload;
      if (!clientId) {
        return onInvalidPayload(new Error('Invalid client and project id.'));
      }

      let payments;

      if ((acctIsoType && acctIsoType === ACCT_ISO_TYPE.client) || (projectId && Number(projectId) === -1)) {
        payments = await paymentRepository.getClientPayments(clientId, withCurrentUserToken);
      } else if (projectId) {
        payments = await paymentRepository.getProjectPayments(clientId, projectId, withCurrentUserToken);
      }

      return onSuccess(payments);
    } catch(error) {
      return onError(error);
    }
  };

  return getPaymentsBehaviour;
};
