import { type PaymentBody, type PendingPayment, PAYMENT_METHOD, } from 'domain/payment';
import { type PaypalCreateOrderPayload, type PaypalPaymentPayload, } from 'state/payment/types';
import React, { useCallback, useMemo, useState, useEffect, } from 'react';

import { Alert, Spinner } from 'react-bootstrap';
import { Colors, } from 'assets';
import Container from 'react-bootstrap/Container';
import { type InvoicePaymentRouteParams, type PaymentSuccess, } from '../types';
import { PaymentActionCreators, } from 'state/actions';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useDispatch, } from 'react-redux';
import { APP_CONFIG, API_CONFIG, } from 'constants/index';

// Create paypal button component

const StyledContainer = styled(Container)`
  flex: 1;
  padding-top: 40px;
  background: white;
  border-radius: 0px;
  border: solid 1px ${Colors.gainsBoro};
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
`;

// connect redux
const useConnect = () => {
  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    createPaypalOrder: (payload: PaypalCreateOrderPayload) => dispatch(PaymentActionCreators.paypalCreateOrder(payload, { thunk: true, })),
    payByPaypal: (payload: PaypalPaymentPayload) => dispatch(PaymentActionCreators.paypalPay(payload, { thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapDispatch,
  };
};

type Props = {
  afterSubmit: Function;
  invoicePayment: InvoicePaymentRouteParams;
  goBack: Function;
  currencyCode?: string,
  paypalMerchantId?: string,
  onButtonReady: Function,
};

const PaypalCard = ({ afterSubmit, currencyCode, invoicePayment, paypalMerchantId, onButtonReady, } : Props) => {
  const { payByPaypal, createPaypalOrder, } = useConnect();
  const [ error, setError, ] = useState(undefined);
  const [ sdkReady, setSdkReady, ] = useState(false);

  const dataPayload: PaypalCreateOrderPayload = useMemo(() => {
    // process stripe pay request data
    const payCart: Array<InvoicePayCart> = invoicePayment.cart.map((item) => ({
      invoiceId: item.id,
      invoiceBalance: item.invoiceBalance,
      invoiceLabel: item.invoiceLabel,
      amountApplied: item.userPayment,
    }));

    const payment: PaymentBody = {
      cart: payCart.filter(item => item.invoiceBalance !== APP_CONFIG.paymentCart.additionalPayment),
      amount: invoicePayment.amount,
      source: "CC",
      projectId: invoicePayment.projectId,
    };

    const data: PaypalCreateOrderPayload = {
      clientId: invoicePayment.clientId,
      payment,
    };

    return data;
  }, [ invoicePayment, ]);

  const addPaypalSdk = useCallback(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.paypal.com/sdk/js?client-id=${API_CONFIG.PAYPAL_CLIENT_ID}&merchant-id=${paypalMerchantId}&currency=${currencyCode}&intent=capture&disable-funding=credit,card`;
    script.async = true;
    script.onload = () => {
      setSdkReady(true);
    };
    script.onerror = () => {
      throw new Error('Paypal SDK could not be loaded.');
    };

    document.body.appendChild(script);
  }, [ currencyCode, paypalMerchantId, ]);

  useEffect(() => {
    if (window !== undefined && window.paypal === undefined) {
      addPaypalSdk();
    } else if (
      window !== undefined &&
      window.paypal !== undefined &&
      onButtonReady
    ) {
      onButtonReady();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onButtonReady, ]);

  const PaypalButton = useMemo(() => {
    // useMemo to prevent recreate paypal button every render
    // and check for window paypal sdk injection
    if (!window.paypal) {return null;}

    return window.paypal.Buttons.driver('react', { React, ReactDOM, });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ window.paypal, ]); // Fix error if only integrated paypal

  const createOrder = useCallback(async (data, actions) => {
    try {
      // call create paypal order api
      const orderId: string = await createPaypalOrder(dataPayload);

      // resolve promise with orderid
      return orderId;
    } catch (e) {
      console.log(`[Create Paypal Order Error]:`, { e, });
      setError(e);
      throw e;
    }
  }, [ createPaypalOrder, dataPayload, setError, ]);

  const onApprove = useCallback(async (data, actions) => {
    
    try {
      // add orderId to payload
      let paypalOrderId = data.orderID;

      const paypalPaymentPayload: PaypalPaymentPayload = {
        clientId: dataPayload.clientId,
        payment: {
          ...dataPayload.payment,
          paypalOrderId,
        },
      };

      const res: PendingPayment = await payByPaypal(paypalPaymentPayload);
      const paymentSuccess : PaymentSuccess = {
        ...res,
        amount: paypalPaymentPayload.payment.amount,
        last4: paypalPaymentPayload.payment.paypalOrderId,
        type: PAYMENT_METHOD.paypal,
      };
      afterSubmit(paymentSuccess);
    } catch (e) {
      setError(e);
    }
  }, [ dataPayload.clientId, dataPayload.payment, payByPaypal, afterSubmit, ]);

  return (
    <StyledContainer>
      {
        error && <Alert dismissible variant='danger' onClose={() => { setError(undefined); }}>{error}</Alert>
      }
      {
        !sdkReady && window.paypal === undefined ? (
          <Spinner animation="border" />
        ) : (
          <PaypalButton createOrder={createOrder} onApprove={onApprove}></PaypalButton>
        )
      }
    </StyledContainer>
  );
};

PaypalCard.defaultProps = {
  invoicePayment: {
    cart: [],
  },
};

export default PaypalCard;
