import React, { useState } from 'react';
import { Form, Alert, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import { Button, Container, Grid, makeStyles, MenuItem, TextField, ThemeProvider } from '@material-ui/core';
import styled from 'styled-components';
import { mainTheme, greenTheme } from '../styles/themes';
import { Firm } from 'domain/firm';
import PaymentMethodRequestCardV from './components/PaymentMethodRequestCardV';
import PaymentMethodRequestECheckARNumbersV from './components/PaymentMethodRequestECheckARNumbersV';
import PaymentMethodRequestECheckPlaidV from './components/PaymentMethodRequestECheckPlaidV';
import * as yup from 'yup';
import { PAYMENT_METHOD_REQUEST_TYPES } from 'domain/paymentMethodRequest';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const TOS = () => <a href='https://www.bill4time.com/terms-of-service' target='_blank'><strong>{`Terms & Conditions`}</strong></a>

const StyledContainer = styled(Container)`
    Margin: 20px;
`;

const useStyles = makeStyles({
    form: {
        backgroundColor: '#fff',
        marginTop: '10px',
        padding: '10px',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        boxShadow: 'rgb(204 204 204) 1px 1px 1px'
    },
    header: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 9
    },
    firmName: {
        fontSize: 12,
        fontWeight: 'bold'
    },
    firmContactInfo: {
        fontSize: 11,
        marginBottom: 24
    },
    thankYouMessage: {
        fontSize: 12,
        overflowWrap: 'break-word'
    },
    payingOnBehalfMessage: {
        fontSize: 10
    },
    subtitle: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    termsAndConditions: {
        fontSize: 14
    },
    amount: {
        fontSize: 12
    },
    helperText: {
        minHeight: 0
    }
});

type PaymentMethodDropdownOptions = {
    label: string,
    type: string,
    id?: string
}

const paymentMethodOptions: PaymentMethodDropdownOptions[] = [
    {
        label: 'Credit Card',
        type: PAYMENT_METHOD_REQUEST_TYPES.CARD,
    },
    {
        label: 'eCheck Payment via Account & Routing Numbers',
        type: PAYMENT_METHOD_REQUEST_TYPES.ARNUMBER,
    },
    {
        label: 'eCheck Payment via Secure Bank Login',
        type: PAYMENT_METHOD_REQUEST_TYPES.PLAID
    }
];

const PaymentMethodRequestV = ({ error, submitPayment, firm, showSuccess, selectedPaymentMethod, setSelectedPaymentMethod, setVgsLoadState, ...props }: PaymentMethodRequestViewProps) => {
    const classes = useStyles();
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [isPlaidConnectionFailed, setIsPlaidConnectionFailed] = useState(false);

    const onPaymentMethodChange = (event) => {
        setSelectedPaymentMethod(event.target.value);
        formik.setFieldValue('paymentMethod', event.target.value);

        if (event.target.value !== PAYMENT_METHOD_REQUEST_TYPES.CARD && props.vgsForm) {
            props.vgsForm.unmount();
            props.setVgsForm(null);
            setVgsLoadState(false);
        }
    }

    const PaymentMethod = ({ error = {}, }) => {
        switch (selectedPaymentMethod) {
            case PAYMENT_METHOD_REQUEST_TYPES.CARD:
                return <PaymentMethodRequestCardV
                    error={error}
                    vgsForm={props.vgsForm}
                    vgsErrors={props.vgsErrors}
                    subtitle={classes.subtitle}
                />
            case PAYMENT_METHOD_REQUEST_TYPES.ARNUMBER:
                return <PaymentMethodRequestECheckARNumbersV
                    error={error}
                    formik={formik}
                    subtitle={classes.subtitle}
                />
            case PAYMENT_METHOD_REQUEST_TYPES.PLAID:
                return <PaymentMethodRequestECheckPlaidV
                    isPlaidConnectionFailed={isPlaidConnectionFailed}
                    subtitle={classes.subtitle}
                    error={error}
                />
            default:
        }
    };

    const exactRoutingNumDigits = 9;
    const maxAccountNumDigits = 17;
    const minAccountNumDigits = 3;

    const REGEX = {
        ALPHANUMERIC: /^[a-zA-Z0-9 ]*$/,
        NUMBER: /^[0-9]*$/,
        CURRENCY: /^\$?(([1-9]\d{0,2}(,\d{3})*)|0)?\.\d{1,2}$/
    }

    let validationSchema = yup.object().shape({
        first_name: yup.string().required('First Name is required'),
        last_name: yup.string().required('Last Name is required'),
        email: yup.string().email('Enter a valid email').required('Email is required'),
        paymentMethod: yup.string(),
        routingNumber: yup
            .string()
            .when('paymentMethod', {
                is: (value) => value === PAYMENT_METHOD_REQUEST_TYPES.ARNUMBER,
                then: yup
                    .string()
                    .matches(REGEX.NUMBER, 'Must be a number')
                    .length(exactRoutingNumDigits, `Must be ${exactRoutingNumDigits} digits`)
                    .required('Routing Number is Required'),
                otherwise: yup.string().notRequired()
            }),
        accountNumber: yup
            .string()
            .when('paymentMethod', {
                is: (value) => value === PAYMENT_METHOD_REQUEST_TYPES.ARNUMBER,
                then: yup
                    .string()
                    .matches(REGEX.NUMBER, 'Must be a number')
                    .min(minAccountNumDigits, `Must be greater than ${minAccountNumDigits} digits`)
                    .max(maxAccountNumDigits, `Must be less than ${maxAccountNumDigits} digits`)
                    .required('Account Number is Required'),
                otherwise: yup.string().notRequired()
            }),
        accountNumberConfirm: yup
            .string()
            .when('paymentMethod', {
                is: (value) => value === PAYMENT_METHOD_REQUEST_TYPES.ARNUMBER,
                then: yup
                    .string()
                    .oneOf([yup.ref('accountNumber'), null], 'Account numbers must match'),
                otherwise: yup.string().notRequired()
            })
    })

    const formik = useFormik({
        initialValues: {
            first_name: null,
            last_name: null,
            email: null,
            paymentMethod: PAYMENT_METHOD_REQUEST_TYPES.CARD,
            accountType: 'Checking',
        },
        validationSchema,
        onSubmit: submitPayment,
        enableReinitialize: true
    });

    return (
        <StyledContainer>
            <ThemeProvider theme={mainTheme}>
                <Form onSubmit={formik.handleSubmit} style={{ maxWidth: '800px', margin: 'auto' }}>
                    {!showSuccess && <Grid className={classes.form} container spacing={4} >
                        <Grid item xs={12}>
                            <div className={`${classes.header}`}>Account Holder Information</div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                fullWidth
                                label={"First Name"}
                                name="first_name"
                                type={'text'}
                                variant="outlined"
                                value={formik.values.first_name}
                                onChange={formik.handleChange}
                                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                helperText={(formik.touched.first_name && formik.errors.first_name) || " "}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                fullWidth
                                label={"Last Name"}
                                name={"last_name"}
                                type={'text'}
                                variant="outlined"
                                value={formik.values.last_name}
                                onChange={formik.handleChange}
                                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                helperText={(formik.touched.last_name && formik.errors.last_name) || " "}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                label={"Email Address"}
                                name={"email"}
                                type={'text'}
                                variant="outlined"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={(formik.touched.email && formik.errors.email) || " "}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={`${classes.header}`}>Add a Payment Method</div>
                            <div>Please enter your payment method information. You will not be charged at this time.</div>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                onChange={onPaymentMethodChange}
                                label={'Payment Method Type'}
                                placeholder={'Debit / Credit Card'}
                                name={'payment_method'}
                                type={'text'}
                                variant='outlined'
                                value={selectedPaymentMethod}
                                select
                            >
                                {paymentMethodOptions.map((option) => (
                                    <MenuItem key={option.type} value={option.type}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {PaymentMethod({ error })}
                        <Grid container item spacing={2}>
                            {error.msg && <Alert variant='danger'>{error.msg}</Alert>}
                            <Grid container item xs={12} spacing={2} style={{ justifyContent: 'center' }}>
                                <div style={{ float: 'left', marginRight: '15px', paddingTop: '5px' }}>
                                    <input type='checkbox' id='persist' name='persist' defaultChecked={false}
                                        onChange={e => {
                                            setSubmitButtonDisabled(!e.target.checked);
                                        }}
                                        onClick={() => { }} />
                                </div>

                                <div style={{ float: 'right', lineHeight: '25px' }}>
                                    <div className={classes.termsAndConditions}>By adding a credit card or eCheck payment method and clicking 'Save' you agree</div>
                                    <div className={classes.termsAndConditions}>to the relevant <TOS /> and authorize {firm.firmName} to use your saved</div>
                                    <div className={classes.termsAndConditions}>payment method(s) in the future per their payment and billing terms.</div>
                                </div>
                                <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <ThemeProvider theme={greenTheme}>
                                        {formik.isSubmitting && <Spinner animation='border' className='mr-2' role='status' />}
                                        <Button
                                            disabled={submitButtonDisabled}
                                            variant='contained'
                                            color='primary'
                                            type='submit'>
                                            SAVE PAYMENT METHOD
                                        </Button>
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
                    {showSuccess && 
                    <Grid container style={{ maxWidth: '800px', margin: 'auto' }}>
                        <Grid className={classes.form} container spacing={4} >
                            <Grid item xs={12}>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <div>
                                        <CheckCircleIcon style={{fontSize: "44px", color: "#6fcf97"}} />
                                    </div>
                                    <div style={{marginLeft: "15px"}}>
                                        <p style={{marginBottom: "0px"}}>Your payment method was saved.</p>
                                        <p>Close this window to continue.</p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>}
                </Form>
            </ThemeProvider>
        </StyledContainer>
    );

}

type PaymentMethodRequestViewProps = {
    vgsForm: object,
    vgsErrors: object,
    firm: Firm,
    errors: { [string]: string }
}

export default PaymentMethodRequestV;