import React from 'react'; 
import ConnectedPanel from "./Panels/ConnectedPanel";
import DeclinedPanel from "./Panels/DeclinedPanel";
import DisabledPanel from "./Panels/DisabledPanel";
import IncompletePanel from "./Panels/IncompletePanel";
import IntroPanel from "./Panels/IntroPanel";
import PausedPanel from "./Panels/PausedPanel";
import PendingReviewPanel from "./Panels/PendingReviewPanel";
import UnderReviewPanel from "./Panels/UnderReviewPanel";
import { Image } from 'react-bootstrap';
import { Images } from 'assets/index';
import { type HeadnoteApplication } from "domain/payment";

export default ({status, wasSubmitted, headnoteFirmId, headnoteIsConnected}: {status: String; wasSubmitted: Boolean, headnoteFirmId: String; headnoteIsConnected: Boolean;}) => {

    const Panel = getPanel(status, wasSubmitted, headnoteIsConnected);

    return (
        <>
            <Image style={{width: "300px"}} src={Images.settings.paid4timeLogo} />
            <Panel headnoteFirmId={headnoteFirmId} />
        </>
    )
}

/**
 * Gets the panel to show according to the current application status.
 */
const getPanel = (status, wasSubmitted, headnoteIsConnected) => {
    switch(status) {
        case STATUSES.UNVERIFIED:
            if (!wasSubmitted) {
                return IncompletePanel;
            }
            return PendingReviewPanel;
        case STATUSES.UNDER_REVIEW:
            return UnderReviewPanel;
        case STATUSES.VERIFIED:
            if (headnoteIsConnected) {
                return ConnectedPanel;
            }
            return PausedPanel;
        case STATUSES.DECLINED:
            return DeclinedPanel;
        case STATUSES.PAUSED:
            return PausedPanel;
        case STATUSES.DISABLED:
            return DisabledPanel;
        default:
            return IntroPanel;
    }
}

export const STATUSES = {
    UNVERIFIED: "UNVERIFIED",
    INCOMPLETE: "INCOMPLETE",
    UNDER_REVIEW: "UNDER_REVIEW",
    VERIFIED: "VERIFIED",
    DECLINED: "DECLINED",
    PAUSED: "PAUSED",
    DISABLED: "DISABLED"
}