// @flow
import * as R from 'ramda';

import { UserRedux, } from 'state/reducers';
import { useSelector, } from 'react-redux';

// hook check if user logged in
export const useAuthenticated = (): boolean => {
  const user = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));

  return Boolean(user);
};

// check if logged in by token
export const useTokenAuthenticated = (): boolean => {
  const tokens = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

  return Boolean(tokens && tokens.authToken);
}
