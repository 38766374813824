// @flow
import { DropzoneOptions, useDropzone, } from 'react-dropzone';
import React, { useEffect, } from 'react';

import Button from 'react-bootstrap/Button';
import { Colors, } from 'assets';
import { type FieldProps, } from 'formik';
import FormControl from 'react-bootstrap/FormControl';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${Colors.blueCurious} !important;
  border: none !important;

  :hover {
    background-color: ${Colors.blueAstral} !important;
  }
`;

type Props = {
  dropZoneOptions?: DropzoneOptions,
};

const FormUpload = ({
  field: { value, name, },
  form: { handleChange, setValues, submitForm, },
  dropZoneOptions = {}, ...restProps
}: FieldProps<any> & Props) => {
  const { getRootProps, getInputProps, } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      if (acceptedFiles.length === 0) {return;}

      // create image preview for each files
      const images = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
      }));
      const image = dropZoneOptions.multiple ? images : images[0];
      // formik handleChange will look for an input's id attribute
      // https://jaredpalmer.com/formik/docs/api/formik#handlechange-e-reactchangeevent-any-void
      handleChange(name)(image);

      submitForm();
    },
    ...dropZoneOptions,
  });

  // let browser know not to keep reference to file any longer when image changes
  useEffect(() => () => {
    if (!value) {return;}

    if (Array.isArray(value)) {
      value.forEach(file => URL.revokeObjectURL(file.preview));
    } else {
      URL.revokeObjectURL(value.preview);
    }
  }, [ value, ]);

  return (
    <div {...getRootProps()}>
      <FormControl {...getInputProps()} />
      <StyledButton className='font-weight-bold'>{`Upload`}</StyledButton>
    </div>
  );
};

export default FormUpload;
