/* @flow */
import { type PaymentRepository, type PendingPayment, type StripePayBody, } from 'domain/payment';

import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (res: PendingPayment) => void,
  onInvalidPayload: (Error) => void,
  onError: (Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
};

type Payload = {
  clientId: number,
  payment: StripePayBody,
};

export type StripePayBehaviour = (
  Payload,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { paymentRepository, }: Dependencies, ) => {
  /**
   * Stripe payment processors: pay
   * 
   * @param {Payload} payload - client id and request body
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const stripePayBehaviour: StripePayBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { clientId, payment, } = payload;
      if (!clientId) {
        return onInvalidPayload(new Error('Invalid client id.'));
      }

      const pendingPayment = await paymentRepository.stripePay(clientId, payment, withCurrentUserToken);
      
      return onSuccess(pendingPayment);
    } catch(error) {
      return onError(error);
    }
  };

  return stripePayBehaviour;
};
