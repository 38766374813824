/* @flow */
import { Colors, Images, } from 'assets/index';
import { Alert, Card, Col, Row, Form, Container, FormGroup, } from 'react-bootstrap';
import { Link, } from 'react-router-dom';
import RegisterForm from './components/RegisterForm';
import React from 'react';
import styled from 'styled-components';
import { type UserAuthInfo, } from 'domain/user';

const StyledLogin = styled(Container)`
  flex: 1;
`;

const StyledContainer = styled(Card)`
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
  .card-text {
    padding-bottom: 1rem;
  }
`;

type Props = {
  onSubmit: Function,
  initialFormValues: UserAuthInfo,
  error: ?string,
  email: string,
}

const RegisterPageV = ({ initialFormValues, onSubmit, error, email, }: Props) => {
  return (
    <StyledLogin className="d-flex align-items-center justify-content-center">
      <Row className="w-100">
        <Col className="my-5" md={{ span: 4, offset: 4, }}>
          <StyledContainer>
            <Link className="d-block mx-5 mt-5 mb-4" to={'/'}>
              <Card.Img src={Images.common.defaultLogo}></Card.Img>
            </Link>
            <Card.Body className="pb-5">
              <Card.Text>
                {`Hi, please signup.`}
              </Card.Text>
              <FormGroup>
                <Form.Control disabled value={email} />
              </FormGroup>
              <RegisterForm
                email={email}
                initialFormValues={initialFormValues}
                onSubmit={onSubmit}
              />
            </Card.Body>
          </StyledContainer>
        </Col>
      </Row>
    </StyledLogin>
  );
};

export default RegisterPageV;
