export default {
  accountSettings: {
    dateFormatUpdated: 'Date format updated.',
    emailUpdated: 'Email updated.',
    passwordUpdated: 'Password updated.',
    emailNotficationUpdated: 'Email notification settings updated.',
  },
  setting: {
    remove: 'Are you sure you want to stop accepting payments from this payment processor?',
    removeCurrentPayment: payment => (`You are already using ${payment} to accept payments via credit card. Changing your credit card processor will override your current payment option, and any credit cards and bank accounts that your clients have saved with ${payment} will be removed.`),
  },
};
