// @flow
import * as R from 'ramda';

import { type FieldProps, getIn, } from 'formik';
import React, { useCallback, } from 'react';

import { APP_CONFIG, } from 'constants/index';
import { Colors, } from 'assets';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import MaskedInput, {conformToMask} from 'react-text-mask';
import { UserRedux, } from 'state/reducers';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import styled from 'styled-components';
import { unmaskCurrencyToNumber, formatNumber, } from 'utilities/stringUtils';
import { useSelector, } from 'react-redux';

const StyledFormGroup = styled(FormGroup)`
  padding: 0px !important;
  width: 250px;
  .is-invalid {
    &::placeholder {
      color: ${Colors.redDanger} !important;
    }
  };
  .remainingBalance {
    font-size: small;
    margin-top: 5px;
    text-align: right;
  };
  .btn-link {
    font-size: small;
    padding: 4px;
  }
`;

// mask for currency input
// thousand separator is default
const currencyMask = createNumberMask({
  // decimalLimit: 4,
  prefix: '',
  suffix: '',
  allowDecimal: true, // allow the user to enter a fraction with the amount
  integerLimit: 26,
  // requireDecimal: true, // always include a decimal point and placeholder for decimal digits after the integer
});

type Props = {
  className: string,
  label: string,
  remainingBalance?: number
};

const PaymentField = ({
  field, form: { touched, errors, setFieldValue, setFieldTouched, },
  label, className, remainingBalance, ...restProps
}: FieldProps<any> & Props) => {
  // get firm from redux for currency format
  const currency: string = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getCurrencySymbol));

  // field states:
  const _error = getIn(errors, field.name);
  const _touched = getIn(touched, field.name);

  const remainingBalanceFormatted = conformToMask(formatNumber(remainingBalance ? remainingBalance : 0), currencyMask, {guide: false}).conformedValue;

  /**
   * handle input value update on blur
  */
  const onChangeValue = useCallback((e: SyntheticInputEvent<HTMLInputElement>) => {
    // unmask value before updating to formik state
    const value = e.currentTarget.value;

    handleFieldValueChange(value);
  }, [ field.name, setFieldTouched, setFieldValue, ]);

  const onRemainingBalanceClick = () => {

    handleFieldValueChange(remainingBalanceFormatted);
  };

  const handleFieldValueChange = (value) => {
    // remove all non-digit and not "." character
    const unMaskedValue = unmaskCurrencyToNumber(value);

    // update formik value state
    setFieldValue(field.name, unMaskedValue);
    // set field touched
    setFieldTouched(field.name, true);
  } 

  return (
    <StyledFormGroup className={`m-0 py-2 ${className}`}>
      {
        label && <FormLabel>{label}</FormLabel>
      }
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>{currency || APP_CONFIG.defaultCurrency.symbol}</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          as={MaskedInput}
          mask={currencyMask}
          {...field}
          {...restProps}
          isInvalid={_error && _touched}
          value={formatNumber(field.value)} // remove onchange passed from field
          onBlur={onChangeValue}
          onChange={undefined}
        />
        <FormControl.Feedback type="invalid">
          {_error}
        </FormControl.Feedback>
      </InputGroup>
      {remainingBalance > 0 && <div className={`remainingBalance`}>Remaining balance: <Button variant='link' onClick={onRemainingBalanceClick}>{currency || APP_CONFIG.defaultCurrency.symbol}{remainingBalanceFormatted}</Button></div>}
    </StyledFormGroup>
  );
};

export default PaymentField;
