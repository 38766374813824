// @flow
import * as R from 'ramda';
import React, { useMemo, useEffect, useCallback, useState, } from 'react';
import { ERRORS, } from 'constants/index';
import InvitesListV from './InvitesListV';
import { InviteRedux, UserRedux, ClientRedux, } from 'state/reducers';
import { useSelector, useDispatch, } from 'react-redux';
import { type InviteMap, } from 'state/invite/types';
import { type ClientMap, type deleteInvitePayload, } from 'state/client/types';
import { InviteActionCreators, } from 'state/actions';
import { convertEpocToDate, } from 'utilities/stringUtils';

const useConnect = () => {
  // mapState
  const user = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const inviteMap: InviteMap = useSelector(R.pipe(InviteRedux.getReducerState, InviteRedux.selectors.getInvite));
  const clientsMap: ClientMap = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getClients));

  const mapState = {
    inviteMap,
    user,
    clientsMap,
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    fetchInvites: () => dispatch(InviteActionCreators.getInvite(undefined, { thunk: true, })),
    deleteInvite: (payload: deleteInvitePayload) => dispatch(InviteActionCreators.deleteInvite(payload, { thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/**
 * Invite VM
 *
 * @returns
 */
const InvitesListVM = () => {
  // connect redux
  const { inviteMap, fetchInvites, deleteInvite, user, clientsMap, } = useConnect();

  const [ error, setError, ] = useState(null);
  const [ messages, setMessages, ] = useState(null);
  const [ loading, setLoading, ] = useState<boolean>(false);

  // Get admin token to send invite
  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        await fetchInvites();
      } catch (e) {
        setError({ auth: ERRORS.admin_token, });
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [ fetchInvites, ]);

  // convert map to list
  const listInvites = () => {
    return Array.from(inviteMap).map(([ key, value, ]) => ({
      ...value,
      date: convertEpocToDate(value.createdDate),
      client: R.map(item => clientsMap.get(item), value.accessibleClients),
    }));
  };

  // revoke invite
  const onSubmit = useCallback(async (tokenId: string) => {
    try {
      const data : deleteInvitePayload = {
        tokenId: tokenId,
      };

      const message = await deleteInvite(data);
      setMessages({ msg: message.msg, });
    } catch (e) {
      setError({ auth: ERRORS.revoke_invite, });
    };
  }, [ deleteInvite, setError, setMessages, ]);

  return (
    <InvitesListV
      data={listInvites()}
      error={error}
      loading={loading}
      messages={messages}
      onDelete={onSubmit}
    />
  );
};

export default InvitesListVM;
