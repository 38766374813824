import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Colors, } from 'assets/index';
import Container from 'react-bootstrap/Container';
import React from 'react';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

const StyledPrivacy = styled(Container)`
color: ${Colors.charcoal};
line-height: 20px;
  .privacy-container {
    box-shadow: 1px 1px 1px ${Colors.lightGrey};
  }
  p {
    margin-bottom: .5rem;
  }
`;

const StyledHeader = styled(Card.Header)`
  background-color: rgba(0,0,0,0) !important;
  margin-top: .75rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
`;

const StyledTitle = styled(Card.Title)`
  font-size: 18px !important;
`;

const StyledLink = styled(Card.Link)`
  color: ${Colors.blueCurious} !important;

  :hover {
    color: ${Colors.blueAstral} !important;
    text-decoration: underline !important;
  }
`;

const StyledSmall = styled.small`
  color: ${Colors.greyEmpress};
`;

const lastUpdated = 'June 10, 2019';

const PrivacyPage = () => {
  return (
    <StyledPrivacy className='my-4'>
      <Row>
        <Col md={{ span: 8, offset: 2, }}>
          <Card className="privacy-container">
            <StyledHeader className="p-0">
              <StyledTitle>
                {`Privacy Policy`}
              </StyledTitle>
            </StyledHeader>
            <Card.Body>
              <StyledSmall>
                {`Last updated on ${lastUpdated}`}
              </StyledSmall>
              <StyledTitle className="my-2">
                {`WHAT INFORMATION DO WE COLLECT?`}
              </StyledTitle>
              <Card.Text>
                {`We collect information from you when you register and make a payment. When making a payment on our site, as appropriate, you may be asked to enter your credit card information.`}<br />{`Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information. We use cookies to understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. We may contract with third-party service providers to assist us in better understanding our site visitors. These service providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.`}
              </Card.Text>
              <StyledTitle className="my-2">
                {`WHAT DO WE USE YOUR INFORMATION FOR?`}
              </StyledTitle>
              <Card.Text>
                {`Any of the information we collect from you may be used in one of the following ways: to personalize your experience (your information helps us to better respond to your individual needs), to improve our website (we continually strive to improve our website based on the information and feedback we receive from you), to improve customer service (your information helps us to more effectively respond to your customer service requests and support needs) and to process transactions.Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.The email address you provide will only be used to send you information and updates pertaining to your order.`}
              </Card.Text>
              <StyledTitle className="my-2">
                {`HOW DO WE PROTECT YOUR INFORMATION?`}
              </StyledTitle >
              <Card.Text>
                {`We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Database to be only accessed by those authorized with special access rights to our systems, and are required to?keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) will be kept on file for more than 60 days in order to fulfill ongoing subscription.`}
              </Card.Text>
              <StyledTitle className="my-2">
                {`DO WE DISCLOSE ANY INFORMATION TO OUTSIDE PARTIES?`}
              </StyledTitle >
              <Card.Text>
                {`We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.`}
              </Card.Text>
              <StyledTitle className="my-2">
                {`SAFE HARBOR STATEMENT`}
              </StyledTitle >
              <Card.Text>
                {`Bill4Time complies with the U.S.-EU Safe Harbor Framework and the U.S.-Swiss Safe Harbor Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information from European Union member countries and Switzerland. Bill4Time has certified that it adheres to the Safe Harbor Privacy Principles of notice, choice, onward transfer, security, data integrity, access, and enforcement. To learn more about the Safe Harbor program, please visit: `}
                <StyledLink href="http://www.export.gov/safeharbor">
                  {`www.export.gov/safeharbor`}
                </StyledLink>
              </Card.Text>
              <StyledTitle className="my-2">
                {`CALIFORNIA ONLINE PRIVACY PROTECTION ACT COMPLIANCE`}
              </StyledTitle >
              <Card.Text>
                {`Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent. As part of the California Online Privacy Protection Act, all users of our site may make any changes to their information at anytime by logging into their account and going to the 'Settings' page.`}
              </Card.Text>
              <StyledTitle className="my-2">
                {`CHILDRENS ONLINE PRIVACY PROTECTION ACT COMPLIANCE`}
              </StyledTitle >
              <Card.Text>
                {`We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or older.`}
              </Card.Text>
              <StyledTitle className="my-2">
                {`TERMS AND CONDITIONS`}
              </StyledTitle >
              <Card.Text>
                {`Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at `}
                <StyledLink href="https://www.b4tportal.com/terms">
                  {`here`}
                </StyledLink>
              </Card.Text>
              <StyledTitle className="my-2">
                {`YOUR CONSENT`}
              </StyledTitle >
              <Card.Text>
                {`By using our site, you consent to our websites privacy policy.`}
              </Card.Text>
              <StyledTitle className="my-2">
                {`CHANGES TO OUR PRIVACY POLICY`}
              </StyledTitle >
              <Card.Text>
                {`If we decide to change our privacy policy, we will post those changes on this page, send an email notifying you of any changes, and/or update the Privacy Policy modification date above.`}
              </Card.Text>
              <StyledTitle className="my-2">
                {`CONTACTING US`}
              </StyledTitle >
              <Card.Text>
                {`If there are any questions regarding this privacy policy you may contact us at `}
                <StyledLink href="mailto:info@bill4time.com">
                  {`info@bill4time.com`}
                </StyledLink>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </StyledPrivacy>
  );
};

export default PrivacyPage;
