// @flow
import React, { type Node, } from 'react';

import Col from 'react-bootstrap/Col';
import { Colors, } from 'assets';
import { type FieldProps, } from 'formik';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

const StyledForm = styled(FormGroup)`
  .is-invalid {
    &::placeholder {
      color: ${Colors.redDanger} !important;
    }
  }
  input.form-control {
    outline: none;
    box-shadow: none;
  }
  &.invalid {
    .is-invalid {
      &::placeholder {
        color: ${Colors.redDanger} !important;
      }
    }
    input {
      background-color: ${Colors.shadingInputbg};
    }
    .invalid-feedback {
      display: none;
    }
  }
`;

export type Props = {
  className?: string,
  controlWrapperClass?: string, 
  label?: string,
  labelClassName?: string,
  isRow?: boolean,
  children: Node,
  labelSize: number, 
  inputSize: number,
};

type WrapperProps = {
  isRow?: boolean,
  controlWrapperClass?: string,
  children: Node,
  size: number,
};

const ControlWrapper = ({ isRow, controlWrapperClass, children, size, }: WrapperProps) => {
  return isRow ? (
    <Col className={`pl-1 ${controlWrapperClass || ''}`} sm={size}>
      {children}
    </Col>
  ) : (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

ControlWrapper.defaultProps = {
  size: 8
};

const CustomFormGroup = ({
  isRow, label, className = '',
  controlWrapperClass,
  labelClassName = '', children,
  labelSize, inputSize,
}: Props) => {
  return (
    <StyledForm as={isRow ? Row : undefined} className={className}>
      {
        label &&
          <FormLabel
            className={`${isRow ? 'pr-1' : ''} ${labelClassName}`}
            column={isRow}
            sm={labelSize}
          >
            {label}
          </FormLabel>
      }
      <ControlWrapper 
        controlWrapperClass={controlWrapperClass} 
        isRow={isRow}
        size={inputSize}
      >
        {children}
      </ControlWrapper>
    </StyledForm>
  );
};

CustomFormGroup.defaultProps = {
  labelSize: 4,
  inputSize: 8,
};

export default CustomFormGroup;
