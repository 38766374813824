// @flow
import * as R from 'ramda';

import { END, eventChannel, } from 'redux-saga';
import { ProjectActionCreators, ProjectTypes, } from '../actions';
import { type ProjectMap, type getProjectsPayload, type getProjectsSuccessPayload, } from './types';
import { cancelled, put, select, take, takeLatest, } from 'redux-saga/effects';

import { type AwilixContainer, } from 'awilix';
import { type GetProjectsOfClientBehaviour, } from 'app/project/GetProjectsOfClient';
import { type Project, } from 'domain/project';
import { UserRedux, } from 'state/reducers';
import { type WithCurrentUserToken, } from 'domain/user';

/**
   * get projects of a client redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
const getProjectsOfClient = function * (container: AwilixContainer, { payload, meta, }: { payload: getProjectsPayload, meta: ?Object, }) {
  try {
    // resolve get projects of a client behaviour from awilix container
    const getProjectsOfClientBehaviour: GetProjectsOfClientBehaviour = container.resolve('getProjectsOfClient');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getProjectsOfClientBehaviour(
        payload.clientId,
        withCurrentUserToken,
        {
          onSuccess: (projects) => {
            emitter({ data: projects, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data: projects, error, }: { data: Array<Project>, error: Object | string, } = yield take(channel);
        if (!projects && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (projects) {
          // process project list to js Map ['key', 'value']
          const projectTuple = projects.map((p: Project) => [ p.id, p, ]);
          const projectMap: ProjectMap = new Map(projectTuple);

          const payload: getProjectsSuccessPayload = { projects: projectMap, };
          yield put(ProjectActionCreators.getProjectsSuccess(payload, meta));
        }
    }
  } catch (e) {
    yield put(ProjectActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(ProjectActionCreators.requestFailure(true, null, meta));
    }
  }
};

export default (container: AwilixContainer) => ([
  takeLatest(ProjectTypes.GET_PROJECTS, getProjectsOfClient, container),
]);
