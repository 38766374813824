// @flow
import * as R from 'ramda';
import { GetStatementLinkSuccessPayload, StatementState } from './types';
import { isPersistActive, storage } from '../_reduxPersist/persistConfig';
import { createReducer } from 'reduxsauce';
import { mapTransformer } from '../_reduxPersist/transformer';
import { persistReducer, PersistConfig } from 'redux-persist';
import { produce } from 'immer';
import { StatementTypes } from 'state/actions';

export const stateKey = 'statement';

/* ------------- Initial State ------------- */

const INITIAL_STATE: StatementState = ({
    statementLink: {}
});

/* ------------- Reducers ------------- */

const getStatementLink = R.identity;
const getStatementLinkSuccess = (state: StatementState, { payload }: { payload: StatementLinkPayload }) =>
produce(state, (draft) => {
  Object.assign(draft.statementLink = payload);
});

const requestFailure = R.identity;

/* ------------- Hookup Reducers To Types ------------- */

const reducer = createReducer(INITIAL_STATE, {
    [StatementTypes.GET_STATEMENT_LINK]: getStatementLink,
    [StatementTypes.GET_STATEMENT_LINK_SUCCESS]: getStatementLinkSuccess,

    [StatementTypes.REQUEST_FAILURE]: requestFailure
})

const persistConfig: PersistConfig = {
    key: stateKey,
    storage,
    transforms: [
      mapTransformer({
        whitelist: 'statements',
      }),
    ],
    whitelist: []
  };

  const reducerMap = { [stateKey]: isPersistActive
      ? persistReducer < any, any> (persistConfig, reducer)
    : reducer, };

/* ------------- Selectors ------------- */

const getReducerState: (Object) => StatementState = (state) => (state[stateKey]);

const selectors = {
    getStatementLink: ({ statementLink }: StatementState) => (statementLink)
};

/* ------------- Export ------------- */
export default {
    selectors,
    INITIAL_STATE,
    stateKey,
    getReducerState,
    reducerMap,
  };
  