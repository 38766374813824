/* @flow */
import { createActions } from 'reduxsauce';
import { reducerPrefixFormat } from 'state/common';

/* ------------- Headnote Firm Settings ------------- */
export const { Types, Creators } = createActions({
    getHeadnoteFirmSettings: ['meta'],
    getHeadnoteFirmSettingsSuccess: ['payload', 'meta',],

    requestFailure: ['error', 'payload' /* error payload */, 'meta',]
}, {
    prefix: reducerPrefixFormat('headnoteFirmSettings'),
});