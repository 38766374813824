export default {
    login: 'Invalid email or password',
    revoke_invite: 'Cannot revoke this invite',
    admin_token: 'No permission',
    connect_custom_payment: 'Oops, something went wrong.',
    disconnect_custom_payment: 'Oops, something went wrong.',
    invoice_token: 'Invoice token error',
    retainer_token: 'Retainer token error',
    statement_token: 'Statement token error',
    payment_method_request_token: 'Payment Method Request token error',
    permission: 'No permission',
    invalid_month: 'Please enter a valid credit card expiration month',
    invalid_year: 'Please enter a valid credit card expiration year',
    stripe_amount_at_least: 'Payment amount must be greater than $0.50',
    invalid_password: "Incorrect password entered",
    both_invalid_bank_routing_number: "Please enter a valid Routing and Account number",
    invalid_bank_account_number: "Please enter a valid Account number",
    invalid_routing_number: "Please enter a valid Routing number",
    invalid_fields: 'All fields in blue are required',
};