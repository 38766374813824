// @flow
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { Colors, } from 'assets';
import { currencyFormat } from 'utilities/stringUtils';

const CancelButton = styled(Button)`
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  color: ${Colors.charcoal} !important;
  height: 40px;
`;

const ContinueButton = styled(Button)`
  box-shadow: none !important;
  border: none !important;
  height: 40px;
`;

const ContinueButtonDisabled = styled(Button)`
  background-color: #ced4da;
  box-shadow: none !important;
  border: none !important;
  height: 40px;
`;

const TotalPaymentSection = styled.div`
  width: 100%; 
  text-align: right;
  vertical-align: middle;
  line-height: 40px;
  font-size: 1rem;
  padding-right: 35px;
`;

const TotalAmountValue = styled.span`
  padding-left: 10px;
  font-weight: bold;
  color: #007dff;
`;

const TotalAmountValueOver = styled.span`
    padding-left: 10px;
    font-weight: bold;
    color: red;
`;

const OverpaymentWarning = styled.p`
    font-size: 9pt;
    color: red;
`;

type Props = {
  balance?: number,
  total: number,
  firm: Firm,
  onCancel: any,
  paymentReceivingBankType: any
};

const RetainersFormTotalSection = ({
  balance, total, firm, onCancel, paymentReceivingBankType
}: Props) => {

  const showOverpaymentWarning: Boolean = useMemo(() => {
    if (firm && (!firm.portalSettings.disableOverpayment || paymentReceivingBankType === 'TRUST')) {
      return false;
    }
    return balance + total > 0 ? true : false;
  }, [ balance, total, paymentReceivingBankType ]);

  return (
    <>
        <CancelButton onClick={onCancel}>
            {`Cancel`}
        </CancelButton>

        <TotalPaymentSection>
            <span>Total Payment Amount:</span>
            {showOverpaymentWarning && <TotalAmountValueOver>{currencyFormat(total, firm)}</TotalAmountValueOver>}
            {!showOverpaymentWarning && <TotalAmountValue>{currencyFormat(total, firm)}</TotalAmountValue>}
            {showOverpaymentWarning && <OverpaymentWarning>This payment exceeds your remaining account balance of <strong>{currencyFormat(-1 * balance, firm)}</strong>. Please edit your payment amount.</OverpaymentWarning>}
        </TotalPaymentSection>
        {showOverpaymentWarning &&
          <ContinueButtonDisabled size='lg' variant="secondary">
            {`CONTINUE`}
          </ContinueButtonDisabled>
        }
        {!showOverpaymentWarning && 
          <ContinueButton size='lg' type='submit' variant="success">
              {`CONTINUE`}
          </ContinueButton>
        }
    </>
  );
};

export default RetainersFormTotalSection;
