// @flow
import * as R from 'ramda';

import { ButtonWrapper, StyleContainer, StyledSpan, } from '../UI';
import { Elements, StripeProvider, } from 'react-stripe-elements';
import { PaymentRedux, UserRedux, } from 'state/reducers';
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import { API_CONFIG, } from 'constants/index';
import BankAccountRow from './components/BankAccountRow';
import { type ClientPortalSettings, } from 'domain/firm';
import FormLabel from '../form/FormLabel';
import LawpayAddBankModal from './components/lawpay/LawpayAddBankModal';
import { PaymentActionCreators, } from 'state/actions';
import SettingButton from '../form/SettingsButton';
import StripeAddBankModal from './components/stripe/StripeAddBankModal';
import { isMaxBankAccounts, } from 'domain/payment';

const useConnect = () => {
  // mapState
  const firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const bankAccounts = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getBankAccounts));

  const mapState = {
    firm,
    bankAccounts,
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    getUserBankAccounts: () => dispatch(PaymentActionCreators.getUserBankAccounts(
      // {
      //   thunk: true,
      // }
    )),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/**
 * custom hook handle add bank account
 *
 * @returns
 */
const useAddBankAccount = () => {
  const [ isShowAddBank, setIsShowAddBank, ] = useState<boolean>(false);

  const onAddBankAccountClick = useCallback(() => {
    setIsShowAddBank(true);
  }, []);

  const onAddBankModalHide = useCallback(() => {
    setIsShowAddBank(false);
  }, []);

  return {
    isShowAddBank,
    onAddBankAccountClick,
    onAddBankModalHide,
  };
};

const BankAccount = () => {
  // connect redux
  const {
    firm, bankAccounts,
    getUserBankAccounts,
  } = useConnect();

  // firm data selectors
  const portalSettings: ClientPortalSettings = R.path([ 'portalSettings', ], firm) || {};

  // load user bank accounts on mount
  useEffect(() => {
    (
      async function fetchDatas() {
        try {
          await getUserBankAccounts();
        } catch (e) {
          console.log(e);
        }
      }
    )();
    
  }, [ getUserBankAccounts, ]);

  const {
    isShowAddBank,
    onAddBankAccountClick,
    onAddBankModalHide,
  } = useAddBankAccount();

  // only render add bank button if max bank accounts has not exceeded
  const isAllowAddBankAccount = !isMaxBankAccounts(bankAccounts.length);

  // Get publish key from firm
  const publishKey = useMemo(() => {
    const portalSettings: ClientPortalSettings = R.path([ 'portalSettings', ], firm);
    if (portalSettings && portalSettings.stripe && portalSettings.stripe.stripePublishableKey) {
      return portalSettings.stripe.stripePublishableKey;
    }
    return API_CONFIG.stripeApiKey;
  }, [ firm, ]);

  return (
    <StyleContainer>
      <FormLabel
        label={(
          <>
            {`Bank Account (ACH)`}
          </>
        )}
      />
      {
        // only render add bank button if max bank accounts has not exceeded
        isAllowAddBankAccount && (
          <ButtonWrapper className='d-flex flex-row justify-content-end pt-4'>
            <SettingButton onClick={onAddBankAccountClick}>
              {`Add a Bank Account`}
            </SettingButton>
          </ButtonWrapper>
        )
      }

      {
        bankAccounts.map((b, key) => {
          return (
            <BankAccountRow bankAccount={b} key={key} />
          );
        })
      }

      {
        portalSettings.isStripe && (
          <StripeProvider apiKey={publishKey}>
            <Elements>
              <StripeAddBankModal
                isShow={isShowAddBank}
                onHide={onAddBankModalHide}
              />
            </Elements>
          </StripeProvider>
        )
      }
      {
        portalSettings.isLawpay && (
          <LawpayAddBankModal
            isShow={isShowAddBank}
            onHide={onAddBankModalHide}
          />
        )
      }
    </StyleContainer>
  );
};

export default BankAccount;
