// @flow
import { FormInput, FormSelect, } from 'view/components';

import { Field, } from 'formik';
import Form from 'react-bootstrap/Form';
import React from 'react';
import { STRIPE_ACCOUNT_HOLDER_TYPE, } from 'domain/payment';
import styled from 'styled-components';
import { Colors, } from 'assets';

type props = {
  errors: object,
  values: object,
}

const StyledFormInput = styled(FormInput)`
  && {
    .is-invalid {
    }
    .invalid-feedback {
      display: none;
    }
  }
`;

const BankForm = ({ errors, values, } : props) => {
  return (
    <Form>

      <Field
        isRow
        required
        className={`payment-validate ${errors.account_holder_name ? 'invalid' : 'valid'}`}
        component={StyledFormInput}
        label={'Account Holder Name'}
        labelClassName={`text-right font-weight-bold`}
        maxLength={1000}
        name={'account_holder_name'}
        type={'text'}
      />

      <Field
        fullWidth
        isRow
        required
        className={`mt-3`}
        component={FormSelect}
        defaultValue={STRIPE_ACCOUNT_HOLDER_TYPE[0].value}
        isSearchable={false}
        label={'Account Holder Type'}
        labelClassName={`text-right font-weight-bold`}
        name={'account_holder_type'}
        options={STRIPE_ACCOUNT_HOLDER_TYPE}
        placeholder={'Account Holder Type'}
      />

      <Field
        isRow
        required
        className={`mt-3 payment-validate ${errors.routing_number ? 'invalid' : 'valid'}`}
        component={StyledFormInput}
        label={'Routing Number'}
        labelClassName={`text-right font-weight-bold`}
        maxLength={9}
        name={'routing_number'}
        type={'text'}
      />

      <Field
        isRow
        required
        className={`mt-3 payment-validate ${errors.account_number ? 'invalid' : 'valid'}`}
        component={StyledFormInput}
        label={'Account Number'}
        labelClassName={`text-right font-weight-bold`}
        maxLength={17}
        name={'account_number'}
        type={'text'}
      />
      
    </Form>
  );
};

export default BankForm;
