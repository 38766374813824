import React from 'react';
import ApplicationStatus from "./components/ApplicationStatus";
import SupportInfo from "./components/SupportInfo";
import {STATUSES} from "../PaymentsPanel";
import styled from 'styled-components';
import ErrorMessage from "./components/ApplicationErrorMessage";

export default () => {
    return (
        <>
            <StyledH5 className="mt-3">{`Something doesn't seem right.`}</StyledH5>
            <ApplicationStatus status={STATUSES.DECLINED} />
            <ErrorMessage message={`It seems your application has been declined.`} />
        </>
    )
}

const StyledH5 = styled.h5`
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
`;