/* @flow */
import { type WithCurrentUserToken, } from 'domain/user';
import { type Client, } from 'domain/client';

import { type FirmRepository, } from 'domain/firm';

type Callbacks = {
  onSuccess: (clients: Client[]) => void,
  onError: (e: Error) => void,
};

type Dependencies = {
  firmRepository: FirmRepository,
};

export type GetFirmClientsBehaviour = (
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export default ( { firmRepository, }: Dependencies, ) => {
  /**
   * get firm clients
   * 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getFirmClientsBehaviour: GetFirmClientsBehaviour = async (
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      // get firm clients
      const clients = await firmRepository.getFirmClients(withCurrentUserToken);

      return onSuccess(clients);
    } catch(error) {
      return onError(error);
    }
  };

  return getFirmClientsBehaviour;
};
