/* @flow */
import { type Firm, type FirmRepository, } from 'domain/firm';

import { type FirmUpdateRequestBody, } from 'infra/firm/FirmRepository';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (firm: Firm) => void,
  onInvalidPayload: (e: Error) => void,
  onError: (e: Error) => void,
};

type Dependencies = {
  firmRepository: FirmRepository,
};

type Payload = {
  data: FirmUpdateRequestBody,
};

export type UpdateFirmBehaviour = (
  payload: Payload,
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export default ( { firmRepository, }: Dependencies, ) => {
  /**
   * update firm settings (except payment processors)
   * 
   * @param {Payload} payload - stripe bank account token
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const updateFirmBehaviour: UpdateFirmBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { data, } = payload;
      if (!data) {
        return onInvalidPayload(new Error('Invalid settings.'));
      };

      // update firm
      await firmRepository.updateFirm(data, withCurrentUserToken);

      // get updated firm
      const updatedFirm = await firmRepository.getFirm(withCurrentUserToken);
      
      return onSuccess(updatedFirm);
    } catch(error) {
      return onError(error);
    }
  };

  return updateFirmBehaviour;
};
