// @flow
import Button from 'react-bootstrap/Button';
import { Colors, } from 'assets';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${Colors.blueButton} !important;
  font-weight: bold !important;
  &:hover {
    background-color: ${Colors.blueButtonHover} !important;
  }
`;

export default StyledButton;
