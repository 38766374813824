/* @flow */

import React from "react"
import {Button, ThemeProvider, Theme, ButtonProps, } from "@material-ui/core"
import {mainTheme, greenTheme, greyTheme, redTheme} from "../../styles/themes"
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import {
    defaultFontFamilies,
    defaultFontWeight,
} from "../../styles/styles";

// B4T button offering various themes
export default (props: IPropArgs) => {
    const { className, onClick, B4TColor, style,  ...other } = props;
    let theme: Theme;

    switch (B4TColor) {
        case ButtonColors.BLUE:
            theme = mainTheme;
            break;
        case ButtonColors.GREEN:
            theme = greenTheme;
            break;
        case ButtonColors.GREY:
            theme = greyTheme;
            break;
        case ButtonColors.RED:
            theme = redTheme;
            break;
    }
    return (
        <ThemeProvider theme={theme}>
            <Button                      
            size="large"
            style={{...commonButtonStyling, ...style}}
            disableRipple={true}
            color="primary"
            variant= "contained"
            onClick={props.onClick}
            {...other}
            >
            {props.text}
            </Button>
        </ThemeProvider>
    )
}

interface IPropArgs extends ButtonProps {
    text: string;
    B4TColor: ButtonColors;
    target?: string;
}

const commonButtonStyling: CSSProperties = {
    fontSize: '0.95rem',
    fontFamily: defaultFontFamilies,
    fontWeight: defaultFontWeight,
    border: "0px",
    boxShadow: "none",
    fontWeight: "bold",
    letterSpacing: "0.46px"

}

export const ButtonColors = {
    RED: 1,
    BLUE: 2,
    GREY: 3,
    GREEN: 4
}

