/* @flow */
import * as R from 'ramda';

import { BrowserRouter, Redirect, Route, Switch, } from 'react-router-dom';
import { ErrorActionCreators, UserActionCreators, } from 'state/actions';
import { PrivateRoute, PublicOnlyRoute, TokenPrivateRoute, NormalRouter, TokenPrivateRouteWithoutLayout, } from './auth/controlledRoute';
import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import AccessPage from './access';
import AccountSettingsPage from './accountSettings/AccountSettingsVM';
import AccoutingPage from './accounting/AccountingVM';
import AuthBoundary from './auth/AuthBoundary';
import Button from 'react-bootstrap/Button';
import { ErrorRedux, } from 'state/reducers';
import HomePage from './home/HomePageVM';
import IntegratePayment from './integratePayment';
import InvoiceFromEmail from './invoice/InvoiceFromEmailVM';
import RetainerFromEmail from './retainer/RetainerFromEmailVM';
import InvoicePage from './invoice/InvoiceVM';
import InvoicePaymentPage from './InvoicePayment/InvoicePaymentVM';
import StatementFromEmail from './statement/StatementFromEmailVM';
import StatementPage from './statement/StatementVM';
import RetainerPaymentPage from './RetainerPayment/RetainerPaymentVM'
import LoginPage from './auth/LoginPage/LoginPageVM';
import Modal from 'react-bootstrap/Modal';
import PaymentMethods from './payment/methods/PaymentMethodsVM';
import PaymentPage from './payment/cart/PaymentPageVM';
import PaymentSuccessPage from './payment/success';
import B4tPaymentSuccessPage from './payment/b4tSuccess';
import Link2PayPage from './link2pay/Link2PayVM';
import Link2PayFromLinkVM from './link2pay/Link2PayFromLinkVM';
import PaymentMethodRequestFromEmailVM from './paymentMethodRequest/PaymentMethodRequestFromEmailVM';
import PaymentMethodRequestPage from './paymentMethodRequest/PaymentMethodRequestVM';
import PrivacyPage from './footer/PrivacyPage';
import RegisterPage from './auth/Register/RegisterPageVM';
import ResetPasswordVM  from "./auth/ResetPasswordPage/ResetPasswordVM";
import ResetRequestVM  from "./auth/ResetRequestPage/ResetRequestVM";
import SecurityStatementPage from './footer/SecurityStatementPage';
import SettingsPage from './settings/SettingsPage';
import TermsOfServicePage from './footer/TermsOfServicePage';

var mixpanel = global.mixpanel;
if (!mixpanel) {
    mixpanel = require('mixpanel-browser');
}

var token = 'a618934f10a00bde4762441c91a2b9bf'; // development project token - used for localhost/staging/development to test that new events firing

// if production (and not staging/development deployment on .corp domain)
if (window.location.host.indexOf('b4tportal.com') !== -1) {
    token = 'c18f80af9e1cd47e6e8de016212c02e6'; // production project token
}
try {
    mixpanel.init(token);
} catch (e) {
    //throw Error('mixpanel init error: ', e);
}

global.mixpanel = mixpanel;

/**
 * handle app start
 *
 */
const useFirstLoad = () => {
  // dispatch load firm data
  const dispatch = useDispatch();
  const loadFirm = useCallback(() => {
    dispatch(UserActionCreators.getFirm());
  }, [ dispatch, ]);

  // load firm data on app start
  useEffect(() => {
    // const isAccessPage: boolean = window.location.href.indexOf('/access') !== -1;

    // if (!isAccessPage) {
    //   loadFirm();
    // }

  }, [ loadFirm, ]);
};

/**
 * handle showing app global error modal
 *
 */
const useErrorModal = () => {
  // current error from redux
  const currentError = useSelector(R.pipe(ErrorRedux.getReducerState, ErrorRedux.selectors.getCurrentError));

  // dispatch current error finish action
  const dispatch = useDispatch();
  const currentErrorFinish = useCallback(() => {
    dispatch(ErrorActionCreators.currentErrorFinish());
  }, [ dispatch, ]);

  // modal display state
  const [ showModal, setShowModal, ] = useState(false);

  const onModalOkClick = useCallback<() => void>(() => {
    // close modal and dispatch current error finish action
    setShowModal(false);
    currentErrorFinish();
  }, [ currentErrorFinish, ]);

  // display new modal error whenever there is new error
  useEffect(() => {
    if (!currentError) {return;}

    setShowModal(true);
  }, [ currentError, ]);

  return {
    currentError,
    showModal,
    onModalOkClick,
  };
};

const Router = () => {
  useFirstLoad();

  const {
    currentError,
    showModal,
    onModalOkClick,
  } = useErrorModal();

  return (
    <AuthBoundary>
      <BrowserRouter>
        <Switch>
          {/*
            settings page will be embedded within an iFrame within the main Bill4time application
            -> This page will not has client portal header and footer ( layout )
          */}
          <TokenPrivateRouteWithoutLayout component={SettingsPage} path='/settings' title="Settings" />
          {/* access integrate payment */}
          <Route exact component={IntegratePayment} path='/integrate-payment' />

          {/* access route take in { token, redirect_url } in url params */}
          <Route exact component={AccessPage} path='/access' />

          <Route path='/'>
              <Switch>
                <PrivateRoute exact component={HomePage} path='/' title="Dashboard" />

                <NormalRouter exact component={PrivacyPage} path='/privacy' title="Privacy" />
                <NormalRouter exact component={TermsOfServicePage} path='/terms' title="Terms" />
                <NormalRouter exact component={SecurityStatementPage} path='/security' title="Security" />
                <PublicOnlyRoute exact component={RegisterPage} path='/register' title="Register" />
                <PublicOnlyRoute component={LoginPage} path='/login' title="Login" />
                <PublicOnlyRoute component={ResetRequestVM} path='/reset-password' title="Reset password" />
                <PublicOnlyRoute component={ResetPasswordVM} path='/reset/:token' title="Reset password" />
                <PrivateRoute component={AccoutingPage} path='/accounting' title="Accounting" />
                {/* Access invoice from email and redirect to invoice detail  */}
                <NormalRouter exact component={InvoiceFromEmail} path='/invoice' title="Invoice from email" />
                <TokenPrivateRoute component={InvoicePage} path='/invoice/:id/:clientId/:projectId?' title="Invoice" />

                <TokenPrivateRoute component={StatementPage} path='/statement/:id/:clientId/' title='Statement'/>
                <NormalRouter exact component={StatementFromEmail} path='/statements' title='Statement from email' />

                <TokenPrivateRoute component={RetainerPaymentPage} path='/retainer-payment/:retainerId/:clientId' title="Payment request" />
                <NormalRouter exact component={RetainerFromEmail} path='/retainer' title="Request from email" />

                <NormalRouter exact component={PaymentMethodRequestFromEmailVM} path='/pmtMethodRequest' title="Payment method request from email" />
                <TokenPrivateRoute component={PaymentMethodRequestPage} path='/payment-method-request/:firmId/:clientId' title="Payment Method Request" />

                /*
                {/* Access invoice from email and redirect to invoice detail  */}
                <NormalRouter exact component={InvoiceFromEmail} path='/invoice' title="Invoice from email" />
                 */

                {/* invoice payment from client and project id */}
                <NormalRouter component={InvoicePaymentPage} path='/invoice-payment/:clientId/:projectId?' title="Invoice payment" />
                {/* invoice payment from single invoice */}
                <NormalRouter component={InvoicePaymentPage} path='/invoice-payment-single/:invoiceId/:clientId/:projectId?' title="Invoice payment" />

                <PrivateRoute component={ PaymentPage } path='/payment' title="Payment" />
                <NormalRouter component={ PaymentMethods } path='/payment-methods' title="Payment methods" />
                <PrivateRoute component={ AccountSettingsPage } path='/account-settings' title="Account settings" />
                <NormalRouter component={PaymentSuccessPage} path='/payment-success' title="Payment success" />
                <NormalRouter component={B4tPaymentSuccessPage} path='/b4t-payment-success' title="Payment success" />
                
                <TokenPrivateRoute component={Link2PayPage} path='/link2pay/:urlGuid' title="Link 2 Pay" />                
                <NormalRouter exact component={Link2PayFromLinkVM} path='/link2pay' title="Request from link" />

                <Redirect to='/' />
              </Switch>
          </Route>

          <Redirect to='/' />
        </Switch>

        <Modal
          // backdrop='static'
          className="p-0"
          show={showModal}
        >
          <Modal.Header closeButton className="border-bottom-0">
            <h2>{`Error`}</h2>
          </Modal.Header>
          <Modal.Body>
            {currentError && currentError.message}
          </Modal.Body>
          <Modal.Footer className="border-top-0">
            <Button
              className="font-weight-bold"
              variant="danger"
              onClick={onModalOkClick}
            >
              {`Ok`}
            </Button>
          </Modal.Footer>
        </Modal>
      </BrowserRouter>
    </AuthBoundary>
  );
};

export default Router;
