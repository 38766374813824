/* @flow */
import React, { useEffect, useMemo, useState, } from 'react';
import { getLocationQueryString, jwtDecoded, } from 'utilities/stringUtils';
import { useLocation, useHistory, } from 'react-router-dom';
import { Container, Alert, } from 'react-bootstrap';
import { useDispatch, } from 'react-redux';
import { UserToken } from 'domain/user';
import { Firm } from 'domain/firm';
import { UserActionCreators } from 'state/actions';
import { Loading } from 'view/components/Loading';
import { ERRORS } from 'constants/index';

const useConnect = () => {

    const dispatch = useDispatch();
    const mapDispatch = useMemo(() => ({
        setTokens: (payload: UserToken) => dispatch(UserActionCreators.setTokens(payload)),
        loadFirm: () => dispatch(UserActionCreators.getFirm({ thunk: true, })),
    }), [dispatch]);

    return {
        ...mapDispatch
    }
}

const StatementFromEmailVM = () => {
    const { setTokens, loadFirm } = useConnect();
    const [error, setError] = useState(null);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const checkGuestAccess = async () => {
            try {
                const { statementToken }: { statementToken: string } = getLocationQueryString(location.search);
                const { statementId, statementCreatedDate, accessibleClients }: StatementTokenData = jwtDecoded(statementToken);

                // set User data to re-use later
                const userTokenData: UserToken = { authToken: statementToken };
                await setTokens(userTokenData);

                // re-direct to login if Guest Access is not enabled for the firm
                const firm: Firm = await loadFirm();
                if (!firm?.portalSettings?.allowGuests) {
                    history.push('/login');
                }

                // re-direct to Statement PDF Page
                const url = { pathname: `/statement/${statementId}/${accessibleClients[0]}` }
                history.push(url, { statementCreatedDate: statementCreatedDate });

            } catch (error) {
                setError(ERRORS.statement_token);
            }
        }
        checkGuestAccess();
    }, [location, history, setTokens, loadFirm]);

    return (
        <Container>
            {error ? <Alert className='mt-4' variant='warning'>{error}</Alert> : <Loading />}
        </Container>
    )
}

type StatementTokenData = {
    statementId: number;
    statementCreatedDate: number; // in seconds
    projectId?: number;
    accessibleClients: number[];
    exp: number; // in seconds
    firmId: number;
}

export default StatementFromEmailVM;