/* @flow */
import { type ApiResponse, type ConduitApiService, } from 'infra/conduit/ApiService';

import { type AccountHistoryRepository, } from 'domain/accountHistory';

type Dependencies = {
  conduitApiService: ConduitApiService
};

export default ({ conduitApiService, }: Dependencies): AccountHistoryRepository => ({
  /**
   * request acocunt histories list of a client with auth credentials
   * 
   * @param {number} clientId - client id
   * @param {WithCurrentUserToken} params - user token
   */
  async getClientAccountHistories(clientId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}/accountHistory`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * request acocunt histories list of a client, project with auth credentials
   * 
   * @param {number} clientId - client id
   * @param {WithCurrentUserToken} params - user token
   */
  async getProjectAccountHistories(clientId, projectId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}/projects/${projectId}/accountHistory`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },
});
