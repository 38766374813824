// @flow
import * as R from 'ramda';

import { Button, Spinner, } from 'react-bootstrap';
import { type ClientPortalSettings, PAYMENT_OPTIONS, } from 'domain/firm';
import { type FormikActions, type FormikProps } from 'formik';
import { PaymentActionCreators, UserActionCreators, } from 'state/actions';
import React, { useCallback, useMemo, useState, } from 'react';

import { APP_CONFIG, } from 'constants/index';
import { Colors, } from 'assets';
import { openPopupWindow, } from 'utilities/generalUtils';
import { site_url, } from 'utilities/stringUtils';
import styled from 'styled-components';
import { useDispatch, } from 'react-redux';

const StyledButton = styled(Button)`
  background-color: ${Colors.blueButton} !important;
  border: none !important;

  :hover {
    background-color: ${Colors.blueButtonHover} !important;
  }
`;

const useConnect = () => {
  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    getSignupLink: (payload: string) => dispatch(PaymentActionCreators.paypalGetSignupLink(payload, { thunk: true, })),
    getFirm: () => dispatch(UserActionCreators.getFirm()),
  }), [ dispatch, ]);

  return {
    ...mapDispatch,
  };
};

type Props = {
  setErrors: Function,
  setMessage: Function,
  portalSettings: ClientPortalSettings,
  onStopPaymentClick: Function,
};

const PayPalTab = ({ 
  onStopPaymentClick, portalSettings, 
  setErrors,
}: Props) => {
  // connect redux
  const { getSignupLink, getFirm, } = useConnect();
 
  // Handle connect paypal
  const [ loading, setLoading, ] = useState(false);
  const onSubmit = useCallback(async () => {    
    try {
      setLoading(true);
      // Return setting url
      const returnUrl = site_url() + APP_CONFIG.redirectLink.integratePayment;
      const paypalUrl = await getSignupLink(returnUrl);

      // Open popup when getting paypal url is done in response
      const popup = openPopupWindow(paypalUrl, 'paypal-connect');

      if (!popup) return;
      // polling for popup window close
      const timer = setInterval(() => { 
        if(popup.closed) {
          clearInterval(timer);
          // update firm data
          getFirm();
        }
      }, 1000);

    } catch (e) {
      setErrors({
        auth: e.message || e,
      });
    } finally {
      setLoading(false);
    }
  }, [ getSignupLink, getFirm, setErrors, ]);

  // Open disconnect modal
  const onShowDisconnectModal = useCallback(() => {
    onStopPaymentClick(PAYMENT_OPTIONS.paypal.key);
  }, [ onStopPaymentClick, ]);

  return (
    <div className='connect-paypal-form'>
      {
        portalSettings && portalSettings.isPaypal ? (
          <StyledButton className='font-weight-bold' onClick={onShowDisconnectModal}>{`Disconnect From PayPal`}</StyledButton>
        ) : (
          <div className='d-flex flex-row align-items-center'>
            <StyledButton className='font-weight-bold' onClick={onSubmit}>{`Connect with PayPal`}</StyledButton>
            {
              loading && (
                <Spinner animation="border" className="ml-2" role="status" />
              )
            }
          </div>      
        )
      }
    </div>
  );
};

export default PayPalTab;
