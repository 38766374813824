import React, { useEffect } from 'react'
import styled from "styled-components";

const classes = {
    empty: 'field--empty',
    valid: 'field--valid',
    invalid: 'field--invalid',
    focused: 'field--focused',
    dirty: 'field--dirty',
    touched: 'field--touched'
};

const css = {
    "fontSize": "16px",
    "&:focus": {
        outline: "none"
    }
};

const StyledVGSMuiTextField = styled.div`
.form-control-static {
    height: 70px;
  }
  
  .field iframe {
    appearance: none;
    outline: none;
    height: 52px;
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px transparent;
    transition: box-shadow .25s, border-color .25s;
    font-size: 16px;
    padding: 0 15px;
  }
  
  .field {
    padding-top: 18px;
    position: relative;
    margin-bottom: 15px;
  }
  
  .field label {
    position: absolute;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    bottom: 21px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform-origin: 0 50%;
    cursor: text;
    pointer-events: none;
    transition-property: color, transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: transform;
  }
  
  .field.field--focused iframe {
    border-color: #3774D9;
    border-width: 2px;
  }
  
  .field.field--focused label,
  .field:not(.field--empty) label {
    color: #3774D9;
    background-color: white;
    transform: scale(0.85) translate(-3px, -22px);
    cursor: default;
    left: 5px;
    top: 27px;
    font-size: 14px;
    padding: 0 8px;
  }
`

export const VGS_TYPES = {
    CARD_NUMBER: "card-number",
    CARD_SECURITY_CODE: "card-security-code",
    CARD_EXPIRATION_DATE: "card-expiration-date"
}

const VGSMuiTextField = ({
    vgsForm,
    type,
    id,
    label,
    required,
    successColor,
    errorColor,
    showCardIcon,
    yearLength,
    maxLength,
    serializers,
    extraValidations }: Props) => {
    const validations = [];

    if (required) {
        validations.push('required');
    }

    if (type) {
        switch (type) {
            case VGS_TYPES.CARD_NUMBER:
                validations.push("validCardNumber");
                break;
            case VGS_TYPES.CARD_SECURITY_CODE:
                validations.push("validCardSecurityCode");
                break;
            case VGS_TYPES.CARD_EXPIRATION_DATE:
                validations.push("validCardExpirationDate");
                break;
        }
    }
    else {
        type = "text";
    }

    if(extraValidations) {
        extraValidations.forEach(validation => {
            validations.push(validation);
        });
    }

    useEffect(() => {
        vgsForm.field(`#${id}`, {
            type: type,
            name: `${id}`,
            validations: validations,
            successColor: successColor,
            errorColor: errorColor,
            showCardIcon: showCardIcon,
            yearLength: yearLength,
            maxLength: maxLength,
            serializers: serializers,
            classes: classes,
            css: css
        });
    }, [vgsForm])

    return (
        <StyledVGSMuiTextField className="form-group">
            <div className="form-control-static">
                <div id={id} className="field field--empty">
                    <label>
                        {label}
                        {required ? <span className="MuiFormLabel-asterisk"> *</span> : ""}
                    </label>
                </div>
            </div>
        </StyledVGSMuiTextField>
    )
}

export default VGSMuiTextField;