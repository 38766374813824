// @flow
import { createTransform, } from 'redux-persist';

export const mapTransformer = (config: Object) =>
  createTransform(
    map => {
      return JSON.stringify(Array.from(map || []));
    },
    arrayString => {
      return new Map(arrayString ? JSON.parse(arrayString) : arrayString);
    },
    config,
  );
