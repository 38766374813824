// @flow
import { type Invoice, invoiceAdditionalPaymentValidation, } from 'domain/invoice';
import React, { useCallback, } from 'react';

import { Field, } from 'formik';
import PaymentField from './field/PaymentField';

type Props = {
  className?: string,
  invoice: Invoice,
  rowIndex: number,
};

const InvoicePaymentField = ({
  invoice, rowIndex, ...restProps
}: Props) => {
  // payment validation
  // need to pass to formik field
  const paymentValidation = useCallback((value: number) => {
    let error;
    const isInvoicePaymentInvalid = invoiceAdditionalPaymentValidation(value);

    // 1. Value should be a valid amount number
    // 2. There must be a least one invoice, and at the same time it can't be zero.
    if (isInvoicePaymentInvalid || (!rowIndex && value === 0)) {
      error = 'Payment must be greater than 0.';
    }

    return error;
  }, []);

  return (
    <Field
      required
      component={PaymentField}
      name={`invoices[${rowIndex}].userPayment`}
      placeholder={'Payment'}
      style={{
        textAlign: 'right',
      }}
      validate={paymentValidation}
      remainingBalance={invoice.remainingBalance}
      {...restProps}
    />
  );
};

export default InvoicePaymentField;
