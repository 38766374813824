// @flow
import * as R from 'ramda';

import { type GetSingleInvoicePayload, type InvoiceMap, type getInvoicesPayload, } from 'state/invoice/types';
import { type Invoice, type InvoicePayment, } from 'domain/invoice';
import { type Client, getAcctIsoType, } from 'domain/client';
import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, useParams, } from 'react-router-dom';
import { type Firm, } from 'domain/firm';

import { type FormikActions, } from 'formik';
import { InvoiceActionCreators, } from 'state/actions';
import { type InvoicePaymentRouteParams, } from '../payment/methods/types';
import { type ClientMap, } from 'state/client/types';
import InvoicePaymentV from './InvoicePaymentV';
import { InvoiceRedux, ClientRedux, UserRedux, } from 'state/reducers';

// connect redux
const useConnect = () => {
  // mapState
  const invoicesMap: InvoiceMap = useSelector(R.pipe(InvoiceRedux.getReducerState, InvoiceRedux.selectors.getInvoices));
  const clientsMap: ClientMap = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getClients));
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));

  const mapState = {
    invoicesMap,
    clientsMap,
    firm,
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    getSingleInvoice: (payload: GetSingleInvoicePayload) => dispatch(InvoiceActionCreators.getSingleInvoice(payload, { thunk: true, })),
    fetchInvoices: (payload: getInvoicesPayload) => dispatch(InvoiceActionCreators.getInvoices(payload)),
  }), [dispatch,]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/**
 * get list invoice and handle local invoices state hook
 */
const useInvoicePaymentBalance = ({
  invoiceId,
  invoiceItem,
  clientId,
  clientsMap,
  projectId,
  invoicesMap,
  fetchInvoices,
  firm,
}: {
  invoiceId?: number,
  invoiceItem?: Invoice,
  clientId: number,
  clientsMap: ClientMap,
  projectId: number,
  invoicesMap: InvoiceMap,
  fetchInvoices: (payload: getInvoicesPayload) => void,
  firm: Firm,
}): {
  invoices: Array<Invoice>,
} => {
  // load invoices by client and project id
  // transform invoice, payment map to value array
  const listInvoice = useMemo(() => {
    return Array.from(invoicesMap.values());
  }, [invoicesMap,]);

  // fetch invoices when client / project id change
  useEffect(() => {
    // don't request anything if has invoice id
    if (invoiceId) { return; }

    const selectedClient: Client = clientsMap && clientsMap.get(Number(clientId));

    const payload: getInvoicesPayload = {
      clientId,
      projectId,
      acctIsoType: getAcctIsoType(firm, selectedClient),
    };

    fetchInvoices(payload);
  }, [clientId, projectId, fetchInvoices, invoiceId, clientsMap, firm,]);

  // return single invoice array if has invoice item
  if (invoiceItem) {
    return {
      invoices: [invoiceItem,],
    };
  }

  return {
    invoices: listInvoice,
  };
};

/**
 * hook handle loading single invoice
 *
 */
const useSingleInvoice = ({
  invoiceId,
  clientId,
  invoicesMap,
  getSingleInvoice,
  setError,
  setLoading,
}: {
  invoiceId: number,
  clientId: number,
  invoicesMap: InvoiceMap,
  getSingleInvoice: Function,
  setError: Function,
  setLoading: Function,
}): {
  invoiceItem?: Invoice,
} => {
  // load single invoice
  useEffect(() => {
    setError(undefined);

    const getInvoiceItem = async () => {
      // don't request anything if no invoice id
      if (!invoiceId) { return; }

      try {
        setLoading(true);

        const payload: GetSingleInvoicePayload = {
          clientId,
          invoiceId,
        };
        await getSingleInvoice(payload);
      } catch (e) {
        setError(e.message || e);
      } finally {
        setLoading(false);
      }
    };

    getInvoiceItem();
  }, [clientId, getSingleInvoice, invoiceId, setError, setLoading,]);

  // get invoice from invoice map
  const invoiceItem = useMemo<Invoice | undefined>(
    () => invoicesMap.get(parseInt(invoiceId)),
    [invoicesMap, invoiceId,]
  );

  // return undefined if no invoice id
  if (!invoiceId) {
    return {
      invoiceItem: undefined,
    };
  }

  return {
    invoiceItem,
  };
};

/**
 * Main component
 *
 * @returns
 */
const InvoicePaymentVM = () => {
  const {
    invoicesMap,
    clientsMap,
    fetchInvoices,
    getSingleInvoice,
    firm,
  } = useConnect();

  // get url params client id, project id / invoice id
  const { clientId, projectId, invoiceId, } = useParams();

  // error and loading state
  const [error, setError,] = useState<string | undefined>(undefined);
  const [loading, setLoading,] = useState<boolean>(false);

  const { invoiceItem, } = useSingleInvoice({
    invoiceId,
    clientId,
    invoicesMap,
    getSingleInvoice,
    setError,
    setLoading,
  });

  const {
    invoices,
  } = useInvoicePaymentBalance({
    invoiceId,
    invoiceItem,
    clientId,
    clientsMap,
    projectId,
    invoicesMap,
    fetchInvoices,
    firm,
  });

  const history = useHistory();

  /**
   * on pay form submit
   * 
   * @param {UserAuthInfo} values - auth info values from form
   * @param {FormikActions} actions - formik bag actions
   */
  const onSubmit = useCallback(async (values: InvoicePayment, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);

      const params: InvoicePaymentRouteParams = {
        clientId: Number(clientId),
        projectId: Number(projectId),
        cart: values.invoices,
        amount: values.total,
      };
      
      const isHeadnote: boolean = R.path(['portalSettings', 'isHeadnote'], firm);
      if (isHeadnote) {
        params.firstName = values.firstName;
        params.lastName = values.lastName;
      }
      // nav to payment method screen with user edited invoice payments
      history.push({
        pathname: '/payment-methods',
        state: params,
      });
      // TODO: handle continue case
    } catch (e) {
      // handle error
      actions.setErrors({
        auth: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [clientId, history, projectId,]);


  return (
    <InvoicePaymentV
      error={error}
      invoices={invoices}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
};

export default InvoicePaymentVM;
