/* @flow */
import { type Reducer, applyMiddleware, compose, createStore, } from 'redux';
import { isPersistActive, persistConfig, } from './_reduxPersist/persistConfig';
import { persistReducer, persistStore, } from 'redux-persist';

import container from 'container';
import createSagaMiddleware from 'redux-saga';
import { middleware as thunkMiddleware, } from 'redux-saga-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// creates the store
export default (rootReducer: Reducer, rootSaga: Generator<any, any, any>) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [ thunkMiddleware, ];
  const enhancers = [];

  /* ------------- Saga Middleware ------------- */

  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);
  // middleware.push(GraphQLClient.middleware())

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware));

  /* ------------- createStore ------------- */

  const store = createStore(
    isPersistActive
      ? persistReducer(persistConfig, rootReducer)
      : rootReducer,
      composeEnhancers(...enhancers)
  );

  const persistor = isPersistActive
    ? persistStore(store)
    : null;

  // kick off root saga
  // sagaMiddleware.run(rootSaga, getFirebase)
  sagaMiddleware.run(rootSaga, container);

  return { store, persistor, };
};
