// @flow
import * as R from 'ramda';

import { type AccountBalance, type AcctIsoType, type Client, getAcctIsoType, } from 'domain/client';
import { ClientActionCreators, InvoiceActionCreators, PaymentActionCreators, RetainerActionCreators, } from 'state/actions';
import { type ClientMap, type getAccountBalancePayload, } from 'state/client/types';
import { type ClientPortalSettings, type Firm, } from 'domain/firm';
import { ClientRedux, InvoiceRedux, PaymentRedux, UserRedux, RetainerRedux } from 'state/reducers';
import { type GetPaymentsPayload, type PaymentMap, } from 'state/payment/types';
import { type InvoiceMap, type getInvoicesPayload, } from 'state/invoice/types';
import { type RetainerMap, type GetRetainersPayload, } from 'state/retainer/types';
import { type ProjectMap, type getProjectsPayload, } from 'state/project/types';
import React, { useCallback, useEffect, useMemo, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import HomePageV from './HomePageV';
import { Project, } from 'domain/project';
import { type User, } from 'domain/user';
import { getAdjacentUrlParams, } from 'utilities/stringUtils';
import { useClientProjectList, } from 'view/hooks';
import { useHistory, } from 'react-router-dom';

// connect redux
const useConnect = () => {
  // mapState
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const user: User  = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const accountBalance: AccountBalance = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getAccountBalance));
  const paymentsMap: PaymentMap = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getPayments));
  const invoicesMap: InvoiceMap = useSelector(R.pipe(InvoiceRedux.getReducerState, InvoiceRedux.selectors.getInvoices));
  const retainersMap: RetainerMap = useSelector(R.pipe(RetainerRedux.getReducerState, RetainerRedux.selectors.getRetainers));
  const headnoteApplication = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getHeadnoteApplication));

  const mapState = {
    firm,
    user,
    accountBalance,
    paymentsMap,
    invoicesMap,
    retainersMap,
    headnoteApplication
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    fetchAccountBalance: (payload: getAccountBalancePayload) => dispatch(ClientActionCreators.getAccountBalance(payload)),
    fetchPayments: (payload: getPaymentsPayload) => dispatch(PaymentActionCreators.getPayments(payload)),
    fetchInvoices: (payload: getInvoicesPayload) => dispatch(InvoiceActionCreators.getInvoices(payload)),
    fetchRetainers: (payload: GetRetainersPayload) => dispatch(RetainerActionCreators.getRetainers(payload)),
    fetchHeadnoteApplication: (): Promise<any> => dispatch(PaymentActionCreators.getHeadnoteApplication({ thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/**
 * hook handle loading account balance, invoices and payments when selected client / project update
 */
const usePaymentBalance: ({
  paymentsMap: PaymentMap,
  invoicesMap: InvoiceMap,
  retainersMap: RetainerMap,
  selectedClient: Client,
  selectedProject: Project,
  fetchAccountBalance: (payload: getAccountBalancePayload) => void,
  fetchPayments: (payload: getPaymentsPayload) => void,
  fetchInvoices: (payload: getInvoicesPayload) => void,
  fetchRetainers: (payload: GetRetainersPayload) => void,
  firm: Firm,
}) => Object = ({
  invoicesMap,
  retainersMap,
  paymentsMap,
  selectedClient,
  selectedProject,
  fetchAccountBalance,
  fetchInvoices,
  fetchRetainers,
  fetchPayments,
  firm,
}) => {
  // transform invoice, payment map to value array
  const listInvoice = useMemo(() => {
    return Array.from(invoicesMap.values());
  }, [ invoicesMap, ]);

    // transform retainer, payment map to value array
    const listRetainer = useMemo(() => {
      return Array.from(retainersMap.values());
    }, [ retainersMap, ]);

  const listPayment = useMemo(() => {
    return Array.from(paymentsMap.values());
  }, [ paymentsMap, ]);
  
  // check if has unpaid invoice base on paid status
  const hasUnpaidInvoice = useMemo(() => {
    if (!listInvoice) {return false;}

    return listInvoice.some(i => {
      return i.isPaid === false && i.invoiceBalance > 0;
    });

  }, [ listInvoice, ]);

  // fetch account balance, invoices and payments of client, project when selected client / project update
  useEffect(() => {
    if (selectedClient) {
      const payload: getInvoicesPayload | GetPaymentsPayload | getAccountBalancePayload | GetRetainersPayload = {
        clientId: selectedClient.id,
        acctIsoType: getAcctIsoType(firm, selectedClient),
        projectId: selectedProject ? selectedProject.id : undefined,
      };

      fetchInvoices(payload);
      fetchRetainers(payload);
      fetchPayments(payload);
      fetchAccountBalance(payload);
    }
  }, [ selectedClient, selectedProject, fetchAccountBalance, fetchInvoices, fetchRetainers, fetchPayments, firm, ]);

  return {
    listInvoice,
    listRetainer,
    listPayment,
    hasUnpaidInvoice,
  };
};

/**
 * main component
 *
 * @returns
 */
const HomePageVM = () => {
  const {
    firm,
    user,
    accountBalance,
    invoicesMap,
    retainersMap,
    paymentsMap,
    headnoteApplication,
    fetchAccountBalance,
    fetchInvoices,
    fetchRetainers,
    fetchPayments,
    fetchHeadnoteApplication
  } = useConnect();

  const {
    loadingClient,
    loadingProject,
    acctIsoType,
    listClient,
    listProject,
    selectedClient,
    selectedProject,
    setSelectedClient,
    setSelectedProject,

    getClientDropDownLabel,
    getClientOptionValue,
    getClientOptionLabel,

    getProjectDropDownLabel,
    getProjectOptionValue,
    getProjectOptionLabel,
  } = useClientProjectList();

  const {
    listInvoice,
    listRetainer,
    listPayment,
    hasUnpaidInvoice,
  } = usePaymentBalance({
    accountBalance,
    invoicesMap,
    retainersMap,
    paymentsMap,
    selectedClient,
    selectedProject,
    fetchAccountBalance,
    fetchInvoices,
    fetchRetainers,
    fetchPayments,
    firm,
  });

  // react router history object
  const history = useHistory();

  /**
   * handle pay now button press
   * nav to invoice payment screen
   */
  const onPayNow = useCallback(() => {
    const clientId = selectedClient ? selectedClient.id : '';
    const projectId = selectedProject ? selectedProject.id : '';

    // do nothing if no client selected
    if (!clientId) {return;}

    // nave to invoice payment page with selected client and project id
    const url = `/invoice-payment/${clientId}${getAdjacentUrlParams(projectId)}`;

    history.push(url);
  }, [ selectedClient, selectedProject, history, ]);

  /**
   * Check if firm has integration payment
   */
  const noPayment = useMemo(() => {
    const { isLawpay, isPaypal, isStripe, isCustom, isHeadnote } = firm.portalSettings;
    const isHeadnoteActive = isHeadnote && headnoteApplication.status === 'VERIFIED';
    if (!isLawpay && !isPaypal && !isStripe && !isCustom && !isHeadnoteActive) {
      return true;
    }
    return false;
  }, [ firm, headnoteApplication]);

  // Check hiding account history
  const isHideAccounting = useMemo(() => R.path([ 'portalSettings', 'hideAccounting', ], firm), [ firm, ]);

  useEffect(() => {
    if (firm && firm.portalSettings.isHeadnote) {
      fetchHeadnoteApplication();
    }
  }, [firm])

  return (
    <HomePageV
      accountBalance={accountBalance}
      acctIsoType={acctIsoType}
      firm={firm}
      getClientDropDownLabel={getClientDropDownLabel}
      getClientOptionLabel={getClientOptionLabel}
      getClientOptionValue={getClientOptionValue}
      getProjectDropDownLabel={getProjectDropDownLabel}
      getProjectOptionLabel={getProjectOptionLabel}
      getProjectOptionValue={getProjectOptionValue}
      groupClient={listClient}
      groupProject={listProject}
      hasUnpaidInvoice={hasUnpaidInvoice}
      invoices={listInvoice}
      retainers={listRetainer}
      isHideAccounting={isHideAccounting}
      loadingClient={loadingClient}
      
      loadingProject={loadingProject}
      noPayment={noPayment}
      payments={listPayment}

      selectedClient={selectedClient}
      selectedProject={selectedProject}
      user={user}
      onPayNow={onPayNow}
      onSelectClient={setSelectedClient}
      onSelectProject={setSelectedProject}
    />
  );
};

export default HomePageVM;
