// @flow
import React from 'react';
import Col from 'react-bootstrap/Col';
import { Colors, } from 'assets/index';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertSystemDateFormat } from 'domain/firm';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { faPrint, } from '@fortawesome/free-solid-svg-icons';
import B4TPDFViewer from 'view/reusableComponents/b4tPdfViewer';
import styled from 'styled-components';

const StyledPrintButton = styled.a`
  padding-left: 5px;
  padding-right: 5px;
  color: ${Colors.greyLight};
  font-size: 15pt;
  text-decoration: none;
  &:hover {
    color: ${Colors.charcoal};
  }
`;

const StatementV = (options: StatementViewOptions) => {
    const { dateFormat, statementDate, statementLink, isLoading, error } = options;

    return (
        <Container className='py-3 d-flex' style={{ flex: 1 }}>
            <div className='d-flex flex-column' style={{ flex: 1 }}>
                <div className='py-3'>
                    <h2 style={{ fontSize: '30px' }}>Statement Date {convertSystemDateFormat(statementDate, dateFormat)}</h2>
                </div>

                <Row style={{ flex: 1 }}>
                    <Col lg={12}>
                        {isLoading ?
                            <Spinner animation='border' role='status' /> :
                            <>
                                <div className='text-right'>
                                    <StyledPrintButton href={`${statementLink}`} target='_blank' title='Print as PDF'>
                                        <FontAwesomeIcon icon={faPrint} />
                                    </StyledPrintButton>
                                </div>
                                <>
                                    {error ?
                                        <div>{error}</div> :
                                        <>
                                            <B4TPDFViewer pdfLocation={statementLink} />
                                        </>
                                    }
                                </>
                            </>
                        }
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

type StatementViewOptions = {
    dateFormat: string;
    statementDate: string;
    statementLink: string;
    isLoading: boolean;
    error: string;
    // ...
}

export default StatementV;