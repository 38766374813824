/* @flow */
import { type InvoicePayment, type PaymentRepository, } from 'domain/payment';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (Array<InvoicePayment>) => void,
  onInvalidPayload: (Error) => void,
  onError: (Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
};

type Payload = {
  clientId: number,
  projectId: ?number,
};

export type GetInvoicePaymentsBehaviour = (
  Payload,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { paymentRepository, }: Dependencies, ) => {
  /**
   * get list payments of a client or project behaviour
   * 
   * @param {Payload} payload - client id and project id
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getInvoicePaymentsBehaviour: GetInvoicePaymentsBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { invoiceId, } = payload;
      if (!invoiceId) {
        return onInvalidPayload(new Error('Invalid invoice id.'));
      }

      let payments;

      payments = await paymentRepository.getInvoicePayments(invoiceId, withCurrentUserToken);

      return onSuccess(payments);
    } catch(error) {
      return onError(error);
    }
  };

  return getInvoicePaymentsBehaviour;
};
