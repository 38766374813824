/* @flow */
import { type ApiResponse, type ConduitApiService, } from 'infra/conduit/ApiService';

import { type InvoiceRepository, type Invoice, } from 'domain/invoice';

type Dependencies = {
  conduitApiService: ConduitApiService
};

type GetInvoiceTokenBody = {
  invoice: {
    id: number,
    clientId: number,
    createdDate: string
  }
}

export default ({ conduitApiService, }: Dependencies): InvoiceRepository => ({
  /**
   * request invoices list of a client, project with auth credentials
   * 
   * @param {number} clientId - client id
   * @param {WithCurrentUserToken} params - user token
   */
  async getClientInvoices(clientId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}/invoices`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * request invoices list of a client, project with auth credentials
   * 
   * @param {number} clientId - client id
   * @param {number} projectId - project id
   * @param {WithCurrentUserToken} params - user token
   */
  async getProjectInvoices(clientId, projectId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}/projects/${projectId}/invoices`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * request a single invoice of a client with auth credentials
   * 
   * @param {number} clientId - client id
   * @param {number} invoiceId - invoice id
   * @param {WithCurrentUserToken} params - user token
   */
  async getSingleInvoice(clientId, invoiceId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}/invoices/${invoiceId}`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    // server will return an array with 1 invoice
    // | 200 | { "success" : IInvoice[] } |
    return res.success[0];
  },

  /**
   * request a token of invoice with auth credentials
   * 
   * @param {GetInvoiceTokenBody} data - invoice infor
   * @param {WithCurrentUserToken} params - user token
   */
  async getInvoiceToken(invoice: Invoice, { currentUserToken, }) {
    const data : GetInvoiceTokenBody = {
      invoice: {
        id: invoice.id,
        clientId: invoice.clientId,
        createdDate: invoice.createdDate,
      },
    };

    const res: ApiResponse = await conduitApiService.authPost({
      url: `tokens/session/invoice`,
      userToken: currentUserToken,
      data: data,
    });

    if (res.error) {throw res.error;}

    // server will return a token 
    // | 200 | { "success" : string } |
    return res.success;
  },
});
