// @flow
import Container from 'react-bootstrap/Container';
import { type Invoice, } from 'domain/invoice';
import InvoicesForm from './components/InvoicesForm';
import Media from 'react-bootstrap/Media';
import React from "react";
import styled from "styled-components";

type Props = {
  error: string | undefined,
  loading: boolean,
  invoices: Array<Invoice>,
  onSubmit: Function,
}

const StyledContainer = styled(Container)`
  flex: 1;
`;

const InvoicePaymentV = ({
  invoices,
  onSubmit,
}: Props) => {

  return (
    <StyledContainer className="my-4">
      <div className="container">
        <Media className="mb-1">
          <Media.Body>
            <h4>{`Pay Now`}</h4>
          </Media.Body>
        </Media>

        <InvoicesForm
          invoices={invoices}
          onSubmit={onSubmit}
        />
      </div>
    </StyledContainer>
  );
};

export default InvoicePaymentV;
