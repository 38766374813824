// @flow
import React from 'react';

import { type FieldProps, } from 'formik';
import FormControl from 'react-bootstrap/FormControl';

export default ({
  field, form: { touched, errors, },
  ...restProps
}: FieldProps<any> & CustomGroupProps) => {
  // field states:
  const _error = errors[field.name];
  const _touched = touched[field.name];

  return (
    <FormControl
      {...field}
      {...restProps}
      isInvalid={_error && _touched}
    />
  );
};
