/* @flow */
import { type RetainerRequestPayments, type RetainerRepository, } from 'domain/retainer';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
    onSuccess: (i: RetainerRequestPayments) => void,
    onInvalidPayload: (e: Error) => void,
    onError: (e: Error) => void,
  };

type Dependencies = {
    retainerRepository: RetainerRepository
};

type Payload = {
    clientId: number,
    retainerId: number
};

export type GetSingleRetainerBehaviour = (
    Payload,
    WithCurrentUserToken,
    Callbacks
 ) => any

export default ( { retainerRepository }: Dependencies ) => {
    /**
     * Get single retainers of a firm behaviour
     * @param {Payload} payload - client id and retainer id
     * @param {WithCurrentUserToken} withCurrentUserToken - auth token
     * @param {Callbacks} callbacks - call back for each use case
     */
    const getSingleRetainerBehaviour: GetSingleRetainerBehaviour = async (
        payload,
        withCurrentUserToken,
        { onSuccess, onError, onInvalidPayload, }
    ) => {
        try {
            const {clientId, retainerId } = payload            
            if (!clientId || !retainerId) {
                return onInvalidPayload(new Error('Invalid client and retainer id'));
            }

            const retainer = await retainerRepository.getRetainerAmounts(retainerId, clientId, withCurrentUserToken)
            return onSuccess(retainer);
            
        } catch (e) {
            return onError(e);
        }
    }
    return getSingleRetainerBehaviour;
};