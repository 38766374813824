// @flow
import { APP_CONFIG, } from 'constants/index';

/**
 * check if current row is last row
 *
 * @param {number} index: current row index
 * @param {number} dataLen: total rows length
 * @returns {boolean} is last row
 */
export const isLastRow = (index: number, dataLen: number): boolean => {
  return index === dataLen - 1;
};

/**
 * check if current window is popup window
 * ONLY work with same domain popup window
 *
 * @returns {boolean} is popup window
 */
export const isPopupWindow = (): boolean => {
  return window.opener || window.history.length === 1;
}

/**
 * open popup window with configured size 
 *
 * @param {*} url
 * @param {string} [target]
 * @param {string} [feature]
 * @param {boolean} [replace]
 * @returns {(Window | null)}
 */
export const openPopupWindow = (url, target?: string, feature?: string, replace?: boolean): Window | null => {
  const width = APP_CONFIG.popUpWindowWidth; // height has same value
 
  const left = (window.screen.width / 2) - (width / 2);
  const top = (window.screen.height / 2) - (width/ 2);

  return window.open(url, target,`width=${width}, height=${width}, top=${top}, left=${left} ${feature || ''}`, replace);
}
