// @flow
import Container from 'react-bootstrap/Container';
import { /*type,*/ RetainerRequestPayments } from 'domain/retainer';
import RetainerForm from './components/RetainersForm';
import Media from 'react-bootstrap/Media';
import React from "react";
import styled from "styled-components";

type Props = {
    error: string | undefined,
    loading: boolean,
    retainers: Array<RetainerRequestPayments>,
    onSubmit: Function,
  }

  const StyledContainer = styled(Container)`
  flex: 1;
`;

const RetainerPaymentV = ({
    retainers,
    onSubmit
}: Props) => {
    
    return (
        <StyledContainer className="my-4">
          <div className="container">
            <Media className="mb-1">
              <Media.Body>
                <h4>{`Pay Request Now`}</h4>
              </Media.Body>
            </Media>
    
            <RetainerForm
              retainers={retainers}
              onSubmit={onSubmit}
            />
          </div>
        </StyledContainer>
      );
};

export default RetainerPaymentV;