/* @flow */
import { createActions, } from 'reduxsauce';
import { reducerPrefixFormat, } from '../common';

/* ------------- Invoice ------------- */
export const { Types, Creators, } = createActions({
  getAccountHistories: [ 'payload', 'meta', ],

  getAccountHistoriesSuccess: [ 'payload' /* GetAccountHistoriesResponsePayload */, 'meta', ],

  requestFailure: [ 'error', 'payload' /* error payload */, 'meta', ],
}, {
  prefix: reducerPrefixFormat('accountHistory'),
});
