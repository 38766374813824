/* @flow */
import { type CardData, type Payment, type PaymentRepository, } from 'domain/payment';

import { type Firm, } from 'domain/firm';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (bankAccounts: Array<CardData>) => void,
  onError: (e: Error) => void,
  onInvalidPayload: (e: Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
};

export type GetAllCreditCardsBehaviour = (
  firm: Firm,
  tokens: WithCurrentUserToken,
  cb: Callbacks
) => any

export default ( { paymentRepository, }: Dependencies, ) => {
  /**
   * get list cards of user ( stripe, lawpay, or headnote )
   * 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getAllCreditCardsBehaviour: GetAllCreditCardsBehaviour = async (
    firm,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      let request: Promise<Array<BankAccountData>>;
      // request paypal / stripe / headnote cards based on user settings
      if (firm.portalSettings.isHeadnote) {
        request = paymentRepository.getHeadnoteCards(withCurrentUserToken);
      } else if (firm.portalSettings.isStripe) {
        request = paymentRepository.getStripeCards(withCurrentUserToken);
      } else if (firm.portalSettings.isLawpay) {
        request = paymentRepository.getLawpayCards(withCurrentUserToken);
      } else {
        // return invalid error if firm portal settings doesn't
        // have stripe, lawpay or headnote integrated
        console.log(1)
        return onInvalidPayload(new Error('Invalid payment type.'));
      }
      
      const bankAccounts = await request;

      return onSuccess(bankAccounts);
    } catch(error) {
      return onError(error);
    }
  };

  return getAllCreditCardsBehaviour;
};
