/* @flow */
import * as R from 'ramda';

import React, {
  type ComponentType,
  type FC,
  useEffect,
} from 'react';
import {Redirect, Route,} from 'react-router-dom';
import {useAuthenticated, useTokenAuthenticated,} from 'view/hooks';
import {titleFormat,} from 'utilities/stringUtils';
import {Helmet,} from 'react-helmet';
import Layout from '../layout/Layout';

type Props = {
  component: ComponentType<any>,
  [string]: any,
};

/**
 * Private only route
 * check if user logged in
 *
 * @param {Props} { component: Component, ...props }
 * @returns
 */
export const PrivateRoute: FC<Props> = ({component: Component, ...props}) => {
  const isAuthenticated = useAuthenticated();


  useEffect(() => {
    document.title = props.title || '';
  }, [ props.title, ]);


  return (
    <Route
      {...props}
      // eslint-disable-next-line react/jsx-no-bind
      render={(matchProps) =>
        isAuthenticated ? (
          <Layout>
            <Helmet>
              <title>{titleFormat(props.title)}</title>
            </Helmet>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

/**
 * Private Token only route
 * check if has token set
 *
 * @param {Props} { component: Component, ...props }
 * @returns
 */
export const TokenPrivateRoute: FC<Props> = ({
  component: Component,
  ...props
}) => {
  const isAuthenticated = useTokenAuthenticated();

  return (
    <Route
      {...props}
      // eslint-disable-next-line react/jsx-no-bind
      render={(matchProps) =>
        isAuthenticated ? (
          <Layout>
            <Helmet>
              <title>{titleFormat(props.title)}</title>
            </Helmet>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

/**
 * Private Token only route
 * check if has token set
 *
 * @param {Props} { component: Component, ...props }
 * @returns
 */
export const TokenPrivateRouteWithoutLayout: FC<Props> = ({
  component: Component,
  ...props
}) => {
  const isAuthenticated = useTokenAuthenticated();

  return (
    <Route
      {...props}
      // eslint-disable-next-line react/jsx-no-bind
      render={(matchProps) =>
        isAuthenticated ? (
          <Component {...matchProps} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

/**
 * Public only route
 * check if user not logged in
 *
 * @param {Props} { component: Component, ...props }
 * @returns
 */
export const PublicOnlyRoute: FC<Props> = ({
  component: Component,
  ...props
}) => {
  const isAuthenticated = useAuthenticated();

  return (
    <Route
      {...props}
      // eslint-disable-next-line react/jsx-no-bind
      render={(matchProps) =>
        !isAuthenticated ? (
          <Layout>
            <Helmet>
              <title>{titleFormat(props.title)}</title>
            </Helmet>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

/**
 * Normal route
 *
 * @param {Props} { component: Component, ...props }
 * @returns
 */
export const NormalRouter: FC<Props> = ({component: Component, ...props}) => {
  return (
    <Route
      {...props}
      // eslint-disable-next-line react/jsx-no-bind
      render={(matchProps) => (
        <Layout>
          <Helmet>
            <title>{titleFormat(props.title)}</title>
          </Helmet>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};
