// @flow
import { Colors, } from 'assets/index';
import { NavLink, Link, } from 'react-router-dom';
import React from 'react';
import Table from './components/Table';
import styled from 'styled-components';
import { Alert, Container, Row, Spinner, Col, Button, } from 'react-bootstrap';

type Props = {
  data: Array<any>,
  onDelete: Function,
  loading: boolean,
  error: Object,
  messages: Object,
};

const StyledContainer = styled(Container)`
  color: ${Colors.charcoal};
  flex: 1;
`;

const StyledText = styled.p`
  font-size: .75rem;
  color: ${Colors.greyEmpress};
`;

const StyledNavLink = styled(NavLink)`
  color: ${Colors.greyEmpress} !important;
  text-decoration: underline;
`;

const StyledAlertSuccess = styled(Alert)`
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
  font-size: 14px;
`;

const InvitesListV = (props: Props) => {
  return (
    <StyledContainer>
      <h3>{`Sent Invites`}</h3>
      <StyledText>{`An invitation will expire after 30 days of inactivity. If multiple invites are sent to the same email address, only the most recent one will be valid.`}</StyledText>
      <StyledText>
        {`If you have allowed access to the wrong client, make all changes in `}
        <StyledNavLink to={'/settings/permissions'}>{`User Permissions.`}</StyledNavLink>
        {` If the the invitation recipient has not accepted your invitation, send a new invite with the corrected client(s). This action will cancel the previous invite.`}
      </StyledText>
      {
        props.messages && props.messages.msg && (
          <StyledAlertSuccess variant='success'>{props.messages.msg}</StyledAlertSuccess>
        )
      }
      {
        props.error && props.error.auth && (
          <Alert className="font-weight-bold" variant='danger'>{props.error.auth}</Alert>
        )
      }
      <Container className="overflow-auto">
        {props.loading ? <Spinner animation="border" /> : <Table {...props} />}
        {!props.loading && props.data.length === 0 && <h3>{`No outstanding invites`}</h3>}
        <Row className="justify-content-center">
          <Col className="text-right" md="9">
            <Link to="/settings/invites">
              <Button size="lg" variant="link">{`Back`}</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </StyledContainer>
  );
};

export default InvitesListV;
