/* @flow */
import { createActions, } from 'reduxsauce';
import { reducerPrefixFormat, } from '../common';

/* ------------- Client ------------- */
export const { Types, Creators, } = createActions({
  getClients: [ 'payload', 'meta', ],
  getClientsSuccess: [ 'payload' /* GetClientsResponsePayload */, 'meta', ],

  getSingleClient: [ 'payload', 'meta', ],
  getSingleClientSuccess: [ 'payload' /* GetSingleClientResponsePayload */, 'meta', ],

  getAccountBalance: [ 'payload', 'meta', ],
  getAccountBalanceSuccess: [ 'payload' /* GetAccountBalanceSuccessResponsePayload */, 'meta', ],

  setSelectedClient: [ 'payload', ],

  requestFailure: [ 'error', 'payload' /* error payload */, 'meta', ],

  getClientHeadnoteInfo: ['payload', 'meta'],
  getClientHeadnoteInfoSuccess: ['payload' /* GetClientHeadnoteInfoSuccessPayload */, 'meta']
}, {
  prefix: reducerPrefixFormat('Client'),
});
