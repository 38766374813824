/* @flow */
import { type UserResetCredentials, type UserNewPasswordCredentials, type UserRepository, } from 'domain/user';

type Callbacks = {
  onSuccess: (string) => void,
  onError: (Error) => void,
};

type Dependencies = {
  userRepository: UserRepository,
};

export type ResetBehaviour = (
  UserResetCredentials,
  Callbacks
) => any

export type NewPasswordBehaviour = (
  UserNewPasswordCredentials,
  Callbacks
) => any

export const makeResetRequest = ( { userRepository, }: Dependencies, ) => {
  /**
   * reset password behaviour
   * 
   * @param {UserResetCredentials} email - email for reset request
   * @param {Callbacks} callbacks - call back for each use case
   */
  const resetRequest: ResetBehaviour = async ( 
    resetCredentials: UserResetCredentials, 
    { onSuccess, onError, }: Callbacks, 
  ) => {
    try {
      const message: string = await userRepository.resetRequest(resetCredentials);

      return onSuccess(message);
    } catch(error) {
      return onError(error);
    }
  };
  return resetRequest;
};

export const makeResetPassword = ( { userRepository, }: Dependencies, ) => {
  /**
   * reset password behaviour
   * 
   * @param {UserNewPasswordCredentials} password - user auth info
   * @param {Callbacks} callbacks - call back for each use case
   */
  const resetRequest: NewPasswordBehaviour = async ( 
    newPasswordCredentials: UserNewPasswordCredentials, 
    { onSuccess, onError, }: Callbacks, 
  ) => {
    try {
      const message: string = await userRepository.resetPassword(newPasswordCredentials);
      return onSuccess(message);
    } catch(error) {
      return onError(error);
    }
  };
  return resetRequest;
};
