import Card from 'react-bootstrap/Card';
import ChangePermissionForm from './_components/ChangePermissionsForm';
import { Colors, } from 'assets/index';
import Container from 'react-bootstrap/Container';
import React from 'react';
import styled from 'styled-components';

type Props = {
};

const StyledContainer = styled(Container)`
  color: ${Colors.charcoal};
  flex: 1;
`;

const UserPermissionsV = (props: Props) => {
  return (
    <StyledContainer>
      <h3>{`User Permissons`}</h3>
      <p className="m-0">{`Give users access to your Bill4Time client accounts, allowing them to view invoices, make payments and track their account history.`}</p>
      <Card border="light" className="px-3">
        <Card.Header className="px-0">
          <h5>{`Change User Permissions`}</h5>
        </Card.Header>
        <Card.Body>
          <ChangePermissionForm />
        </Card.Body>
      </Card>
    </StyledContainer>
  );
};

export default UserPermissionsV;
