import { type WithCurrentUserToken, } from 'domain/user';
import { type FirmStatusInfo, } from 'domain/firm';
import { type FirmRepository } from 'domain/firm';

type Callbacks = {
    onSuccess: (firmStatusInfo: FirmStatusInfo) => void,
    onError: (e: Error) => void,
};

type Dependencies = {
    firmRepository: FirmRepository,
};

export type GetFirmStatusInfoBehaviour = (
    tokens: WithCurrentUserToken,
    cbs: Callbacks
) => any


export default ( { firmRepository, }: Dependencies, ) => {
    const getFirmStatusInfoBehaviour: GetFirmStatusInfoBehaviour = async (
        withCurrentUserToken,
        { onSuccess, onError, },
      ) => {
        try {
            // get firm features
            const firmStatusInfo = await firmRepository.getFirmStatusInfo(withCurrentUserToken);
      
            return onSuccess(firmStatusInfo);
          } catch(error) {
            return onError(error);
          }
    };
    return getFirmStatusInfoBehaviour;
};