/* @flow */
import * as Yup from 'yup';

import { type AcctIsoType, type Client, } from '../client';
import { type FirmUpdateRequestBody, type FirmUpdateUserRequestBody, } from 'infra/firm/FirmRepository';
import { type User, type WithCurrentUserToken, } from '../user';
import { type PaymentProcessorType } from "../payment";

import { APP_CONFIG, } from 'constants/index';
import moment from 'moment';
import 'moment-timezone';

export type FirmStripeInfo = {
  accessToken: string,
  livemode: boolean,
  refreshToken: string,
  tokenType: string,
  stripePublishableKey: string,
  stripeUserId: string,
  scope: string,
  allowGuests?: boolean, // allowGuests field return here when request firm data without auth
}

export type LawPaySettings = {
  firmId: number,
  currency: string,
  id: string,
  name: string,
  publicKey: string,
  recurringChargesEnabled: boolean,
  secretKey: string,
  trustAccount: boolean,
  type: string,
}

export type PayPalSettings = {
  firmId: number,
  merchantId: string;
}

export type CustomSettings = {
  firmId: number,
  title: string,
  url: string,
}

export type ClientPortalSettings = {
  usePaymentStatus: boolean,
  sendInvoiceEmails: boolean, // Whether invoices are automatically sent out upon being finalized
  allowGuests: boolean, // Whether users are allowed to view/pay invoices w/o logging in
  hideAccounting: boolean, // Whether users can view their accounting history
  invoiceTtl: number,
  disableBalances: boolean, // Whether users see the balance remaining on an invoice or the invoice total
  achEnabled: boolean, // Whether the firm accepts ACH payments
  isLawpay: boolean, // Whether lawpay is enabled
  isPaypal: boolean, // Whether paypal is enabled
  isStripe: boolean, // Whether stripe is enabled
  isCustom: boolean, // Whether a custom payment is enabled
  isHeadnote?: Boolean,
  stripe?: FirmStripeInfo,
  lawpay?: LawPaySettings,
  paypal?: PayPalSettings,
  custom?: CustomSettings,
  disableOverpayment?: boolean,
  headnoteSurchargePercent?: number; // For Headnote Firms that have enabled Surcharging, for Credit Card Payments
}

export type Firm = {
  id: number,
  firmName: string,
  firmCode: string,
  acctIsoType: AcctIsoType,
  currencyCode?: string,
  currencySymbol?: string,
  dashboardLogoFile?: string,
  status: string, // Whether the firm is active or not
  projectLabel: string, // What the firm calls projects
  systemDateFormat: string, // E.g. "mm/dd/yyyy"
  laborLabel: string, // What the firm calls the services that provide to the client
  dashboardLogoFile: string, // Link
  portalSettings: ClientPortalSettings, // Contains client portal specific settings
  billAddr1: string,
  billCity: string,
  billState: string,
  billZip: string,
  billCountry: string,
  billEmail: string,
  billPhone: string,
};

export type Feature = {
  firmId: number,
  featureId: number,
  featureName: string
}

export type FirmStatusInfo = {
  firmId: number,
  isFree?: boolean,
  firmStatus?: string,
  featureTemplate?: string
}

export type FirmInvoiceInfo = {
  firmId: number,
  useFirmInfo?: boolean,
  firmName?: string,
  billAddr1?: string,
  billAddr2?: string,
  billCity?: string,
  billState?: string,
  billZip?: string,
  billCountry?: string,
  billPhone?: string,
  fax?: string,
  billEmail?: string
}

export type HeadnoteFirmSettings = {
  firm_id: string,
  card_transaction_limit_in_cents: number,
  ach_transaction_limit_in_cents: number,
  batch_fees_processing: boolean,
  batch_fees_processing_schedule: any, // entity model is not on headnote docs. It has startDate & period obj
  batch_payouts_processing: boolean,
  client_fee_enabled: boolean,
  credit_card_processing_allowed: boolean,
  debit_card_processing_allowed: boolean,
  ach_processing_allowed: boolean
}

export const PAYMENT_OPTIONS = {
  currentSettings: {
    label: 'Current Settings',
    key: 'currentSettings',
    value: 0,
  },
  lawPay: {
    label: 'LawPay',
    key: 'lawPay',
    value: 1,
  },
  stripe: {
    label: 'Stripe',
    key: 'stripe',
    value: 2,
  },
  custom: {
    label: 'Custom',
    key: 'custom',
    value: 3,
  },
  paypal: {
    label: 'PayPal',
    key: 'paypal',
    value: 4,
  },
};

export type FirmDateFormatInfo = {
  systemDateFormat: string,
};

export const FIRM_DATE_FORMAT = {
  mmddyyyy: 'mm/dd/yyyy',
  ddmmyyyy: 'dd/mm/yyyy',
};

export const FirmDateFormatInfoSchema = Yup.object().shape({
  systemDateFormat: Yup.string()
    .oneOf([FIRM_DATE_FORMAT.mmddyyyy, FIRM_DATE_FORMAT.ddmmyyyy,])
    .required(),
});

/* ------------- Functions ------------- */

/**
 * convert system date format string to correct date format
 * @param {string|undefined} date iso date string
 * @param {string} formatDate format string
 * @returns {string}
 */

export const convertSystemDateFormat = (dateString?: string, formatDate?: string): string => {
  if (!dateString) {
    return '';
  }

  const formatString: string = getFormatString(formatDate);

  return moment(dateString).utcOffset(0).format(formatString);
};

const getFormatString = (formatDate?: string): string => {
  let formatString: string;

  // mm is minute, need to convert to MM ( month )
  switch (formatDate) {
    case FIRM_DATE_FORMAT.ddmmyyyy:
      formatString = 'DD/MM/yyyy';
      break;
    case FIRM_DATE_FORMAT.mmddyyyy:
      formatString = 'MM/DD/yyyy';
      break;
    default:
      formatString = APP_CONFIG.dateFormat;
      break;
  }
  return formatString;
}

export const getLocalDateTime = (formatDate?: string): string => {
  const getTZAbbr = () => {
    const tz = moment.tz.guess();
    const tzAbbr = moment.tz(tz).format('z');
    return tzAbbr;
  }

  const formatString: string = getFormatString(formatDate);
  const localTime = moment().format('h:mmA');
  const localDate = moment().format(formatString);

  return `${localDate} ${localTime} ${getTZAbbr()}`;
};


/* ------------- Repository ------------- */
export type FirmRepository = {
  getFirm: (tokens?: WithCurrentUserToken) => Promise<Firm>,
  updateFirm: (data: FirmUpdateRequestBody, tokens: WithCurrentUserToken) => Promise<void>,
  updateFirmUser: (data: FirmUpdateUserRequestBody, tokens: WithCurrentUserToken) => Promise<void>,
  getFirmUsers: (tokens: WithCurrentUserToken) => Promise<User[]>,
  getFirmClients: (tokens: WithCurrentUserToken) => Promise<Client[]>,
  getFirmFeatures: (tokens: WithCurrentUserToken) => Promise<Feature[]>,
  getFirmStatusInfo: (tokens: WithCurrentUserToken) => Promise<FirmStatusInfo>,
  getFirmInvoiceInfo: (tokens: WithCurrentUserToken) => Promise<FirmInvoiceInfo>,
  getDisconnectedProcessors: (tokens: WithCurrentUserToken) => Promise<PaymentProcessorType[]>,
  getHeadnoteFirmSettings: (tokens: WithCurrentUserToken) => Promise<HeadnoteFirmSettings>,
};
