// @flow
import * as R from 'ramda';

import { type Firm, convertSystemDateFormat, } from 'domain/firm';
import React, { useMemo, } from 'react';
import { currencyFormat, getAdjacentUrlParams, } from 'utilities/stringUtils';
import { usePagination, useTable, } from 'react-table';

import APP_CONFIG from 'constants/APP_CONFIG';
import { Colors, } from 'assets';
import { type RetainerRequestPayments, } from 'domain/retainer';
import { Link, } from 'react-router-dom';
import { Pagination, } from 'view/components';
import Table from 'react-bootstrap/Table';
import { type User, } from 'domain/user';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${Colors.blueCurious};
`;

const StyledTableWrapper = styled.div`
  overflow-y: auto !important;
`;

type Props = {
    className?: string,
    data: Array<RetainerRequestPayments>,
    user: User,
    firm: Firm,
    isPaid?: ?boolean,
    clientId?: number,
    projectId?: number,
};

const RetainerTable = ({ data, isPaid, clientId, firm, user, }: Props) => {
    const COLUMNS = useMemo(() => (
        [
            {
                Header: 'Request Date',
                accessor: 'createdDate',
                Cell: ({ cell: { value, }, }) => {
                    return convertSystemDateFormat(value, user.systemDateFormat);
                },
            },
            {
                Header: 'Due Date',
                accessor: 'dueDate',
                Cell: ({ cell: { value, }, }) => {
                    return convertSystemDateFormat(value, user.systemDateFormat);
                },
            },
            {
                Header: 'Request ID',
                accessor: 'retainerRequestId',
                Cell: ({ cell, }) => {
                    const history: RetainerRequestPayments = R.path(['row', 'original',], cell);
                    let balance = history.requestAmount - history.pmtAmounts;
                    // link to specific retainer page                  
                    if (balance && balance > 0) {
                        return (
                            <StyledLink to={`/retainer-payment/${cell.value}${getAdjacentUrlParams(clientId)}`} >
                                { history.retainerRequestId}
                            </StyledLink>
                        );
                    }
                    // TODO: Links for fully paid retainers disabled. To be implemented later.
                    return history.retainerRequestId;
                },
            },
            {
                Header: () => (
                    <div className="text-right">
                        {`Balance`}
                    </div>
                ),
                //'balance',
                accessor: (row : RetainerRequestPayments) => {
                    return row.requestAmount - row.pmtAmounts;
                },
                id: -1,
                Cell: ({ cell: { value } }) => {
                    return (
                        <div className="font-weight-bold text-right">
                            {currencyFormat(value, firm)}
                        </div>
                    );
                },
            },
        ]
    ), [user.systemDateFormat, clientId, firm]);

    // filter retainers based on paid status
    const filteredPaidData = useMemo(() => {
        if (isPaid != null) {
            return data.filter(r => {
                const isPaidItem = (r.requestAmount - r.pmtAmounts) <= 0;
                return isPaidItem === isPaid;
            });
        }

        return data;
    }, [data, isPaid]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, },
    } = useTable(
        {
            columns: COLUMNS,
            data: filteredPaidData,
            initialState: { pageIndex: 0, pageSize: APP_CONFIG.QUERY_PAGE_SIZE, },
        },
        usePagination,
    );


    return (
        <StyledTableWrapper className="h-100 d-flex flex-column">

            <div className='d-flex' style={{ flex: 1, }}>
                <Table borderless responsive {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            page.map((row, i) =>
                                prepareRow(row) || (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                        })}
                                    </tr>
                                ))
                        }
                    </tbody>
                </Table>
            </div>

            <Pagination
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                gotoPage={gotoPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                setPageSize={setPageSize}
                onNext={nextPage}
                onPrev={previousPage}
            />

        </StyledTableWrapper>
    );

};

export default RetainerTable;