// @flow
import { type Invoice, invoicePaymentValidation, } from 'domain/invoice';
import React, { useCallback, } from 'react';

import { Field, } from 'formik';
import PaymentField from './field/PaymentField';

type Props = {
  className?: string,
  invoice: Invoice,
  rowIndex: number,
};

const InvoicePaymentField = ({
  invoice, rowIndex, ...restProps
}: Props) => {
  // payment validation
  // need to pass to formik field
  const paymentValidation = useCallback((value: number) => {
    let error;
    const isInvoicePaymentInvalid = invoicePaymentValidation(value, invoice);

    if (isInvoicePaymentInvalid) {
      error = 'Payment must be bigger than 0 and less than or equal to invoice due.';
    }

    return error;
  }, [ invoice, ]);

  return (
    <Field
      required
      component={PaymentField}
      name={`invoices[${rowIndex}].userPayment`}
      placeholder={'Payment'}
      style={{
        textAlign: 'right',
      }}
      validate={paymentValidation}
      {...restProps}
    />
  );
};

export default InvoicePaymentField;
