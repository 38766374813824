/* @flow */
import { type User, type UserRepository, type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
    onSuccess: (User) => void,
    onError: (Error) => void,
  };

type Dependencies = {
  userRepository: UserRepository,
};

export type GetByTokenBehaviour = (
    WithCurrentUserToken,
    Callbacks
) => any;

export default ( { userRepository, }: Dependencies, ) => {
  /**
   * Get by token behaviour
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getByTokenBehaviour: GetByTokenBehaviour = async (
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      const user: User = await userRepository.getByToken(withCurrentUserToken);

      return onSuccess(user);
    } catch(error) {
      return onError(error);
    }
  };

  return getByTokenBehaviour;
};
