/* @flow */
import * as R from 'ramda';

import useVirtualizedSelectorHook, { IVirtualizedSelectorReturnValues } from "./virtualizedSelectorHook";
import { IUserDropdownOption } from "./B4TUserSelector";
import { useMemo } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { InviteRedux } from '../../../state/reducers';
import { InviteActionCreators, } from 'state/actions';

const useConnect = () => {
    // mapState
    const userMap = useSelector(R.pipe(InviteRedux.getReducerState, InviteRedux.selectors.getContact));
  
    const mapState = {
        userMap,
    };
  
    // mapDispatch
    const dispatch = useDispatch();
    const mapDispatch = useMemo(() => ({
      getContacts: () => dispatch(InviteActionCreators.getContact(undefined, { thunk: true, })),
    }), [ dispatch, ]);
  
    return {
      ...mapState,
      ...mapDispatch,
    };
  };

export default function(props: IUserSelectorHookOptions): IVirtualizedSelectorReturnValues<IUserSelectorResult> {
    const { selectedUser } = props;

    const { userMap, getContacts, } = useConnect();

    const userArray: IUserSelectorResult[] = Array.from(userMap).map(([ key, value, ]) => ({
        name: `${value.fname} ${value.lname} [${value.email}]`,
        id: value.id,
        ...value
    })).filter((user) => {
        // filter out users w/o an email
        return !!user.email;
    });

    // fetches users and populates local data
    const fetchUsers = async () => {
        await getContacts();
    };

    // invoke virtual selector hook that'll handle common selector logic
    const virtualizedHookValues: IVirtualizedSelectorReturnValues<IUserSelectorResult> =
        useVirtualizedSelectorHook<IUserSelectorResult>({fetchData: fetchUsers, selectedOption: selectedUser, data: userArray, ...props});

    return virtualizedHookValues;
}

export interface IUserSelectorResult {
    id: number;
    name: String;
}

export interface IUserSelectorHookOptions {
    fetchErrorHandler?: (error: Error) => void;
    selectedUser: IUserDropdownOption | IUserDropdownOption[];
    isLoading?: boolean; // pass if clients are to be loaded as soon as selector renders
    hasLoaded?: boolean; // pass if clients have been loaded outside of the hook
}