// @flow
import { Field, Formik, type FormikState, } from 'formik';
import { type UserAuthInfo, UserAuthInfoSchema, } from 'domain/user/index';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { Colors, } from 'assets/index';
import { FormInput, } from 'view/components/index';
import { Link, } from 'react-router-dom';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${Colors.blueButton} !important;
  border: none !important;
  font-weight: bold !important;
  width: 140px;

  :hover {
    background-color: ${Colors.blueButtonHover} !important;
  }
`;

const StyledForgotPassword = styled(Link)`
  font-size: .85rem;
  font-weight: 500;
  color: ${Colors.blueCurious} !important;

  :hover {
    color: ${Colors.blueAstral} !important;
  }
`;

type Props = {
  onSubmit: Function,
  initialFormValues: UserAuthInfo,
}

const FormEl = ({ isSubmitting, values, errors, touched, handleChange, handleBlur, handleSubmit, }: FormikState<UserAuthInfo>) => (
  <form onSubmit={handleSubmit}>
    {
      errors.auth && (
        <Alert variant='danger'>{errors.auth}</Alert>
      )
    }

    <Field
      required
      component={FormInput}
      maxLength={100}
      name={'username'}
      placeholder={'Email'}
      type={'text'}
    />

    <Field
      required
      component={FormInput}
      maxLength={100}
      name={'password'}
      placeholder={'Password'}
      type={'password'}
    />


    <div className='d-flex flex-row justify-content-end'>
      {
        isSubmitting && (
          <Spinner
            animation="border"
            className='mr-2'
            role="status"
          />
        )
      }
      <div className='d-flex flex-column'>
        <StyledButton className='border-0 mb-3' disabled={isSubmitting} type='submit'>
          {`Login`}
        </StyledButton>
        <StyledForgotPassword to='reset-password'>
          {`Forgot your password?`}
        </StyledForgotPassword>
      </div>
    </div>
  </form>
);

const LoginForm = ({ initialFormValues, onSubmit, }: Props) => (
  <div className='login-form'>
    <Formik
      initialValues={initialFormValues}
      // eslint-disable-next-line
      render={FormEl}
      validationSchema={UserAuthInfoSchema}
      // eslint-disable-next-line
      onSubmit={onSubmit}
    />
  </div>
);

export default LoginForm;
