/* @flow */
import { createActions, } from 'reduxsauce';
import { reducerPrefixFormat, } from '../common';

/* ------------- User ------------- */
export const { Types, Creators, } = createActions({
  login: [ 'payload', 'meta', ],
  loginSessionSuccess: [ 'payload' /* LoginResponsePayload */, 'meta', ],

  logout: null,
  logoutSessionSuccess: [ 'meta', ],

  setTokens: [ 'payload', ],
  
  getFirm: [ 'meta', ],
  updateFirm: [ 'payload', 'meta', ],

  getFirmStatusInfo: [ 'payload', 'meta', ],
  getFirmStatusInfoSuccess: [ 'payload', 'meta', ],

  getFirmInvoiceInfo: [ 'payload', 'meta', ],
  getFirmInvoiceInfoSuccess: [ 'payload', 'meta', ],

  updateFirmSettings: [ 'payload', 'meta', ],
  updateFirmSettingsSuccess: [ 'payload', 'meta', ],

  updateFirmUser: [ 'payload', 'meta', ],
  updateFirmUserSuccess: [ 'payload', 'meta', ],

  updateUser: [ 'payload', 'meta', ],
  updateUserSuccess: [ 'payload', 'meta', ],
    
  resetRequest: [ 'payload', 'meta', ],
  resetRequestSuccess: [ 'payload' /* ResetResponsePayload */, 'meta', ],

  resetPassword: [ 'payload', 'meta', ],
  resetPasswordSuccess: [ 'payload' /* ResetPasswordResponsePayload */, 'meta', ],

  getFirmUsers: [ 'payload', 'meta', ],
  getFirmUsersSuccess: [ 'payload', 'meta', ],

  getFirmClients: [ 'payload', 'meta', ],
  getFirmClientsSuccess: [ 'payload', 'meta', ],

  getByToken: [ 'meta', ],
  getByTokenSuccess: [ 'payload' /* GetByTokenResponsePayload */, 'meta', ],

  setTokenFromOutSide: [ 'payload', 'meta', ],
  setTokenFromOutsideSuccess: [ 'payload', 'meta', ],
  
  registerUser: [ 'payload', 'meta', ],
  registerUserSuccess: [ 'payload' /* RegisterUserResponsePayload */, 'meta', ],

  requestFailure: [ 'error', 'payload' /* error */, 'meta', ],

  getFeatureList: ['meta'],
  getFeatureListSuccess: ['payload', 'meta']
}, {
  prefix: reducerPrefixFormat('User'),
});
