/* @flow */
import * as R from 'ramda';

import { type WithCurrentUserToken, } from '../user';

export const INVOICE_STATUS = {
  closed: "Closed",
  open: "Open",
};

export const INVOICE_EMAIL_LINK_EXPIRATION = {
  days7: 7,
  days14: 14,
  days30: 30,
  days45: 45,
  days60: 60
};

export type Invoice = {
  id: number,
  invoiceLabel: string, // Custom invoice label to be shown in place of id
  firmId: number,
  clientId: number, // Client invoice is attached to
  projectId: number, // Project invoice is attached to if project accounting isolation
  isPaid: boolean,
  description: string,
  invoiceStatus: "Closed" | "Open",
  invoiceDate: string,
  createdDate: string,
  invoiceTotal: number,
  totalPayments: number,
  invoiceBalance: number,
  encryptedInvoiceReference?: string,
};

// invoice + user payment
export type InvoiceUserPayment = Invoice & { userPayment: number, remainingBalance: number }

export type InvoicePayment = {
  invoices: Array<InvoiceUserPayment>,
  total: number,
}

/* ------------- Functions ------------- */
/**
 * validate user additional invoice payment
 * Payment must be 0 and less than or equal to invoice due
 *
 * @param {number} value
 * @param {number} totalPayments
 * @returns
 */
export const invoiceAdditionalPaymentValidation = (value: number) => {
  return R.isEmpty(value) || value < 0;
};

/**
 * validate user invoice payment
 * Payment must be bigger than 0 and less than or equal to invoice due
 *
 * @param {number} value
 * @param {number} totalPayments
 * @returns
 */
export const invoicePaymentValidation = (value: number, invoice: Invoice) => {
  return R.isEmpty(value) || value > invoice.invoiceBalance || value <= 0;
};

/**
 * validate user total invoices payments
 * must be equal or higher than sum of all invoices payments
 *
 * @param {number} value
 * @param {number} totalInvoicesPayments
 * @returns
 */
export const invoiceTotalPaymentValidation = (value: number, totalInvoicesPayments: number) => {
  return R.isEmpty(value) || value < totalInvoicesPayments;
};


/**
 * check if invoice payable
 *
 * @param {Invoice} [i]
 * @returns {boolean}
 */
export const isInvoiceCanPay = (i?: Invoice): boolean => {
  if (!i) {return false;}

  return !(i.invoiceBalance === 0 || i.isPaid);
};

/* ------------- Repository ------------- */

export type InvoiceRepository = {
  getClientInvoices: (clientId: number, WithCurrentUserToken) => Promise<Array<Invoice>>,
  getProjectInvoices: (clientId: number, projectId: number, WithCurrentUserToken) => Promise<Array<Invoice>>,
  getSingleInvoice: (clientId: number, invoiceId: number, WithCurrentUserToken) => Promise<Invoice>,
  getInvoiceToken: (Invoice, WithCurrentUserToken) => Promise<string>,
  // add: (EditingClient, WithCurrentUserToken) => Promise<Client>,
  // remove: (Client, WithCurrentUserToken) => Promise<void>,
  // update: (EditingClient, WithCurrentUserToken) => Promise<Client>
};
