/* @flow */
import { Colors, Images, } from 'assets/index';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Link, } from 'react-router-dom';
import React from 'react';
import ResetPasswordForm from './components/ResetPasswordForm';
import Row from 'react-bootstrap/Row';
import { type UserNewPasswordCredentials, } from 'domain/user';
import styled from 'styled-components';

const StyledLogin = styled(Container)`
  flex: 1;
`;

const StyledContainer = styled(Card)`
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
  border-radius: 0px !important;
  padding-bottom: 45px;
  padding-top: 65px;
`;

const StyledCardText = styled(Card.Text)`
  margin: 0px 0 20px;
  padding: 20px 0;
  font-weight: 500;
  font-size: 14px;
`;
type Props = {
  onSubmit: Function,
  initialFormValues: UserNewPasswordCredentials,
  username: string,
};

export default ({ initialFormValues, onSubmit, username, }: Props) => {
  return (
    <StyledLogin className="d-flex align-items-center justify-content-center">
      <Row className="w-100">
        <Col className="my-5" md={{ span: 4, offset: 4, }}>
          <StyledContainer>
            <Link className="d-block mx-5" to={'/login'}>
              <Card.Img src={Images.common.defaultLogo}></Card.Img>
            </Link>
            <Card.Body>
              <StyledCardText>{`Enter a new password.`}</StyledCardText>
              <ResetPasswordForm initialFormValues={initialFormValues} username={username} onSubmit={onSubmit} />
            </Card.Body>
            
          </StyledContainer>
        </Col>
      </Row>
    </StyledLogin>
  );
};
