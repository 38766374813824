import React from 'react';
import styled from 'styled-components';
import {triggerChatWindow} from "../../../../../../../iframeMessage";

export default () => {
    return (
        <div>
            <p className="mb-1"><strong>Have a question?</strong></p>
            <p><StyledLink onClick={triggerChatWindow}>Start a live support chat</StyledLink> or email <a href="mailto:support@bill4time.com">support@bill4time.com</a></p>
        </div>
    )
}

const StyledLink = styled.span`
    &:hover {
        color: #0056b3;
    };
    cursor: pointer;
    color: #007bff;
`;