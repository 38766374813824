// @flow
import { Alert, } from 'react-bootstrap';
import React from 'react';
import Styled from 'styled-components';
import { Colors, } from 'assets';

const StyledAlert = Styled(Alert)`
  &.alert-info {
    color: ${Colors.shadingtext};
    background-color: ${Colors.shadingbg};
    border-color: ${Colors.shadingbg};
  }
`;

type Props = {
  text: string,
}

export default ({ text, ...props } : Props) => {
  return (
    <StyledAlert variant="info" {...props}>{text}</StyledAlert>
  );
};
