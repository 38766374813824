/* @flow */
import { type Firm, } from '../firm';
import { type WithCurrentUserToken, } from '../user';
import { type BilledProjectBalance, } from '../project';

export const ACCT_ISO_TYPE = {
  client: "Client",
  project: "Project",
};

export type AcctIsoType = "Client" | "Project" | null

export type Client = {
  id: number,
  clientLabel: string, // Custom label to be shown in the place of id
  firmId: number,
  currency: string,
  name: string,
  status: "Active" | "Disabled" | any, // Can be a custom status
  currencyCode: string,
  acctIsoType: AcctIsoType, // If null, will use firm default
  email: string,
};

export type BilledClientBalance = {
  balance: number,
}

export type AccountBalance = BilledClientBalance | BilledProjectBalance;

export type EditingClient = {
  clientLabel: string,
  name: string,
};

export type ClientHeadnoteInfo = {
  firmId: number,
  clientId: number,
  clientName: string,
  clientStatus: string,
  isSurchargeEnabled: boolean,
  headnoteSurchargePercent: number,
}

/* ------------- Functions ------------- */

/**
 *  get accounting iso type from firm, client
 *  if client doesn't have acctIsoType -> get from firm
 *
 * @param {Firm} firm
 * @param {Client} client
 * @returns {AcctIsoType} accounting iso type
 */
export const getAcctIsoType: (firm: Firm, client: Client) => AcctIsoType = (firm, client) => {
  return client.acctIsoType || firm.acctIsoType;
};

/**
 * get client display text
 *
 * @param {Client} client
 * @returns {string}
 */
export const getClientDisplay = (client: Client): string => {
  return client.clientLabel || String(client.id);
};

/* ------------- Repository ------------- */
export type ClientRepository = {
  getClients: (tokens: WithCurrentUserToken) => Promise<Array<Client>>,
  getSingleClient: (clientId: number, tokens: WithCurrentUserToken) => Promise<Client>,
  getBilledClientBalances: (clientId: number, WithCurrentUserToken) => Promise<BilledClientBalance>,
  getClientHeadnoteInfo: (clientId: number, tokens: WithCurrentUserToken) => Promise<ClientHeadnoteInfo>
};
