export default {
  common: {
    defaultLogo: require('./images/default-logo.png'),
    portalClientLogo: require('./images/portal-client-logo.png'),
    bill4timeLogo: require('./images/bill4time-logo.png'),
    dropdownIcon: require('./images/dropdown-icon.svg'),
    stripe: require('./images/stripe.png'),
    defaultImage: require('./images/default-user-image.png'),
    removeIcon: require('./images/remove-icon.svg'),
  },
  payment: {
    lawpay: {
      button: require('./images/payment/lawpay/connectBtn.png'),
      getStarted: require('./images/payment/lawpay/getStarted.jfif'),
    },
    check: require('./images/payment/check.png'),
    checkCircle: require('./images/checkCircle.png'),
  },
  settings: {
    paid4timeLogo: require('./images/Paid4Time-logo-placeholder.png'),
    ccImages: require('./images/CC-images.png'),
  },
};

