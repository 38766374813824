// @flow
import { FormInput, FormSelect, } from 'view/components';

import { Field, } from 'formik';
import Form from 'react-bootstrap/Form';
import React from 'react';

const VerificationForm = () => {
  return (
    <Form>

      <Field
        isRow
        required
        component={FormInput}
        label={'Amount 1'}
        labelClassName={`text-right font-weight-bold`}
        name={'amount1'}
        placeholder={'amount in cents'}
        type={'number'}
      />

      <Field
        isRow
        required
        className={`mt-3`}
        component={FormInput}
        label={'Amount 2'}
        labelClassName={`text-right font-weight-bold`}
        name={'amount2'}
        placeholder={'amount in cents'}
        type={'number'}
      />
      
    </Form>
  );
};

export default VerificationForm;
