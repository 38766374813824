/* @flow */
import { type Project, type ProjectRepository, } from 'domain/project';

import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (Array<Project>) => void,
  onInvalidPayload: (Error) => void,
  onError: (Error) => void,
};

type Dependencies = {
  projectRepository: ProjectRepository,
};

export type GetProjectsOfClientBehaviour = (
  number,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { projectRepository, }: Dependencies, ) => {
  /**
   * get list projects of a client behaviour
   * 
   * @param {number} clientId - client id
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getProjectsOfClientBehaviour: GetProjectsOfClientBehaviour = async (
    clientId,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      if (clientId == null || isNaN(clientId)) {
        return onInvalidPayload(new Error('Invalid client id.'));
      }

      const projects: Array<Project> = await projectRepository.getProjectsOfClient(clientId, withCurrentUserToken);

      return onSuccess(projects);
    } catch(error) {
      return onError(error);
    }
  };

  return getProjectsOfClientBehaviour;
};
