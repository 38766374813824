/* @flow */
import { type WithCurrentUserToken, } from 'domain/user';
import { type ConduitApiService, } from 'infra/conduit/ApiService';

type Callbacks = {
  onSuccess: (string) => void,
  onError: (Error) => void,
};

type Dependencies = {
  conduitApiService: ConduitApiService,
};

export type SignOutUserBehaviour = (
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { conduitApiService, }: Dependencies, ) => {
  /**
   * sign out user behaviour
   * 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const signOutUserBehaviour: SignOutUserBehaviour = async (
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      // This route voids the user's refresh token.
      // so we need to send refresh token in header instead of auth token
      const res = await conduitApiService.getNoHook({
        url: 'tokens/session/user/logout',
        options: {
          headers: {
            authorization: withCurrentUserToken ? `Bearer ${withCurrentUserToken.currentUserToken.refreshToken}` : undefined,
          },
        },
      });

      if (res.error) {throw res.error;}

      return onSuccess(res.success);
    } catch(error) {
      return onError(error);
    }
  };

  return signOutUserBehaviour;
};
