/* @flow */
import * as R from 'ramda';

import { Colors, Images, } from 'assets/index';
import React, { useCallback, useState, } from 'react';

import Container from 'react-bootstrap/Container';
import { type Firm, } from 'domain/firm';
import { type FormikActions, } from 'formik';
import Image from 'react-bootstrap/Image';
import UploadLogoForm from './UploadLogoForm';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  color: ${Colors.charcoal};
  flex: 1;
`;

const StyledText = styled.p`
  color: ${Colors.greyEmpress};
  font-size: .875rem;
`;

const StyledImage = styled(Image)`
  max-width: 100%;
  max-height: 200px;
`;

type Props = {
  firm: Firm,
};

type ImageFormValues = {
  image: Object,
};

const FirmLogoUpload = ({ firm, }: Props) => {
  // firm logo default to saved logo in firm settings
  const logoLink = R.path([ 'dashboardLogoFile', ], firm);
  const originalLogoLink = logoLink || Images.common.defaultImage;

  const [ firmLogo, setFirmLogo, ] = useState(null);

  const image = firmLogo ? firmLogo.preview : originalLogoLink;

  const onSubmitUploadImage = useCallback((values: ImageFormValues, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);
      setFirmLogo(values.image);
    } catch (e) {
      actions.setErrors({
        auth: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, []);

  return (
    <StyledContainer>
      <StyledImage src={image} />
      {/* <StyledText className="pt-2">
        {`For best results, logo should be a .png image between 200px-600px wide and about 200px tall.`}
      </StyledText>
      <UploadLogoForm onSubmit={onSubmitUploadImage} /> */}
    </StyledContainer>
  );
};

export default FirmLogoUpload;
