// @flow
import React, { FC } from 'react';

import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import styled from "styled-components";

const StyledContainer = styled(Container)`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledSpinner = styled(Spinner)`
  width: ${({ size }) => size ? (size + 'rem') : '4rem'};
  height: ${({ size }) => size ? (size + 'rem') : '4rem'};
`;

interface Props {
  size?: number;
}

export const Loading: FC<Props> = ({ size }) => {
  return (
    <StyledContainer>
      <StyledSpinner size={size} animation="border" role="status" />
      <p className="pt-3">{`Loading ...`}</p>
    </StyledContainer>
  );
};
