// @flow
import * as R from 'ramda';

import { END, eventChannel, } from 'redux-saga';
import { InviteActionCreators, InviteTypes, } from '../actions';
import { type InviteMap, type getInviteSuccessPayload, type PostInvitePayload, deleteInvitePayload, deleteInvitePayloadSuccess, type ContactMap, type getContactSuccessPayload, } from './types';
import { cancelled, put, take, takeLatest, select, } from 'redux-saga/effects';

import { type AwilixContainer, } from 'awilix';
import { type GetInviteBehaviour, } from 'app/invite/GetInvite';
import { type PostInviteBehaviour, } from 'app/invite/PostInvite';
import { type DeleteInviteBehaviour, } from 'app/invite/DeleteInvite';
import { type GetContactBehaviour, } from 'app/invite/GetContact';
import { type Invite, type Contact, } from 'domain/invite';
import { type WithCurrentUserToken, } from 'domain/user';
import { UserRedux, } from 'state/reducers';

/**
 * get invite list redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const getInvite = function * (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // resolve get invite list behaviour from awilix container
    const getInviteBehaviour: GetInviteBehaviour = container.resolve('getInvite');
    
    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
      
    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getInviteBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (invites) => {
            emitter({ data: invites, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data: invites, error, }: { data: Array<Invite>, error: ?Error, } = yield take(channel);
        if (!invites && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (invites) {
          // process client list to js Map ['key', 'value']
          const inviteTuple = invites.map((c: Invite) => [ c._id, c, ]);
          const inviteMap: InviteMap = new Map(inviteTuple);
          const payload: getInviteSuccessPayload = { invites: inviteMap, };
          yield put(InviteActionCreators.getInviteSuccess(payload, meta));
        }
    }
  } catch (e) {
    yield put(InviteActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(InviteActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * send invite to client redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const postInvite = function * (container: AwilixContainer, { payload, meta, }: { payload: PostInvitePayload, meta: ?Object, }) {
  try {
    const { postInviteBody, } = payload;
    // resolve get invite list behaviour from awilix container
    const postInviteBehaviour: PostInviteBehaviour = container.resolve('postInvite');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
    
    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      postInviteBehaviour(
        postInviteBody,
        withCurrentUserToken,
        {
          onSuccess: (invites) => {
            emitter({ data: invites, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data: invite, error, }: { data: Invite, error: ?Error, } = yield take(channel);
        if (!invite && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (invite) {
          yield put(InviteActionCreators.postInviteSuccess(invite, meta));
        }
    }
  } catch (e) {
    yield put(InviteActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(InviteActionCreators.requestFailure(true, null, meta));
    }
  }
};

  /**
   * revoke invite redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
const deleteInvite = function * (container: AwilixContainer, { payload, meta, }: { payload: deleteInvitePayload, meta: ?Object, }) {
  try {
    const { tokenId, } = payload;
    // resolve get invite list behaviour from awilix container
    const deleteClientBehaviour: DeleteClientBehaviour = container.resolve('deleteInvite');
    
    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
      
    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      deleteClientBehaviour(
        tokenId,
        withCurrentUserToken,
        {
          onSuccess: (invites) => {
            emitter({ data: invites, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data: message, error, }: { data: string, error: ?Error, } = yield take(channel);
        if (!message && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (message) {
          const deletePayloadSuccess : deleteInvitePayloadSuccess = {
            msg: message,
            id: tokenId,
          };
          yield put(InviteActionCreators.deleteInviteSuccess(deletePayloadSuccess, meta));
        }
    }
  } catch (e) {
    yield put(InviteActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(InviteActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * get contact list redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const getContact = function * (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // resolve get invite list behaviour from awilix container
    const getContactBehaviour: GetContactBehaviour = container.resolve('getContact');
    
    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
      
    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getContactBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (invites) => {
            emitter({ data: invites, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data: contacts, error, }: { data: Array<Contact>, error: ?Error, } = yield take(channel);
        if (!contacts && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (contacts) {
          // process client list to js Map ['key', 'value']
          const contactTuple = contacts.map((c: Contact) => [ c.id, { ...c, c, }, ]);
          const contactMap: ContactMap = new Map(contactTuple);
          const payload: getContactSuccessPayload = { contacts: contactMap, };
          yield put(InviteActionCreators.getContactSuccess(payload, meta));
        }
    }
  } catch (e) {
    yield put(InviteActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(InviteActionCreators.requestFailure(true, null, meta));
    }
  }
};

export default (container: AwilixContainer) => ([
  takeLatest(InviteTypes.GET_INVITE, getInvite, container),
  takeLatest(InviteTypes.POST_INVITE, postInvite, container),
  takeLatest(InviteTypes.DELETE_INVITE, deleteInvite, container),
  takeLatest(InviteTypes.GET_CONTACT, getContact, container),
]);
