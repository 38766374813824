// @flow
import * as R from 'ramda';

import { type ConnectUserStripePayload, type ConnectUserStripeSuccessPayload, type FirmUserMap, type GetFirmUsersSuccessPayload,
  type LogInSuccessPayload, type RegisterUserPayload, type ResetPasswordResponsePayload, type ResetResponsePayload,
  type SetOutsideTokenPayload, type UpdateFirmSettingsPayload, type UpdateFirmUserPayload, type UpdateFirmUserSuccessPayload,
  type UpdateUserPayload, type UpdateUserSuccessPayload, type GetFeatureListSuccessPayload,
  type GetFirmStatusInfoSuccessPayload, type GetFirmInvoiceInfoSuccessPayload } from './types';
import { END, eventChannel, } from 'redux-saga';
import { type Firm, type FirmRepository, type Feature, type FirmStatusInfo } from 'domain/firm';
import { type NewPasswordBehaviour, type ResetBehaviour, } from 'app/user/ResetCredentialsUser';
import { type User, type UserAuthInfo, type UserNewPasswordCredentials, type UserResetCredentials, type WithCurrentUserToken, } from 'domain/user';
import { UserActionCreators, UserTypes, } from '../actions';
import { call, cancelled, put, select, take, takeLatest, } from 'redux-saga/effects';

import { type AwilixContainer, } from 'awilix';
import { type GetByTokenBehaviour, } from 'app/user/GetByToken';
import { type GetFirmUsersBehaviour, } from 'app/firm/GetFirmUsers';
import { type RegisterBehaviour } from 'app/user/RegisterUser';
import { type SetTokenFromOutsideBehaviour, } from 'app/user/SetTokenFromOutside';
import { type SignInUserBehaviour, } from 'app/user/SignInUser';
import { type SignOutUserBehaviour, } from 'app/user/SignOutUser';
import { type UpdateFirmBehaviour, } from 'app/firm/UpdateFirmSettings';
import { type UpdateFirmUserBehaviour, } from 'app/firm/UpdateFirmUser';
import { type UpdateUserBehaviour, } from 'app/user/UpdateUser';
import { UserRedux, } from 'state/reducers';

/**
   * log in redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
const login = function * (container: AwilixContainer, { payload, meta, }: { payload: UserAuthInfo, meta: ?Object, }) {
  try {
    // resolve login behaviour from awilix container
    const signInUserBehaviour: SignInUserBehaviour = container.resolve('signInUser');

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      signInUserBehaviour(
        payload,
        {
          onSuccess: (tokens, firm, user) => {
            emitter({ data: { tokens, firm, user, }, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        },
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data, error, }: { data: ?Object, error: ?Error, } = yield take(channel);
        if (!data && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (data) {
          const { tokens, firm, user, } = data;
          const payload: LogInSuccessPayload = { tokens, firm, user, };
          
          yield put(UserActionCreators.loginSessionSuccess(payload, meta));
        }
    }
  } catch (e) {
    yield put(UserActionCreators.requestFailure(true, e, meta));
  }
};

const logout = function * (container: AwilixContainer, { meta, }) {
  try {
    // resolve sign out behaviour from awilix container
    const signOutUserBehaviour: SignOutUserBehaviour = container.resolve('signOutUser');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      signOutUserBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (successMsg) => {
            emitter({ data: successMsg, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        },
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data, error, }: { data: ?string, error: ?Error, } = yield take(channel);
        if (!data && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (data) {
          // dispatch logout success action to clear user auth from redux state and storage
          yield put(UserActionCreators.logoutSessionSuccess());
        }
    }
  } catch (e) {
    yield put(UserActionCreators.requestFailure(e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(UserActionCreators.requestFailure(undefined, meta));
    } else {
      yield put(UserActionCreators.logoutSessionSuccess());
    }
  }
};

const resetRequest = function *(
  container: AwilixContainer,
  { payload, meta, }: { payload: UserResetCredentials, meta: ?Object, }
) {
  try {
    const resetCredentialsUser: ResetBehaviour = container.resolve("resetRequestUser");

    const channel = eventChannel(emitter => {
      resetCredentialsUser(payload, {
        onSuccess: (data) => {
          emitter({ data, });
          emitter(END);
        },
        onError: (error) => {
          emitter({ data: {}, error, });
          emitter(END);
        },
      });
      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });
      // Process events until operation completes
    while (true) {
      const { data, error, }: { data: string, error: ?Error, } = yield take(channel);
      if (!data && !error) {
          break;
      }
      // Handle the data...
      if (error) { throw error;}
      
      if (data) {
        const payload: ResetResponsePayload = {
          message: data,
        };
        yield put(UserActionCreators.resetRequestSuccess(payload, meta));
      }
    }
  } catch (e) {
    yield put(UserActionCreators.requestFailure(true, e, meta));
  } 
};

const resetPassword = function *(
  container: AwilixContainer,
  { payload, meta, }: { payload: UserNewPasswordCredentials, meta: ?Object, }
) {
  try {
    const newPasswordUserBehaviour: NewPasswordBehaviour = container.resolve("resetPasswordUser");

    const channel = eventChannel(emitter => {
      newPasswordUserBehaviour(payload, {
        onSuccess: (data) => {
          emitter({ data, });
          emitter(END);
        },
        onError: (error) => {
          emitter({ data: {}, error, });
          emitter(END);
        },
      });
      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });
      // Process events until operation completes
    while (true) {
      const { data, error, }: { data: string, error: ?Error, } = yield take(channel);
      if (!data && !error) {
          break;
      }
      // Handle the data...
      if (error) { throw error;}
      
      if (data) {
        const payload: ResetPasswordResponsePayload = {
          message: data,
        };

        yield put(UserActionCreators.resetPasswordSuccess(payload, meta));
      }
    }
  } catch (e) {
    yield put(UserActionCreators.requestFailure(true, e, meta));
  }
};

/**
 *
 *
 * @param {AwilixContainer} container
 * @param {{ meta: ?Object, }} { meta: redux saga thunk meta, }
 */
const getByToken = function *(
  container: AwilixContainer,
  { meta, }: { meta: ?Object, }
) {
  try {
    const getByTokenBehaviour: GetByTokenBehaviour = container.resolve("getByToken");

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
  
    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    const channel = eventChannel(emitter => {
      getByTokenBehaviour(withCurrentUserToken, {
        onSuccess: (data) => {
          emitter({ data, });
          emitter(END);
        },
        onError: (error) => {
          emitter({ data: {}, error, });
          emitter(END);
        },
      });
      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });
      // Process events until operation completes
    while (true) {
      const { data, error, }: { data: object, error: ?Error, } = yield take(channel);
      if (!data && !error) {
          break;
      }
      // Handle the data...
      if (error) { throw error;}
      
      if (data) {
        yield put(UserActionCreators.getByTokenSuccess(data, meta));
      }
    }
  } catch (e) {
    yield put(UserActionCreators.requestFailure(true, e, meta));
  } 
};

/**
 * udpate firm settings saga side effect
 *
 * @param {AwilixContainer} container
 * @param {{ meta: ?Object, }} { meta: redux saga thunk meta, }
 */
const updateFirmSettings = function *(
  container: AwilixContainer,
  { payload, meta, }: { payload: UpdateFirmSettingsPayload, meta: ?Object, }
) {
  try {
    const updateFirmBehaviour: UpdateFirmBehaviour = container.resolve("updateFirmSettings");

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
  
    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    const channel = eventChannel(emitter => {
      updateFirmBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (data) => {
            emitter({ data, });
            emitter(END);
          },
          onInvalidPayload: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );
      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });
      // Process events until operation completes
    while (true) {
      const { data: firm, error, }: { data: Firm, error: ?Error, } = yield take(channel);
      if (!firm && !error) {
          break;
      }
      // Handle the data...
      if (error) { throw error;}
      
      if (firm) {
        yield put(UserActionCreators.updateFirmSettingsSuccess(firm, meta));
      }
    }
  } catch (e) {
    yield put(UserActionCreators.requestFailure(true, e, meta));
  }
};

/**
 * udpate firm user saga side effect
 *
 * @param {AwilixContainer} container
 * @param {{ meta: ?Object, }} { meta: redux saga thunk meta, }
 */
const updateFirmUser = function *(
  container: AwilixContainer,
  { payload, meta, }: { payload: UpdateFirmUserPayload, meta: ?Object, }
) {
  try {
    const updateFirmUserBehaviour: UpdateFirmUserBehaviour = container.resolve("updateFirmUser");

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
  
    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    const channel = eventChannel(emitter => {
      updateFirmUserBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (data) => {
            emitter({ data, });
            emitter(END);
          },
          onInvalidPayload: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // only 1 callback will be called, no need to loop
    const { error, }: { error: ?Error, } = yield take(channel);
    // Handle the data...
    if (error) { throw error;}
    
    if (!error) {
      const firmUsersMap: FirmUserMap = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirmUsersMap));

      const prevUser = firmUsersMap.get(payload.data.userId);

      // get updated user
      const data: UpdateFirmUserSuccessPayload = {
        user: {
          ...prevUser,
          ...payload.data.user,
        },
      };

      // put updated user data to redux
      yield put(UserActionCreators.updateFirmUserSuccess(data, meta));
    }
  } catch (e) {
    yield put(UserActionCreators.requestFailure(true, e, meta));
  } 
};

/**
 * update user info saga side effect
 *
 * @param {AwilixContainer} container
 * @param {{ meta: ?Object, }} { meta: redux saga thunk meta, }
 */
const updateUser = function *(
  container: AwilixContainer,
  { payload, meta, }: { payload: UpdateUserPayload, meta: ?Object, }
) {
  try {
    const updateUserBehaviour: UpdateUserBehaviour = container.resolve("updateUser");

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
  
    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    const channel = eventChannel(emitter => {
      updateUserBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (data) => {
            emitter({ data, });
            emitter(END);
          },
          onInvalidPayload: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );
      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });
      // Process events until operation completes
    while (true) {
      const { data: user, error, }: { data: User, error: ?Error, } = yield take(channel);
      if (!user && !error) {
          break;
      }
      // Handle the data...
      if (error) { throw error;}
      
      if (user) {
        const payload: UpdateUserSuccessPayload = {
          user,
        };

        yield put(UserActionCreators.updateUserSuccess(payload, meta));
      }
    }
  } catch (e) {
    yield put(UserActionCreators.requestFailure(true, e, meta));
  } 
};

/**
 * get Firm users, project redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const getFirm = function * (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // resolve get payments of a client, project behaviour from awilix container
    const firmRepository: FirmRepository = container.resolve('firmRepository');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
  
    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // simple usecase, no need to use app behaviour
    const firm: Firm = yield call(
      firmRepository.getFirm,
      token ? withCurrentUserToken : undefined
    );

    // update firm to redux state
    yield put(UserActionCreators.updateFirm(firm, meta));
  } catch (e) {
    yield put(UserActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(UserActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * get Firm users, project redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const getFirmUsers = function * (container: AwilixContainer, { payload, meta, }: { payload: any, meta: ?Object, }) {
  try {
    // resolve get payments of a client, project behaviour from awilix container
    const getFirmUsersBehaviour: GetFirmUsersBehaviour = container.resolve('getFirmUsers');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getFirmUsersBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (users) => {
            emitter({ data: users, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const input: { data: User[], error: Object | string, } = yield take(channel);
        const { data: users, error, } = input;
        if (input === END) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (users) {
          // process user list to js Map ['key', 'value']
          const usersTuple = users.map((u: User) => [ u._id, u, ]);
          const usersMap: FirmUserMap = new Map(usersTuple);

          const payload: GetFirmUsersSuccessPayload = {
            usersMap,
          };

          yield put(UserActionCreators.getFirmUsersSuccess(payload, meta));
        }
    }
  } catch (e) {
    yield put(UserActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(UserActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
   * set outside token redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
  const setTokenFromOutside = function * (container: AwilixContainer, { payload, meta, }: { payload: SetOutsideTokenPayload, meta: ?Object, }) {
    try {
      // resolve login behaviour from awilix container
      const setTokenFromOutsideBehaviour = container.resolve<SetTokenFromOutsideBehaviour>('setTokenFromOutside');
  
      // app behaviour will use callbacks to handle multiple use cases
      // so we need to use eventChannel pattern to deal with callback in sagas
      const channel = eventChannel(emitter => {
        setTokenFromOutsideBehaviour(
          payload.token,
          {
            onSuccess: (tokens, firm, user) => {
              emitter({ data: { tokens, firm, user, }, });
              emitter(END);
            },
            onError: (error) => {
              emitter({ data: {}, error, });
              emitter(END);
            },
          },
        );
  
        // Return an unsubscribe method
        return () => {
            // Perform any cleanup you need here
        };
      });
  
      // Process events until operation completes
      while (true) {
          const { data, error, }: { data: ?Object, error: ?Error, } = yield take(channel);
          if (!data && !error) {
              break;
          }
          // Handle the data...
          if (error) {throw error;}
          
          if (data) {
            const { tokens, firm, user, } = data;
            const payload: LogInSuccessPayload = { tokens, firm, user, };
            
            yield put(UserActionCreators.setTokenFromOutsideSuccess(payload, meta));
          }
      }
    } catch (e) {
      yield put(UserActionCreators.requestFailure(true, e, meta));
    }
  };
  
/*
 * register new user from invite token redux saga side effect
 * 
 * @param {RegisterCredentials} payload - payload including first name, last name and password
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const registerUser = function * (container: AwilixContainer, { payload, meta, }: { payload: RegisterUserPayload, meta: ?Object, }) {
  try {
    // resolve get payments of a client, project behaviour from awilix container
    const registerBehaviour: RegisterBehaviour = container.resolve('registerUser');

    // get data from redux state
    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: {
        authToken: payload.inviteToken,
      },
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      registerBehaviour(
        payload.data,
        withCurrentUserToken,
        {
          onSuccess: (users) => {
            emitter({ data: users, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data: message, error, }: { data: string, error: Object | string, } = yield take(channel);
      if (!message && !error) {
          break;
      }
      // Handle the data...
      if (error) {throw error;}
      
      if (message) {
        yield put(UserActionCreators.registerUserSuccess(message, meta));
      }
    }
  } catch (e) {
    yield put(UserActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(UserActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * get feature list redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const getFeatureList = function * (container: AwilixContainer, { payload, meta, }: { payload: any, meta: ?Object, }) {
  try {
    // resolve get feature list behaviour from awilix container
    const getFeatureListBehavior: GetFeatureListBehavior = container.resolve('getFeatureList');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getFeatureListBehavior(
        withCurrentUserToken,
        {
          onSuccess: (featureList: Array<Feature>) => {
            emitter({ data: featureList, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data: features, error, }: { data: Array<Feature>, error: Object | string, } = yield take(channel);
        if (!features && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (features) {
          const payload: GetFeatureListSuccessPayload = {
            featureList: features,
          };

          yield put(UserActionCreators.getFeatureListSuccess(payload, meta));
        }
    }
  } catch (e) {
    yield put(UserActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(UserActionCreators.requestFailure(true, null, meta));
    }
  }
};

const getFirmStatusInfo = function * (container: AwilixContainer, { payload, meta, }: { payload: any, meta: ?Object, }) {
  try {
    // resolve get firm status info behaviour from awilix container
    const getFirmStatusInfoBehaviour: GetFirmStatusInfoBehaviour = container.resolve('getFirmStatusInfo');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };
    
    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getFirmStatusInfoBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (firmStatusInfo: FirmStatusInfo) => {
            emitter({ data: firmStatusInfo, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data: firmStatusInfo, error, }: { data: FirmStatusInfo, error: Object | string, } = yield take(channel);
      if (!firmStatusInfo && !error) {
          break;
      }
      // Handle the data...
      if (error) {throw error;}
      
      if (firmStatusInfo) {
        const payload: GetFirmStatusInfoSuccessPayload = {
          firmStatusInfo: firmStatusInfo,
        };

        yield put(UserActionCreators.getFirmStatusInfoSuccess(payload, meta));
      }
  }



  } catch (e) {
      yield put(UserActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(UserActionCreators.requestFailure(true, null, meta));
    }
  }
};

const getFirmInvoiceInfo = function * (container: AwilixContainer, { payload, meta, }: { payload: any, meta: ?Object, }) {
  try {
    // resolve get firm invoice info behaviour from awilix container
    const getFirmInvoiceInfoBehaviour: GetFirmInvoiceInfoBehaviour = container.resolve('getFirmInvoiceInfo');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };
    
    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getFirmInvoiceInfoBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (firmInvoiceInfo: FirmInvoiceInfo) => {
            emitter({ data: firmInvoiceInfo, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data: firmInvoiceInfo, error, }: { data: FirmInvoiceInfo, error: Object | string, } = yield take(channel);
      if (!firmInvoiceInfo && !error) {
          break;
      }
      // Handle the data...
      if (error) {throw error;}
      
      if (firmInvoiceInfo) {
        const payload: GetFirmInvoiceInfoSuccessPayload = {
          firmInvoiceInfo: firmInvoiceInfo,
        };

        yield put(UserActionCreators.getFirmInvoiceInfoSuccess(payload, meta));
      }
  }



  } catch (e) {
      yield put(UserActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(UserActionCreators.requestFailure(true, null, meta));
    }
  }
};

export default (container: AwilixContainer) => ([
  takeLatest(UserTypes.LOGIN, login, container),
  takeLatest(UserTypes.LOGOUT, logout, container),
  takeLatest(UserTypes.RESET_REQUEST, resetRequest, container),
  takeLatest(UserTypes.RESET_PASSWORD, resetPassword, container),
  takeLatest(UserTypes.GET_BY_TOKEN, getByToken, container),

  takeLatest(UserTypes.GET_FIRM, getFirm, container),

  takeLatest(UserTypes.UPDATE_FIRM_SETTINGS, updateFirmSettings, container),
  takeLatest(UserTypes.UPDATE_FIRM_USER, updateFirmUser, container),

  takeLatest(UserTypes.UPDATE_USER, updateUser, container),

  takeLatest(UserTypes.SET_TOKEN_FROM_OUT_SIDE, setTokenFromOutside, container),
  takeLatest(UserTypes.REGISTER_USER, registerUser, container),

  takeLatest(UserTypes.GET_FIRM_USERS, getFirmUsers, container),

  takeLatest(UserTypes.GET_FEATURE_LIST, getFeatureList, container),

  takeLatest(UserTypes.GET_FIRM_STATUS_INFO, getFirmStatusInfo, container),

  takeLatest(UserTypes.GET_FIRM_INVOICE_INFO, getFirmInvoiceInfo, container),
]);
