// @flow
import * as R from 'ramda';

import { END, eventChannel, } from 'redux-saga';
import { type GetSingleInvoicePayload, type GetSingleInvoiceSuccessPayload, type InvoiceMap, type getInvoicesPayload, type getInvoicesSuccessPayload, } from './types';
import { InvoiceActionCreators, InvoiceTypes, } from '../actions';
import { InvoiceRedux, UserRedux, } from 'state/reducers';
import { cancelled, put, select, take, takeLatest, } from 'redux-saga/effects';

import { type AwilixContainer, } from 'awilix';
import { type GetInvoiceTokenBehaviour, } from 'app/invoice/GetInvoiceToken';
import { type GetInvoicesBehaviour, } from 'app/invoice/GetInvoices';
import { type GetSingleInvoiceBehaviour, } from 'app/invoice/GetSingleInvoice';
import { type Invoice, } from 'domain/invoice';
import { type WithCurrentUserToken, } from 'domain/user';
import { compareDesc } from 'date-fns';

/**
   * get invoices of a client, project redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
const getInvoices = function * (container: AwilixContainer, { payload, meta, }: { payload: getInvoicesPayload, meta: ?Object, }) {
  try {
    // resolve get invoices of a client, project behaviour from awilix container
    const getInvoicesBehaviour: GetInvoicesBehaviour = container.resolve('getInvoices');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getInvoicesBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (invoices) => {
            emitter({ data: invoices, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data: invoices, error, }: { data: Array<Invoice>, error: ?Error, } = yield take(channel);
        if (!invoices && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (invoices) {
          // process invoice list to js Map ['key', 'value']
          // isPaid return 0 and 1, 1 is true and 0 is false
          // server only returning invoice in asc created date, sorting to desc created date in client
          const invoiceSort = invoices.sort((a,b) => compareDesc(new Date(a.createdDate), new Date(b.createdDate)));
          const invoiceTuple = invoiceSort.map((i: Invoice) => [ i.id, {...i, isPaid: i.isPaid === 1, }, ]);
          
          const invoiceMap: InvoiceMap = new Map(invoiceTuple);

          const payload: getInvoicesSuccessPayload = { invoices: invoiceMap, };
          yield put(InvoiceActionCreators.getInvoicesSuccess(payload));
        }
    }
  } catch (e) {
    yield put(InvoiceActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(InvoiceActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
   * get single invoice, project redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
  const getSingleInvoice = function * (container: AwilixContainer, { payload, meta, }: { payload: GetSingleInvoicePayload, meta: ?Object, }) {
    try {
      // resolve get invoices of a client, project behaviour from awilix container
      const getSingleInvoiceBehaviour: GetSingleInvoiceBehaviour = container.resolve('getSingleInvoice');
      
      // get data from redux state
      const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

      const withCurrentUserToken: WithCurrentUserToken = {
        currentUserToken: token,
      };
  
      // app behaviour will use callbacks to handle multiple use cases
      // so we need to use eventChannel pattern to deal with callback in sagas
      const channel = eventChannel(emitter => {
        getSingleInvoiceBehaviour(
          payload,
          withCurrentUserToken,
          {
            onSuccess: (token) => {
              emitter({ data: token, });
              emitter(END);
            },
            onError: (error) => {
              emitter({ data: {}, error, });
              emitter(END);
            },
            onInvalidPayload: (invalidError) => {
              emitter({ data: {}, error: invalidError, });
              emitter(END);
            },
          }
        );
  
        // Return an unsubscribe method
        return () => {
            // Perform any cleanup you need here
        };
      });
  
      // Process events until operation completes
      while (true) {
          const { data: invoice, error, }: { data: Invoice, error: ?Error, } = yield take(channel);
          if (!invoice && !error) {
              break;
          }
          // Handle the data...
          if (error) {throw error;}
          
          if (invoice) {
            const payload: GetSingleInvoiceSuccessPayload = {
              invoice: {
                ...invoice,
                isPaid: invoice.isPaid !== 0,
              },
            };
            yield put(InvoiceActionCreators.getSingleInvoiceSuccess(payload, meta));
          }
      }
    } catch (e) {
      yield put(InvoiceActionCreators.requestFailure(true, e, meta));
    } finally {
      if (yield cancelled()) {
        yield put(InvoiceActionCreators.requestFailure(true, null, meta));
      }
    }
  };

/**
   * get invoice token, project redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
const getInvoiceToken = function * (container: AwilixContainer, { payload, meta, }: { payload: Invoice, meta: ?Object, }) {
  try {
    // resolve get invoices of a client, project behaviour from awilix container
    const getInvoiceTokenBehaviour: GetInvoiceTokenBehaviour = container.resolve('getInvoiceToken');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getInvoiceTokenBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (token) => {
            emitter({ data: token, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
          // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
        const { data: invoice, error, }: { data: string, error: ?Error, } = yield take(channel);
        if (!invoice && !error) {
            break;
        }
        // Handle the data...
        if (error) {throw error;}
        
        if (invoice) {
          yield put(InvoiceActionCreators.getInvoiceTokenSuccess(invoice));
        }
    }
  } catch (e) {
    yield put(InvoiceActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(InvoiceActionCreators.requestFailure(true, null, meta));
    }
  }
};

export default (container: AwilixContainer) => ([
  takeLatest(InvoiceTypes.GET_INVOICES, getInvoices, container),
  takeLatest(InvoiceTypes.GET_SINGLE_INVOICE, getSingleInvoice, container),
  takeLatest(InvoiceTypes.GET_INVOICE_TOKEN, getInvoiceToken, container),
]);
