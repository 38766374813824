// @flow
import * as R from 'ramda';
import { HeadnoteFirmSettingsState } from './types';
import { isPersistActive, storage } from '../_reduxPersist/persistConfig';
import { createReducer } from 'reduxsauce';
import { mapTransformer } from '../_reduxPersist/transformer';
import { persistReducer, PersistConfig } from 'redux-persist';
import { produce } from 'immer';
import { HeadnoteFirmSettingsTypes } from 'state/actions';

export const stateKey = 'headnoteFirmSettings';

/* ------------- Initial State ------------- */

const INITIAL_STATE: HeadnoteFirmSettingsState = ({
    headnoteFirmSettings: {}
});

/* ------------- Reducers ------------- */

const getHeadnoteFirmSettings = R.identity;
const getHeadnoteFirmSettingsSuccess = (state: HeadnoteFirmSettingsState, { payload }: { payload: HeadnoteFirmSettingsPayload }) =>
    produce(state, (draft) => {
        Object.assign(draft.headnoteFirmSettings = payload);
    });

const requestFailure = R.identity;

/* ------------- Hookup Reducers To Types ------------- */

const reducer = createReducer(INITIAL_STATE, {
    [HeadnoteFirmSettingsTypes.GET_HEADNOTE_FIRM_SETTINGS]: getHeadnoteFirmSettings,
    [HeadnoteFirmSettingsTypes.GET_HEADNOTE_FIRM_SETTINGS_SUCCESS]: getHeadnoteFirmSettingsSuccess,

    [HeadnoteFirmSettingsTypes.REQUEST_FAILURE]: requestFailure
})

const persistConfig: PersistConfig = {
    key: stateKey,
    storage,
    transforms: [
        mapTransformer({
            whitelist: 'headnoteFirmSettings',
        }),
    ],
    whitelist: []
};

const reducerMap = {
    [stateKey]: isPersistActive
        ? persistReducer < any, any> (persistConfig, reducer)
  : reducer, };

/* ------------- Selectors ------------- */

const getReducerState: (Object) => HeadnoteFirmSettingsState = (state) => (state[stateKey]);

const selectors = {
    getHeadnoteFirmSettings: ({ headnoteFirmSettings }: HeadnoteFirmSettingsState) => (headnoteFirmSettings)
};

/* ------------- Export ------------- */
export default {
    selectors,
    INITIAL_STATE,
    stateKey,
    getReducerState,
    reducerMap,
};
