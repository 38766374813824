// @flow
import * as R from 'ramda';
import React, { useCallback, useState, useMemo, } from 'react';
import Select, { type Props as RSProps, } from 'react-select';

import Button from 'react-bootstrap/Button';
import { Colors, } from 'assets/index';
import { type DropDownOption, } from './types';
import styled from 'styled-components';

const StyledContainer = styled.div`
  position: relative;
`;

const StyledMenu = styled.div`
  background-color: white;
  border: 1px solid ${Colors.lightGrey};
  margin-top: 4px;
  position: absolute;
  z-index: 2;
  border-radius: 4px;
`;

const StyledBlanket = styled.div`
  bottom: 0px;
  left: 0px;
  top: 0px;
  right: 0px;
  position: fixed;
  z-index: 1;
`;

const StyledButton = styled(Button)`
  background-color: white !important;
  color: ${Colors.greyEmpress} !important;
  border-color: ${Colors.lightGrey} !important;
  height: 28px;
  &:after {
    content: ' ▾';
  }
`;

type Props = {
  className: string,
  customSelectStyles?: Object,
  disable: boolean,
  placeholder: string,
  value: any,
  onChange: Function,
  options: Array<DropDownOption>,
  width?: number,
  isSearchable?: boolean,
  fullWidth?: boolean,
  getDropDownLabel?: (v: any) => string,
} & RSProps

const selectStyles = {
  control: provided => ({
    ...provided,
    margin: 8,
    border: `1px solid ${Colors.greyEmpress} !important`,
  }),
  menu: () => ({
    backgroundColor: 'white',
    borderTop: 1,
    borderTopStyle: 'solid',
    borderColor: Colors.lightGrey,
  }),
};

const selectHideInputStyles = {
  control: provided => ({
    ...provided,
    display: 'none !important',
  }),
  menu: () => ({
    backgroundColor: 'white',
    borderTop: 1,
    borderTopStyle: 'solid',
    borderColor: Colors.lightGrey,
  }),
};

const Container = ({ className, children, isOpen, style, target, onClose, }: any) => (
  <StyledContainer className={className} style={style}>
    {target}
    {isOpen ? <StyledMenu className="w-100">{children}</StyledMenu> : null}
    {isOpen ? <StyledBlanket onClick={onClose} /> : null}
  </StyledContainer>
);

const Dropdown = ({
  className, customSelectStyles,
  disable, placeholder = '',
  value, onChange,
  width = 190, fullWidth, isSearchable,
  getDropDownLabel, ...restProps
}: Props) => {
  const [ isOpen, setIsOpen, ] = useState(null);

  const onToggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [ isOpen, ]
  );

  const onSelectChange = useCallback((item) => {
    onToggleOpen();
    onChange && onChange(item);
  }, [ onToggleOpen, onChange, ]
  );

  // handle select style with isSearchable
  const selectStyle = {
    ...(isSearchable ? selectStyles : selectHideInputStyles),
    ...customSelectStyles,
    option: provided => ({
      ...provided,
      wordBreak: 'break-word',
    }),
  };

  // Use selected value if can't find label
  const selectedValue = useMemo(() => {
    return R.find(i => i.value === value, restProps.options);
  }, [ restProps.options, value, ]);

  return (
    <Container
      className={className}
      isOpen={isOpen}
      style={{
        width: fullWidth ? undefined : width,
      }}
      target={
        <StyledButton
          className="d-flex align-items-center justify-content-between w-100 py-0 overflow-auto"
          size="xl"
          onClick={onToggleOpen}
        >
          {
            value ?
              (
                <p className="text-dark text-left m-0 overflow-hidden text-truncate">
                  {getDropDownLabel ? getDropDownLabel(value) : value.label || selectedValue.label}
                </p>
              ) :
              (
                <p className="text-secondary text-left m-0 overflow-hidden text-truncate">
                  {placeholder}
                </p>
              )
          }
        </StyledButton>
      }
      onClose={onToggleOpen}
    >
      <Select
        autoFocus
        menuIsOpen
        backspaceRemovesValue={false}
        components={{ DropdownIndicator: null, IndicatorSeparator: null, }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        isDisabled={disable}
        isSearchable={isSearchable}
        placeholder={placeholder}
        styles={selectStyle}
        tabSelectsValue={false}
        value={value}
        onChange={onSelectChange}
        {...restProps}
      />
    </Container>
  );
};

export default Dropdown;
