export default {
  gainsBoro: '#D3D3D3',
  greenCredit: '#3c763d',
  greenContinue: '#4daf59',
  greenCheck: 'green',
  greyEmpress: '#737373',
  lightGrey: '#CCCCCC',
  lightBorderGrey: '#e5e5e5',
  whiteSmoke: '#ebeced',
  blueCurious: '#428BCA',
  blueAstral: '#3071A9',
  blueEndeavour: '#27489E',
  blueNavy: '#3685C7',
  blueTropaz: '#30547D',
  redDebit: '#a94442',
  redRemove: '#EF4343',
  redRemoveHover: '#BA2521',
  redDanger: '#dc3545',
  solitude: '#f5f5f5',
  charcoal: '#444444',
  blackGrey: '#474747',
  nyGrey: '#9C9C9C',
  dark: '#343a40',
  grey: '#495057',
  whiteBlack: '#ced4da',
  white: '#FFFFFF',
  black: '#000000',
  greyLight: '#999999',
  yellowInfo: '#F0AD4E',
  grayLight: '#f5f5f5',
  greyWhite: '#f0f0f0',
  greenBlur: '#5db85b',
  whiteBlur: '#d6e9d6',
  shadingbg: '#F8D7DA',
  shadingtext: '#7F1C47',
  shadingInputbg: '#E8F0FE',
  darkBlue: '#000033',
  greenLight: '#22B573',
  blueButton: '#3774d9',
  blueButtonHover: '#235cba',
};
