import { Field, Formik, type FormikState, } from 'formik';
import { type UserNewPasswordCredentials, UserNewPasswordCredentialsSchema, } from 'domain/user';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { Colors, } from 'assets/index';
import { FormInput, } from 'view/components/index';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${Colors.blueButton} !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;

  :hover {
    background-color: ${Colors.blueButtonHover} !important;
  }
`;

type Props = {
  onSubmit: Function,
  initialFormValues: UserNewPasswordCredentials,
  username: string,
};

const FormEl = ({ isSubmitting, status, values, errors, touched, handleChange, handleBlur, handleSubmit, }: FormikState<UserNewPasswordCredentials>) => (
  <form onSubmit={handleSubmit}>
    {
      errors.auth && (
        <Alert variant='danger'>{errors.auth}</Alert>
      )
    }
    {!!status && <Alert variant='success'>{status}</Alert>}

    <Field
      required
      component={FormInput}
      name={'password'}
      placeholder={'Password'}
      type={'password'}
    />

    <Field
      required
      component={FormInput}
      name={'confirmPassword'}
      placeholder={'Confirm Password'}
      type={'password'}
    />

    <div className='d-flex flex-row align-items-center justify-content-end pt-2'>
      {
        isSubmitting && (
          <Spinner animation="border" className='mr-2' role="status" />
        )
      }
      <StyledButton className='border-0' disabled={isSubmitting} type='submit'>{`Submit`}</StyledButton>
    </div>
  </form>
);

const ResetPasswordForm = ({ initialFormValues, onSubmit, username, }: Props) => (
  <div className='reset-password'>
    <Formik
      initialValues={initialFormValues}
      // eslint-disable-next-line
      render={FormEl}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={UserNewPasswordCredentialsSchema(username)}
      // eslint-disable-next-line
      onSubmit={onSubmit}
    />
  </div>
);

export default ResetPasswordForm;
