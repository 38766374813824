import * as R from 'ramda';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PaymentMethodRequestV from './PaymentMethodRequestV';
import { useDispatch, useSelector } from 'react-redux';
import { loadScript } from 'utilities/dynamicLoading';
import { API_CONFIG } from 'constants/index';
import { UserRedux, PaymentRedux } from 'state/reducers';
import { PaymentActionCreators } from 'state/actions';
import { useHistory, useLocation, } from 'react-router-dom';
import { IB4TClientPmtMethodRequestBody, PAYMENT_METHOD_REQUEST_TYPES } from 'domain/paymentMethodRequest'
import ErrorIcon from '@material-ui/icons/Error';

const { VGS_KEY, VGS_ENVIRONMENT } = API_CONFIG;
const vgsScriptUrl = 'https://js.verygoodvault.com/vgs-collect/2.12.0/vgs-collect.js';

const useConnect = () => {
    const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
    const headnoteApplication = useSelector(R.pipe(PaymentRedux.getReducerState, PaymentRedux.selectors.getHeadnoteApplication));

    const mapState = {
        firm,
        headnoteApplication
    };

    const dispatch = useDispatch();
    const mapDispatch = useMemo(() => ({
        submitPaymentMethodHeadnote: (payload: IB4TClientPmtMethodRequestBody) => dispatch(PaymentActionCreators.submitPaymentMethodHeadnote(payload, { thunk: true })),
        fetchHeadnoteApplication: (): Promise<any> => dispatch(PaymentActionCreators.getHeadnoteApplication({ thunk: true, })),
    }), [dispatch]);
    return {
        ...mapState,
        ...mapDispatch
    };
};

const PaymentMethodRequestVM = () => {

    const { firm, headnoteApplication, fetchHeadnoteApplication, submitPaymentMethodHeadnote } = useConnect();
    const history = useHistory();
    const location = useLocation();
    const [vgsForm, setVgsForm] = useState(null);
    const [vgsErrors, setVgsErrors] = useState({});
    const [isVgsScriptLoaded, setIsVgsScriptLoaded] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PAYMENT_METHOD_REQUEST_TYPES.CARD);
    const [error, setError] = useState(false);
    const [showInvalidRequestWarning, setShowInvalidRequestWarning] = useState(true);
    const [showSuccess, setShowSuccess] = useState(false);

    const locationState = useMemo(() => {
        return location.state;
    }, [location.state,]);

    useEffect(() => {
        const isHeadnoteActive = firm.portalSettings.isHeadnote && headnoteApplication.status === 'VERIFIED';
        setShowInvalidRequestWarning(!isHeadnoteActive);

    }, [headnoteApplication, firm]);

    useEffect(() => {
        if (isVgsScriptLoaded) {
            const vgsCollect = window.VGSCollect.create(VGS_KEY, VGS_ENVIRONMENT, function (state) { });
            setVgsForm(vgsCollect);
        }
    }, [isVgsScriptLoaded]);

    useEffect(() => {
        const loadVGS = async () => {
            const isLoaded = await loadScript(vgsScriptUrl);
            setIsVgsScriptLoaded(isLoaded);
        }
        if (!isVgsScriptLoaded && selectedPaymentMethod === PAYMENT_METHOD_REQUEST_TYPES.CARD) { loadVGS(); }
    }, [isVgsScriptLoaded, selectedPaymentMethod]);

    useEffect(() => {
        if (firm && firm.portalSettings.isHeadnote) {
            fetchHeadnoteApplication();
        }

    }, [firm]);

    const vgsSubmit = () => {
        return new Promise((resolve, reject) => {
            vgsForm.submit('/api/cards/volatile', {},
                function (status, data) {
                    const result = {
                        saveCard: false,
                        card_details: {
                            cardholder_name: data.cardholder_name,
                            card_type: vgsForm.state.card_number.cardType,
                            card_number_token: data.card_number,
                            card_zip_code_token: data.zip_code,
                            card_cvv_token: data.cvv_code, // optional
                            card_exp_date: data.exp_date,
                            last4: vgsForm.state.card_number.last4, // optional
                        }
                    }
                    resolve(result);
                },
                function (errors) {
                    reject({ type: 'VGS errors', errors });
                });
        });
    }

    const afterSubmitSuccess = useCallback((successData) => {
        setShowSuccess(true);
    }, [history]);

    const submitPayment = useCallback(async (values, actions: FormikActions) => {
        try {
            actions.setSubmitting(true);
            const paymentMethodRequestBody: IB4TClientPmtMethodRequestBody = {
                userEmail: values.email,
                userFName: values.first_name,
                userLName: values.last_name,
                card: null,
                bank: null,
            };

            switch (values.paymentMethod) {
                case PAYMENT_METHOD_REQUEST_TYPES.CARD: {
                    const { card_details } = await vgsSubmit();
                    paymentMethodRequestBody.card = card_details;
                    break;
                }
                case PAYMENT_METHOD_REQUEST_TYPES.ARNUMBER: {
                    const { accountType, routingNumber, accountNumber } = values;
                    const accountNumberlength = accountNumber.length;
                    const accountNumberLast4 = accountNumber.slice(accountNumberlength - 4);
                    const accountName = `${accountType} Account x${accountNumberLast4}`;
                    const bankAccountInfo: IBankAccountInfo = {
                        routingNumber,
                        accountNumber,
                        accountClass: accountType,
                        accountName
                    };
                    paymentMethodRequestBody.bank = bankAccountInfo;
                    paymentMethodRequestBody.source = 'ACH';
                    break;
                }
                case PAYMENT_METHOD_REQUEST_TYPES.PLAID: {
                    const plaidAccountDetails = R.path(['plaidAccountDetails',], locationState);
                    if (!plaidAccountDetails) {
                        throw Error('Plaid Not Connected');
                    }
                    paymentMethodRequestBody.source = 'ACH';
                    paymentMethodRequestBody.bank = plaidAccountDetails;
                    break;
                }
            }

            const result = await submitPaymentMethodHeadnote(paymentMethodRequestBody);
            afterSubmitSuccess(result);

        } catch (e) {
            if (e && e.type && e.type == 'VGS errors') {
                setVgsErrors(e.errors);
            } else {
                setError({ msg: e.message || e, });
            }
        } finally {
            actions.setSubmitting(false);
        }
    });

    const invalidRequestWarning = () => {
        return (
            <div style={{ marginTop: '63px', textAlign: 'center' }}>
                <ErrorIcon
                    style={{ fontSize: '63px', color: '#ee6723', marginBottom: '30px' }}
                />
                <h3>This link is no longer active.</h3>
                <br />
                <p>Please contact your attorney to request a new payment method link.</p>
            </div>
        );
    };

    return (
        <>
            {!showInvalidRequestWarning &&
                <PaymentMethodRequestV
                    vgsForm={vgsForm}
                    setVgsForm={setVgsForm}
                    vgsErrors={vgsErrors}
                    error={error}
                    firm={firm}
                    submitPayment={submitPayment}
                    showSuccess={showSuccess}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    setVgsLoadState={setIsVgsScriptLoaded}
                />
            }
            {showInvalidRequestWarning && invalidRequestWarning()}
        </>
    );

}

export default PaymentMethodRequestVM;

type IBankAccountInfo = {
    routingNumber: string,
    accountNumber: string,
    accountClass: string,
    accountName: string,
}