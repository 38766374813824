import { HeadnoteFirmSettings, FirmRepository } from "domain/firm";
import { WithCurrentUserToken } from 'domain/user';

type Callbacks = {
    onSuccess: (i: HeadnoteFirmSettings) => void;
    onError: (e: Error) => void;
}

type Dependencies = {
    firmRepository: FirmRepository;
}

type Payload = {
    firmId: number;
}

export type GetHeadnoteFirmSettingsBehaviour = (
    Payload, WithCurrentUserToken, Callbacks
) => any

export default ({ firmRepository }: Dependencies) => {

    /**
     * Get the Firm's Headnote settings
     * @param {Payload} payload - firm id
     * @param {WithCurrentUserToken} withCurrentUserToken - auth token
     * @param {Callbacks} callbacks - call back for each use case 
     * @returns 
     */
    const getHeadnoteFirmSettings: GetHeadnoteFirmSettingsBehaviour = async (
        payload, withCurrentUserToken, { onSuccess, onError }
    ) => {
        try {
            const headnoteFirmSettings: HeadnoteFirmSettings = await firmRepository.getHeadnoteFirmSettings(withCurrentUserToken);
            return onSuccess(headnoteFirmSettings);
        } catch (e) {
            return onError(e);
        }
    }
    return getHeadnoteFirmSettings;
}