/* @flow */
import { type User, type WithCurrentUserToken, } from 'domain/user';

import { type FirmRepository, } from 'domain/firm';

type Callbacks = {
  onSuccess: (users: User[]) => void,
  onError: (e: Error) => void,
};

type Dependencies = {
  firmRepository: FirmRepository,
};

export type GetFirmUsersBehaviour = (
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export default ( { firmRepository, }: Dependencies, ) => {
  /**
   * get firm users
   * 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getFirmUsersBehaviour: GetFirmUsersBehaviour = async (
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      // get firm users
      const users = await firmRepository.getFirmUsers(withCurrentUserToken);

      return onSuccess(users);
    } catch(error) {
      return onError(error);
    }
  };

  return getFirmUsersBehaviour;
};
