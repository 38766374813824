/* @flow */
import * as R from 'ramda';

import React, { useCallback, useState, } from 'react';

import AccountSettingsV from './AccountSettingsV';
import { UserRedux, } from 'state/reducers';
import { useSelector, } from 'react-redux';

/**
 * redux connect hook
 */
const useConnect = () => {
  // mapState
  const firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const mapState = {
    firm,
  };

  // mapDispatch
  // const dispatch = useDispatch();

  return {
    ...mapState,
    // ...mapDispatch,
  };
};

const AccountSettingsVM = () => {
  // react redux connect
  const rdx = useConnect();

  const [ settingStatus, setSettingStatus, ] = useState<string | undefined>(undefined);

  const cancelSettingStatus = useCallback(() => {
    setSettingStatus(undefined);
  }, []);

  return (
    <AccountSettingsV
      {...rdx}
      cancelSettingStatus={cancelSettingStatus}
      setSettingStatus={setSettingStatus}
      settingStatus={settingStatus}
    />
  );
};

export default AccountSettingsVM;
