// @flow
import {
  Form,
  InputGroup,
} from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';

const StyledFormCheck = styled(Form.Check)`
  min-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const StyledFormLabel = styled(Form.Label)`
  font-weight: bold;
`;
const StyledFormControl = styled(Form.Control)`
  text-align: right;
  font-weight: 500;
`;

type Props = {
  amount: Number,
  setAmount: Function,
};

export default ({amount, setAmount,}:Props) => {
  return (
    <Form>
      <StyledFormCheck label="Pay invoice total: $1,319.87" name="formHorizontalRadios" type="radio" value={1319.87} onChange={setAmount} />
      <StyledFormCheck label="Pay account balance: $6,471.22" name="formHorizontalRadios" type="radio" value={6471.22} onChange={setAmount} />
      <StyledFormCheck label="Pay other amount" name="formHorizontalRadios" type="radio" />
      <Form.Group>
        <StyledFormLabel>{`Payment Amount:`}</StyledFormLabel>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroupPrepend" >{`$`}</InputGroup.Text>
          </InputGroup.Prepend>
          <StyledFormControl type="text" value={amount} onChange={setAmount} />
        </InputGroup>
      </Form.Group>
    </Form>
  );
};
