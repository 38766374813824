// @flow
import * as R from 'ramda';

import { type ProjectState, type getProjectsSuccessPayload, } from './types';
import { type Project, } from 'domain/project';
import { isPersistActive, storage, } from '../_reduxPersist/persistConfig';

import { ProjectTypes, UserTypes, } from '../actions';
import { createReducer, } from 'reduxsauce';
import { mapTransformer, } from '../_reduxPersist/transformer';
import { persistReducer, type PersistConfig, } from 'redux-persist';
import { produce, } from 'immer';

export const stateKey = 'project';

/* ------------- Initial State ------------- */
const INITIAL_STATE: ProjectState = ({
  projects: new Map(),
  selectedProject: undefined,
});

/* ------------- Reducers ------------- */
const getProjects = R.identity;

const initDataSuccess = (state: UserState) =>
  produce(state, (draft) => {
    Object.assign(draft, INITIAL_STATE);
  });

const getProjectsSuccess = (state: ProjectState, { payload, }: { payload: getProjectsSuccessPayload, }) =>
  produce(state, (draft) => {
    Object.assign(draft, payload);
  });

const setSelectedProject = (state: ProjectState, { payload, }: { payload: Project, }) =>
  produce(state, (draft) => {
    draft.selectedProject = payload;
  });

const requestFailure = R.identity;

/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
  [ProjectTypes.GET_PROJECTS]: getProjects,
  [ProjectTypes.GET_PROJECTS_SUCCESS]: getProjectsSuccess,

  [ProjectTypes.SET_SELECTED_PROJECT]: setSelectedProject,

  [UserTypes.LOGOUT_SESSION_SUCCESS]: initDataSuccess,

  [ProjectTypes.REQUEST_FAILURE]: requestFailure,
});

const persistConfig: PersistConfig = {
  key: stateKey,
  storage,
  transforms: [
    mapTransformer({
      whitelist: 'projects',
    }),
  ],
};

const reducerMap = { [stateKey]: isPersistActive
  ? persistReducer<any, any>(persistConfig, reducer)
  : reducer, };

/* ------------- Selectors ------------- */
const getReducerState: (Object) => ProjectState = (state) => (state[stateKey]);

const selectors = {
  getProjects: ({ projects, }: ProjectState) => (projects),
  getProjectById: (id: number) => ({ projects, }: ProjectState) => (projects.get(id)),
  getSelectedProjects: ({ selectedProject, }: ProjectState): Project | undefined => (selectedProject),
};

/* ------------- Export ------------- */
export default {
  selectors,

  // default export
  INITIAL_STATE,

  stateKey,
  getReducerState,
  reducerMap,
};
