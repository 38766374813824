// @flow
import React, { FC, useEffect, useMemo, useState, } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import { Loading } from 'view/components/Loading';
import { SetOutsideTokenPayload } from 'state/user/types';
import { UserActionCreators } from 'state/actions';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

const StyledContainer = styled(Container)`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TOKEN_PARAM_KEY = 'token';
const REDIRECT_URL_PARAM_KEY = 'redirect_url';

// connect redux
const useConnect = () => {
  // // mapState

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    setOutsideToken: (payload: SetOutsideTokenPayload) => dispatch(UserActionCreators.setTokenFromOutSide(payload, { thunk: true, })),
  }), [ dispatch, ]);

  return {
    // ...mapState,
    ...mapDispatch,
  };
};

const AccessPage: FC = () => {
  const {
    setOutsideToken,
  } = useConnect();

  // router history
  const history = useHistory();
  // get query params from url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get(TOKEN_PARAM_KEY);
  const redirectUrl = params.get(REDIRECT_URL_PARAM_KEY);

  const [ error, setError ] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!token || !redirectUrl) return;

    const setToken = async () => {
      try {
        setError(undefined);

        // set outside token
        const params: SetOutsideTokenPayload = {
          token,
        }
        await setOutsideToken(params);

        // nav to redirect url if success
        history.push(redirectUrl);
      } catch (e) {
        setError(e.message || e);
      }
    }

    setToken();
  }, [ token, redirectUrl, setOutsideToken, history, ]);

  // redirect to home if no token or redirect url provided
  if (!token || !redirectUrl)  {
    return <Redirect to='/' />
  }

  return (
    <StyledContainer>
      {
        error ? (
          <Alert className='my-3 w-100' variant={`danger`}>
            {error}
          </Alert>
        ) : (
          <Loading />
        )
      }
    </StyledContainer>
  );
};

export default AccessPage;
