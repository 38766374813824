import React from 'react';
import styled from 'styled-components';
import { APP_CONFIG } from 'constants/index';
import { Colors, Images } from 'assets/index';
import SupportInfo from "./components/SupportInfo";
import {STATUSES} from "../PaymentsPanel";
import ApplicationStatus from "./components/ApplicationStatus";
import FeatureList from "./components/FeatureList";
import { Button } from 'react-bootstrap';
import { Image } from 'react-bootstrap';

export default () => {
    return (
        <>
            <StyledH5 className="mt-3">{`You have not completed your application!`}</StyledH5>
            <ApplicationStatus status={STATUSES.INCOMPLETE} />
            <FeatureList />
            <StyledButton className="mb-3" href={APP_CONFIG.activeForFreeLink} target="_blank">
            {`Complete Application`}
            </StyledButton>
            <Image className="d-block pt-3" src={Images.settings.ccImages} width="300" />
            <SupportInfo />
        </>
    )
}

const StyledH5 = styled.h5`
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
`;

const StyledButton = styled(Button)`
  && {
    background: ${Colors.greenLight};
    line-height: 28px;
    width: 180px;
    white-space: nowrap;
    font-weight: bold;
    &:active {
      background: ${Colors.greenLight} !important;
    }
    &:hover {
      background: #00875a !important;
      border-color: #00875a !important;
    }
    border: none;
  }
`;