// @flow
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import React from 'react';
import Row from 'react-bootstrap/Row';

type Props = {
  alert: string,
  className?: string,
};

const FormAlert = ({ alert, className = '', }: Props) => {
  if (!alert) {return null;}

  return (
    <Row className={`pb-4 ${className}`}>
      <Col>
        <Alert className='mb-0' variant="info">{alert}</Alert>
      </Col>
    </Row>
  );
};

export default FormAlert;
