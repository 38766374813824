import React, { useState } from 'react';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import { Images } from 'assets/index';
import { LINK2PAY_ACCOUNT_CLASS } from 'domain/payment';


const accountTypeOptions = [{
    label: 'Checking',
    type: LINK2PAY_ACCOUNT_CLASS.CHECKING
}, {
    label: 'Savings',
    type: LINK2PAY_ACCOUNT_CLASS.SAVINGS
}];

type Props = {
    error: Object;
};

const Link2PayECheckARNumbersV = ({ error, subtitle, formik }: Props) => {

    const [selectedAccountClass, setSelectedAccountClass] = useState(LINK2PAY_ACCOUNT_CLASS.CHECKING)

    const onSelectedClassChanged = (event) => {
        setSelectedAccountClass(event.target.value);
        formik.setFieldValue("accountType", event.target.value);
    }

    return (
        <Grid container item spacing={2}>
            <Grid item xs={12}>
                <div className={subtitle}>eCheck Payment via Account & Routing Numbers</div>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
                <img src={Images.payment.check} width="331" />
            </Grid>
            <Grid container item spacing={4}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        onChange={onSelectedClassChanged}
                        label={"Account Type"}
                        name={"accountType"}
                        type={'text'}
                        variant="outlined"
                        id={"accountType"}
                        value={selectedAccountClass}
                        select
                    >
                        {accountTypeOptions.map((option) => (
                            <MenuItem key={option.type} value={option.type}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        label={"ABA Routing Number"}
                        name={"routingNumber"}
                        type={'text'}
                        variant="outlined"
                        id={"routingNumber"}
                        value={formik.values.routingNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.routingNumber && Boolean(formik.errors.routingNumber)}
                        helperText={(formik.touched.routingNumber && formik.errors.routingNumber) || " "}
                    ></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        label={"Account Number"}
                        name={"accountNumber"}
                        type={'text'}
                        variant="outlined"
                        id={"accountNumber"}
                        value={formik.values.accountNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
                        helperText={(formik.touched.accountNumber && formik.errors.accountNumber) || " "}
                    ></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        label={"Re-Enter Account Number"}
                        name={"accountNumberConfirm"}
                        type={'text'}
                        variant="outlined"
                        id={"accountNumberConfirm"}
                        value={formik.values.accountNumberConfirm}
                        onChange={formik.handleChange}
                        error={formik.touched.accountNumberConfirm && Boolean(formik.errors.accountNumberConfirm)}
                        helperText={(formik.touched.accountNumberConfirm && formik.errors.accountNumberConfirm) || " "}
                    ></TextField>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Link2PayECheckARNumbersV;