/* @flow */

import { Collapse, IconButton } from "@material-ui/core";
import React, { ReactNode } from "react";
import { Alert } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

// Collapsible alert
export default function(props: IB4TAlertProps) {
    return (
        <Collapse in={props.isOpen} classes={props.collapseClasses}>
            <Alert
            classes={props.alertClasses}
            severity={props.alertType}
            icon={props.icon}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={props.onClose}
                >
                    <Close fontSize="inherit" />
                </IconButton>
            }>
            {props.children || props.message}
            </Alert>
        </Collapse>
    )
}

export interface IB4TAlertProps {
    isOpen: boolean; // whether Alert should be visible
    onClose?: () => void; // function invoked upon close button being clicked
    message?: string; // test the alert should display
    collapseClasses?: {[x: string]: any}; // classes to apply to collapsible container that surrounds alert
    alertClasses?: {[x: string]: any}; // classes to apply to alert itself
    alertType: AlertTypes; // the type of alert theme to apply
    icon?: boolean; // setting to false will remove the icon
    children?: ReactNode;
}

export const AlertTypes = {
    Error: "error",
    Warning: "warning",
    Info: "info",
    Success: "success"
}