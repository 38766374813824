// @flow
import { CardElement, } from 'react-stripe-elements';
import { Colors, } from 'assets/index';
import { Field, } from 'formik';
import Form from 'react-bootstrap/Form';
import { FormInput, } from 'view/components';
import React, { useMemo, } from 'react';
import styled from 'styled-components';

type props = {
  errors: object,
  values: object,
}

const StyledFormStripe = styled.div`
  padding: 0.5rem 0.75rem;
  color: ${Colors.grey};
  border: 1px solid ${Colors.whiteBlack};
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  &.is-invalid {
    background: ${Colors.shadingInputbg};
  }
`;

const StyledFormInput = styled(FormInput)`
  && {
    .is-invalid {
      background: ${Colors.shadingInputbg};
    }
    .invalid-feedback {
      display: none;
    }
  }
`;

const CardForm = ({ errors, values, } : props) => {
  // Check if form card is not valid
  const ErrorStatus = useMemo(() => {
    if(errors.code) {
      return true;
    }
    return false;
  }, [ errors, ]);

  return (
    <Form>
      <Field
        required
        className={`payment-validate ${errors.name ? 'invalid' : 'valid'}`}
        component={StyledFormInput}
        label={`Name on Card:`}
        maxLength={26}
        name={'name'}
        type={'text'}
      />

      <Form.Group>
        <StyledFormStripe className={ErrorStatus && 'is-invalid'}>
          <CardElement />
        </StyledFormStripe>
      </Form.Group>
      
    </Form>
  );
};

export default CardForm;
