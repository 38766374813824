// @flow
import * as R from 'ramda';
import { type GetSingleRetainerSuccessPayload, type RetainerState, type GetRetainersSuccessPayload, } from './types';
import { isPersistActive, storage, } from '../_reduxPersist/persistConfig';

import { RetainerTypes } from '../actions';
import { createReducer, } from 'reduxsauce';
import { mapTransformer, } from '../_reduxPersist/transformer';
import { persistReducer, type PersistConfig, } from 'redux-persist';
import { produce, } from 'immer';

export const stateKey = 'retainer';

/* ------------- Initial State ------------- */
const INITIAL_STATE: RetainerState = ({
    retainers: new Map()
});

/* ------------- Reducers ------------- */
 const getRetainers = R.identity;

const getRetainersSuccess = (state: RetainerState, { payload }: { payload: GetRetainersSuccessPayload }) =>
    produce(state, (draft) => {
        Object.assign(draft, payload);
    });

const getRetainerToken = R.identity;
const getRetainerTokenSuccess = R.identity;

const getRetainerRequestAmount = R.identity;
const getSingleRetainerSuccess = (state: RetainerState, { payload, }: { payload: GetSingleRetainerSuccessPayload, }) =>
produce(state, (draft) => {
    draft.retainers.set(payload.retainerRequestId, payload);
  });

const requestFailure = R.identity;

/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
    [RetainerTypes.GET_RETAINERS]: getRetainers,
    [RetainerTypes.GET_RETAINERS_SUCCESS]: getRetainersSuccess,

    [RetainerTypes.GET_RETAINER_TOKEN]: getRetainerToken,
    [RetainerTypes.GET_RETAINER_TOKEN_SUCCESS]: getRetainerTokenSuccess,
  
    [RetainerTypes.GET_RETAINER_REQUEST_AMOUNT]: getRetainerRequestAmount,
    [RetainerTypes.GET_SINGLE_RETAINER_SUCCESS]: getSingleRetainerSuccess,
  
    [RetainerTypes.REQUEST_FAILURE]: requestFailure,
  });

  const persistConfig: PersistConfig = {
    key: stateKey,
    storage,
    transforms: [
      mapTransformer({
        whitelist: 'retainers',
      }),
    ],
    whitelist: [],
  };

  const reducerMap = { [stateKey]: isPersistActive
    ? persistReducer<any, any>(persistConfig, reducer)
    : reducer, };

/* ------------- Selectors ------------- */
const getReducerState: (Object) => RetainerState = (state) => (state[stateKey]);

const selectors = {
  getRetainerRequestAmount: ({ retainers, }: RetainerState) => (retainers),
  getRetainers: ({ retainers, }: RetainerState) => (retainers)
};

/* ------------- Export ------------- */
export default {
    selectors,
  
    // default export
    INITIAL_STATE,
  
    stateKey,
    getReducerState,
    reducerMap,
  };