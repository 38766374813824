/* @flow */
import * as R from 'ramda';

import React, { useCallback, useMemo, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import { ERRORS, } from 'constants/index';
import { type FormikActions, } from 'formik';
import LoginPageV from './LoginPageV';
import { UserActionCreators, } from 'state/actions';
import { type UserAuthInfo, } from 'domain/user';
import { UserRedux, } from 'state/reducers';
import { useLocation, } from "react-router-dom";

/**
 * redux connect hook
 */
const useConnect = () => {
  // mapState
  const user = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const mapState = {
    firm,
    user,
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    onLogin: (userInfo: UserAuthInfo) => dispatch(UserActionCreators.login(userInfo, { thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

const initialFormValues: UserAuthInfo = {
  username: '',
  password: '',
};

const LoginPageVM = () => {
  // react redux connect
  const rdx = useConnect();
  const { onLogin, } = rdx;

  // get message and replace location state
  const location = useLocation();

  // init message state with router state params
  const [ message, setMessage, ] = useState(R.path([ 'state', 'message', ], location));

  /**
   * set message state to null to hide message on close
   * 
   */
  const onClose = useCallback<() => void>(() => {
    setMessage(null);
  }, [ setMessage, ]);

  /**
   * on log in form submit
   * 
   * @param {UserAuthInfo} values - auth info values from form
   * @param {FormikActions} actions - formik bag actions
   */
  const onSubmit = useCallback(async (values: UserAuthInfo, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);
      await onLogin(values);
    } catch (e) {
      // handle error
      actions.setErrors({
        auth: e.message || ERRORS.login,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ onLogin, ]);

  return (
    <LoginPageV
      {...rdx}
      initialFormValues={initialFormValues}
      message={message}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default LoginPageVM;
