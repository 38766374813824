// @flow
import React, { useCallback, } from 'react';

import { Colors, } from 'assets';
import Feedback from 'react-bootstrap/Feedback';
import { type FieldProps, } from 'formik';
import FormGroup from 'react-bootstrap/FormGroup';
import FormCheck from 'react-bootstrap/FormCheck';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

const StyledForm = styled(FormGroup)`
  .is-invalid {
    &::placeholder {
      color: ${Colors.redDanger} !important;
    }
  }

  .boolean-button {
    border: none !important;
    font-weight: bold !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .active {
    background-color: ${Colors.blueCurious} !important;

    :hover {
      background-color: ${Colors.blueAstral} !important;
    }
  }

  .inactive {
    color: ${Colors.blackGrey}
    background-color: transparent !important;
  }
`;

const StyledFormError = styled(Feedback)`
  color: ${Colors.redDanger};
`;

type Props = {
  className: String,
  label: String,
  disabled?: boolean,
};

const FormCheckBox = ({
  field: { value, name, },
  form: { errors, setFieldValue, },
  label, className, disabled,
  ...restProps
}: FieldProps<any> & Props) => {
  // field states:
  const _error = errors[name];

  const onChangeValue = useCallback(() => {
    // formik handleChange will look for an input's id attribute
    // https://jaredpalmer.com/formik/docs/api/formik#handlechange-e-reactchangeevent-any-void
    setFieldValue(name, !value);
  }, [ setFieldValue, name, value, ]);

  return (
    <StyledForm as={Row} className={`mx-0 ${className}`}>
      {
        _error && <StyledFormError type="invalid">{_error}</StyledFormError>
      }

      <FormCheck
        checked={value}
        isInvalid={_error}
        label={label}
        name={name}
        type='checkbox'
        value={value}
        onChange={onChangeValue}
        id="value"
        {...restProps}
      />
    </StyledForm>
  );
};

export default FormCheckBox;
