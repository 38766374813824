import { type UpdateUserPayload, } from 'state/user/types';
import { UserActionCreators, } from 'state/actions';
import { useDispatch, } from 'react-redux';
import { useMemo, } from 'react';

/**
 * custom hooks for getting update user actions dispatch
 *
 */
export const useUpdateUserInfo = () => {
  const dispatch = useDispatch();

  const mapDispatch = useMemo(() => ({
    updateUserInfo: (payload: UpdateUserPayload) => dispatch(UserActionCreators.updateUser(payload, { thunk: true, })),
  }));

  return {
    ...mapDispatch,
  };
};
