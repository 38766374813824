import { Field, Formik, type FormikState, } from 'formik';
import { type UserResetCredentials, UserResetCredentialsSchema, } from 'domain/user';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { Colors, } from 'assets/index';
import { FormInput, } from 'view/components/index';
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${Colors.blueButton} !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;

  :hover {
    background-color: ${Colors.blueButtonHover} !important;
  }
`;

type Props = {
  onSubmit: Function,
  initialFormValues: UserResetCredentials
};

const FormEl = ({ isSubmitting, values, errors, touched, handleChange, handleBlur, handleSubmit, }: FormikState<UserResetCredentials>) => (
  <form onSubmit={handleSubmit}>
    {
      errors.auth && (
        <Alert variant='danger'>{errors.auth}</Alert>
      )
    }

    <Field
      required
      component={FormInput}
      name={'email'}
      placeholder={'Email'}
      type={'email'}
    />

    <div className='d-flex flex-row align-items-center justify-content-end pt-2'>
      {
        isSubmitting && (
          <Spinner animation="border" className='mr-2' role="status" />
        )
      }
      <StyledButton className='border-0' disabled={isSubmitting} type='submit'>{`Reset Password`}</StyledButton>
    </div>
  </form>
);

const ResetPasswordForm = ({ initialFormValues, onSubmit, }: Props) => (
  <div className='reset-password'>
    <Formik
      initialValues={initialFormValues}
      // eslint-disable-next-line
      render={FormEl}
      validationSchema={UserResetCredentialsSchema}
      // eslint-disable-next-line
      onSubmit={onSubmit}
    />
  </div>
);

export default ResetPasswordForm;
