// @flow
import { type ClientPortalSettings, PAYMENT_OPTIONS, } from 'domain/firm';
import React, { useCallback, } from 'react';

import { Colors, } from 'assets';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faCheck, } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const StyledStopButton = styled.span`
  color: ${Colors.blueButton};
  margin-left: 8px;
  font-weight: bold;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StopPaymentButton = (props) => {
  return (
    <StyledStopButton {...props}>
      {`Stop using`}
    </StyledStopButton>
  );
};

type Props = {
  title: string,
  name: string,
  onStopPaymentClick: Function,
};

const PaymentInfo = ({
  title = '', onStopPaymentClick, name
}: Props) => {
  const onClick = useCallback(() => {onStopPaymentClick(name); window.mixpanel.track('b4tp_stop_using')}, [onStopPaymentClick]);
  return (
    <p>
      <FontAwesomeIcon
        icon={faCheck}
        style={{ color: Colors.greenCheck, }}
      />
      <b className="px-3">{`${title} `}</b>
      {'|'}
      <StopPaymentButton onClick={onClick} />
    </p>
  );
};

type PropsCustomTab = {
  portalSettings: ClientPortalSettings,
  onStopPaymentClick: Function,
};

const CustomTab = ({ onStopPaymentClick, portalSettings, }: PropsCustomTab) => {

  const hasCurrentProcessor = portalSettings.isStripe || portalSettings.isCustom || portalSettings.isLawpay || portalSettings.isPaypal;

  return (
    <div>
      {/* TODO */}
      <p>
        {hasCurrentProcessor ? `You have the following payment options configured:` : `You have not set up a payment option yet.`}
      </p>
      {
        portalSettings && portalSettings.isCustom && (
          <PaymentInfo
            name={PAYMENT_OPTIONS.custom.key}
            title={`Custom Payment Link`}
            onStopPaymentClick={onStopPaymentClick}
          />
        )
      }
      {
        portalSettings && portalSettings.isLawpay && (
          <PaymentInfo
            name={PAYMENT_OPTIONS.lawPay.key}
            title={`Lawpay`}
            onStopPaymentClick={onStopPaymentClick}
          />
        )
      }
      {
        portalSettings && portalSettings.isStripe && (
          <PaymentInfo
            name={PAYMENT_OPTIONS.stripe.key}
            title={`Stripe`}
            onStopPaymentClick={onStopPaymentClick}
          />
        )
      }
      {
        portalSettings && portalSettings.isPaypal && (
          <PaymentInfo
            name={PAYMENT_OPTIONS.paypal.key}
            title={`PayPal`}
            onStopPaymentClick={onStopPaymentClick}
          />
        )
      }
    </div>
  );
};

export default CustomTab;
