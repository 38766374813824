import React, { useEffect, useMemo, useState, } from 'react';
import { getLocationQueryString, jwtDecoded, } from 'utilities/stringUtils';
import { useLocation, useHistory, } from "react-router-dom";
import { Container, Alert, } from 'react-bootstrap';
import { useDispatch, } from 'react-redux';
import { UserActionCreators, } from 'state/actions';
import { type UserToken, } from 'domain/user';
import { Loading, } from 'view/components/Loading';
import { ERRORS, } from 'constants/index';

const useConnect = () => {
    const dispatch = useDispatch();

    const mapDispatch = useMemo(() => ({
        setTokens: (payload: UserToken) => dispatch(UserActionCreators.setTokens(payload)),
        loadFirm: () => dispatch(UserActionCreators.getFirm({ thunk: true, })),
    }), [dispatch,]);

    return {
        ...mapDispatch,
    };
}


type DecodeData = {
    link2PayId: string,
    firmId: string
}

const Link2PayFromLinkVM = () => {
    const { setTokens, loadFirm } = useConnect();

    const location = useLocation();
    const history = useHistory();
    const [error, setError] = useState(null);

    useEffect(() => {
        (
            async function checkLink2PayGuest() {
                try {
                    const { link2PayToken }: { link2PayToken: string } = getLocationQueryString(location.search);
                    const { urlGuid } : DecodeData = jwtDecoded(link2PayToken);
                    // save token and re-use
                    const data: UserToken = {
                        authToken: link2PayToken
                    };
                    await setTokens(data);

                    // Load firm to get guest setting
                    const firm: Firm = await loadFirm();

                    if (firm.portalSettings.allowGuests) {
                        const url = {
                            pathname: `/link2pay/${urlGuid}`,
                            state: {}
                        };
                        history.push(url);
                    } else {
                        history.push('/login');
                    }
                } catch (e) {
                    setError(ERRORS.link2PayToken);
                }
            }
        )();

    }, [location, history, setTokens, loadFirm]);

    return (
        <Container>
            { error ? (
                <Alert className="mt-4" variant="warning" >
                    {error}
                </Alert>
            ) : <Loading />}
        </Container>
    );
}

export default Link2PayFromLinkVM;