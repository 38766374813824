/* @flow */
import * as R from 'ramda';

import React, { useCallback, useMemo, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import { type FormikActions, } from 'formik';
import ResetRequestV from "./ResetRequestV";
import { type ResetResponsePayload, } from 'state/user/types';
import { UserActionCreators, } from 'state/actions';
import { UserRedux, } from 'state/reducers';
import { type UserResetCredentials, } from 'domain/user';
import { useHistory, } from "react-router-dom";

const useConnect = () => {
  // mapState
  const user = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const mapState = {
    user,
  };

  // mapDispatch
  const dispatch = useDispatch();
  
  const mapDispatch = useMemo(() => ({
    onReset: (userReset: UserResetCredentials) => dispatch(UserActionCreators.resetRequest(userReset, { thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

const initialFormValues: UserResetCredentials = {
  email: '',
};

 const ResetRequestVM = () => {
  // react redux connect
  const rdx = useConnect();
  const { onReset, } = rdx;

  // History for redirect
  let history = useHistory();

  /**
   * on login form submit
   * request reset password then nav to login screen with success message
   * 
   * @param {UserResetCredentials} values - form values
   * @param {FormikActions} actions - formikbag actions
   */
  const onSubmit = useCallback(async (values: UserResetCredentials, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);

      const { message, }: ResetResponsePayload = await onReset(values);
      history.push('/login', { message, });
    } catch (e) {
      // handle error
      actions.setStatus(null);
      actions.setErrors({
        auth: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ onReset, history, ]);

  return (
    <ResetRequestV
      {...rdx}
      initialFormValues={initialFormValues}
      onSubmit={onSubmit}
    />
  );
};

export default ResetRequestVM;
