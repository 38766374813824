/* @flow */
import { type Node, useEffect, } from 'react';

import { UserRedux, } from 'state/reducers';
import { type UserState, } from 'state/user/types';
import { connect, } from 'react-redux';

type Props = {
  user: UserState,
  children: Node
};

const AuthBoundary = (props: Props) => {
  const { children, } = props;

  useEffect(() => {
    // props.loadUser();
  }, []);

  // if(user.status === UserStatuses.INIT || user.status === UserStatuses.SIGNING) {
  //   return null;
  // }

  return children;
};

const mapStateToProps = (state) => {
  const { user, } = UserRedux.getReducerState(state);
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthBoundary);
