// @flow
import * as R from 'ramda';

import { type AddBankAccountPayload, type AddCardPayload, type ConnectPayload, type ConnectUserLawpayPayload, type ConnectUserStripePayload, type ConnectUserStripeSuccessPayload, type GetPaymentMethodSuccessPayload, type GetPaymentsPayload, type GetPaymentsSuccessPayload, type GetUserBankAccountsSuccessPayload, type GetUserCardsSuccessPayload, type LawpayPaymentPayload, type PaymentMap, type PaypalCreateOrderPayload, type PaypalPaymentPayload, type RemoveBankAccountPayload, type RemoveCardpayload, type StripePaymentPayload, type VerifyBankAccountPayload, type GetInvoicePaymentsPayload, type LinkToPayPayload, type LinkToPayTemplate, } from './types';
import { type BankAccountData, type CardData, type ConnectCustomPaymentBody, type Payment, type PaymentRepository, type ConnectPaypalBody, type RequiredFields, type LinkToPay, } from 'domain/payment';
import { type ConnectCusttomPaymentBehaviour, type DisconnectCusttomPaymentBehaviour, } from 'app/payment/CustomPayment;';
import { type ConnectLawpayBehaviour, type DisconnectLawpayBehaviour, } from 'app/payment/LawpayPayment';
import { type ConnectPaypalBehaviour, type DisconnectPaypalBehaviour, } from 'app/payment/PaypalPayment';
import { type GetLinkToPayTemplateBehaviour } from 'app/payment/GetLinkToPayTemplate';
import { type PaymentProcessorType } from 'domain/payment';
import { END, eventChannel, } from 'redux-saga';
import { PaymentActionCreators, PaymentTypes, UserActionCreators, } from '../actions';
import { call, cancelled, put, putResolve, select, take, takeLatest, } from 'redux-saga/effects';

import { type AddBankAccountBehaviour, } from 'app/payment/AddBankAccount';
import { type AddCreditCardBehaviour, } from 'app/payment/AddCreditCard';
import { type AwilixContainer, } from 'awilix';
import { type ConnectStripeBehaviour, } from 'app/payment/ConnectStripe';
import { type DisconnectStripeBehaviour, } from 'app/payment/DisconnectStripe';
import { type GetAllBankAccountsBehaviour, } from 'app/payment/GetAllBankAccounts';
import { type GetAllCreditCardsBehaviour, } from 'app/payment/GetAllCreditCards';
import { type GetPaymentsBehaviour, } from 'app/payment/GetPayments';
import { type PaypalGetSignupLinkBehaviour, } from 'app/payment/PaypalGetSignupLink';
import { type RemoveAccountBehaviour, } from 'app/payment/RemoveBankAccount';
import { type RemoveCreditCardBehaviour, } from 'app/payment/RemoveCreditCard';
import { type StripePayBehaviour, } from 'app/payment/StripePayment';
import { type StripePaymentMethodsBehaviour, } from 'app/payment/StripePaymentMethods';
import { type GetInvoicePaymentsBehaviour, } from 'app/payment/GetInvoicePayment';
import { UserRedux, } from 'state/reducers';
import { type VerifyAccountBehaviour, } from 'app/payment/VerifyBankAccount';
import { type RequiredFieldsLawpayBehaviour, } from 'app/payment/RequiredFieldsLawpay';
import { type GetHeadnoteApplicationBehaviour, } from 'app/payment/getHeadnoteApplication';
import { type GetPaymentReceivingBankTypeBehaviour, } from 'app/payment/GetPaymentReceivingBankType';
import { type WithCurrentUserToken, } from 'domain/user';
import { compareAsc } from 'date-fns';

const getLinkToPayTemplate = function* (container: AwilixContainer, { payload, meta }: { payload: LinkToPayPayload, meta: ?Object }) {
  try {
    const getLinkToPayTemplateBehaviour: GetLinkToPayTemplateBehaviour = container.resolve("getLinkToPayTemplate")

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    const channel = eventChannel(emitter => {
      getLinkToPayTemplateBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (data) => {
            emitter({ data, });
            emitter(END);
          },
          onInvalidPayload: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },          
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data: linkToPayTemplate, error }: { data: LinkToPayTemplate, error: ?Error } = yield take(channel);
      if (!linkToPayTemplate && !error) {
        break;
      }

      if (error) { throw error; }

      if (linkToPayTemplate) {
        yield put(PaymentActionCreators.getLinkToPayTemplateSuccess(linkToPayTemplate));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

const getPaymentReceivingBankType = function* (container: AwilixContainer, { payload, meta }: { payload: PaymentReceivingBankTypePayload, meta: ?Object }) {
  try {
    const getPaymentReceivingBankTypeBehaviour: GetPaymentReceivingBankTypeBehaviour = container.resolve("getPaymentReceivingBankType")

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    const channel = eventChannel(emitter => {
      getPaymentReceivingBankTypeBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (data) => {
            emitter({ data, });
            emitter(END);
          },
          onInvalidPayload: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },          
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data: paymentReceivingBankType, error }: { data: Object, error: ?Error } = yield take(channel);
      if (!paymentReceivingBankType && !error) {
        break;
      }

      if (error) { throw error; }

      if (paymentReceivingBankType) {
        yield put(PaymentActionCreators.getPaymentReceivingBankTypeSuccess(paymentReceivingBankType));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
   * get payments of a client, project redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
const getPayments = function* (container: AwilixContainer, { payload, meta, }: { payload: GetPaymentsPayload, meta: ?Object, }) {
  try {
    // resolve get payments of a client, project behaviour from awilix container
    const getInvoicesBehaviour: GetPaymentsBehaviour = container.resolve('getPayments');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getInvoicesBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (invoices) => {
            emitter({ data: invoices, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data: payments, error, }: { data: Array<Payment>, error: Object | string, } = yield take(channel);
      if (!payments && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (payments) {
        // process payment list to js Map ['key', 'value']
        // sorting to asc payment date in payment
        const paymentSort = payments.sort((a, b) => compareAsc(new Date(a.paymentDate), new Date(b.paymentDate)));
        const paymentTuple = paymentSort.map((p: Payment, index: number) => [index, p,]);

        const paymentMap: PaymentMap = new Map(paymentTuple);

        const payload: GetPaymentsSuccessPayload = { payments: paymentMap, };
        yield put(PaymentActionCreators.getPaymentsSuccess(payload));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
   * get payments of a invoice redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
const getInvoicePayments = function* (container: AwilixContainer, { payload, meta, }: { payload: GetInvoicePaymentsPayload, meta: ?Object, }) {
  try {
    // resolve get payments of a client, project behaviour from awilix container
    const getInvoicePaymentsBehaviour: GetInvoicePaymentsBehaviour = container.resolve('getInvoicePayments');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getInvoicePaymentsBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (invoices) => {
            emitter({ data: invoices, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data: invoicePayments, error, }: { data: Array<Payment>, error: Object | string, } = yield take(channel);
      if (!invoicePayments && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (invoicePayments) {
        // process payment list to js Map ['key', 'value']
        // sorting to asc payment date in payment
        const paymentSort = invoicePayments.sort((a, b) => compareAsc(new Date(a.dateApplied), new Date(b.dateApplied)));
        const paymentTuple = paymentSort.map((p: Payment, index: number) => [index, p,]);

        const paymentMap: PaymentMap = new Map(paymentTuple);

        const payload: GetPaymentsSuccessPayload = { invoicePayments: paymentMap, };
        yield put(PaymentActionCreators.getInvoicePaymentsSuccess(payload));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
   * Pay by stripe redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
const stripePay = function* (container: AwilixContainer, { payload, meta, }: { payload: StripePaymentPayload, meta: ?Object, }) {
  try {
    // resolve stripe payment method, stripe pay behaviour from awilix container
    const stripePayBehaviour: StripePayBehaviour = container.resolve('stripePay');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      stripePayBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (invoices) => {
            emitter({ data: invoices, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data: payments, error, }: { data: Array<Payment>, error: Object | string, } = yield take(channel);
      if (!payments && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (payments) {
        yield put(PaymentActionCreators.stripePaySuccess(payments, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * Pay by lawpay redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const lawpayPay = function* (container: AwilixContainer, { payload, meta, }: { payload: LawpayPaymentPayload, meta: ?Object, }) {
  try {
    // resolve payment repo from awilix container
    const paymentRepository = container.resolve < PaymentRepository > ('paymentRepository');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // simple call, no need to use app behaviour
    const res = yield call(
      paymentRepository.lawpayPay,
      payload.clientId,
      payload.payment,
      withCurrentUserToken
    );

    yield put(PaymentActionCreators.lawpayPaySuccess(res, meta));
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * Pay by headnote redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const headnotePay = function* (container: AwilixContainer, { payload, meta, }: { payload: headnotePaymentPayload, meta: ?Object, }) {
  try {
    // resolve payment repo from awilix container
    const paymentRepository = container.resolve < PaymentRepository > ('paymentRepository');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // simple call, no need to use app behaviour
    const res = yield call(
      paymentRepository.headnotePay,
      payload.clientId,
      payload,
      withCurrentUserToken
    );

    yield put(PaymentActionCreators.headnotePaySuccess(res, meta));
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * LinkPay payments by headnote redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
 const linkToPayHeadnotePay = function* (container: AwilixContainer, { payload, meta, }: { payload: headnotePaymentPayload, meta: ?Object, }) {
  try {
    // resolve payment repo from awilix container
    const paymentRepository = container.resolve < PaymentRepository > ('paymentRepository');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // simple call, no need to use app behaviour
    const res = yield call(
      paymentRepository.linkToPayHeadnotePay,
      payload.clientId,
      payload,
      withCurrentUserToken
    );

    yield put(PaymentActionCreators.linkToPayHeadnotePaySuccess(res, meta));
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * Submit payment method by headnote redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
 const submitPaymentMethodHeadnote = function* (container: AwilixContainer, { payload, meta, }: { payload: IB4TClientPmtMethodRequestBody, meta: ?Object, }) {
  try {
    // resolve payment repo from awilix container
    const paymentRepository = container.resolve < PaymentRepository > ('paymentRepository');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // simple call, no need to use app behaviour
    const res = yield call(
      paymentRepository.submitPaymentMethodHeadnote,
      payload.clientId,
      payload,
      withCurrentUserToken
    );

    yield put(PaymentActionCreators.submitPaymentMethodHeadnoteSuccess(res, meta));
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * create paypal order redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const paypalCreateOrder = function* (container: AwilixContainer, { payload, meta, }: { payload: PaypalCreateOrderPayload, meta: ?Object, }) {
  try {
    // resolve payment repo from awilix container
    const paymentRepository = container.resolve < PaymentRepository > ('paymentRepository');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // simple call, no need to use app behaviour
    const res = yield call(
      paymentRepository.paypalCreateOrder,
      payload.clientId,
      payload.payment,
      withCurrentUserToken
    );

    yield put(PaymentActionCreators.paypalCreateOrderSuccess(res, meta));
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * make paypal payment redux saga side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const paypalPay = function* (container: AwilixContainer, { payload, meta, }: { payload: PaypalPaymentPayload, meta: ?Object, }) {
  try {
    // resolve payment repo from awilix container
    const paymentRepository = container.resolve < PaymentRepository > ('paymentRepository');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // simple call, no need to use app behaviour
    const res = yield call(
      paymentRepository.paypalPay,
      payload.clientId,
      payload.payment,
      withCurrentUserToken
    );

    yield put(PaymentActionCreators.paypalPaySuccess(res, meta));
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
   * get stripe payment method redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
const stripePaymentMethods = function* (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // Stripe get payment methods, stripe payment methods behaviour from awilix container
    const stripePaymentMethodsBehaviour: StripePaymentMethodsBehaviour = container.resolve('stripePaymentMethods');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      stripePaymentMethodsBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (methods) => {
            emitter({ data: methods, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: string, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        const payload: GetPaymentMethodSuccessPayload = {
          isBankAccount: true,
        };

        yield put(PaymentActionCreators.stripePaymentMethodsSuccess(payload));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
   * get user bank accounts redux saga side effect
   * 
   * @param {AwilixContainer} container - awilix container
   * @param {Object} data - action params
   */
const getUserBankAccounts = function* (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // get all bank accounts behaviour from awilix container
    const getAllBankAccountsBehaviour: GetAllBankAccountsBehaviour = container.resolve('getAllBankAccounts');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
    const firm = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getAllBankAccountsBehaviour(
        firm,
        withCurrentUserToken,
        {
          onSuccess: (bankAccounts) => {
            emitter({ data: bankAccounts, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: Array<BankAccountData>, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        const payload: GetUserBankAccountsSuccessPayload = {
          bankAccounts: data,
        };

        yield put(PaymentActionCreators.getUserBankAccountsSuccess(payload, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * add bank account side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const addBankAccount = function* (container: AwilixContainer, { payload, meta, }: { payload: AddBankAccountPayload, meta: ?Object, }) {
  try {
    // stripe add bank account from awilix container
    const addBankAccountBehaviour: AddBankAccountBehaviour = container.resolve('addBankAccount');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
    const firm = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      addBankAccountBehaviour(
        payload,
        firm,
        withCurrentUserToken,
        {
          onSuccess: () => {
            emitter({ data: true, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: string, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        // load user bank accounts again after resolve success
        yield putResolve(PaymentActionCreators.getUserBankAccounts({ thunk: true, }));

        yield put(PaymentActionCreators.addBankAccountSuccess(undefined, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * verify bank account side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const verifyBankAccount = function* (container: AwilixContainer, { payload, meta, }: { payload: VerifyBankAccountPayload, meta: ?Object, }) {
  try {
    // stripe add bank account from awilix container
    const verifyBankAccountBehaviour: VerifyAccountBehaviour = container.resolve('verifyBankAccount');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      verifyBankAccountBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: () => {
            emitter({ data: true, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: string, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        // load user bank accounts again after resolve success
        yield putResolve(PaymentActionCreators.getUserBankAccounts({ thunk: true, }));

        yield put(PaymentActionCreators.verifyBankAccountSuccess(undefined, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * remove bank account side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const removeBankAccount = function* (container: AwilixContainer, { payload, meta, }: { payload: RemoveBankAccountPayload, meta: ?Object, }) {
  try {
    // stripe add bank account from awilix container
    const removeAccountBehaviour: RemoveAccountBehaviour = container.resolve('removeBankAccount');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
    const firm = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      removeAccountBehaviour(
        payload,
        firm,
        withCurrentUserToken,
        {
          onSuccess: () => {
            emitter({ data: true, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: string, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(PaymentActionCreators.removeBankAccountSuccess(payload, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

const getUserCards = function* (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // get all bank accounts behaviour from awilix container
    const getAllCreditCardsBehaviour: GetAllCreditCardsBehaviour = container.resolve('getAllCreditCards');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
    const firm = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getAllCreditCardsBehaviour(
        firm,
        withCurrentUserToken,
        {
          onSuccess: (cards) => {
            emitter({ data: cards, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: Array<CardData>, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        const payload: GetUserCardsSuccessPayload = {
          cards: data,
        };

        yield put(PaymentActionCreators.getUserCardsSuccess(payload, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * add card side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const addCard = function* (container: AwilixContainer, { payload, meta, }: { payload: AddCardPayload, meta: ?Object, }) {
  try {
    // add card from awilix container
    const addCreditCardBehaviour: AddCreditCardBehaviour = container.resolve('addCreditCard');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
    const firm = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      addCreditCardBehaviour(
        payload,
        firm,
        withCurrentUserToken,
        {
          onSuccess: () => {
            emitter({ data: true, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: string, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        // load user cards again after resolve success
        yield putResolve(PaymentActionCreators.getUserCards({ thunk: true, }));

        yield put(PaymentActionCreators.addCardSuccess(undefined, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * remove card side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const removeCard = function* (container: AwilixContainer, { payload, meta, }: { payload: RemoveCardpayload, meta: ?Object, }) {
  try {
    // remove card from awilix container
    const removeCreditCardBehaviour: RemoveCreditCardBehaviour = container.resolve('removeCreditCard');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
    const firm = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      removeCreditCardBehaviour(
        payload,
        firm,
        withCurrentUserToken,
        {
          onSuccess: () => {
            emitter({ data: true, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: string, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(PaymentActionCreators.removeCardSuccess(payload, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * connect custom payment side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const connectCustomPayment = function* (container: AwilixContainer, { payload, meta, }: { payload: ConnectCustomPaymentBody, meta: ?Object, }) {
  try {
    // connect custom payment from awilix container
    const connectCusttomPaymentBehaviour: ConnectCusttomPaymentBehaviour = container.resolve('connectCustomPayment');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      connectCusttomPaymentBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (message) => {
            emitter({ data: message, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: ConnectPayload, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(UserActionCreators.updateFirm(data.firm));
        yield put(PaymentActionCreators.connectCustomPaymentSuccess(data.message, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * remove card side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const disconnectCustomPayment = function* (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // remove card from awilix container
    const disconnectCusttomPaymentBehaviour: DisconnectCusttomPaymentBehaviour = container.resolve('disconnectCustomPayment');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      disconnectCusttomPaymentBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (message) => {
            emitter({ data: message, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: ConnectPayload, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(UserActionCreators.updateFirm(data.firm));
        yield put(PaymentActionCreators.disconnectCustomPaymentSuccess(data.message, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * paypal get signup link side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const paypalGetSignupLink = function* (container: AwilixContainer, { payload, meta, }: { payload: string, meta: ?Object, }) {
  try {
    // paypal get signup link from awilix container
    const paypalGetSignupLinkBehaviour: PaypalGetSignupLinkBehaviour = container.resolve('paypalGetSignupLink');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      paypalGetSignupLinkBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (message) => {
            emitter({ data: message, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: string, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(PaymentActionCreators.paypalGetSignupLinkSuccess(data, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * connect Paypal side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const connectPaypal = function* (container: AwilixContainer, { payload, meta, }: { payload: ConnectPaypalBody, meta: ?Object, }) {
  try {
    // connect paypal from awilix container
    const connectPaypalBehaviour: ConnectPaypalBehaviour = container.resolve('connectPaypal');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      connectPaypalBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (message) => {
            emitter({ data: message, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: ConnectPayload, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(UserActionCreators.updateFirm(data.firm));
        yield put(PaymentActionCreators.connectCustomPaymentSuccess(data.message, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * Disconnect paypal side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const disconnectPaypal = function* (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // Disconnect paypal from awilix container
    const disconnectPaypalBehaviour: DisconnectPaypalBehaviour = container.resolve('disconnectPaypal');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      disconnectPaypalBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (message) => {
            emitter({ data: message, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: ConnectPayload, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(UserActionCreators.updateFirm(data.firm));
        yield put(PaymentActionCreators.connectCustomPaymentSuccess(data.message, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * connect Lawpay side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const connectLawpay = function* (container: AwilixContainer, { payload, meta, }: { payload: ConnectUserLawpayPayload, meta: ?Object, }) {
  try {
    // connect lawpay from awilix container
    const connectLawpayBehaviour: ConnectLawpayBehaviour = container.resolve('connectLawpay');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      connectLawpayBehaviour(
        payload,
        withCurrentUserToken,
        {
          onSuccess: (message) => {
            emitter({ data: message, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: ConnectPayload, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(UserActionCreators.updateFirm(data.firm));
        yield put(PaymentActionCreators.connectLawpaySuccess(data.message, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * Disconnect lawpay side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const disconnectLawpay = function* (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // Disconnect lawpay from awilix container
    const disconnectLawpayBehaviour: DisconnectLawpayBehaviour = container.resolve('disconnectLawpay');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      disconnectLawpayBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (message) => {
            emitter({ data: message, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: ConnectPayload, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(UserActionCreators.updateFirm(data.firm));
        yield put(PaymentActionCreators.connectCustomPaymentSuccess(data.message, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * connect firm with stripe account
 *
 * @param {AwilixContainer} container
 * @param {{ payload: ConnectUserStripePayload, meta: Object, }} { payload, meta, }
 */
const connectStripe = function* (
  container: AwilixContainer,
  { payload, meta, }: { payload: ConnectUserStripePayload, meta: Object, },
) {
  try {
    const connectStripeBehaviour: ConnectStripeBehaviour = container.resolve("connectStripe");

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    const channel = eventChannel(emitter => {
      connectStripeBehaviour(
        payload.authCode,
        withCurrentUserToken,
        {
          onSuccess: (data) => {
            emitter({ data, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );
      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });
    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: ConnectPayload, error: ?Error, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        // udpate new firm to state
        yield put(UserActionCreators.updateFirm(data.firm));

        const payload: ConnectUserStripeSuccessPayload = {
          message: data.message,
        };

        // resolve connect user stripe account dispatch
        yield put(PaymentActionCreators.connectStripeSuccess(payload, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  }
};

/**
 * Disconnect stripe side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const disconnectStripe = function* (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // Disconnect lawpay from awilix container
    const disconnectStripeBehaviour: DisconnectStripeBehaviour = container.resolve('disconnectStripe');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      disconnectStripeBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (message) => {
            emitter({ data: message, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: ConnectPayload, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(UserActionCreators.updateFirm(data.firm));
        yield put(PaymentActionCreators.disconnectStripeSuccess(data.message, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};

/**
 * Get required field lawpay side effect
 * 
 * @param {AwilixContainer} container - awilix container
 * @param {Object} data - action params
 */
const requiredFieldsLawpay = function* (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // Disconnect lawpay from awilix container
    const requiredFieldsLawpayBehaviour: RequiredFieldsLawpayBehaviour = container.resolve('requiredFieldsLawpay');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      requiredFieldsLawpayBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (message) => {
            emitter({ data: message, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: RequiredFields, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(PaymentActionCreators.requiredFieldsLawpaySuccess(data, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
};


/**
 * Retrieves Headnote application side effect.
 **/
const getHeadnoteApplication = function* (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // get headnote application behavior
    const getHeadnoteApplicationBehaviour: GetHeadnoteApplicationBehaviour = container.resolve('getHeadnoteApplication');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getHeadnoteApplicationBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (application) => {
            emitter({ data: application, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
          onInvalidPayload: (invalidError) => {
            emitter({ data: {}, error: invalidError, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: HeadnoteApplication, error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(PaymentActionCreators.getHeadnoteApplicationSuccess(data, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
}

/**
 * Retrieves payment processors previously disconnected.
 **/
const getDisconnectedProcessors = function* (container: AwilixContainer, { meta, }: { meta: ?Object, }) {
  try {
    // get disconnected processors behavior
    const getDisconnectedProcessorsBehaviour: GetDisconnectedProcessorsBehaviour = container.resolve('getDisconnectedProcessors');

    // get data from redux state
    const token = yield select(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));

    const withCurrentUserToken: WithCurrentUserToken = {
      currentUserToken: token,
    };

    // app behaviour will use callbacks to handle multiple use cases
    // so we need to use eventChannel pattern to deal with callback in sagas
    const channel = eventChannel(emitter => {
      getDisconnectedProcessorsBehaviour(
        withCurrentUserToken,
        {
          onSuccess: (processors) => {
            emitter({ data: processors, });
            emitter(END);
          },
          onError: (error) => {
            emitter({ data: {}, error, });
            emitter(END);
          },
        }
      );

      // Return an unsubscribe method
      return () => {
        // Perform any cleanup you need here
      };
    });

    // Process events until operation completes
    while (true) {
      const { data, error, }: { data: PaymentProcessorType[], error: Object | string, } = yield take(channel);
      if (!data && !error) {
        break;
      }
      // Handle the data...
      if (error) { throw error; }

      if (data) {
        yield put(PaymentActionCreators.getDisconnectedProcessorsSuccess(data, meta));
      }
    }
  } catch (e) {
    yield put(PaymentActionCreators.requestFailure(true, e, meta));
  } finally {
    if (yield cancelled()) {
      yield put(PaymentActionCreators.requestFailure(true, null, meta));
    }
  }
}

export default (container: AwilixContainer) => ([
  takeLatest(PaymentTypes.GET_PAYMENTS, getPayments, container),
  takeLatest(PaymentTypes.GET_INVOICE_PAYMENTS, getInvoicePayments, container),
  takeLatest(PaymentTypes.STRIPE_PAY, stripePay, container),
  takeLatest(PaymentTypes.LAWPAY_PAY, lawpayPay, container),
  takeLatest(PaymentTypes.HEADNOTE_PAY, headnotePay, container),
  takeLatest(PaymentTypes.LINK_TO_PAY_HEADNOTE_PAY, linkToPayHeadnotePay, container),
  takeLatest(PaymentTypes.SUBMIT_PAYMENT_METHOD_HEADNOTE, submitPaymentMethodHeadnote, container),

  takeLatest(PaymentTypes.PAYPAL_CREATE_ORDER, paypalCreateOrder, container),
  takeLatest(PaymentTypes.PAYPAL_PAY, paypalPay, container),

  takeLatest(PaymentTypes.STRIPE_PAYMENT_METHODS, stripePaymentMethods, container),

  takeLatest(PaymentTypes.GET_USER_BANK_ACCOUNTS, getUserBankAccounts, container),
  takeLatest(PaymentTypes.ADD_BANK_ACCOUNT, addBankAccount, container),
  takeLatest(PaymentTypes.VERIFY_BANK_ACCOUNT, verifyBankAccount, container),
  takeLatest(PaymentTypes.REMOVE_BANK_ACCOUNT, removeBankAccount, container),

  takeLatest(PaymentTypes.GET_USER_CARDS, getUserCards, container),
  takeLatest(PaymentTypes.ADD_CARD, addCard, container),
  takeLatest(PaymentTypes.REMOVE_CARD, removeCard, container),

  takeLatest(PaymentTypes.CONNECT_CUSTOM_PAYMENT, connectCustomPayment, container),
  takeLatest(PaymentTypes.DISCONNECT_CUSTOM_PAYMENT, disconnectCustomPayment, container),

  takeLatest(PaymentTypes.PAYPAL_GET_SIGNUP_LINK, paypalGetSignupLink, container),
  takeLatest(PaymentTypes.CONNECT_PAYPAL, connectPaypal, container),
  takeLatest(PaymentTypes.DISCONNECT_PAYPAL, disconnectPaypal, container),

  takeLatest(PaymentTypes.REQUIRED_FIELDS_LAWPAY, requiredFieldsLawpay, container),
  takeLatest(PaymentTypes.CONNECT_LAWPAY, connectLawpay, container),
  takeLatest(PaymentTypes.DISCONNECT_LAWPAY, disconnectLawpay, container),

  takeLatest(PaymentTypes.CONNECT_STRIPE, connectStripe, container),
  takeLatest(PaymentTypes.DISCONNECT_STRIPE, disconnectStripe, container),

  takeLatest(PaymentTypes.GET_HEADNOTE_APPLICATION, getHeadnoteApplication, container),

  takeLatest(PaymentTypes.GET_LINK_TO_PAY_TEMPLATE, getLinkToPayTemplate, container),

  takeLatest(PaymentTypes.GET_DISCONNECTED_PROCESSORS, getDisconnectedProcessors, container),

  takeLatest(PaymentTypes.GET_PAYMENT_RECEIVING_BANK_TYPE, getPaymentReceivingBankType, container),
]);
