// @flow
import * as R from 'ramda';

import React, { useMemo, useEffect } from 'react';
import { type FirmStatusInfo, } from 'domain/firm';
import FeatureList from "./components/FeatureList";
import { UserRedux, } from 'state/reducers';
import { UserActionCreators, } from 'state/actions';
import { useDispatch, useSelector, } from 'react-redux';
import { APP_CONFIG } from 'constants/index';
import { Colors, Images } from 'assets/index';
import styled from 'styled-components';
import { Button, Image } from 'react-bootstrap';
import B4TButton, {ButtonColors} from 'view/reusableComponents/buttons/B4TButton';
import { triggerParentChangeSubscriptionTab } from "../../../../../../iframeMessage";

const trackMixpanelEvent = () => { window.mixpanel.track("b4tp_activate") };

// connect redux
const useConnect = () => {
  // mapState
  const firmStatusInfo: FirmStatusInfo = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirmStatusInfo));

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    getFirmStatusInfo: (): Promise<any> => dispatch(UserActionCreators.getFirmStatusInfo({ thunk: true })),
  }), [ dispatch, ]);

  const mapState = {
    firmStatusInfo,
  };

  return {
      ...mapState,
      ...mapDispatch,
  };
};

export default () => {
  const redux = useConnect();
  const { firmStatusInfo, getFirmStatusInfo } = redux;
  const isFree: Boolean = R.path(['isFree'], firmStatusInfo);
  const firmStatus: string = R.path(['firmStatus'], firmStatusInfo);

  useEffect(() => {
    (async () => {
      try {
        await getFirmStatusInfo();
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div>
      <StyledH5 className="mt-3">{`Easily accept credit cards and eChecks directly from Bill4Time.`}</StyledH5>
      <FeatureList />
      {!isFree && firmStatus === 'Active' ?
        <StyledButton onClick={trackMixpanelEvent} href={APP_CONFIG.activeForFreeLink} target="_blank">
          {`Activate for Free`}
        </StyledButton>
        :
        <div>
          <div style={{ color: 'red', marginBottom: '15px' }} >
            {`A Bill4Time paid subscription is required to apply for online payments.`}
          </div>
          <B4TButton
            B4TColor={ButtonColors.BLUE}
            text='MANAGE ACCOUNT'
            onClick={() => { triggerParentChangeSubscriptionTab(); }}
          />
        </div>

      }
      <Image className="d-block pt-3" src={Images.settings.ccImages} width="300" />
    </div>
  )
}

const StyledH5 = styled.h5`
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
`;

const StyledButton = styled(Button)`
  && {
    background: ${Colors.blueButton};
    line-height: 28px;
    width: 160px;
    font-weight: bold;
    &:hover {
      background: ${Colors.blueButtonHover};
    }
  }
`;