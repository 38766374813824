import React from 'react';
import {STATUSES} from "../../PaymentsPanel";

const StatusIndicator = ({text, color}) => {
    return <div style={{border: `1px solid ${color}`, color, display: "inline", padding: "5px 7px", borderRadius: "2px", marginLeft: "5px", fontWeight: 600}}>{text}</div>
}

export default ({status}) => {

    const statusInfo = getStatusInfo(status);

    return (
        <div className="mt-3 mb-4">
            <span>Status: </span>
            <StatusIndicator {...statusInfo} />
        </div>
    )
}

/**
 * Gets the text and color to show according to the current status.
 */
const getStatusInfo = (status) => {
    switch(status) {
        case STATUSES.PENDING_REVIEW:
            return {
                text: "Pending Review",
                color: "#3374d9"
            };
        case STATUSES.INCOMPLETE:
            return {
                text: "Incomplete",
                color: "#ffab00"
            };
        case STATUSES.UNDER_REVIEW:
            return {
                text: "Under Review",
                color: "#3374d9"
            };
        case STATUSES.VERIFIED:
            return {
                text: "Connected",
                color: "#22b573"
            };
        case STATUSES.DECLINED:
            return {
                text: "Declined",
                color: "#ef4343"
            };
        case STATUSES.PAUSED:
            return {
                text: "Paused",
                color: "#ffab00"
            };
        case STATUSES.DISABLED:
            return {
                text: "Disabled",
                color: "#ffab00"
            };
    }
}