/* @flow */
import React, { useEffect, useMemo, useState, } from 'react';
import { getLocationQueryString, jwtDecoded, } from 'utilities/stringUtils';
import { useLocation, useHistory, } from "react-router-dom";
import { Container, Alert, } from 'react-bootstrap';
import { useDispatch, } from 'react-redux';
import { UserActionCreators, } from 'state/actions';
import { type UserToken, } from 'domain/user';
import { Loading, } from 'view/components/Loading';
import { type Firm, } from 'domain/firm';
import { ERRORS, } from 'constants/index';


// connect redux
const useConnect = () => {

  // mapDispatch
  const dispatch = useDispatch();

  const mapDispatch = useMemo(() => ({
    setTokens: (payload: UserToken) => dispatch(UserActionCreators.setTokens(payload)),
    loadFirm: () => dispatch(UserActionCreators.getFirm({ thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapDispatch,
  };
};

type DecodeData = {
  invoiceId: string,
  projectId: string,
  accessibleClients: number[]
}

const InvoiceFromEmailVM = () => {
  // connect redux
  const { setTokens, loadFirm, } = useConnect();

  // get location
  const location = useLocation();

  // react router history object
  const history = useHistory();

  // handle querry string
  const [ error, setError, ] = useState(null);

  useEffect(() => {
    (
      async function checkInvoiceGuest () {
        try {
          const { invoiceToken, } : { invoiceToken : string, } = getLocationQueryString(location.search);
          const { invoiceId, projectId, accessibleClients, } : DecodeData = jwtDecoded(invoiceToken);
          
          // save token and re-use
          const data : UserToken = {
            authToken: invoiceToken,
          };
          await setTokens(data);

          // Load firm to get guest setting
          const firm : Firm = await loadFirm();

          if (firm.portalSettings.allowGuests) {
            const url = {
              pathname: `/invoice/${invoiceId}/${accessibleClients[0]}/${projectId}`, // need to get client id in accessibleClients array
            };
            history.push(url);
          } else {
            history.push('/login');
          }
        } catch (err) {
          setError(ERRORS.invoice_token);
        }
      }
    )();
  }, [ location, history, setTokens, loadFirm, ]);

  return (
    <Container>
      {error ? (
        <Alert className="mt-4" variant="warning" >
          {error}
        </Alert>
      ) : <Loading />}
    </Container>
  );
};

export default InvoiceFromEmailVM;
