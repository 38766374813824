// @flow
import { NavLink, useRouteMatch, } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import { type Client, } from 'domain/client';
import { type ClientMap, } from 'state/client/types';
import { Colors, } from 'assets/index';
import { type Contact, } from 'domain/invite';
import Container from 'react-bootstrap/Container';
import { type FirmUserMap, } from 'state/user/types';
import InvitesForm from '../components/InvitesForm';
import React from 'react';
import { type UserInvitesInfo, } from 'domain/user/index';
import styled from 'styled-components';

type Props = {
  initialFormValues: UserInvitesInfo,
  onSubmit: Function,
  clients: Array<Contact>,
  users: Array<Client>,
  firmUsers: FirmUserMap,
  firmclientsMap: ClientMap,
};

const StyledContainer = styled(Container)`
  color: ${Colors.charcoal};
  flex: 1;
`;

const StyledNavLink = styled(NavLink)`
  color: ${Colors.blueCurious};

  :hover {
    color: ${Colors.blueAstral};
  }
`;

const InvitesV = (props: Props) => {
  // react router current path info
  const { url, } = useRouteMatch();

  return (
    <StyledContainer>
      <h3>
        {`Invite Your Clients`}
      </h3>
      <p className="m-0">
        {`Allow your clients 24/7 access to pay their invoices and view their history. Once an invitation is sent, your client will be notified via email to create a password to login.`}
      </p>

      <Card border="light" className="px-3">
        <Card.Header className="px-0">
          <h5>{`Invite Client`}</h5>
        </Card.Header>

        <Card.Body>
          <InvitesForm
            {...props}
          />
        </Card.Body>

        <Card.Body className="text-right p-0">
          <StyledNavLink to={`${url}/list`}>{`View sent invites`}</StyledNavLink>
        </Card.Body>
      </Card>
    </StyledContainer>
  );
};

export default InvitesV;
