// @flow
import * as R from 'ramda';

import { type AffinipayHostedFieldState, type AffinipayPaymentTokenRes, } from './types';
import { Formik, type FormikActions, type FormikProps, } from 'formik';
import { LAWPAY_ACCOUNT_HOLDER_TYPE, LAWPAY_ACCOUNT_TYPE, type LawpayAddBankAccoutnInfo, } from 'domain/payment';
import React, { useCallback, useEffect, useRef, useMemo, } from 'react';

import { API_CONFIG, ERROR_MESSAGE_MAPPING, ERRORS, } from 'constants/index';
import { type AddBankAccountPayload, } from 'state/payment/types';
import BankForm from './LawpayBankForm';
import { Button, Modal, Spinner, Alert, } from 'react-bootstrap';
import { Colors, } from 'assets';
import FormAlert from 'view/accountSettings/components/form/FormAlert';
import { PaymentActionCreators, } from 'state/actions';
import SettingButton from 'view/accountSettings/components/form/SettingsButton';
import styled from 'styled-components';
import { useDispatch, useSelector, } from 'react-redux';
import { UserRedux, } from 'state/reducers';
import { type User, } from 'domain/user';
import { type Firm, type ClientPortalSettings, } from 'domain/firm';
import { ValidateShading, } from 'view/components';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 550px !important;
  }
`;

/* ------------- connect redux ------------- */
const useConnect = () => {
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const user: User  = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));

  const dispatch = useDispatch();

  const onAddBankAccount = useCallback((payload: AddBankAccountPayload) => {
    return dispatch(PaymentActionCreators.addBankAccount(payload, { thunk: true, }));
  }, [ dispatch, ]);

  return {
    onAddBankAccount,
    user,
    firm,
  };
};
/* ------------- End ------------- */

/* ------------- Lawpay hosted fields configs and handling ------------- */
const hostedFieldStyle = {
  'font-size': '1rem',
  'font-weight': '400',
  'line-height': '1.5',
  color: Colors.grey,
  width: '100%',
  padding: '.375rem .75rem',
  transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
};

/**
 * handle lawpay hosted fields initialization
 *
 * @param {boolean} isShow - modal show boolean
 */
const useLawpayHostedFields = (isShow: boolean): Object => {
  const { firm, } = useConnect();

  const lawpayBankAccountHostedFieldsConfig = useMemo(() => {
    // Get publish key from firm
    let publishKey = API_CONFIG.AFFINIPAY_PUBLIC_KEY;
    const portalSettings: ClientPortalSettings = R.path([ 'portalSettings', ], firm);
    if (portalSettings && portalSettings.lawpay && portalSettings.lawpay.publicKey) {
      publishKey = portalSettings.lawpay.publicKey;
    }
    
    return {
      publicKey: publishKey,
      css: hostedFieldStyle,
      fields: [
        {
          selector: '#lawpay_bank_account_number_id',
          placeholder: 'Bank Account Number',
          input: {
            type: 'bank_account_number',
            css: hostedFieldStyle,
          },
        },
        {
          selector: '#lawpay_routing_number_id',
          placeholder: 'Routing Number',
          input: {
            type: 'routing_number',
            css: hostedFieldStyle,
          },
        },
      ],
    };
  }, [ firm, ]);

  // store hosted fields in a ref
  const lawpayHostFieldsRef = useRef();

  const hostedFieldsCallBack = useCallback((state) => {
    // TODO: do we need to do some thing in this call back ?
    // console.log('>>> hosted fiellds', { state })
  }, []);

  useEffect(() => {
    // do nothing if modal is not show
    if (!isShow) {return;}

    // init lawpay hosted fields
    lawpayHostFieldsRef.current = window.AffiniPay.HostedFields.initializeFields(
      lawpayBankAccountHostedFieldsConfig,
      hostedFieldsCallBack
    );    

    // clean up
    // remove ref reference to hosted fields
    return () => {
      lawpayHostFieldsRef.current = undefined;
    };
  }, [ hostedFieldsCallBack, isShow, lawpayBankAccountHostedFieldsConfig, ]);

  return {
    lawpayHostFieldsRef,
  };
};
/* ------------- End ------------- */

type Props = {
  isShow: boolean,
  onClose?: Function,
  onHide: Function,
};

/* ------------- Component ------------- */
const LawpayAddBankModal = ({ isShow = false, onHide, }: Props) => {

  const { onAddBankAccount, user, } = useConnect();

  // Init value including reference
  const initialValues: LawpayAddBankAccoutnInfo = useMemo(() => ({
    given_name: '',
    business_name: '',
    surname: '',
    account_holder_type: LAWPAY_ACCOUNT_HOLDER_TYPE[0].value,
    account_type: LAWPAY_ACCOUNT_TYPE.checking,
    reference: user._id,
  }), [ user, ]);

  const {
    lawpayHostFieldsRef,
  } = useLawpayHostedFields(isShow);

  // on add bank submit
  // Custom validate form
  const validate = useCallback(values => {
    if (!lawpayHostFieldsRef.current) {
      return {};
    };

    // get hosted field state from ref
    const hostedFieldState: AffinipayHostedFieldState = lawpayHostFieldsRef.current.getState();
    // check lawpay hosted fields validation
    let errors = {};
    hostedFieldState.fields.forEach((field) => {
      if (field.error) {
        errors[field.type] = field.error;
      }
    });

    if (values.given_name.length === 0) {
      errors.given_name = "Given name is required";
    }

    if (values.surname.length === 0) {
      errors.surname = "Surname is required";
    }

    if (values.business_name.length === 0) {
      errors.business_name = "Business name is required";
    }

    if (Object.keys(errors).length > 0 || values.given_name.length === 0 || values.business_name.length === 0) {
      return errors;
    }
    return {};
  }, [ lawpayHostFieldsRef, ]);

  // start verifying
  const onVerify = useCallback(async (values: LawpayAddBankAccoutnInfo, actions: FormikActions) => {
    try {
      // throw error if hosted fields not found
      if (!lawpayHostFieldsRef.current) {
        throw new Error('Lawpay hosted field not found.');
      };

      actions.setSubmitting(true);

      // TODO: tokenize lawpay bank account
      const result: AffinipayPaymentTokenRes = await lawpayHostFieldsRef.current.getPaymentToken(values);
      
      // request attach bank account api
      const payload: AddBankAccountPayload = {
        token: result.id,
      };
      await onAddBankAccount(payload);

      // use status to display verification success modal
      actions.setStatus(true);
    } catch (e) {
      actions.setErrors({
        bank: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ onAddBankAccount, lawpayHostFieldsRef, ]);

  // Check error in formik and show message
  const ErrorStatus = useCallback(errors => {
    if(errors.bank_account_number === ERROR_MESSAGE_MAPPING.invalid_bank_account_number && errors.routing_number === ERROR_MESSAGE_MAPPING.invalid_routing_number) {
      return ERRORS.both_invalid_bank_routing_number;
    }

    if(errors.bank_account_number === ERROR_MESSAGE_MAPPING.invalid_bank_account_number) {
      return ERRORS.invalid_bank_account_number;
    }

    if(errors.routing_number === ERROR_MESSAGE_MAPPING.invalid_routing_number) {
      return ERRORS.invalid_routing_number;
    }
    
    if(errors.given_name || errors.surname || errors.business_name || errors.bank_account_number || errors.routing_number) {
      return `All fields in blue are required.`;
    }
    return undefined;
  }, []);

  return (
    <StyledModal
      backdrop='static'
      className="p-0"
      show={isShow}
      onHide={onHide}
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onVerify}
      >
        {({ errors, handleSubmit, isSubmitting, status, values, }: FormikProps<LawpayAddBankAccoutnInfo>) => (
          <>
            <Modal.Header closeButton className="border-bottom-0">
              <h2>
                {
                  status ? `Verification` : `Bank Account (ACH)`}
              </h2>
            </Modal.Header>
            <Modal.Body>
              <FormAlert alert={errors.bank} />
              { ErrorStatus(errors) && (
                  <ValidateShading 
                    className="w-100" 
                    text={ErrorStatus(errors)}
                  />
                )
              }
              {
                status ? (
                  <>
                    <p>
                      {`Success!`}
                    </p>
                    <p>
                      {`In a couple of days you should see two small deposits labelled VERIFICATION on your bank statement. When you have these deposits return here and enter the amounts to finish verifying your account.`}
                    </p>
                  </>
                ) : (  
                  <BankForm errors={errors} values={values} />
                )
              }
            </Modal.Body>
            <Modal.Footer className="border-top-0">
              {
                isSubmitting && (
                  <Spinner animation="border" className="mr-2" role="status" />
                )
              }
              <Button
                className="font-weight-bold border-0"
                disabled={isSubmitting}
                variant="light"
                onClick={onHide}
              >
                {`Close`}
              </Button>
              {
                !status && (
                  <SettingButton
                    className="font-weight-bold"
                    disabled={isSubmitting}
                    type='submit'
                    onClick={handleSubmit}
                  >
                    {`Verify`}
                  </SettingButton>
                )
              }
            </Modal.Footer>
          </>
        )}
      </Formik>
    </StyledModal>
  );
};

export default LawpayAddBankModal;
