/* @flow */
import { type Client, type ClientRepository, } from 'domain/client';

import { type WithCurrentUserToken, } from 'domain/user';

type Payload = any;

type Callbacks = {
  onSuccess: (Array<Client>) => void,
  onError: (Error) => void,
};

type Dependencies = {
  clientRepository: ClientRepository,
};

export type GetClientsBehaviour = (
  Payload,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { clientRepository, }: Dependencies, ) => {
  /**
   * get list clients behaviour
   * 
   * @param {Payload} payload - query clients params
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getClientsBehaviour: GetClientsBehaviour = async (
    payload: Payload,
    withCurrentUserToken: WithCurrentUserToken,
    { onSuccess, onError, }: Callbacks,
  ) => {
    try {
      const clients: Array<Client> = await clientRepository.getClients(withCurrentUserToken);

      return onSuccess(clients);
    } catch(error) {
      return onError(error);
    }
  };

  return getClientsBehaviour;
};
