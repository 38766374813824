/* @flow */
import { type Invoice, type InvoiceRepository, } from 'domain/invoice';

import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (i: Invoice) => void,
  onInvalidPayload: (e: Error) => void,
  onError: (e: Error) => void,
};

type Dependencies = {
  invoiceRepository: InvoiceRepository,
};

type Payload = {
  clientId: number,
  invoiceId: number,
};

export type GetSingleInvoiceBehaviour = (
  Payload,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { invoiceRepository, }: Dependencies, ) => {
  /**
   * get single invoices of a client behaviour
   * 
   * @param {Payload} payload - client id and invoice id
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getSingleInvoiceBehaviour: GetSingleInvoiceBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { clientId, invoiceId, } = payload;
      if (!clientId || !invoiceId) {
        return onInvalidPayload(new Error('Invalid client and invoice id.'));
      }
      
      const invoice = await invoiceRepository.getSingleInvoice(clientId, invoiceId, withCurrentUserToken);
      
      return onSuccess(invoice);
    } catch(error) {
      return onError(error);
    }
  };

  return getSingleInvoiceBehaviour;
};
