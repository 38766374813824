/* @flow */
import * as R from 'ramda';

import { type ClientPortalSettings, type Firm, } from 'domain/firm';

import Alert from 'react-bootstrap/Alert';
import BankAccount from './components/BankAccount';
import Card from 'react-bootstrap/Card';
import Cards from './components/Cards';
import Container from 'react-bootstrap/Container';
import DateFormatForm from './components/DateFormatForm';
import EmailForm from './components/EmailForm';
import NewPasswordForm from './components/NewPasswordForm';
import NotificationEmailForm from './components/NotificationEmailForm';
import React from 'react';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

const StyledWrapper = styled(Container)`
  flex: 1;
`;

type Props = {
  firm: Firm,
  settingStatus?: string;
  setSettingStatus: Function;
  cancelSettingStatus: Function;
};

const AccountSettingsV = ({ firm, settingStatus, setSettingStatus, cancelSettingStatus, }: Props) => {
  const portalSettings: ClientPortalSettings = R.path([ 'portalSettings', ], firm);
  const achEnabled: boolean = R.path([ 'portalSettings', 'achEnabled', ], firm);
  const paymentIntegrated = portalSettings && (portalSettings.isLawpay || portalSettings.isStripe);

  return (
    <StyledWrapper>
      <h3 className='p-3'>
        {`Account Settings`}
      </h3>

      {
        settingStatus && (
          <Alert dismissible className='my-2' variant='warning' onClose={cancelSettingStatus}>
            {settingStatus}
          </Alert>
        )
      }

      <Card className='p-4'>
        <Row className='mx-0'>
          <EmailForm setSettingStatus={setSettingStatus} />
        </Row>
        <Row className='mx-0 pt-3'>
          <NewPasswordForm setSettingStatus={setSettingStatus} />
        </Row>
        <Row className='mx-0 pt-3'>
          <DateFormatForm setSettingStatus={setSettingStatus} />
        </Row>
        <Row className='mx-0 pt-3'>
          <NotificationEmailForm setSettingStatus={setSettingStatus} />
        </Row>
        {
          paymentIntegrated && achEnabled && (
            <Row className='mx-0 pt-3'>
              <BankAccount setSettingStatus={setSettingStatus} />
            </Row>
          )
        }
        {
          // only show saved card section if one of lawpay or stripe is integrated
          paymentIntegrated && (
            <Row className='mx-0 pt-3'>
              <Cards setSettingStatus={setSettingStatus} />
            </Row>
          )
        }
      </Card>
    </StyledWrapper>
  );
};

export default AccountSettingsV;
