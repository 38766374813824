/* @flow */
import { createActions, } from 'reduxsauce';
import { reducerPrefixFormat, } from '../common';

/* ------------- Invoice ------------- */
export const { Types, Creators, } = createActions({
  getInvoices: [ 'payload', 'meta', ],

  getInvoicesSuccess: [ 'payload' /* GetInvoicesResponsePayload */, 'meta', ],

  getInvoiceToken: [ 'payload', 'meta', ],
  getInvoiceTokenSuccess: [ 'payload' /* GetInvoiceTokenResponsePayload */, 'meta', ],

  getSingleInvoice: [ 'payload', 'meta', ],
  getSingleInvoiceSuccess: [ 'payload' /* GetSingleInvoiceResponsePayload */, 'meta', ],

  requestFailure: [ 'error', 'payload' /* error payload */, 'meta', ],
}, {
  prefix: reducerPrefixFormat('invoice'),
});
