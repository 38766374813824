// @flow
import { ButtonWrapper, CancelButton, } from '../../UI';
import React, { useCallback, useMemo, useState, } from 'react';

import { type CardData, } from 'domain/payment';
import ConfirmRemoveModal from './ConfirmRemoveModal';
import { PaymentActionCreators, } from 'state/actions';
import { type RemoveCardpayload, } from 'state/payment/types';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, } from 'react-redux';

type Props = {
  card: CardData,
};

// connect redux
const useConnect = () => {
  const dispatch = useDispatch();

  const mapDispatch = useMemo(() => ({
    removeCard: (payload: RemoveCardpayload) => dispatch(PaymentActionCreators.removeCard(payload, { thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapDispatch,
  };
};

// custom hook handle remove credit card
const useRemoveCard = (card: CardData, removeCard: Function) => {
  const [ isShowRemoveModal, setIsShowRemoveModal, ] = useState<boolean>(false);
  const [ isRemoving, setIsRemoving, ] = useState<boolean>(false);

  const onRemoveModalHide = useCallback(() => {
    setIsShowRemoveModal(false);
  }, []);

  const onPressRemove = useCallback(() => {
    setIsShowRemoveModal(true);
  }, []);

  const onRemoveCard = useCallback(async () => {
    // request remove bank
    try {
      // turn off remove confirm remove modal
      setIsShowRemoveModal(false);
      // start remove loading indicator
      setIsRemoving(true);
      const data: RemoveCardpayload = {
        cardId: card.id,
      };

      await removeCard(data);
    } catch (e) {
      console.log('[Remove Credit Card Error]', { e, });
    } finally {
      setIsRemoving(false);
    }
  }, [ card.id, removeCard, ]);

  return {
    isRemoving,
    isShowRemoveModal,
    onRemoveModalHide,
    onPressRemove,
    onRemoveCard,
  };
};

const CreditCardRow = ({ card, }: Props) => {
  const { removeCard, } = useConnect();

  const {
    isRemoving,
    isShowRemoveModal,
    onRemoveModalHide,
    onPressRemove,
    onRemoveCard,
  } = useRemoveCard(card, removeCard);

  return (
    <React.Fragment>
      <ButtonWrapper className='d-flex flex-row justify-content-between pt-4'>
        <div className={`d-flex flex-column justify-content-center`}>
          <span>
            {`Credit Card ending in ${card.last4}`}
          </span>
        </div>

        <div className={`d-flex flex-row justify-content-center`}>
          {
            isRemoving && (
              <Spinner animation="border" className="mr-2" role="status" />
            )
          }
          <div>
            <CancelButton className={`border-0`} onClick={onPressRemove}>
              {`Remove Credit Card`}
            </CancelButton>
          </div>
        </div>
      </ButtonWrapper>

      <ConfirmRemoveModal
        isShow={isShowRemoveModal}
        onHide={onRemoveModalHide}
        onModalAccept={onRemoveCard}
      />
    </React.Fragment>
  );
};

export default CreditCardRow;
