import React from 'react';
import styled from 'styled-components';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { APP_CONFIG, } from 'constants/index';
import { Colors, } from 'assets'

const StyledTable = styled(Table)`
  font-size: .875rem;
  overflow-x: auto;
`;

const StyledRemoveButton = styled(Button)`
  background-color: ${Colors.redRemove} !important;
  border: none !important;
  font-weight: bold !important;
  box-shadow: none !important;
  text-transform: uppercase !important;
  &:hover {
    background-color: ${Colors.redRemoveHover} !important;
  }
`;

const ClientList = ({ clients, }) => {
  let str = '';
  clients.forEach((client, index) => {
    if(client){
      str += client.clientLabel || client.name;
      if (index !== (clients.length - 1)) {
        str += ', ';
      };
    }
  });
  return str;
};

const Status = ({status,}) => {
  switch (status) {
    case APP_CONFIG.invite_status.SENT:
      return <td className="align-middle text-warning font-weight-bold">{`Waiting`}</td>;
    case APP_CONFIG.invite_status.expired:
      return <td className="align-middle text-danger font-weight-bold">{`Expired`}</td>;
    case APP_CONFIG.invite_status.accepted:
      return <td className="align-middle text-success font-weight-bold">{`Accepted`}</td>;
    default:
      break;
  }
};

const DeleteButton = ({tokenId, onDelete,}) => {
  function revoke() {
    onDelete(tokenId);
  }
  return (
    <StyledRemoveButton onClick={revoke}>{'Revoke'}</StyledRemoveButton>
  );
};

const TableRow = ({ data, onDelete, }) => {
  return (
    data.map((data) => (
      <tr key={data._id}>
        <td className="align-middle">{data.date}</td>
        <td className="align-middle">{data.email}</td>
        <td className="align-middle w-25"><ClientList clients={data.accessibleClients} /></td>
        <Status status={data.status} />
        <td><DeleteButton tokenId={data._id} onDelete={onDelete} /> </td>
      </tr>
    ))
  );
};

const TableContainer = (props) => {
  return (
    <StyledTable borderless>
      <thead>
        <tr>
          <th>{`Date`}</th>
          <th>{`Email`}</th>
          <th>{`Client`}</th>
          <th>{`Status`}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <TableRow {...props} />
      </tbody>
    </StyledTable>
  );
};

export default TableContainer;
