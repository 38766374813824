/* @flow */
import React, { useCallback, useState, useEffect, useMemo, } from 'react';
import { useDispatch, } from 'react-redux';

import { type FormikActions, } from 'formik';
import RegisterPageV from './RegisterPageV';
import { UserActionCreators, } from 'state/actions';
import { type RegisterCredentials, } from 'domain/user';
import { useLocation, } from "react-router-dom";
import { useHistory, } from "react-router-dom";
import { ERRORS, } from 'constants/index';
import { getLocationQueryString, jwtDecoded, } from 'utilities/stringUtils';

/**
 * redux connect hook
 */
const useConnect = () => {

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    onRegister: (payload: RegisterCredentials) => dispatch(UserActionCreators.registerUser(payload, { thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapDispatch,
  };
};

const initialFormValues: RegisterCredentials = {
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
};

const RegisterPageVM = () => {
  // react redux connect
  const rdx = useConnect();
  const { onRegister, } = rdx;

  // get email in location state
  const location = useLocation();

  // History for redirect
  const history = useHistory();

  // handle querry string
  const email = useMemo(() => {
    try {
      const { inviteToken, } : { invoice_token: string, } = getLocationQueryString(location.search);
      
      const { email, } : { email: string, } = jwtDecoded(inviteToken);
      
      if (!email) {
        throw new Error('Email does not exist.');
      }

      return email;
    } catch (err) {
      history.push('/login', { message: ERRORS.invoice_token, });
    }
    
  }, [ location, history, ]);

  /**
   * on register form submit
   * 
   * @param {RegisterCredentials} values - auth info values from form
   * @param {FormikActions} actions - formik bag actions
   */
  const onSubmit = useCallback(async (values: RegisterCredentials, actions: FormikActions) => {
    try {
      const { inviteToken, } : { inviteToken: string, } = getLocationQueryString(location.search);
      actions.setSubmitting(true);

      // Come back login when register success
      const payload = {
        data: {
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
        },
        inviteToken: inviteToken,
      };
      const message : string = await onRegister(payload);
      history.push('/login', { message, });
    
    } catch (e) {
      // handle error
      actions.setErrors({
        auth: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ onRegister, history, ]);

  return (
    <RegisterPageV
      {...rdx}
      email={email}
      initialFormValues={initialFormValues}
      onSubmit={onSubmit}
    />
  );
};

export default RegisterPageVM;
