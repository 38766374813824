// @flow
import * as R from 'ramda';
import { Col, Form, Row, } from 'react-bootstrap';
import { Field, Formik, type FormikActions, type FormikProps, } from 'formik';
import React, { useCallback, } from 'react';
import { type UserChangePasswordInfo, UserChangePasswordInfoSchema, } from 'domain/user';

import FormAlert from './form/FormAlert';
import { FormInput, } from 'view/components/index';
import FormLabel from './form/FormLabel';
import FormSubmit from './form/FormSubmit';
import { MESSAGES, } from 'constants/index';
import { type UpdateUserPayload, } from 'state/user/types';
import styled from 'styled-components';
import { useUpdateUserInfo, } from '../hooks/updateUser';
import { useSelector, } from 'react-redux';
import { UserRedux, } from 'state/reducers';
import { changeErrorToMessage, } from 'utilities/stringUtils';

const trackMixPanelEventPasswordChange = () => { window.mixpanel.track("b4tp_client_portal_change_password") }

const StyleContainer = styled.div`
  flex: 1;
`;

type Props = {
  setSettingStatus: Function;
}

const INITIAL_FORM_VALUES: UserChangePasswordInfo = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

/**
 * redux connect hook
 */
const useConnect = () => {
  // mapState
  const user = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const mapState = {
    user,
  };

  return {
    ...mapState,
  };
};

/**
 * Main Component
 *
 * @param {Props} { setSettingStatus }
 * @returns
 */
const SettingsForm = ({ setSettingStatus, }: Props) => {
  const { user, } = useConnect();
  // update user dispatch action
  const { updateUserInfo, } = useUpdateUserInfo();

  /**
   * on change email
   * 
   * @param {UserChangePasswordInfo} values - auth info values from form
   * @param {FormikActions} actions - formik bag actions
   */
  const onSubmit = useCallback(async (values: UserChangePasswordInfo, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);
      // TODO: Update user password
      const payload: UpdateUserPayload = {
        info: {
          password: values.newPassword,
          currentPassword: values.currentPassword,
        },
      };

      await updateUserInfo(payload);

      // update page status success
      setSettingStatus(MESSAGES.accountSettings.passwordUpdated);
    } catch (e) {
      const { key, message, } : { key: string, message: string, } = changeErrorToMessage(e.message || e);
      // handle error
      actions.setErrors({
        [key]: message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ setSettingStatus, updateUserInfo, ]);

  return (
    <StyleContainer>
      <Formik
        initialValues={INITIAL_FORM_VALUES}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={UserChangePasswordInfoSchema(user.email)}
        // eslint-disable-next-line
        onSubmit={onSubmit}
      >
        {({ errors, handleSubmit, isSubmitting, }: FormikProps<UserChangePasswordInfo>) => (
          <Form onSubmit={handleSubmit}>

            <FormLabel label='Password' />

            <FormAlert alert={errors.password} />

            <Row className='mx-0 pt-4'>
              <Col md={5}>
                <Row className='mx-0'>
                  <Col className={`px-0`} xs={6}>
                    <Field
                      required
                      className='pr-3'
                      component={FormInput}
                      name={'currentPassword'}
                      placeholder='Current Password'
                      type={'password'}
                      maxLength={100}
                    />
                  </Col>
                </Row>
                <Row className='mx-0'>
                  <Col className={`px-0`} xs={6}>
                    <Field
                      required
                      className='pr-3'
                      component={FormInput}
                      name={'newPassword'}
                      placeholder='New Password'
                      type={'password'}
                      maxLength={100}
                    />
                  </Col>

                  <Col className={`px-0`} xs={6}>
                    <Field
                      required
                      component={FormInput}
                      name={'confirmNewPassword'}
                      placeholder='Confirm New Password'
                      type={'password'}
                      maxLength={100}
                    />
                  </Col>
                </Row>
              </Col>

              <Col className='align-self-center' md={7} onClick={trackMixPanelEventPasswordChange}>
                <FormSubmit
                  isSubmitting={isSubmitting}
                  text={`Change Password`}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </StyleContainer>
  );
};

export default SettingsForm;
