// @flow
import { type ConnectUserLawpayPayload, type ConnectUserStripePayload, } from 'state/payment/types';
import { PAYMENT_PROCESSOR_TYPES, getLawpayAuthUrl, } from 'domain/payment'
import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { APP_CONFIG, } from 'constants/index';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { type Firm, } from 'domain/firm';
import { Loading, } from 'view/components/Loading';
import { PaymentActionCreators, } from 'state/actions';
import queryString from 'query-string';
import styled from 'styled-components';
import { useDispatch, } from 'react-redux';
import { useLocation, } from 'react-router-dom';
import { type ConnectPaypalBody, } from 'domain/payment';

const StyledContainer = styled(Container)`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LAWPAY_REDIRECT_URL_KEY = 'LawpayRedirectUrlKey';

const PARAMS_KEY = {
  // paypal params
  MERCHANT_ID_IN_PAYPAL: 'merchantIdInPayPal',
  PERMISSIONS_GRANTED: 'permissionsGranted',
  IS_EMAIL_CONFIRMED: 'isEmailConfirmed',

  // params
  ARGS: 'state',
  AUTH_CODE: 'code', // general param of lawpay & stripe

  // stripe params
  ERROR: 'error',
  ERROR_DESCRIPTION: 'error_description',
}

interface PaymentArgs {
  firmCode: string;
  type: string;
}

/**
 * Deserialize payment args
 *
 * @param {*} params
 * @returns
 */
const getPaymentParams = (params: any): PaymentArgs | undefined => {
  // lawpay args is passed in as "args=firmCode=DEM,type=lawpay"
  // get firmCode and type from args
  const args = params[PARAMS_KEY.ARGS];
  if (!args) return undefined;

  const data = {};
  // first split into [ 'firmCode=DEM', 'type=lawpay' ]
  const splitedParams = args.split(',');
  splitedParams.forEach(element => {
    // each split into [ 'firmCode', 'DEM' ]
    const s = element.split('=');
    // set corresponding key - value to data
    data[s[0]] = s[1];
  });

  return data;
}

/**
 * Redirect current page to correct firmcode subdomain url
 * url -> ${firmCode}.${url}
 * 
 * @param {*} params
 */
const redirectToFirmUrl = (params: any) => {
  const args = getPaymentParams(params);
  const { firmCode, type } = args;
  // remove firmCode from params's args
  params[PARAMS_KEY.ARGS] = `type=${type}`;

  // construct new query params
  const newQueryParams = queryString.stringify(params);
  const linkWithFirmCode = `${window.location.protocol}//${firmCode}.${window.location.host}${APP_CONFIG.redirectLink.integratePayment}?${newQueryParams}`;
  // redirect this page with firm code
  window.location.replace(linkWithFirmCode);
}

// check if params is from lawpay
const isLawpayParams = (params: any): boolean => {
  const authCode = params[PARAMS_KEY.AUTH_CODE];
  const args = getPaymentParams(params);

  return authCode && args && args.type === PAYMENT_PROCESSOR_TYPES.LAWPAY;
};

// check if params is from stripe
const isStripeParams = (params: any): boolean => {
  const authCode = params[PARAMS_KEY.AUTH_CODE];
  const args = getPaymentParams(params);

  return authCode && args && args.type === PAYMENT_PROCESSOR_TYPES.STRIPE;
};

// check if params is from paypal
const isPaypalParams = (params: any): boolean => {
  const merchantId = params[PARAMS_KEY.MERCHANT_ID_IN_PAYPAL];
  const permissionsGranted = params[PARAMS_KEY.PERMISSIONS_GRANTED];

  return merchantId && permissionsGranted;
};

// check error is from params (stripe)
const isErrorParam = (params: any): boolean => {
  const error = params[PARAMS_KEY.ERROR];
  const errorDescription = params[PARAMS_KEY.ERROR_DESCRIPTION];

  return error && errorDescription;
}
 
// connect redux
const useConnect = () => {
  const mapState = {}

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    connectUserLawpayAccount: (payload: ConnectUserLawpayPayload): Promise<any> => dispatch(PaymentActionCreators.connectLawpay(payload, { thunk: true })),
    connectUserStripeAccount: (payload: ConnectUserStripePayload): Promise<any> => dispatch(PaymentActionCreators.connectStripe(payload, { thunk: true, })),
    connectUserPaypalAccount: (payload: ConnectPaypalBody) => dispatch(PaymentActionCreators.connectPaypal(payload, { thunk: true, })),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

const IntegratePayment = () => {
  const {
    connectUserLawpayAccount,
    connectUserPaypalAccount,
    connectUserStripeAccount,
  } = useConnect();

  // get query params from url
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [ error, setError ] = useState<string | undefined>(undefined);

  useEffect(() => {
    // Check if connect stripe account failed
    if (isErrorParam(params)) {
      const errorDescription = params[PARAMS_KEY.ERROR_DESCRIPTION];
      setError(errorDescription);
      return;
    }
    // get args from params
    const args = getPaymentParams(params);

    // redirect if args has firmCode
    if (args && args.firmCode) {
      redirectToFirmUrl(params);
      return;
    }

    // Connect lawpay handle
    if (isLawpayParams(params)) {
      const authCode = params[PARAMS_KEY.AUTH_CODE];

      // continue integrate lawpay when firmcode has been added to host url
      const setLawpayAuthCode = async () => {
        try {
          setError(undefined);
  
          const lawpayRedirectUrl = getLawpayAuthUrl(authCode);
          if (!lawpayRedirectUrl) {
            throw ('No lawpay redirect url.')
          }

          // set outside token
          const payload: ConnectUserLawpayPayload = {
            authCode,
            redirectUrl: lawpayRedirectUrl,
          };
          await connectUserLawpayAccount(payload);
  
          // close window popup if success
          window.close();
        } catch (e) {
          setError(e.message || e);
        }
      }

      setLawpayAuthCode();
      return;
    }

    // Connect stripe handle
    if (isStripeParams(params)) {
      const setStripeAuthCode = async () => {
        const authCode = params[PARAMS_KEY.AUTH_CODE];

        try {
          setError(undefined);
  
          // set outside token
          const payload: ConnectUserStripePayload = {
            authCode,
          };
          await connectUserStripeAccount(payload);

          // close window popup if success
          window.close();
        } catch (e) {
          setError(e.message || e);
        }
      }

      setStripeAuthCode();
      return;
    }

    // Connect paypal handle
    if (isPaypalParams(params)) {
      const setPaypalMerchantId = async () => {
        const merchantId = params[PARAMS_KEY.MERCHANT_ID_IN_PAYPAL];
        const permissionsGranted = params[PARAMS_KEY.PERMISSIONS_GRANTED];
        const isEmailConfirmed = params[PARAMS_KEY.IS_EMAIL_CONFIRMED];

        try {
          setError(undefined);
  
          // set outside merchantId
          const payload: ConnectPaypalBody = {
            merchantIdInPayPal: merchantId,
            permissionsGranted,
            isEmailConfirmed,
          };
          await connectUserPaypalAccount(payload);

          // close window popup if success
          window.close();
        } catch (e) {
          setError(e.message || e);
        }
      }

      setPaypalMerchantId();
      return;
    }

    setError('Invalid params.');
    return;
    // Do not push 'params' to dependency of useEffect (infinite loop)
  }, [ setError, connectUserLawpayAccount, connectUserPaypalAccount, connectUserStripeAccount, ]);

  const onCloseWindowPopup = useCallback(() => {
    // close window if is popup window
    window.close();
  }, []);

  return (
    <StyledContainer>
      {
        error ? (
          <div className="integrate-payment-error">
            <Alert className='my-3 w-100' variant={`danger`}>
              {error}
            </Alert>
            <div className="d-flex flex-row justify-content-center">
              <Button
                className="font-weight-bold"
                variant="danger"
                onClick={onCloseWindowPopup}
              >
                {`Ok`}
              </Button>
            </div>
          </div>
        ) : (
          <Loading />
        )
      }
    </StyledContainer>
  );
};

export default IntegratePayment;
