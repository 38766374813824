/* @flow */
import { type EditingUser, EditingUserSchema, type User, type UserRepository, type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (user: User) => void,
  onInvalidPayload: (e: Error) => void,
  onError: (e: Error) => void,
};

type Dependencies = {
  userRepository: UserRepository,
};

type Payload = {
  info: EditingUser,
};

export type UpdateUserBehaviour = (
  payload: Payload,
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export default ( { userRepository, }: Dependencies, ) => {
  /**
   * update user info
   * 
   * @param {Payload} payload - 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const updateUserBehaviour: UpdateUserBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { info, } = payload;

      const isValid = await EditingUserSchema.isValid(info);
      if (!isValid) {
        return onInvalidPayload(new Error('Invalid user info.'));
      };

      // update user info
      await userRepository.update(info, withCurrentUserToken);

      // get new updated user
      const user = await userRepository.getByToken(withCurrentUserToken);
      
      return onSuccess(user);
    } catch(error) {
      return onError(error);
    }
  };

  return updateUserBehaviour;
};
