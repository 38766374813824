/* @flow */

import VirtualizedSelector, { IDropDownOption, IVirtualizedOptions } from "./B4TVirtualizedSelector";
import React from "react";
import { IUserSelectorResult } from "./UserSelectorHook";

/**
 * Selector designed to handle data fetching of user list. Should be used in conjunction with user selector hook.
 * Built on top of the virtualized selector.
 */
export default (props: IUserSelectorOptions) => {

    const { options, filterCallback, placeholder, ...others } = props;

    // apply filtering
    let filteredData: IUserSelectorResult[] = options;
    if (filterCallback) {
        filteredData = filteredData.filter(filterCallback);
    }

    // transform data to selector format
    const modifiedOptions: IUserDropdownOption[] = filteredData.map((user: IUserSelectorResult) => ({
        id: user.userId,
        name: user.fullName,
        ...user
    }));

    return (
        <VirtualizedSelector
            options={modifiedOptions}
            label={!placeholder ? "Contact" : null}
            placeholder={placeholder}
            freeSolo={true}
            textWidth="100%"
            {...others}
        />
    );
}

export type IUserSelectorOptions = ISpecificOptions & Omit<IVirtualizedOptions, "label" | "options">;
export type IUserDropdownOption = IDropDownOption & IUserSelectorResult;

interface ISpecificOptions {
    filterCallback?: (user: IUserSelectorResult) => boolean; // pass to filter clients shown - this does not prevent them from being retrieved from API
    options: IUserSelectorResult[];
    selectedOption: IUserDropdownOption | IUserDropdownOption[];
}