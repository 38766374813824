/* @flow */
import { type ApiResponse, type ConduitApiService, } from 'infra/conduit/ApiService';

import { type FirmRepository, } from 'domain/firm';

export type FirmUpdateRequestBody = {
  achEnabled?: boolean,
  allowGuests?: boolean,
  disableBalances?: boolean,
  hideAccounting?: boolean,
  invoiceTtl?: number,
  sendInvoiceEmails?: boolean,
  usePaymentStatus?: boolean,
  headnoteSurchargePercent?: number
}

export type FirmUpdateUserRequestBody = {
  userId: string, // Only needed if an admin is updating user
  user: {
      email?: string,
      password?: string, // Provide if updating to a new password
      clients?: number[], // Ids of clients user has access to
      firstName?: string,
      lastName?: string,
      systemDateFormat?: string,
      invoiceNotificationsEnabled?: boolean,
  },
  currentPassword?: string, // Only needed if updating password
}

type UpdateFirmBody = {
  portalSettings: FirmUpdateRequestBody,
};

type Dependencies = {
  conduitApiService: ConduitApiService
};

export default ({ conduitApiService, }: Dependencies): FirmRepository => ({
  /**
   * request firm information with auth credentials
   * 
   * @param {WithCurrentUserToken} params - user token
   */
  async getFirm(tokens) {
    let request;
    if (tokens) {
      request = conduitApiService.authGet({
        url: ``,
        userToken: tokens.currentUserToken,
      });
    } else {
      request = conduitApiService.get({
        url: ``,
      });
    }

    const res: ApiResponse = await request;

    if (res.error) {throw res.error;}

    // get firm api return array of firm with only 1 item
    return res.success[0];
  },

  /**
   * Updates firm's portal settings (except payment processors)
   *
   * @param {FirmUpdateRequestBody} data
   * @param {WithCurrentUserToken} tokens
   */
  async updateFirm (data, { currentUserToken, }) {
    const body: UpdateFirmBody = {
      portalSettings: data,
    };

    const res: ApiResponse = await conduitApiService.authPost({
      url: ``,
      data: body,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Updates firm's portal settings (except payment processors)
   *
   * @param {FirmUpdateRequestBody} data
   * @param {WithCurrentUserToken} tokens
   */
  async updateFirmUser (data, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authPut({
      url: `users`,
      data: data,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * get firm users
   * using admin token
   *
   * @param {*} { currentUserToken, }
   * @returns
   */
  async getFirmUsers ({ currentUserToken, }) {
    const res = await conduitApiService.authGet({
      url: 'users',
      userToken: currentUserToken,
    });
    
    if (res.error) {throw res.error;}
    
    return res.success;
  },

  /**
   * request clients list with admin credentials
   * 
   * @param {WithCurrentUserToken} params - user token
   */
  async getFirmClients({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: 'clients',
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  async getFirmFeatures({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: 'features',
      userToken: currentUserToken
    });

    if (res.error) { throw res.error; }

    return res.success;
  },

  async getDisconnectedProcessors({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: 'paymentProcessors/disconnectedProcessors',
      userToken: currentUserToken
    });

    if (res.error) { throw res.error; }

    return res.success;
  },

  async getFirmStatusInfo({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: 'firmStatusInfo',
      userToken: currentUserToken
    });

    if (res.error) { throw res.error; }

    return res.success[0];
  },

  async getFirmInvoiceInfo({ currentUserToken }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: 'firmInvoiceInfo',
      userToken: currentUserToken
    });

    if (res.error) { throw res.error; }

    return res.success[0];
  },

  async getHeadnoteFirmSettings({ currentUserToken }) {
    const response: ApiResponse = await conduitApiService.authGet({
      url: `headnote/firmSettings`,
      userToken: currentUserToken
    });
    if (response.error) { throw response.error; }
    return response.success;
  }
});
