/* @flow */
import * as Yup from 'yup';

import { API_CONFIG, APP_CONFIG, } from 'constants/index';

import { type WithCurrentUserToken, } from '../user';

import { type IB4TClientPmtMethodRequestBody, } from '../paymentMethodRequest'

export type Payment = {
  id: number,
  firmId: number,
  clientId: number, // Client payment is attached to
  projectId: number, // Project payment is attached to if project accounting isolation
  description: string,
  amount: number,
  paymentDate: string,
  isAppliedOverride: boolean,
  isBalanceAdjustment: boolean,
};

export type InvoicePayment = {
  id: number,
  firmId: number,
  paymentId: number,
  invoiceId: number,
  amountApplied: number,
  dateApplied: string,
  dateCreated: string
};

export type InvoicePayCart = {
  invoiceId?: number,
  retainerId?: number;
  retainerBalance?: number;
  invoiceLabel: string,
  invoiceBalance: number,
  amountApplied: number,
};

export const STRIPE_PAY_SOURCE = {
  ach: "ACH",
  cc: "CC",
};

export const PAYMENT_METHOD = {
  // TODO: handle other payment methods
  // creditCard: "Credit Card",
  // bankAccount: "Bank Account",
  // anotherCard: "Another Card",
  paypal: 'PayPal',
  stripe: 'Stripe',
  lawpay: 'Lawpay',
};

export type PaymentSource = "ACH" | "CC";

export type PaymentProcessorType = "Lawpay" | "Stripe" | "Paypal" | "Custom";
export const PAYMENT_PROCESSOR_TYPES = {
  LAWPAY: "Lawpay",
  STRIPE: "Stripe",
  PAYPAL: "Paypal",
  CUSTOM: "Custom"
};

export interface PaymentBody {
  cart: Array<InvoicePayCart>,
  amount: number, // Amount being charged
  source: PaymentSource,
  email?: string, // The email address to which to send a receipt of payment
  projectId?: number, // Only needed if client is project isolated
  retainerId?: number, // Only needed for Retainer Requests
  savePaymentMethod?: boolean, // Optional, can be used to save a payment method. Requires a charge token to also be provided.
  saveForFirmUse?: boolean, // Optional, can be used to save a payment method for the firm. Requires a charge token to also be provided.
  isExistingMethod?: boolean, // set to true if using a saved card or bank account
};

export interface StripePayBody extends PaymentBody {
  methodIdOrToken: string; // Either an id if an existing payment method or a charge token
}

export interface LawpayPayBody extends PaymentBody {
  methodIdOrToken: string; // Either an id if an existing payment method or a charge token
}

export interface HeadnotePayBody extends PaymentBody {
  card?: INewHeadnoteCard | IExistingCard;
  bank?: any;
  savePaymentMethod: boolean,
  saveForFirmUse?: boolean,
  payerFirstName: string,
  payerLastName: string,
  link2PayId?: number,
  accountManagerId?: number,
  notes?: string,
  captchaToken: string;
  captchaAction: string;
}

export interface INewHeadnoteCard {
  cardholder_name: string;
  card_number_token: string;
  card_cvv_token: string;
  card_exp_date: string;
  card_zip_code_token: string;
  card_type: string;
  last4: string;
}

export interface INewBankInfo {
  accountName?: string; // required if bank is to be saved for future use
  routingNumber: string;
  accountNumber: string;
  accountClass: HEADNOTE_BANK_ACCOUNT_CLASS;
}

export interface IPlaidInfo {
  accountName?: string; // required if bank is to be saved for future use
  accountId: string;
  publicToken: string;
}

export interface IExistingCard {
  id: string;
}

export interface PaypalPayBody extends PaymentBody {
  paypalOrderId: string;
}

export interface PendingPayment {
  last4: string,
  transaction: string, // transaction id
  // _id: string; // Mongo Id
  // amount: number; // Amount in currency specified
  // cart: Array<InvoicePayCart>;
  // clientId: number;
  // currency: string; // ISO 3 letter currency code
  // dateCreated: string; // Date payment was made
  // dateProcessed: string; // Date payment was marked as processed
  // description: string;
  // firmId: number;
  // projectId: number; // Only necessary if project level accounting is active
  // typeOfTokenUsed: string; // Type of token, used to determine who made the payment
  // source: PaymentProcessorType; // The payment processor
  // userId: string; // Will only be provided by user tokens
  // wasExported: boolean; // Whether payment has been exported to b4tnodeapi
   b4tpmtId?: null | number; // Null initially, but once exported should be populated with the paymentId
  // rejectionReason: string; // Only populated if charge failed to go through (after being authorized)
  // pmtProcessorPmtId: string; // The id of the payment within the processor's system
}

export type PaymentCard = {
  email?: string,
  name: string,
};

export const PaymentCardSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required'),
  name: Yup.string()
    .required('Required'),
});

export type HeadnoteApplication = {
  status: string;
  headnoteFirmId: string;
  wasSubmitted: Boolean;
}

/* ------------- Bank Account (ACH) ------------- */
export type StripeAccountHolderType = 'individual' | 'company';

export const STRIPE_ACCOUNT_HOLDER_TYPE = [
  { value: 'individual', label: 'Individual', },
  { value: 'company', label: 'Company', },
];

export type BankPaymentType = 'lawpay' | 'stripe';

export const BANK_PAYMENT_TYPE = {
  lawpay: 'lawpay',
  stripe: 'stripe',
};

export const StripeAddCreditCardSchema = Yup.object().shape({
  name: Yup.string().required(),
});

export type StripeAddBankAccountInfo = {
  account_holder_name: string,
  account_holder_type: StripeAccountHolderType,
  account_number: string,
  routing_number: string,
  // currency: string,
  // country: string,
};

export const StripeAddBankAccountInfoSchema = Yup.object().shape({
  account_holder_name: Yup.string().required(),
  account_holder_type: Yup.string()
    .oneOf(STRIPE_ACCOUNT_HOLDER_TYPE.map(i => i.value))
    .required(),
  account_number: Yup.string().max(20).required(),
  routing_number: Yup.string().max(9).required(),
});

export const LAWPAY_ACCOUNT_HOLDER_TYPE = [
  { value: 'individual', label: 'Individual', },
  { value: 'company', label: 'Company', },
  { value: 'business', label: 'Business', },
];

export const LAWPAY_ACCOUNT_TYPE = {
  savings: 'savings',
  checking: 'checking',
};

export type LawpayAccountHolderType = 'individual' | 'business';

export type LawpayAccountType = 'savings' | 'checking';

export type LawpayAddBankAccoutnInfo = {
  given_name: string,
  surname: string,
  business_name: string,
  account_holder_type: LawpayAccountHolderType,
  account_type: LawpayAccountType,
  reference: string,
};

export const LawpayAddBankAccountInfoSchema = Yup.object().shape({
  given_name: Yup.string().required(),
  surname: Yup.string(),
  business_name: Yup.string().required(),
  account_holder_type: Yup.string()
    .oneOf(LAWPAY_ACCOUNT_HOLDER_TYPE.map(i => i.value))
    .required(),
  account_type: Yup.string()
    .oneOf([LAWPAY_ACCOUNT_TYPE.savings, LAWPAY_ACCOUNT_TYPE.checking,])
    .required(),
  reference: Yup.string(),
});

export type VerifyBankInfo = {
  amount1: string,
  amount2: string,
};

export const VerifyBankInfoSchema = Yup.object().shape({
  amount1: Yup.number().integer('Amount 1 must be in cents').strict().required('Amount 1 is a required field'),
  amount2: Yup.number().integer('Amount 2 must be in cents').strict().required('Amount 2 is a required field'),
});

export type BankAccountStatus = "verified" | "unverified" | "errored";

export const BANK_ACCOUNT_STATUS = {
  verified: 'verified',
  unverified: 'unverified',
  errored: 'errored',
};

export type BankCardType = "bank" | "card";
export const BANK_CARD_TYPE = {
  bank: "bank",
  card: "card",
  newCard: "newCard"
};

export const HEADNOTE_PAYMENT_METHODS = {
  NEWCARD: "NEWCARD",
  ARNUMBER: "ARNUMBER",
  PLAID: "PLAID",
  SAVED_CC : "SAVED CREDIT CARD",
  SAVED_ECHECK : "SAVED ECHECK",
};

export const HEADNOTE_PAYMENT_STATUS = {
  PENDING: "PENDING",
};

export type BankAccountData = {
  id: string,
  status: BankAccountStatus,
  last4: string,
  type: BankCardType,
};

/* ------------- Credit card ------------- */
export type CardData = {
  brand: string,
  id: string,
  last4: string,
  type: BankCardType,
}

export const NewCardData: CardData = {
  brand: 'New Card',
  id: '-1',
  last4: 'New Card',
  type: BANK_CARD_TYPE.card,
};

export type LawpayAddCardInfo = {
  name: string,
  exp_month: string,
  exp_year: string,
  email?: string,
  savePaymentMethod?: boolean,
  reference?: string,
  address1?: string,
  city?: string,
  state?: string,
  postal_code?: string,
  country?: string,
};

export const LawpayAddCardInfoSchema = Yup.object().shape({
  name: Yup.string().max(255).required(),
  exp_month: Yup.number().min(1).max(12).required(),
  exp_year: Yup.number().min(0).max(99).required(),
  email: Yup.string().email(),
  reference: Yup.string(),
});

export type ConnectPaypalBody = {
  merchantIdInPayPal: string,
  isGrantedPermission: boolean,
  hasConfirmedEmail: boolean,
};

/* ------------- Custom payment ------------- */
export type ConnectCustomPaymentBody = {
  title: string,
  processorUrl: string,
};

export const ConnectCustomPaymentSchema = Yup.object().shape({
  title: Yup.string().required('required'),
  processorUrl: Yup.string().required('required'),
});

/* ------------- Logic Functions ------------- */
/**
 * Get stripe pay source from bank account status
 *
 * @param {boolean} isBankAccount if user use bank account
 * @returns {PaymentSource}
 */
export const getStripePaySource = (isBankAccount: boolean): PaymentSource => {
  return isBankAccount ? STRIPE_PAY_SOURCE.ach : STRIPE_PAY_SOURCE.cc;
};

/**
 * Check if user number of bank accounts is max
 *
 * @param {number} length
 * @returns {boolean}
 */
export const isMaxBankAccounts = (length: number): boolean => length >= 5;

/**
 * Check if user number of credit cards is max
 *
 * @param {number} length
 * @returns {boolean}
 */
export const isMaxCreditCards = (length: number): boolean => length >= 5;

/**
 * get redirectURI from current location
 *
 * @returns {string}
 */
export const getPaymentRedirectURI = () => {
  const host = window.location.host;
  // hostname will be for example : dem.development.development.azure.clientportal.bill4time.com
  // get first "." position
  const firstDotIndex = host.indexOf('.');
  // firmCode will be dem ( before "." )
  const firmCode = host.substring(0, firstDotIndex);
  // host will be the part after "."
  const hostName = host.substring(firstDotIndex + 1);
  const redirectURI = `${window.location.protocol}//${hostName}${APP_CONFIG.redirectLink.integratePayment}`;

  return {
    redirectURI,
    firmCode,
  };
}

/**
 * Get stripe auth url
 *
 * @returns {string}
 */
export const getStripeAuthUrl = (): string => {
  // host url is localhost in development so we need to set it for testing
  if (APP_CONFIG.isDev) {
    const testFirmCode = process.env.REACT_APP_API_DEFAULT_DEV_FIRMNAME || 'dem';
    const redirectURI = `${window.location.protocol}//${window.location.host}${APP_CONFIG.redirectLink.integratePayment}`;

    const testArgs = `firmCode=${testFirmCode},type=${PAYMENT_PROCESSOR_TYPES.STRIPE}`;

    return `${API_CONFIG.STRIPE_CONNECT_AUTH}&state=${testArgs}&redirect_uri=${redirectURI}`;
  }

  const { firmCode, redirectURI, } = getPaymentRedirectURI();

  // arguments from integrate payment redirect
  // send as stripe connect OAuth state
  // https://stripe.com/docs/connect/oauth-reference#get-authorize-request
  const args = `firmCode=${firmCode},type=${PAYMENT_PROCESSOR_TYPES.STRIPE}`;

  return `${API_CONFIG.STRIPE_CONNECT_AUTH}&state=${args}&redirect_uri=${redirectURI}`;
}

/**
 * Get lawpay redirect Url
 *
 * @param {string} authCode
 * @returns {string}
 */
export const getLawpayAuthUrl = (authCode: string): string => {
  const { firmCode, redirectURI, } = getPaymentRedirectURI();

  const args = `firmCode=${firmCode.toUpperCase()},type=${PAYMENT_PROCESSOR_TYPES.LAWPAY}`;
  return `${redirectURI}?state=${args}`;
};

export type LinkToPayTemplate = {
    firmId: number;
    id?: number;
    linkName: string;
    createdDate: Date;
    createdBy: number;
    bankAccountId?: number;
    amount?: number;
    message?: string;
    urlGuid: string;
    isClientRequested?: boolean;
    isProjectRequested?: boolean;
    isDeleted?: boolean;
    acctMgrId?: number;
    isSurchargeEnabled?: boolean;
}

export const LINK2PAY_PAYMENT_METHODS = {
  CARD: "CARD",
  ARNUMBER: "ARNUMBER",
  PLAID: "PLAID",
};

export const LINK2PAY_ACCOUNT_CLASS = {
  CHECKING: 'Checking',
  SAVINGS: 'Savings'
};

export interface IHeadnoteCard {
  id: string;
  client_id: string;
  card_name: string;
  card_type: string;
  exp_date: string;
  last4: string;
  updated_at: string;
  created_at: string;
  archived?: boolean;
}

export interface IHNClientBankAccount {
  id: string;
  client_id: string;
  account_name: string;
  created_at: string;
  updated_at: string;
}

export const PAYMENT_METHOD_REQUEST_ACCOUNT_CLASS = {
  CHECKING: 'Checking',
  SAVINGS: 'Savings'
};

/* ------------- Repository ------------- */
export type ConnectLawpayParams = {
  authCode: string,
  redirectUrl: string,
};

export type RequiredFields = {
  name?: boolean,
  address1?: boolean,
  city?: boolean,
  state?: boolean,
  postal_code?: boolean,
  country?: boolean,
  email?: boolean,
  phone?: boolean,
};

export const HEADNOTE_BANK_ACCOUNT_TYPES = {
  OPERATING: 'OPERATING',
  TRUST: 'TRUST'
}

export type HeadnoteBankAccountTypeRequestBody = {
  retainerId? :number;
  depositBankAccountId?: number;
}

export type PaymentRepository = {
  getClientPayments: (clientId: number, tokens: WithCurrentUserToken) => Promise<Array<Payment>>,
  getProjectPayments: (clientId: number, projectId: number, tokens: WithCurrentUserToken) => Promise<Array<Payment>>,
  getInvoicePayments: (invoiceId: number, tokens: WithCurrentUserToken) => Promise<Array<InvoicePayment>>,
  // payment
  stripePay: (clientId: number, body: StripePayBody, tokens: WithCurrentUserToken) => Promise<PendingPayment>,
  lawpayPay: (clientId: number, body: LawpayPayBody, tokens: WithCurrentUserToken) => Promise<PendingPayment>,
  paypalPay: (clientId: number, body: PaypalPayBody, tokens: WithCurrentUserToken) => Promise<PendingPayment>,
  paypalCreateOrder: (clientId: number, body: PaymentBody, tokens: WithCurrentUserToken) => Promise<string>, // orderId
  stripePaymentMethods: (tokens: WithCurrentUserToken) => Promise<string>,
  // bank account
  addStripeBankAccount: (token: string, tokens: WithCurrentUserToken) => Promise<string>,
  addLawpayBankAccount: (token: string, tokens: WithCurrentUserToken) => Promise<string>,
  getStripeBankAccounts: (tokens: WithCurrentUserToken) => Promise<Array<BankAccountData>>,
  getLawpayBankAccounts: (tokens: WithCurrentUserToken) => Promise<Array<BankAccountData>>,
  getHeadnoteBankAccounts: (tokens: WithCurrentUserToken) => Promise<Array<BankAccountData>>,
  removeStripeBankAccount: (bankId: string, tokens: WithCurrentUserToken) => Promise<any>,
  removeLawpayBankAccount: (bankId: string, tokens: WithCurrentUserToken) => Promise<any>,
  verifyStripeBankAccount: (bankId: string, verifyInfo: VerifyBankInfo, tokens: WithCurrentUserToken) => Promise<any>,
  // credit card
  addStripeCard: (token: string, tokens: WithCurrentUserToken) => Promise<string>,
  addLawpayCard: (token: string, tokens: WithCurrentUserToken) => Promise<string>,
  getStripeCards: (tokens: WithCurrentUserToken) => Promise<Array<CardData>>,
  getLawpayCards: (tokens: WithCurrentUserToken) => Promise<Array<CardData>>,
  getHeadnoteCards: (tokens: WithCurrentUserToken) => Promise<Array<CardData>>,
  removeStripeCard: (cardId: string, tokens: WithCurrentUserToken) => Promise<any>,
  removeLawpayCard: (cardId: string, tokens: WithCurrentUserToken) => Promise<any>,
  // Custom payment
  connectCustomPayment: (data: ConnectCustomPaymentBody, tokens: WithCurrentUserToken) => Promise<any>,
  disconnectCustomPayment: (tokens: WithCurrentUserToken) => Promise<any>,

  // Paypal
  paypalGetSignupLink: (returnUrl: string, tokens: WithCurrentUserToken) => Promise<string>,
  connectPaypal: (data: ConnectPaypalBody, tokens: WithCurrentUserToken) => Promise<any>,
  disconnectPaypal: (tokens: WithCurrentUserToken) => Promise<any>,

  // Lawpay
  requiredFieldsLawpay: (tokens: WithCurrentUserToken) => Promise<ValidFields>,
  connectLawpay: (data: ConnectLawpayParams, tokens: WithCurrentUserToken) => Promise<any>,
  disconnectLawpay: (tokens: WithCurrentUserToken) => Promise<any>,

  // Stripe payment
  connectStripePayment: (authCode: string, WithCurrentUserToken) => Promise<string>,
  disconnectStripe: (tokens: WithCurrentUserToken) => Promise<any>,

  // headnote
  getHeadnoteApplication: (tokens: WithCurrentUserToken) => Promise<HeadnoteApplication>;
  getPaymentReceivingBankType: (data: HeadnoteBankAccountTypeRequestBody, tokens: WithCurrentUserToken) => Promise<HEADNOTE_BANK_ACCOUNT_TYPES>;

  // Link2Pay
  getLinkToPayTemplate: (urlGuid: string, tokens: WithCurrentUserToken) => Promise<LinkToPayTemplate>;
  
  // Payment methods
  submitPaymentMethodHeadnote: (clientId: number, body: IB4TClientPmtMethodRequestBody, tokens: WithCurrentUserToken) => Promise<IHeadnoteCard | IHNClientBankAccount>;
};
