// @flow
import * as R from 'ramda';

import { ACCOUNT_HISTORY_TYPE, type AccountHistory, type AccountHistoryType, } from 'domain/accountHistory';
import { type Firm, convertSystemDateFormat, } from 'domain/firm';
import React, { type Node, useMemo, } from 'react';
import { currencyFormat, getAdjacentUrlParams, getDisplayAccountBalanceText, } from 'utilities/stringUtils';
import { usePagination, useTable, } from 'react-table';
import { HEADNOTE_PAYMENT_STATUS } from 'domain/payment';

import APP_CONFIG from 'constants/APP_CONFIG';
import { Colors, } from 'assets/index';
import { Link, } from 'react-router-dom';
import { Pagination, } from 'view/components';
import Table from 'react-bootstrap/Table';
import { type User, } from 'domain/user';
import { UserRedux, } from 'state/reducers';
import styled from 'styled-components';
import { useSelector, } from 'react-redux';

const trackMixpanelEventInvoiceAcctHistory = () => { window.mixpanel.track("b4tp_client_portal_account_history_invoice_number") }

type Props = {
  data: ?Array<AccountHistory>,
  clientId?: number,
  projectId?: number,
}

const StyledDebitDiv = styled.div`
  color: ${Colors.redDebit};
`;

const StyledCreditDiv = styled.div`
  color: ${Colors.greenCredit};
`;

const StyledTable = styled(Table)`
  a {
    color: ${Colors.blueCurious}
  }
`;

const AccountHistoryTable = ({ data, clientId, projectId, }: Props) => {
  // get firm and user from redux for currency format
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const user: User = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));

  const COLUMNS = useMemo(() => (
    [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ cell: { value, }, }) => {
          return convertSystemDateFormat(value, user.systemDateFormat);
        },
      },
      {
        Header: 'Activity',
        accessor: 'type',
        Cell: ({ cell, }) => {
          const history: AccountHistory = R.path(['row', 'original',], cell);
          const value: AccountHistoryType = R.path(['value',], cell);
          let text: Node;

          // display activity according to account history type
          switch (value) {
            case ACCOUNT_HISTORY_TYPE.invoice:
              text = (
                <Link to={`/invoice/${history.id}${getAdjacentUrlParams(clientId)}${getAdjacentUrlParams(projectId)}`} onClick={trackMixpanelEventInvoiceAcctHistory}>
                  {`Invoice ${history.invoiceLabel}`}
                </Link>
              );
              break;
            case ACCOUNT_HISTORY_TYPE.payment:
              text = 'Payment';
              break;
            case ACCOUNT_HISTORY_TYPE.balanceAdjustment:
              text = 'Balance Adjustment';
              break;

            default:
              text = 'Unknown';
              break;
          }

          return text;
        },
      },
      {
        Header: 'Description',
        accessor: 'desc',
        Cell: ({ cell: { value, row }, }) => {
          const hnPmtStatus = R.path(['original', 'hnPmtStatus'], row);
          const desc = value || '___';
          if (hnPmtStatus === HEADNOTE_PAYMENT_STATUS.PENDING) {
            return `(Pending) ${desc}`;
          }
          return desc;
        }
      },
      {
        Header: () => (
          <div className="text-right">
            {`Debit`}
          </div>
        ),
        accessor: 'debit',
        Cell: ({ cell, }) => {
          const history: AccountHistory = R.path(['row', 'original',], cell);
          if (!history) { return null; }

          let value: string;

          // show debit if history type invoice or balance adjustment and amount < 0 or payment and amount < 0
          if (
            history.type === ACCOUNT_HISTORY_TYPE.invoice ||
            (history.type === ACCOUNT_HISTORY_TYPE.balanceAdjustment && history.amount < 0) ||
            (history.type === ACCOUNT_HISTORY_TYPE.payment && history.amount < 0)
          ) {
            // If amount < 0, The value to display needs to be converted into a positive number
            value = currencyFormat(Math.abs(history.amount), firm);
          } else {
            value = '';
          }

          return (
            <StyledDebitDiv className="text-right">
              {value}
            </StyledDebitDiv>
          );
        },
      },
      {
        Header: () => (
          <div className="text-right">
            {`Credit`}
          </div>
        ),
        accessor: 'credit',
        Cell: ({ cell, }) => {
          const history: AccountHistory = R.path(['row', 'original',], cell);
          if (!history) { return null; }

          let value: string;

          // show credit if history type invoice or balance adjustment and amount >= 0 or payment and amount >= 0
          if (
           ( history.type === ACCOUNT_HISTORY_TYPE.payment && history.amount >= 0) ||
            (history.type === ACCOUNT_HISTORY_TYPE.balanceAdjustment && history.amount >= 0)
          ) {
            value = currencyFormat(history.amount, firm);
          } else {
            value = '';
          }

          return (
            <StyledCreditDiv className="text-right">
              {value}
            </StyledCreditDiv>
          );
        },
      },
      {
        Header: () => (
          <div className="text-right">
            {`Balance`}
          </div>
        ),
        accessor: 'balance',
        Cell: ({ cell: { value, }, }) => (
          <div className="text-right">
            {getDisplayAccountBalanceText(value, firm)}
          </div>
        ),
      },
    ]
  ), [user.systemDateFormat, clientId, projectId, firm,]);
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // pagination props
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, },
  } = useTable(
    {
      columns: COLUMNS,
      data,
      initialState: { pageIndex: 0, pageSize: APP_CONFIG.QUERY_PAGE_SIZE, },
    },
    usePagination,
  );

  return (
    <StyledWrapper className="flex-grow-1 d-flex flex-column">
      <div className="flex-grow-1">
        <StyledTable borderless responsive  {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              page.map((row, i) =>
                prepareRow(row) || (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                ))
            }
          </tbody>
        </StyledTable>
      </div>
      <Pagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        onNext={nextPage}
        onPrev={previousPage}
      />

    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
`;

export default AccountHistoryTable;
