// @flow
import * as R from 'ramda';

import { type Firm, convertSystemDateFormat, } from 'domain/firm';
import React, { type Node, useMemo, } from 'react';
import { currencyFormat, } from 'utilities/stringUtils';
import { usePagination, useTable, } from 'react-table';

import APP_CONFIG from 'constants/APP_CONFIG';
import { Colors, } from 'assets/index';
import { Pagination, } from 'view/components';
import { OverlayTrigger, Tooltip, } from 'react-bootstrap';
import { type User, } from 'domain/user';
import { UserRedux, } from 'state/reducers';
import styled from 'styled-components';
import { useSelector, } from 'react-redux';
import { type Payment, } from 'domain/payment';

const StyledLabel = styled.div`
  color: ${Colors.blueCurious};
`;

type Props = {
  data: ?Array<Payment>,
  clientId?: number,
  projectId?: number,
}

const AccountHistoryTable = ({ data, clientId, projectId, }: Props) => {
  // get firm and user from redux for currency format
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const user: User = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));

  const COLUMNS = useMemo(() => (
    [
      {
        Header: 'Activity',
        accessor: 'type',
        Cell: ({ cell, }) => {
          const payment: Payment = R.path([ 'row', 'original', ], cell);
          const systemDate = user && user.systemDateFormat ? user.systemDateFormat : null;
          return (
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {`${convertSystemDateFormat(payment.dateApplied, systemDate)} | ${payment.description}`}
                </Tooltip>
              }
              placement='top'
            >
              <StyledLabel>{`Payment`}</StyledLabel>
            </OverlayTrigger>
          );
        },
      },
      {
        Header: () => (
          <div className="text-right">
            {`Balance`}
          </div>
        ),
        accessor: 'amountApplied',
        Cell: ({ cell: { value, }, }) => (
          <div className="text-right">
            {currencyFormat(value, firm)}
          </div>
        ),
      },
    ]
  ), [ user, firm, ]);
  // Use the state and functions returned from useTable to build your UI
  const {
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // pagination props
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, },
  } = useTable(
    {
      columns: COLUMNS,
      data,
      initialState: { pageIndex: 0, pageSize: APP_CONFIG.QUERY_PAGE_SIZE, },
    },
    usePagination,
  );

  return (
    <StyledWrapper className="flex-grow-1 d-flex flex-column">
      <div className="flex-grow-1">
        {
          page.map((row, i) =>
          prepareRow(row) || (
            <div className="d-flex justify-content-between">
              {row.cells.map(cell => {
                return cell.render('Cell');
              })}
            </div>
          ))
        }
      </div>
      <Pagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        className="flex-wrap mt-3"
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        onNext={nextPage}
        onPrev={previousPage}
      />

    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .rc-pagination-next, .rc-pagination-prev {
    display: none
  }
`;

export default AccountHistoryTable;
