// @flow
import * as R from 'ramda';

import { type AccountHistoryMap, type getAccountHistoriesPayload, } from 'state/accountHistory/types';
import { AccountHistoryRedux, UserRedux, } from 'state/reducers';
import { type AcctIsoType, type Client, getAcctIsoType, } from 'domain/client';
import { type ProjectMap, type getProjectsPayload, } from 'state/project/types';
import React, { useEffect, useMemo, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import { AccountHistoryActionCreators, ClientActionCreators, InvoiceActionCreators, } from 'state/actions';
import AccountingV from './AccountingV';
import { type ClientMap, type getAccountBalancePayload, } from 'state/client/types';
import { type getInvoicesPayload, } from 'state/invoice/types';
import Container from 'react-bootstrap/Container';
import { type DropDownOption, } from 'view/components/Dropdown/types';
import { type Firm, } from 'domain/firm';
import { type Project, } from 'domain/project';
import styled from 'styled-components';
import { useClientProjectList, } from 'view/hooks';
import { useLocation, } from 'react-router-dom';

const StyledNotAllowView = styled(Container)`
  flex: 1;
`;

// connect redux
const useConnect = () => {
  // mapState
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const accountHistoriesMap: AccountHistoryMap = useSelector(R.pipe(AccountHistoryRedux.getReducerState, AccountHistoryRedux.selectors.getAccountHistories));

  const mapState = {
    accountHistoriesMap,
    firm,
  };

  // mapDispatch
  const dispatch = useDispatch();
  const mapDispatch = useMemo(() => ({
    fetchAccountHistories: (payload: getAccountHistoriesPayload) => dispatch(AccountHistoryActionCreators.getAccountHistories(payload)),
    fetchAccountBalance: (payload: getAccountBalancePayload) => dispatch(ClientActionCreators.getAccountBalance(payload)),
    fetchInvoices: (payload: getInvoicesPayload) => dispatch(InvoiceActionCreators.getInvoices(payload)),
  }), [ dispatch, ]);

  return {
    ...mapState,
    ...mapDispatch,
  };
};

/**
 * hook handle loading account histories when selected client / project update
 */
const useAccountHistories: ({
  accountHistoriesMap: AccountHistoryMap,
  selectedClient: DropDownOption,
  selectedProject: DropDownOption,
  fetchAccountHistories: (payload: getAccountHistoriesPayload) => void,
  fetchAccountBalance: (payload: getAccountBalancePayload) => void,
  fetchInvoices: (payload: getInvoicesPayload) => void,
  firm: Firm,
}) => Object = ({
  accountHistoriesMap,
  selectedClient,
  selectedProject,
  fetchAccountHistories,
  fetchAccountBalance,
  fetchInvoices,
  firm,
}) => {
  // transform accoun histories map to value array
  const listAccountHistories = useMemo(() => {
    return Array.from(accountHistoriesMap.values());
  }, [ accountHistoriesMap, ]);

  // fetch account histories of client, project when selected client / project update
  useEffect(() => {
    if (selectedClient) {
      const payload: getAccountHistoriesPayload = {
        clientId: selectedClient.id,
        acctIsoType: getAcctIsoType(firm, selectedClient),
        projectId: selectedProject ? selectedProject.id : null,
      };

      fetchAccountHistories(payload);
      fetchAccountBalance(payload);
      fetchInvoices(payload);
    }
    }, [ selectedClient, selectedProject, fetchAccountHistories, fetchInvoices, fetchAccountBalance, firm, ]);

  return {
    listAccountHistories,
  };
};

const AccountingVM = () => {
  const {
    firm,
    accountHistoriesMap,
    fetchAccountHistories,
    fetchAccountBalance,
    fetchInvoices,
  } = useConnect();
  
  const location = useLocation();

  const isHideAccounting = R.path(['portalSettings', 'hideAccounting'], firm);

  const {
    loadingClient,
    loadingProject,
    acctIsoType,
    listClient,
    listProject,
    selectedClient,
    selectedProject,
    setSelectedClient,
    setSelectedClientWithoutFetching,
    setSelectedProject,

    getClientDropDownLabel,
    getClientOptionValue,
    getClientOptionLabel,

    getProjectDropDownLabel,
    getProjectOptionValue,
    getProjectOptionLabel,
  } = useClientProjectList();

  const {
    listAccountHistories,
  } = useAccountHistories({
    accountHistoriesMap,
    selectedClient,
    selectedProject,
    fetchAccountHistories,
    fetchAccountBalance,
    fetchInvoices,
    firm,
  });

  // set client and project from state 
  useEffect(() => {
    const { state, } = location;
    if (state && state.selectedClient) {
      setSelectedClientWithoutFetching(state.selectedClient);
    }

    if (state && state.selectedProject) {
      setSelectedProject(state.selectedProject);
    }
  }, [ setSelectedProject, setSelectedClientWithoutFetching, location, ]);

  // Show not allowed to view this page message 
  if (isHideAccounting) {
    return (
      <StyledNotAllowView className="d-flex flex-column justify-content-center">
        <h3 className="text-center">{`You are not allowed to see account history!`}</h3>
      </StyledNotAllowView>
    );
  }

  return (
    <AccountingV 
      accountHistory={listAccountHistories}
      acctIsoType={acctIsoType}
      firm={firm}
      getClientDropDownLabel={getClientDropDownLabel}
      getClientOptionLabel={getClientOptionLabel}
      getClientOptionValue={getClientOptionValue}
      getProjectDropDownLabel={getProjectDropDownLabel}
      getProjectOptionLabel={getProjectOptionLabel}
      getProjectOptionValue={getProjectOptionValue}
      groupClient={listClient}
      groupProject={listProject}

      loadingClient={loadingClient}
      loadingProject={loadingProject}
      selectedClient={selectedClient}

      selectedProject={selectedProject}
      onSelectClient={setSelectedClient}
      onSelectProject={setSelectedProject}
    />	
  );
};

export default AccountingVM;
