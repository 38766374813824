/* @flow */
import React, { useEffect, useMemo, useState, } from 'react';
import { getLocationQueryString, jwtDecoded, } from 'utilities/stringUtils';
import { useLocation, useHistory, } from 'react-router-dom';
import { Container, Alert, } from 'react-bootstrap';
import { useDispatch, } from 'react-redux';
import { UserActionCreators, } from 'state/actions';
import { type UserToken, } from 'domain/user';
import { Loading, } from 'view/components/Loading';
import { ERRORS, } from 'constants/index';

// connect redux
const useConnect = () => {

    // mapDispatch
    const dispatch = useDispatch();

    const mapDispatch = useMemo(() => ({
        setTokens: (payload: UserToken) => dispatch(UserActionCreators.setTokens(payload)),
        loadFirm: () => dispatch(UserActionCreators.getFirm({ thunk: true, })),
    }), [dispatch,]);

    return {
        ...mapDispatch,
    };
};

type DecodeData = {
    clientId: number,
    firmId: number
}

const PaymentMethodRequestFromEmailVM = () => {
    // connect redux
    const { setTokens, loadFirm } = useConnect();

    const location = useLocation();
    const history = useHistory();
    const [error, setError] = useState(null);

    useEffect(() => {
        const checkTokenData = async () => {
            try {
                const { pmtMethodRequestToken }: { pmtMethodRequestToken: string } = getLocationQueryString(location.search);
                const { clientId, firmId }: DecodeData = jwtDecoded(pmtMethodRequestToken);

                // save token and re-use
                const data: UserToken = {
                    authToken: pmtMethodRequestToken
                };
                await setTokens(data);

                // Load firm to get guest setting
                const firm: Firm = await loadFirm();
                if (firm.portalSettings.allowGuests) {
                    const url = {
                        pathname: `/payment-method-request/${firmId}/${clientId}`,
                        state: {}
                    }
                    history.push(url);
                } else {
                    history.push('/login');
                }
            } catch (e) {
                setError(ERRORS.payment_method_request_token);
            }
        };
        checkTokenData();
    }, [location, history, setTokens, loadFirm]);

    return (
        <Container>
            {error ? (
                <Alert className='mt-4' variant='warning' >
                    {error}
                </Alert>
            ) : <Loading />}
        </Container>
    );
};

export default PaymentMethodRequestFromEmailVM;