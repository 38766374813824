// @flow
import * as R from 'ramda';

import { FormInput, } from 'view/components/index';

import { FieldArray, Form, Formik, type FormikProps, Field } from 'formik';
import { type Invoice, isInvoiceCanPay, type InvoicePayment, } from 'domain/invoice';
import React, { useCallback, useMemo, } from 'react';
import { Alert, Button, Card, Col, Container, Row, Spinner, } from 'react-bootstrap';
import { APP_CONFIG, } from 'constants/index';
import { Colors, } from 'assets';
import InvoicePaymentArrayDynamicField from './InvoicePaymentArrayField';
import InvoicesFormTotalSection from './InvoicesFormTotalSection';
import styled from 'styled-components';
import { useHistory, } from 'react-router-dom';
import { ClientRedux, UserRedux, } from 'state/reducers';
import { useSelector, } from 'react-redux';
import { type AccountBalance, } from 'domain/client';
import { type UserToken, } from 'domain/user';
import { useParams, } from 'react-router-dom';

const trackMixpanelEventCancel = () => { window.mixpanel.track("b4tp_client_portal_pay_now_cancel"); }
const trackMixpanelEventContinue = () => { window.mixpanel.track("b4tp_client_portal_pay_now_continue"); }

const minNameLength = 2;
const StyledCard = styled(Card)`
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
`;

const StyledCardBody = styled(Card.Body)`
  /* min-height: ${APP_CONFIG.minTableHeight}; */
  label {
    display: flex;
  }
`;

type Props = {
  onSubmit: Function,
  invoices: Array<Invoice>,
}

// connect redux
const useConnect = () => {
  // mapState
  const accountBalance: AccountBalance = useSelector(R.pipe(ClientRedux.getReducerState, ClientRedux.selectors.getAccountBalance));
  const tokens: UserToken = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getToken));
  const firm: Firm = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getFirm));
  const user: User = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getUser));
  const currencySymbol: string = useSelector(R.pipe(UserRedux.getReducerState, UserRedux.selectors.getCurrencySymbol));

  const mapState = {
    accountBalance,
    tokens,
    firm,
    user,
    currencySymbol
  };

  return {
    ...mapState,
  };
};

const isValidName = (name) => {
  return name && name.length >= minNameLength;
}

const hasValidLastAndFirstNames = (user: User): Boolean => {
  if (!user) { return false; }
  const isLastAndFirstNameValid = isValidName(user.lastName) && isValidName(user.firstName);
  return isLastAndFirstNameValid;
}

const NameField = ({ label, name }) => {
  const validateName = (value) => {
    const nameErrorMessage = `Must be no less than ${minNameLength} characters`;
    let error;
    if (!value) {
      error = 'Required'
    } else if (!isValidName(value)) {
      error = nameErrorMessage;
    }
    return error;
  }

  return <Field
    required
    component={FormInput}
    isRow={true}
    label={label}
    name={name}
    type={'text'}
    inputSize={7}
    labelSize={4}
    validate={validateName}
  />
}

const InvoicePaymentForm = ({ invoices, onSubmit, }: Props) => {
  const { accountBalance, tokens, firm, user, currencySymbol } = useConnect();
  const isHeadnote: Boolean = R.path(['portalSettings', 'isHeadnote'], firm);
  const isNameValid: Boolean = hasValidLastAndFirstNames(user);
  const showPayerInfoCard: Boolean = isHeadnote && !isNameValid;
  // route params and state
  const { invoiceId, } = useParams();

  // if only single invoice
  const invoiceItem = useMemo(() => {
    if (!invoiceId) {
      return;
    }
    return R.find(R.propEq('id', Number(invoiceId)))(invoices);
  }, [invoiceId, invoices,]);

  /**
   * prepare initialValues for formik form
   */
  const initialValues: InvoicePayment = useMemo(() => {
    // set init user payment to invoiceBalance
    // and calculate all invoices total payment
    let total = 0;
    let invoicesWithPayment = [];

    invoices.forEach(i => {
      // leave out closed or paid invoice
      if (!isInvoiceCanPay(i)) { return; }

      // leave out invoice with balance <= 0
      if (i.invoiceBalance <= 0) { return; }

      total += i.invoiceBalance;

      invoicesWithPayment.push({
        ...i,
        userPayment: i.invoiceBalance,
      });
    });

    // check if non logged-in user
    let additionalAmount = 0;
    if (
      tokens.authToken && !tokens.refreshToken &&
      invoiceItem &&
      accountBalance && accountBalance.balance && accountBalance.balance < (0 - invoiceItem.invoiceBalance)
    ) {
      additionalAmount = Math.abs(accountBalance.balance) - invoiceItem.invoiceBalance;
    }

    // Add additional row
    invoicesWithPayment.push({
      invoiceBalance: APP_CONFIG.paymentCart.additionalPayment,
      userPayment: 0,
      remainingBalance: additionalAmount
    });

    const initialValues = {
      invoices: invoicesWithPayment,
      total,
    };

    if (isHeadnote) {
      initialValues.lastName = R.path(['lastName'], user);
      initialValues.firstName = R.path(['firstName'], user);
    }

    return initialValues;
  }, [accountBalance, invoiceItem, invoices, tokens.authToken, tokens.refreshToken,]);

  // react router history object
  const history = useHistory();

  /**
   * On cancel button press
   * back to previous screen
   */
  const onCancel = useCallback(() => {
    trackMixpanelEventCancel();
    history.goBack();
  }, [history,]);

  return (
    <div className='invoices-form'>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // eslint-disable-next-line
        onSubmit={onSubmit}
      >
        {({ errors, values, handleSubmit, }: FormikProps<InvoicePayment>) => (
          <Form onSubmit={handleSubmit}>
            {
              errors.auth && (
                <Alert variant='danger'>{errors.auth}</Alert>
              )
            }

            <StyledCard className='mb-3'>
              <StyledCardBody className="h-100">
                <FieldArray
                  component={InvoicePaymentArrayDynamicField}
                  name="invoices"
                />
              </StyledCardBody>
            </StyledCard>

            {showPayerInfoCard && <StyledCard className='mb-3'>
              <StyledCardBody className="h-100">
                <b>Payer Information</b><br /><br />
                <Row>
                  <Col>
                    <NameField label='• First Name:' name={'firstName'} />
                  </Col>
                  <Col>
                    <NameField label='• Last Name:' name={'lastName'} />
                  </Col>
                  <Col></Col>
                </Row>
              </StyledCardBody>
            </StyledCard>}

            <StyledCard>
              <Card.Body className='d-flex justify-content-between'>
                <InvoicesFormTotalSection balance={accountBalance ? accountBalance.balance : 0} total={values.total} firm={firm} onCancel={onCancel} onSubmit={trackMixpanelEventContinue}>
                </InvoicesFormTotalSection>
              </Card.Body>
            </StyledCard>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InvoicePaymentForm;
