/* @flow */
import { type BankPaymentType, } from 'domain/payment';
import { type Firm, } from 'domain/firm';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: () => void,
  onInvalidPayload: (Error) => void,
  onError: (Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
};

type Payload = {
  bankId: string,
};

export type RemoveAccountBehaviour = (
  payload: Payload,
  firm: Firm,
  tokens: WithCurrentUserToken,
  cbs: Callbacks
) => any

export default ( { paymentRepository, }: Dependencies, ) => {
  /**
   * remove user bank account
   * 
   * @param {Payload} payload - 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const removeAccountBehaviour: RemoveAccountBehaviour = async (
    payload,
    firm,
    withCurrentUserToken,
    { onSuccess, onError, onInvalidPayload, },
  ) => {
    try {
      const { bankId, } = payload;
      if (!bankId) {
        return onInvalidPayload(new Error('Invalid bank info.'));
      };

      // remove stripe or lawpay bank account
      // based on firm settings
      let request;
      const portalSettings = firm.portalSettings;
      if (portalSettings.isLawpay) {
        request = paymentRepository.removeLawpayBankAccount(bankId, withCurrentUserToken);
      } else if (portalSettings.isStripe) {
        request = paymentRepository.removeStripeBankAccount(bankId, withCurrentUserToken);
      } else {
        return onError(new Error('Invalid firm settings.'));
      }

      await request;
      
      return onSuccess();
    } catch(error) {
      return onError(error);
    }
  };

  return removeAccountBehaviour;
};
