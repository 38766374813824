import { type PaymentBody, type PendingPayment, PAYMENT_METHOD, } from 'domain/payment';
import { type PaypalCreateOrderPayload, type PaypalPaymentPayload, } from 'state/payment/types';
import React, { useCallback, useMemo, useState, useEffect, } from 'react';
import styled from 'styled-components';

import { Alert, Spinner, Button } from 'react-bootstrap';
import { type InvoicePaymentRouteParams, type PaymentSuccess, } from '../../types';
import { APP_CONFIG, API_CONFIG, } from 'constants/index';
import { useHistory, useLocation, } from "react-router-dom";
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faCheck, faLock, } from '@fortawesome/free-solid-svg-icons';
import { Colors } from 'assets/index';
import { loadScript, } from 'utilities/dynamicLoading';
import ErrorMsg from './ErrorMsg';

const trackMixpanelEventBankLogin = () => { window.mixpanel.track("b4tp_client_portal_log_in_to_bank"); }

const plaidScriptUrl = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
const { PLAID } = API_CONFIG;
const plaidConfig = {
  env: PLAID.ENV,
  key: PLAID.KEY,
  apiVersion: "v2",
  clientName: "Bill4Time",
  products: ["auth"]
};

const HeadnoteECheck = ({ isPlaidConnectionFailed, error }) => {
  const [isLoaded, scriptLoaded] = useState(false);
  const [plaid, setPlaid] = useState(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const isLoaded = await loadScript(plaidScriptUrl);
      scriptLoaded(isLoaded);
    })();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const plaidObject = window.Plaid.create({
        env: plaidConfig.env,
        clientName: plaidConfig.clientName,
        key: plaidConfig.key,
        product: plaidConfig.products,
        apiVersion: plaidConfig.apiVersion,
        onSuccess: onPlaidSuccess,
      });
      setPlaid(plaidObject);
    };
  }, [isLoaded]);

  const onPlaidSuccess = (publicToken, metadata) => {
    const capitalize = (str) => {
      if (typeof str !== 'string') return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const locationState = location.state;
    const accountType = capitalize(metadata.account.subtype)
    const accountName = `${accountType} Account x${metadata.account.mask}`;

    locationState.plaidAccountDetails = {
      publicToken: publicToken,
      accountId: metadata.account.id,
      accountName
    };
    history.replace(location.pathname, locationState);
  }

  const openPlaid = () => {
    trackMixpanelEventBankLogin();
    plaid.open();
  }

  const PlaidSection = () => {
    const isConnected = !!location.state.plaidAccountDetails;
    return <> {isConnected ? <PlaidConnectedSection /> : <PlaidLoginSection />} </>;
  }

  const PlaidConnectedSection = () => {
    return <div style={{ margin: '10px' }}>
      <FontAwesomeIcon
        size='2x'
        icon={faCheck}
        style={{ color: Colors.greenCheck, marginRight: '5px' }}
      />
      <strong>Bank Account Connected!</strong>
      <Button variant="link"
        onClick={openPlaid}>Use a different bank
      </Button>
    </div>
  }

  const PlaidLoginSection = () => {
    return <>
      {
        !plaid ?
          <Spinner animation="border" />
          : <>
            <div>In order to make an instant eCheck payment directly from your bank account, please securely login to your bank account below. If your bank isn't found in the login list, please change the payment method above to "eCheck via Account & Routing Numbers" to pay with the desired bank.
              </div>
            <div style={{ margin: '20px' }}>
              <Button
                size="lg"
                variant="success"
                onClick={openPlaid}
              >
                <FontAwesomeIcon
                  className='inside-button'
                  icon={faLock}
                />
                <span style={{ margin: '10px' }}>Bank Login</span>
              </Button>
              {isPlaidConnectionFailed && <div style={{ color: 'red' }}>
                Please click here to securely log in to your bank and make your payment.
              </div>}
            </div>
          </>
      }
    </>;
  }

  return (
    <>
      <h5> eCheck Payment via Secure Bank Login </h5>
      <ErrorMsg error={error} />
      {PlaidSection()}
    </>
  );

};

export default React.memo(HeadnoteECheck);
