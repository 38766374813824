/* @flow */
import * as R from 'ramda';
import { type WithCurrentUserToken, } from '../user';

export type RetainerRequestPayments = {
  retainerRequestId: number,
  clientId: number,
  projectId?: number,
  bankAccountId?: number,
  requestAmount: number,
  createdDate?: string,
  dueDate?: string,
  pmtAmounts?: number,
  feeAmountsInCents?: number,
  paymentCount?: number,
  isVoided?: boolean
};

export type Retainer = {  // Check if needed/to be used in the future
  id: number,
  firmId: number,
  clientId: number,
  projectId?: number,
  bankAccountId: number,
  requestAmount: number,
  requestDate: string,
  balance: number,
  dueDate: string,
  createdBy: string,
  createdDate: string,
  updatedBy: number,
  updatedDate: string,
  isVoided?: boolean
};

/**
 * Validates User retainer payment amount
 * Payment must be bigger than 0 and less than or equal to retainer request amount
 *
 * @param {number} value
 * @param {number} totalPayments
 * @returns
 */
 export const retainerPaymentValidation = (value: number, retainer: RetainerRequestPayments) => {
    return R.isEmpty(value) || value > retainer.requestAmount || value <= 0;  //change to validate against balance
  };

/**
 * Check if retainer is payable
 *
 * @param {RetainerRequestPayments} [i]
 * @returns {boolean}
 */
export const isRetainerCanPay = (i?: RetainerRequestPayments): boolean => {
    if (!i) {return false;}
    let balance = i.requestAmount - i.pmtAmounts;
    return !(balance <= 0);
  };

export type RetainerUserPayment = RetainerRequestPayments & { userPayment: number }

export type RetainerPayment = {
    retainers: Array<RetainerUserPayment>,
    total: number,
    balance: number
  }

  /* ------------- Repository ------------- */
  export type RetainerRepository = {
    getClientRetainers: (clientId: number, WithCurrentUserToken) => Promise<Array<RetainerRequestPayments>>,
    getProjectRetainers: (clientId: number, projectId: number, WithCurrentUserToken) => Promise<Array<RetainerRequestPayments>>,
    getRetainerAmounts: (retainerId: number, clientId: number, WithCurrentUserToken) => Promise<RetainerRequestPayments>,
    getRetainerToken: (Retainer, WithCurrentUserToken) => Promise<string>,
  };