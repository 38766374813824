/* @flow */

import { createTheme } from '@material-ui/core/styles';
import {defaultDisabledButtonBackgroundColor,
  defaultSecondaryButtonHoverColor,
  defaultSecondaryButtonBackgroundColor,
  defaultMainBackgroundHoverColor,
  defaultMainBackgroundColor,
  defaultContrastColor,
  defaultFontSize,
  defaultFontWeight,
  defaultFontFamilies,
  defaultHeaderBackgroundColor } from './styles';

export const commonTypography = {
  fontFamily: defaultFontFamilies,
  fontSize: defaultFontSize,
  fontWeightRegular: defaultFontWeight,
};

// main B4T theme
export const mainTheme = createTheme({
  palette: {
    primary: {
      main: defaultMainBackgroundColor,
      dark: defaultMainBackgroundHoverColor,
      contrastText: defaultContrastColor
    },
    action: {
      disabledBackground: defaultDisabledButtonBackgroundColor,
      disabled:defaultContrastColor
    },
  },
  typography: commonTypography,
  overrides: {
    MuiButton: {
      root: {
        '&$disabled': {
          opacity: 0.5,
        },
        disabled: {},
      }
    },
    MuiOutlinedInput: {
      root: {
        "&.Mui-disabled $notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0.25)"
        },
      },
      input: {
        "&$disabled": {
          backgroundColor: "transparent"
        }
      },
      adornedEnd: {
        "& .Mui-disabled": {
          color: "rgba(0, 0, 0, 0.25)"
        }
      },
      disabled: {},
    },
    MuiRadio: {
      colorPrimary: {
        "&$disabled": {
          color: "rgba(0, 0, 0, 0.38)"
        }
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    }
  }
});

export const greyTheme = createTheme({
  palette: {
    primary: {
      main: defaultSecondaryButtonBackgroundColor,
      dark: defaultSecondaryButtonHoverColor,
      contrastText: defaultContrastColor
    },
  },
  typography: commonTypography,
})

// red button
export const redTheme = createTheme({
  palette: {
    primary: {
      main: '#EF4343',
      dark: '#BA2521',
      contrastText: defaultContrastColor
    },
    action: {
      disabledBackground: '#EF4343',
      disabled:defaultContrastColor
    }
  },
  typography: commonTypography,
  overrides: {
    MuiButton: {
      root: {
        '&$disabled': {
          opacity: 0.5,
        },
        disabled: {},
      }
    }
  }
});

// green button
export const greenTheme = createTheme({
  palette: {
    primary: {
      main: '#22B573',
      dark: '#00875A',
      contrastText: defaultContrastColor
    }
  },
  typography: commonTypography,
});

// default modal theme
export const modalTheme = createTheme({
  overrides: {
    MuiDialogTitle: {
      root: {
         background: defaultHeaderBackgroundColor,
         padding: "10px 0px",
         backgroundColor: defaultHeaderBackgroundColor,
         color: defaultContrastColor,
      },
    },
    MuiDialogActions: {
      root: {
        background: "#ffffff",
        padding: '10px 2%'
      },
    },
  },
  typography: commonTypography
});

// used for "success" modals
export const modalSuccessTheme = createTheme({
  overrides: {
    MuiDialogTitle: {
      root: {
         background: "#22B573",
         padding: "10px 0px",
         backgroundColor: "#22B573",
         color: defaultContrastColor,
      },
    },
    MuiDialogActions: {
      root: {
        background: "#ffffff",
        padding: '10px 2%'
      },
    },
  },
  typography: commonTypography
});

// used for "error" modals
export const modalErrorTheme = createTheme({
  overrides: {
    MuiDialogTitle: {
      root: {
         background: "#f44336",
         padding: "10px 0px",
         backgroundColor: "red",
         color: defaultContrastColor,
      },
    },
    MuiDialogActions: {
      root: {
        background: "#ffffff",
        padding: '10px 2%'
      },
    },
  },
  typography: commonTypography
});

// based off the main theme, except that the secondary palette is same as primary
export const mainTableTheme = createTheme({
  palette: {
    primary: {
      main: defaultMainBackgroundColor,
      dark: defaultMainBackgroundHoverColor,
      contrastText: defaultContrastColor
    },
    secondary: {
      main: defaultMainBackgroundColor,
      dark: defaultMainBackgroundHoverColor,
      contrastText: defaultContrastColor
    },
  },
  typography: commonTypography,
});