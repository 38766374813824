// @flow
import * as R from 'ramda';
import { type IB4TClientPmtMethodRequestBody } from 'domain/paymentMethodRequest';
import { type BankAccountData, type CardData, type RequiredFields, type PaymentProcessorType, type LinkToPayTemplate, type HEADNOTE_BANK_ACCOUNT_TYPES } from 'domain/payment';
import {
  type getDisconnectedProcessorsSuccessPayload,
  type GetPaymentMethodSuccessPayload,
  type GetPaymentsSuccessPayload,
  type GetUserBankAccountsSuccessPayload,
  type GetUserCardsSuccessPayload,
  type PaymentMap,
  type PaymentState,
  type RemoveBankAccountPayload,
  type RemoveCardpayload,
  type GetInvoicePaymentsSuccessPayload,
  type InvoicePaymentMap,
  type getHeadnoteApplicationSuccessPayload,
  type getPaymentReceivingBankTypeSuccessPayload,
  type submitPaymentMethodHeadnoteSuccessPayload
} from './types';
import { type PersistConfig, persistReducer, } from 'redux-persist';
import { isPersistActive, storage, } from '../_reduxPersist/persistConfig';

import { PaymentTypes, } from '../actions';
import { createReducer, } from 'reduxsauce';
import { mapTransformer, } from '../_reduxPersist/transformer';
import { produce, } from 'immer';

export const stateKey = 'payment';

/* ------------- Initial State ------------- */
const INITIAL_STATE: PaymentState = ({
  payments: new Map(),
  isBankAccount: false,
  bankAccounts: [],
  creditCards: [],
  invoicePayments: new Map(),
  requiredField: {},
  headnoteApplication: {},
  disconnectedProcessors: [],
  linkToPay: {},
  paymentReceivingBankType: {}
});

/* ------------- Reducers ------------- */
const getPayments = R.identity;

const getPaymentsSuccess = (state: PaymentState, { payload, }: { payload: GetPaymentsSuccessPayload, }) =>
  produce(state, (draft) => {
    Object.assign(draft, payload);
  });

const getInvoicePayments = R.identity;

const getInvoicePaymentsSuccess = (state: PaymentState, { payload, }: { payload: GetInvoicePaymentsSuccessPayload, }) =>
  produce(state, (draft) => {
    Object.assign(draft, payload);
  });

const stripePay = R.identity;
const stripePaySuccess = R.identity;

const lawpayPay = R.identity;
const lawpayPaySuccess = R.identity;

const stripePaymentMethods = R.identity;

const stripePaymentMethodsSuccess = (state: PaymentState, { payload, }: { payload: GetPaymentMethodSuccessPayload, }) =>
  produce(state, (draft) => {
    Object.assign(draft, payload);
  });

const getUserBankAccounts = R.identity;

const getUserBankAccountsSuccess = (state: PaymentState, { payload, }: { payload: GetUserBankAccountsSuccessPayload, }) =>
  produce(state, (draft) => {
    draft.bankAccounts = payload.bankAccounts;
  });

const addBankAccount = R.identity;
const addBankAccountSuccess = R.identity;

const verifyBankAccount = R.identity;
const verifyBankAccountSuccess = R.identity;

const removeBankAccount = R.identity;

const removeBankAccountSuccess = (state: PaymentState, { payload: { bankId, }, }: { payload: RemoveBankAccountPayload, }) =>
  produce(state, (draft) => {
    // remove deleted bank account from bank accounts array
    draft.bankAccounts = draft.bankAccounts.filter(b => b.id !== bankId);
  });

const getUserCards = R.identity;

const getUserCardsSuccess = (state: PaymentState, { payload, }: { payload: GetUserCardsSuccessPayload, }) =>
  produce(state, (draft) => {
    draft.creditCards = payload.cards;
  });

const addCard = R.identity;
const addCardSuccess = R.identity;

const removeCard = R.identity;

const removeCardSuccess = (state: PaymentState, { payload: { cardId, }, }: { payload: RemoveCardpayload, }) =>
  produce(state, (draft) => {
    // remove deleted card from cards array
    draft.creditCards = draft.creditCards.filter(c => c.id !== cardId);
  });

const connectCustomPayment = R.identity;
const connectCustomPaymentSuccess = R.identity;

const disconnectCustomPayment = R.identity;
const disconnectCustomPaymentSuccess = R.identity;

const paypalGetSignupLink = R.identity;
const paypalGetSignupLinkSuccess = R.identity;

const connectPaypal = R.identity;
const connectPaypalSuccess = R.identity;

const disconnectPaypal = R.identity;
const disconnectPaypalSuccess = R.identity;

const requiredFieldsLawpay = R.identity;
const requiredFieldsLawpaySuccess = (state: PaymentState, { payload, }: { payload: RequiredFields, }) =>
  produce(state, (draft) => {
    draft.requiredField = payload;
  });

const getHeadnoteApplication = R.identity;

const getHeadnoteApplicationSuccess = (state: PaymentState, { payload, }: { payload: getHeadnoteApplicationSuccessPayload, }) =>
  produce(state, (draft) => {
    Object.assign(draft.headnoteApplication, payload);
  });

const getDisconnectedProcessors = R.identity;

const getDisconnectedProcessorsSuccess = (state: PaymentState, { payload, }: { payload: getDisconnectedProcessorsSuccessPayload, }) =>
  produce(state, (draft) => {
    Object.assign(draft.disconnectedProcessors, payload);
  });

const getLinkToPayTemplate = R.identity;
const getLinkToPayTemplateSuccess = (state: PaymentState, { payload }: { payload: getLinkToPaySuccessPayload }) => 
  produce(state, (draft) => {
    Object.assign(draft.linkToPay = payload);
  });

  const getPaymentReceivingBankType = R.identity;
  const getPaymentReceivingBankTypeSuccess = (state: PaymentState, {payload}: { payload: getPaymentReceivingBankTypeSuccessPayload }) =>
    produce(state, (draft) => {
      Object.assign(draft.paymentReceivingBankType = payload);
    });

  const submitPaymentMethodHeadnote = R.identity;
  const submitPaymentMethodHeadnoteSuccess = R.identity;

const requestFailure = R.identity;

/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
  [PaymentTypes.GET_PAYMENTS]: getPayments,
  [PaymentTypes.GET_PAYMENTS_SUCCESS]: getPaymentsSuccess,

  [PaymentTypes.GET_INVOICE_PAYMENTS]: getInvoicePayments,
  [PaymentTypes.GET_INVOICE_PAYMENTS_SUCCESS]: getInvoicePaymentsSuccess,

  [PaymentTypes.STRIPE_PAY]: stripePay,
  [PaymentTypes.STRIPE_PAY_SUCCESS]: stripePaySuccess,

  [PaymentTypes.LAWPAY_PAY]: lawpayPay,
  [PaymentTypes.LAWPAY_PAY_SUCCESS]: lawpayPaySuccess,

  [PaymentTypes.STRIPE_PAYMENT_METHODS]: stripePaymentMethods,
  [PaymentTypes.STRIPE_PAYMENT_METHODS_SUCCESS]: stripePaymentMethodsSuccess,

  [PaymentTypes.GET_USER_BANK_ACCOUNTS]: getUserBankAccounts,
  [PaymentTypes.GET_USER_BANK_ACCOUNTS_SUCCESS]: getUserBankAccountsSuccess,

  [PaymentTypes.ADD_BANK_ACCOUNT]: addBankAccount,
  [PaymentTypes.ADD_BANK_ACCOUNT_SUCCESS]: addBankAccountSuccess,

  [PaymentTypes.VERIFY_BANK_ACCOUNT]: verifyBankAccount,
  [PaymentTypes.VERIFY_BANK_ACCOUNT_SUCCESS]: verifyBankAccountSuccess,

  [PaymentTypes.REMOVE_BANK_ACCOUNT]: removeBankAccount,
  [PaymentTypes.REMOVE_BANK_ACCOUNT_SUCCESS]: removeBankAccountSuccess,

  [PaymentTypes.GET_USER_CARDS]: getUserCards,
  [PaymentTypes.GET_USER_CARDS_SUCCESS]: getUserCardsSuccess,

  [PaymentTypes.ADD_CARD]: addCard,
  [PaymentTypes.ADD_CARD_SUCCESS]: addCardSuccess,

  [PaymentTypes.REMOVE_CARD]: removeCard,
  [PaymentTypes.REMOVE_CARD_SUCCESS]: removeCardSuccess,

  [PaymentTypes.CONNECT_CUSTOM_PAYMENT]: connectCustomPayment,
  [PaymentTypes.CONNECT_CUSTOM_PAYMENT_SUCCESS]: connectCustomPaymentSuccess,

  [PaymentTypes.DISCONNECT_CUSTOM_PAYMENT]: disconnectCustomPayment,
  [PaymentTypes.DISCONNECT_CUSTOM_PAYMENT_SUCCESS]: disconnectCustomPaymentSuccess,

  [PaymentTypes.PAYPAL_GET_SIGNUP_LINK]: paypalGetSignupLink,
  [PaymentTypes.PAYPAL_GET_SIGNUP_LINK_SUCCESS]: paypalGetSignupLinkSuccess,

  [PaymentTypes.CONNECT_PAYPAL]: connectPaypal,
  [PaymentTypes.CONNECT_PAYPAL_SUCCESS]: connectPaypalSuccess,

  [PaymentTypes.DISCONNECT_PAYPAL]: disconnectPaypal,
  [PaymentTypes.DISCONNECT_PAYPAL_SUCCESS]: disconnectPaypalSuccess,

  [PaymentTypes.REQUIRED_FIELDS_LAWPAY]: requiredFieldsLawpay,
  [PaymentTypes.REQUIRED_FIELDS_LAWPAY_SUCCESS]: requiredFieldsLawpaySuccess,

  [PaymentTypes.GET_HEADNOTE_APPLICATION]: getHeadnoteApplication,
  [PaymentTypes.GET_HEADNOTE_APPLICATION_SUCCESS]: getHeadnoteApplicationSuccess,

  [PaymentTypes.GET_DISCONNECTED_PROCESSORS]: getDisconnectedProcessors,
  [PaymentTypes.GET_DISCONNECTED_PROCESSORS_SUCCESS]: getDisconnectedProcessorsSuccess,

  [PaymentTypes.GET_LINK_TO_PAY_TEMPLATE]: getLinkToPayTemplate,
  [PaymentTypes.GET_LINK_TO_PAY_TEMPLATE_SUCCESS]: getLinkToPayTemplateSuccess,

  [PaymentTypes.GET_PAYMENT_RECEIVING_BANK_TYPE]: getPaymentReceivingBankType,
  [PaymentTypes.GET_PAYMENT_RECEIVING_BANK_TYPE_SUCCESS]: getPaymentReceivingBankTypeSuccess,

  [PaymentTypes.SUBMIT_PAYMENT_METHOD_HEADNOTE]: submitPaymentMethodHeadnote,
  [PaymentTypes.SUBMIT_PAYMENT_METHOD_HEADNOTE_SUCCESS]: submitPaymentMethodHeadnoteSuccess,

  [PaymentTypes.REQUEST_FAILURE]: requestFailure,
});

const persistConfig: PersistConfig = {
  key: stateKey,
  storage,
  transforms: [
    mapTransformer({
      whitelist: 'payments',
    }),
  ],
  blacklist: ['payments', 'bankAccounts', 'creditCards',],
};

const reducerMap = {
  [stateKey]: isPersistActive
    ? persistReducer < any, any> (persistConfig, reducer)
  : reducer, };

/* ------------- Selectors ------------- */
const getReducerState: (Object) => PaymentState = (state) => (state[stateKey]);

const selectors = {
  getPayments: ({ payments, }: PaymentState): PaymentMap => (payments),
  getInvoicePayments: ({ invoicePayments, }: PaymentState): InvoicePaymentMap => (invoicePayments),
  getBankStatus: ({ isBankAccount, }: PaymentState): boolean => (isBankAccount),
  getBankAccounts: ({ bankAccounts, }: PaymentState): Array<BankAccountData> => (bankAccounts),
  getCreditCards: ({ creditCards, }: PaymentState): Array<CardData> => (creditCards),
  getRequiredFields: ({ requiredField, }: PaymentState): RequiredFields => (requiredField),
  getHeadnoteApplication: ({ headnoteApplication }: PaymentState): HeadnoteApplication => (headnoteApplication),
  getDisconnectedProcessers: ({ disconnectedProcessors }: PaymentState): PaymentProcessorType[] => (disconnectedProcessors),
  getLinkToPayTemplate: ({ linkToPay }: PaymentState): LinkToPayTemplate => (linkToPay),
  getPaymentReceivingBankType: ({ paymentReceivingBankType }: PaymentState): HEADNOTE_BANK_ACCOUNT_TYPES => (paymentReceivingBankType),
};

/* ------------- Export ------------- */
export default {
  selectors,

  // default export
  INITIAL_STATE,

  stateKey,
  getReducerState,
  reducerMap,
};
