/* @flow */
import { type ApiResponse, type ConduitApiService, } from 'infra/conduit/ApiService';

import { type ClientRepository, } from 'domain/client';

type Dependencies = {
  conduitApiService: ConduitApiService
};

export default ({ conduitApiService, }: Dependencies): ClientRepository => ({
  /**
   * request clients list with auth credentials
   * 
   * @param {WithCurrentUserToken} params - user token
   */
  async getClients({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: 'clients',
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * request specific client with auth credentials
   * 
   * @param {WithCurrentUserToken} params - user token
   */
  async getSingleClient(clientId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success[0];
  },

  /**
   * request billed client balance with auth credentials
   * 
   * @param {WithCurrentUserToken} params - user token
   */
  async getBilledClientBalances(clientId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}/billedBalance`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    // api return balance in an array
    return res.success;
  },

  async getClientHeadnoteInfo(clientId, {currentUserToken}) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}/clientHeadnoteInfo`,
      userToken: currentUserToken
    });

    if (res.error) {throw res.error;}

    return res.success;
  }

});
