// @flow
import * as R from 'ramda';

import { Alert, Button, Card, Col, Container, Row, Spinner, } from 'react-bootstrap';
import { BANK_CARD_TYPE, type BankAccountData, type CardData, PAYMENT_METHOD, } from 'domain/payment';
import { type InvoicePaymentRouteParams, type PaymentData, } from './types';

import { Colors, } from 'assets/index';
import { type DropDownOption, } from 'view/components/Dropdown/types';
import { Dropdown, } from 'view/components';
import { type Firm, } from 'domain/firm';
import LawpayCard from './_components/LawpayCard';
import { Link, } from 'react-router-dom';
import PaypalCard from './_components/PaypalCard';
import React from 'react';
import StripeCard from './_components/StripeCard';
import { type User, } from 'domain/user';
import { currencyFormat, } from 'utilities/stringUtils';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  background: white;
  border-radius: 0px;
  border: solid 1px ${Colors.gainsBoro};
  box-shadow: 1px 1px 1px ${Colors.lightGrey};
  z-index: 999; /* Paypal button hide dropdown option so need to put z-index */
`;

const StyleContainer = styled(Container)`
  flex: 1;
  padding-top: 40px;
`;

const StyledHr = styled.hr`
  margin: 5px 0px 20px;
`;

type Props = {
  firm: Firm,
  afterSubmit: Function,
  selectedPaymentBankCard?: PaymentData,
  setSelectedPaymentBankCard: Function,
  bankCards: Array<PaymentData>,
  isSubmitting: boolean,
  user?: User,
  submitPayment: Function,
  invoicePayment: InvoicePaymentRouteParams,
  goBack: Function,
  error: object,
  loading: boolean,
};

const getBankCardDropDownLabel = (c: PaymentData): string => {
  // display dropdown label according to type card / bank account
  switch (c.type) {
    case BANK_CARD_TYPE.card:
      return `Card: ${c.last4}`;

    case BANK_CARD_TYPE.bank:
      return `Bank account: ${c.last4}`;
  
    default:
      return c.last4;
  }
};
const getBankCardOptionValue = (c: PaymentData): PaymentData => c;
const getBankCardOptionLabel = (c: PaymentData): string => {
  if (c.last4 !== 'New Card') {
    if (c.type === BANK_CARD_TYPE.card) {
      return `Card ending in ${c.last4}`;
    }
    if (c.type === BANK_CARD_TYPE.bank) {
      return `Bank ending in ${c.last4}`;
    }
  }
  
  return c.last4;
};

/**
 * Main component
 *
 * @param {(Props & InjectedStripeProps)} props
 * @returns
 */
const PaymentMethodsV = ({ goBack, loading, error, submitPayment, ...props }: Props) => {
  
  const allowGuest = R.path([ 'portalSettings', 'allowGuests', ], props.firm);

  const isStripe = R.path([ 'portalSettings', 'isStripe', ], props.firm);
  const isLawpay = R.path([ 'portalSettings', 'isLawpay', ], props.firm);
  const isPaypal = R.path([ 'portalSettings', 'isPaypal', ], props.firm);

  const paypalMerchantId = R.path([ 'portalSettings', 'paypal', 'merchantId', ], props.firm);
  const currencyCode = R.path([ 'currencyCode', ], props.firm);

  const hasPaymethod = isStripe || isLawpay || isPaypal;

  if (!allowGuest && !props.user) {
    return (
      <StyleContainer>
        <Row className="justify-content-md-center">
          <Col md="8">
            <Alert variant="info">
              {`Please log in to process payment.`}
              <Alert.Link href="/login">{`Click here to login`}</Alert.Link>
            </Alert>
          </Col>
        </Row>
      </StyleContainer>
    );
  }

  // check render correct card form
  let CardComponent: JSX.Element | undefined; 
  if ( props.selectedPaymentBankCard.last4 === 'New Card' ) {
    if (isLawpay) {
      CardComponent = LawpayCard;
    }
    if (isStripe) {
      CardComponent = StripeCard;
    }
  }
  if ( props.selectedPaymentBankCard.last4 === PAYMENT_METHOD.paypal && isPaypal) {
    CardComponent = PaypalCard;
  }
  
  return (
    <StyleContainer>
      <Row className="justify-content-md-center">
        <Col md="8">
          <h3>{`Submit Payment`}</h3>
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col md="8">
          <StyledCard>
            <h4>{`Payment Total: ${currencyFormat(props.invoicePayment && props.invoicePayment.amount, props.firm)}`}</h4>
            <StyledHr />
            {
              loading ? <Spinner animation="border" className="mr-2" role="status" /> : hasPaymethod && (
                <React.Fragment>
                  <h5>{`Step 1: Choose Payment Method`}</h5>

                  <Row className='mx-0 pb-3'>
                    <Dropdown
                      className="mr-1"
                      getDropDownLabel={getBankCardDropDownLabel}
                      getOptionLabel={getBankCardOptionLabel}
                      getOptionValue={getBankCardOptionValue}
                      options={props.bankCards}
                      placeholder="Payment method"
                      value={props.selectedPaymentBankCard}
                      width="210px"
                      onChange={props.setSelectedPaymentBankCard}
                    />
                  </Row>
                </React.Fragment>
              )
            }
          </StyledCard>
            { error.msg && <Alert variant='danger'>{error.msg}</Alert> }
            {
              
                CardComponent ? 
                  loading ? 
                    <></> 
                    :
                    <CardComponent 
                      afterSubmit={props.afterSubmit} 
                      currencyCode={currencyCode} 
                      goBack={goBack} 
                      invoicePayment={props.invoicePayment}
                      isUser={Boolean(props.user)}
                      paypalMerchantId={paypalMerchantId}
                    />
                  :
                  <StyledCard className="position-static">
                    <div className="d-flex justify-content-between align-items-center">
                      <Link className="btn" to="#" onClick={goBack}>{`Cancel`}</Link>
                      <div className='d-flex flex-row align-items-center'>
                        {
                          props.isSubmitting && (
                            <Spinner animation="border" className="mr-2" role="status" />
                          )
                        }
                        <Button
                          disabled={props.isSubmitting}
                          size="lg"
                          type="submit"
                          variant="success"
                          onClick={submitPayment}
                        >
                          {`SUBMIT`}
                        </Button>
                      </div>
                    </div>
                  </StyledCard>
            }
        </Col>
      </Row>
    </StyleContainer>
  );
};

export default PaymentMethodsV;
