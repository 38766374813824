import { type WithCurrentUserToken, } from 'domain/user';
import { type FirmInvoiceInfo, } from 'domain/firm';
import { type FirmRepository, } from 'domain/firm';

type CallBacks = {
    onSuccess: (firmInvoiceInfo: FirmInvoiceInfo) => void,
    onError: (e: Error) => void,
}

type Dependencies = {
    firmRepository: FirmRepository,
};

export type GetFirmInvoiceInfoBehaviour = (
    tokens: WithCurrentUserToken,
    cbs: Callbacks
) => any

export default ( { firmRepository, }: Dependencies, ) => {
    const getFirmInvoiceInfoBehaviour: GetFirmInvoiceInfoBehaviour = async (
        withCurrentUserToken,
        { onSuccess, onError, },
      ) => {
        try {
            // get firm invoice info
            const firmInvoiceInfo = await firmRepository.getFirmInvoiceInfo(withCurrentUserToken);
      
            return onSuccess(firmInvoiceInfo);
          } catch(error) {
            return onError(error);
          }
    };
    return getFirmInvoiceInfoBehaviour;
};