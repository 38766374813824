/* @flow */
import { type ApiResponse, type ConduitApiService, } from 'infra/conduit/ApiService';
import { type BankAccountData, type ConnectCustomPaymentBody, type LawpayPayBody, type PaymentBody, type PaymentRepository, type PaypalPayBody, type StripePayBody, type ConnectPaypalBody, } from 'domain/payment';

export type Dependencies = {
  conduitApiService: ConduitApiService
};

export type AddStripeBankAccountBody = {
  stripeToken: string,
};

export type AddLawpayBankAccountBody = {
  tokenId: string,
};

export type AddLawpayCardBody = AddLawpayBankAccountBody;

export type AddStripeCardBody = {
  stripeToken: string,
};

export type VerifyBankAccountBody = {
  amount1: string,
  amount2: string,
  bankAcctId: string,
};

export type RemoveBankAccountBody = {
  bankAcctId: string,
};

export type RemoveCardBody = {
  cardId: string,
};

export type PaypalGetSignupLinkBody = {
  returnUrl: string,
};

export type ConnectLawpayBody = {
  authCode: string,
  redirectUrl: string,
}

export type ConnectStripeData = {
  authorizationCode: string,
};

export type StripeConnectRedirectSuccess = {
  code: string,
  scope?: string,
};

export type StripeConnectRedirectError = {
  error: string,
  error_description: string,
};

export default ({ conduitApiService, }: Dependencies): PaymentRepository => ({
  /**
   * request payments list of a client
   * 
   * @param {number} clientId - client id
   * @param {WithCurrentUserToken} params - user token
   */
  async getClientPayments(clientId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}/payments`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * request payments list of a invoice
   * 
   * @param {number} invoiceId - invoice id
   * @param {WithCurrentUserToken} params - user token
   */
  async getInvoicePayments(invoiceId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `invoices/${invoiceId}/payments`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * request payments list of a project
   * 
   * @param {number} clientId - client id
   * @param {number} projectId - project id
   * @param {WithCurrentUserToken} params - user token
   */
  async getProjectPayments(clientId, projectId, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `clients/${clientId}/projects/${projectId}/payments`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Makes a payment in the portal via Stripe
   * 
   * @param {number} clientId - client id
   * @param {data} StripePayBody - stripe pay request body
   * @param {WithCurrentUserToken} params - user token
   */
  async stripePay(clientId, data, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/stripe/pay/clients/${clientId}`,
      userToken: currentUserToken,
      data: data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Makes a payment in the portal via lawpay
   * 
   * @param {number} clientId - client id
   * @param {data} LawpayPayBody - lawpay pay request body
   * @param {WithCurrentUserToken} params - user token
   */
  async lawpayPay(clientId, data, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/lawpay/pay/clients/${clientId}`,
      userToken: currentUserToken,
      data: data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Makes a payment in the portal via headnote
   * 
   * @param {number} clientId - client id
   * @param {data} HeadnotePayBody - headnote pay request body
   * @param {WithCurrentUserToken} params - user token
   */
  async headnotePay(clientId, data, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/headnote/pay/clients/${clientId}`,
      userToken: currentUserToken,
      data: data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Makes a Link2Pay payment in the portal via headnote
   * 
   * @param {number} clientId - client id
   * @param {data} HeadnotePayBody - headnote pay request body
   * @param {WithCurrentUserToken} params - user token
   */
   async linkToPayHeadnotePay(clientId, data, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/headnote/link2pay/clients`,
      userToken: currentUserToken,
      data: data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Submits a new Payment Method in the portal via Headnote
   * @param {number} clientId - client id
   * @param {data} IB4TClientPmtMethodRequestBody - headnote payment method body
   * @param {WithCurrentUserToken} params - user token
   * @returns 
   */
  async submitPaymentMethodHeadnote(clientId, data, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/headnote/paymentmethods`,
      userToken: currentUserToken,
      data
    });

    if (res.error) { throw res.error; }

    return res.success;
  },

  /**
   * Makes a paypal order
   * 
   * @param {number} clientId - client id
   * @param {data} PaymentBody - order request body
   * @param {WithCurrentUserToken} params - user token
   */
  async paypalCreateOrder(clientId, data, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/paypal/order/clients/${clientId}`,
      userToken: currentUserToken,
      data: data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Makes a payment in the portal via paypal
   * 
   * @param {number} clientId - client id
   * @param {data} PaypalPayBody - paypal pay request body
   * @param {WithCurrentUserToken} params - user token
   */
  async paypalPay(clientId, data, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/paypal/pay/clients/${clientId}`,
      userToken: currentUserToken,
      data: data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Retrieves all payment methods saved by the user
   * 
   * @param {WithCurrentUserToken} params - user token
   */
  async stripePaymentMethods({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `paymentProcessors/stripe/paymentMethods`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * add stripe bank account to user
   * 
   * @param {string} token - tokenized bank account
   * @param {WithCurrentUserToken} params - user token
   */
  async addStripeBankAccount(token, { currentUserToken, }) {
    const data: AddStripeBankAccountBody = {
      stripeToken: token,
    };

    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/stripe/bankAccounts`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * add lawpay bank account to user
   * 
   * @param {string} token - tokenized bank account
   * @param {WithCurrentUserToken} params - user token
   */
  async addLawpayBankAccount(token, { currentUserToken, }) {
    const data: AddLawpayBankAccountBody = {
      tokenId: token,
    };

    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/lawpay/bankAccounts`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * get user stripe bank accounts
   *
   * @param {*} { currentUserToken, }
   * @returns
   */
  async getStripeBankAccounts({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `paymentProcessors/stripe/bankAccounts`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * get user lawpay bank accounts
   *
   * @param {*} { currentUserToken, }
   * @returns
   */
  async getLawpayBankAccounts({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `paymentProcessors/lawpay/bankAccounts`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * get user Headnote bank accounts
   *
   * @param {*} { currentUserToken, }
   * @returns
   */
  async getHeadnoteBankAccounts({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `paymentProcessors/headnote/bankAccounts`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },


  /**
   * verify user stripe bank account
   *
   * @param {string} bankId
   * @param verifyInfo
   * @param { currentUserToken, }
   * @returns
   */
  async verifyStripeBankAccount(bankId, verifyInfo , { currentUserToken, }) {
    const data: VerifyBankAccountBody = {
      ...verifyInfo,
      bankAcctId: bankId,
    };

    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/stripe/bankAccounts/verify`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * verify user stripe bank account
   *
   * @param {string} bankId
   * @param verifyInfo
   * @param { currentUserToken, }
   * @returns
   */
  async removeStripeBankAccount(bankId, { currentUserToken, }) {
    const data: RemoveBankAccountBody = {
      bankAcctId: bankId,
    };

    const res: ApiResponse = await conduitApiService.authDel({
      url: `paymentProcessors/stripe/bankAccounts`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * verify user stripe bank account
   *
   * @param {string} bankId
   * @param verifyInfo
   * @param { currentUserToken, }
   * @returns
   */
  async removeLawpayBankAccount(bankId, { currentUserToken, }) {
    const data: RemoveBankAccountBody = {
      bankAccountId: bankId,
    };

    const res: ApiResponse = await conduitApiService.authDel({
      url: `paymentProcessors/lawpay/bankAccounts`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  async addStripeCard (token, { currentUserToken, }) {
    const data: AddStripeCardBody = {
      stripeToken: token,
    };

    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/stripe/cards`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  async addLawpayCard (token, { currentUserToken, }) {
    const data: AddLawpayCardBody = {
      tokenId: token,
    };

    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/lawpay/cards`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  async getStripeCards ({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `paymentProcessors/stripe/cards`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  async getLawpayCards ({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `paymentProcessors/lawpay/cards`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  async getHeadnoteCards ({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `paymentProcessors/headnote/cards`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  async removeStripeCard (cardId, { currentUserToken, }) {
    const data: RemoveCardBody = {
      cardId,
    };

    const res: ApiResponse = await conduitApiService.authDel({
      url: `paymentProcessors/stripe/cards`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  async removeLawpayCard (cardId, { currentUserToken, }) {
    const data: RemoveCardBody = {
      cardId,
    };

    const res: ApiResponse = await conduitApiService.authDel({
      url: `paymentProcessors/lawpay/cards`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Connect custom payment
   *
   * @param { title, processorUrl, } payload
   * @param { currentUserToken, }
   * @returns
   */
  async connectCustomPayment (data : ConnectCustomPaymentBody, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/customProcessor`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Disconnect custom payment
   *
   * @param { currentUserToken, }
   * @returns
   */
  async disconnectCustomPayment ({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authDel({
      url: `paymentProcessors/customProcessor`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Paypal Get signup link
   *
   * @param { currentUserToken, }
   * @returns
   */
  async paypalGetSignupLink (returnUrl : string,{ currentUserToken, }) {
    const data : PaypalGetSignupLinkBody = {
      returnUrl,
    };
    
    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/paypal/signupLink`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Paypal Connection
   *
   * @param { currentUserToken, }
   * @returns
   */
  async connectPaypal (data : ConnectPaypalBody, { currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/paypal`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Paypal disconnection
   *
   * @param { currentUserToken, }
   * @returns
   */
  async disconnectPaypal ({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authDel({
      url: `paymentProcessors/paypal`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Lawpay Connection
   *
   * @param { currentUserToken, }
   * @returns
   */
  async connectLawpay ({ authCode, redirectUrl }, { currentUserToken, }) {
    const data : ConnectLawpayBody = {
      authCode,
      redirectUrl,
    };
    
    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/lawpay`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Lawpay disconnection
   *
   * @param { currentUserToken, }
   * @returns
   */
  async disconnectLawpay ({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authDel({
      url: `paymentProcessors/lawpay`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Integrates firm with B4T's Stripe Connect account
   * 
   * @param {string} authCode - stripe connect Oauth code
   * @param {WithCurrentUserToken} params - user token
   */
  async connectStripePayment(authCode, { currentUserToken, }) {
    const data: ConnectStripeData = {
      authorizationCode: authCode,
    };

    const res: ApiResponse = await conduitApiService.authPost({
      url: `paymentProcessors/stripe`,
      userToken: currentUserToken,
      data,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Disconnect firm with B4T's Stripe Connect account
   * 
   * @param {string} authCode - stripe connect Oauth code
   * @param {WithCurrentUserToken} params - user token
   */
  async disconnectStripe({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authDel({
      url: `paymentProcessors/stripe`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Get required fields for lawpay payment.
   * 
   * @param {string} authCode - stripe connect Oauth code
   * @param {WithCurrentUserToken} params - user token
   */
  async requiredFieldsLawpay({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `paymentProcessors/lawpay/requiredFields`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

  /**
   * Get app status
   * 
   * @param {string} authCode - stripe connect Oauth code
   * @param {WithCurrentUserToken} params - user token
   */
  async getHeadnoteApplication({ currentUserToken, }) {
    const res: ApiResponse = await conduitApiService.authGet({
      url: `paymentProcessors/headnote/application`,
      userToken: currentUserToken,
    });

    if (res.error) {throw res.error;}

    return res.success;
  },

    /**
   * Get link2pay template
   */
     async getLinkToPayTemplate(urlGuid: string, { currentUserToken }) {
      const res: ApiResponse = await conduitApiService.authGet({
        url: `link2pay/${urlGuid}`,
        userToken: currentUserToken,
      });
  
      if (res.error) {throw res.error;}
  
      return res.success;
    },

    async getPaymentReceivingBankType({ retainerId, depositBankAccountId } ,{ currentUserToken, }) {
      const data: HeadnoteBankAccountTypeRequestBody = {
        retainerId, depositBankAccountId
      };

      const res: ApiResponse = await conduitApiService.authPost({
        url: `headnote/paymentReceivingBankType`,
        userToken: currentUserToken,
        data: data,
      });
  
      if (res.error) {throw res.error;}
  
      return res.success;
    },
});
