// @flow
import React, { useState, useCallback, } from 'react';

import PayCartPageV from './PaymentPageV';
import { useHistory, } from "react-router-dom";

const PayCartPageVM = () => {
  const history = useHistory();

  const [ step, setStep, ] = useState(0);

  const [ amount, setAmount, ] = useState(1319.87);

  const stepCallback = useCallback(
    () => {
      if(step === 1) {
        history.push({pathname: '/payment-methods', state : { amount, }, });
      }
      setStep((prevStep) => prevStep + 1);
    },
    [ amount, history, step, ],
  );

  const stepAmountPayment = useCallback(
    e => {
      setAmount(e.target.value);
    },
    [ ],
  );

  return (
    <PayCartPageV amount={amount} setAmount={stepAmountPayment} setStep={stepCallback} step={step} />
  );
};

export default PayCartPageVM;
