/* @flow */
import * as R from 'ramda';

import { NavLink, useRouteMatch, } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import React, { useCallback, useState, useMemo, } from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import B4TClientDropdown from '../../reusableComponents/dropdowns/B4TClientSelector';
import useClientSelectorHook from "../../reusableComponents/dropdowns/ClientSelectorHook";
import B4TUserDropdown from '../../reusableComponents/dropdowns/B4TUserSelector';
import useUserSelectorHook from "../../reusableComponents/dropdowns/UserSelectorHook";
import B4TButton, {ButtonColors} from 'view/reusableComponents/buttons/B4TButton';
import { Colors, } from 'assets/index';
import { Formik, } from 'formik';
import { useDispatch, } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { InviteActionCreators, } from 'state/actions';
import AlertNotification, {AlertTypes} from "view/reusableComponents/b4tAlert";
import { defaultFontFamilies, defaultFontREM } from 'view/styles/styles';

const StyledContainer = styled(Container)`
  color: ${Colors.charcoal};
  flex: 1;
  padding-left: 0px;
`;

const StyledNavLink = styled(NavLink)`
  color: ${Colors.blueCurious};

  :hover {
    color: ${Colors.blueAstral};
  }
`;

const InvitesView = (props) => {

  const { url } = useRouteMatch();

  const dispatch = useDispatch();

  const {selectedOption: selectedClient, setSelectedOption: setSelectedClient, options: clients, setData: setClients, ...otherClientSelectorState} =
      useClientSelectorHook({selectedClient: [], fetchErrorHandler: () => {}});

  const {selectedOption: selectedUser, setSelectedOption: setSelectedUser, options: users, setData: setUsers, ...otherUserSelectorState} =
      useUserSelectorHook({selectedUser: null, fetchErrorHandler: () => {}});

  const sendInvites = (payload: PostInvitePayload) => dispatch(InviteActionCreators.postInvite(payload, { thunk: true, }));

  const initialFormValues: PostInviteBody = useMemo(() => ({
    email: '',
    clientIds: [],
  }), []);

  const [alertOptions, setAlertOptions] = useState({
    isOpen: false,
    alertType: AlertTypes.Info,
    message: ''
  });

  // will hide the alert
  const hideAlert = () => {
    setAlertOptions({ ...alertOptions, isOpen: false });
  }

  // will show alert
  const showErrorAlert = (message: string) => {
    setAlertOptions({ ...alertOptions, alertType: AlertTypes.Error, isOpen: true, message });
  }

  // will show alert
  const showSuccessAlert = (message: string) => {
    setAlertOptions({ ...alertOptions, alertType: AlertTypes.Success, isOpen: true, message });
  }

  // handle send invite
  const onSubmit = useCallback(async (values: PostInviteBody, actions: FormikActions) => {
    try {
      actions.setSubmitting(true);

      const userEmail = values.email ? values.email : null;

      if (!userEmail) {throw new Error('Please select a contact.');}
      if (userEmail.indexOf('@') === -1) {throw new Error("Please enter a valid email address.")}
      if (!values.clientIds ||values.clientIds.length === 0) {throw new Error('Please select a client to associate with this contact.');}
      
      // TODO: update firm user clients permisson
      const data : PostInvitePayload = await {
        postInviteBody: {
          clientIds: R.map(item => item.id, values.clientIds),
          email: userEmail, //we need email for this data so we will get email in label exactly
        },
      };
      await sendInvites(data);

      actions.resetForm();
      setSelectedUser(null);
      setSelectedClient([]);

      showSuccessAlert(`Invite sent to ${data.postInviteBody.email}`)
    } catch (e) {
      showErrorAlert(e.message || e);
      actions.setErrors({
        auth: e.message || e,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }, [ sendInvites, ]);

  const classes = useStyling();

  return (
      <StyledContainer>
        <h5 style={{letterSpacing: "0.19px", fontWeight: "600", fontFamily: defaultFontFamilies}}>
          {`Send Invites`}
        </h5>
        <p className="m-0" style={{letterSpacing: "0.14px", fontSize: "0.95rem", fontFamily: defaultFontFamilies}}>
          {`Provide 24/7 access to your clients to pay invoices and view their history. Once an invitation is sent, your contact will be notified via email to create a password to login. Invitations will expire after 30 days if a login is not created.`}
        </p>
  
        <Card border="light">
          <Card.Body className="p-0">
            <Formik
              initialValues={initialFormValues}
              onSubmit={onSubmit}
            >
            {
              ({ isSubmitting, values, errors, setErrors, setFieldValue, handleSubmit, status, setStatus, }: FormikState<UserInvitesInfo>) => (
                <form>
                  <section>
                    <AlertNotification
                        isOpen={alertOptions.isOpen}
                        alertType={alertOptions.alertType}
                        onClose={hideAlert}
                        message={alertOptions.message}
                        collapseClasses={{ root: classes.collapseRoot }}
                    />
                  </section>
                  <div className="my-3">
                    <B4TUserDropdown
                      selectedOption={selectedUser}
                      setSelectedOption={setSelectedUser}
                      options={users}
                      setData={setUsers}
                      onChange={(event, email: any) => {
                        setFieldValue('email', email.email || email.name, false);
                      }}
                      {...otherUserSelectorState}
                    />
                  </div>
                  <div className="my-3">
                    <B4TClientDropdown
                    selectedOption={selectedClient}
                    setSelectedOption={setSelectedClient}
                    options={clients}
                    setData={setClients}
                    multiple
                    onChange={(event, clientIds: number[]) => {
                      setFieldValue('clientIds', clientIds, false);
                    }}
                    {...otherClientSelectorState}
                    / >
                  </div>
                  <div className="d-flex justify-content-end my-3">
                    <B4TButton
                    B4TColor={ButtonColors.BLUE}
                    text='Send Invite'
                    disabled={isSubmitting}
                    onClick={() => {handleSubmit()}}
                    />
                  </div>
                </form>
              )
            }
            </Formik>
          </Card.Body>
          <Card.Body className="text-right p-0">
            <StyledNavLink to={`${url}/list`}>{`View sent invites`}</StyledNavLink>
          </Card.Body>

        </Card>
      </StyledContainer>
    );
}

const useStyling = makeStyles({
  collapseRoot: {
      margin: "15px auto 15px auto",
      width: '100%'
  },
});

export default InvitesView;