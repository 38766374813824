/* @flow */
import * as Yup from 'yup';

import { type Client, } from 'domain/client';

export const USER_ACCESS_LEVELS = {
  TECH_ADMIN: 1,
  SYS_ADMIN: 2,
  REGULAR_USER: 3,
  GUEST: 4,
};

export type UserAccessLevels = 1 | 2 | 3 | 4;

export type User = {
  _id: string, // Mongo id
  firmId: number, // Id of firm user is registered to
  clients: number[], // Ids of clients user has access to
  accessLevel: UserAccessLevels,
  createdDate: string,
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  salt: string,
  isVerified: boolean, // Whether email has been verified
  refreshToken?: string,
  systemDateFormat: string,
  invoiceNotificationsEnabled: boolean,
};

export type UserToken = {
  authToken: string,
  refreshToken?: string,
};

export type EditingUser = {
  email?: string,
  password?: string, // Provide if updating to a new password
  clients?: number[], // Ids of clients user has access to
  firstName?: string,
  lastName?: string,
  systemDateFormat?: string,
  invoiceNotificationsEnabled?: boolean,
  currentPassword?: string, // Only needed if updating password
};

export const EditingUserSchema = Yup.object().shape({
  email: Yup.string().email(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  systemDateFormat: Yup.string(),
  invoiceNotificationsEnabled: Yup.boolean(),
  password: Yup.string()
    .min(5, 'More than 5 characters'),
  confirmPassword: Yup
    .string()
    .oneOf([Yup.ref('password'), null,], 'Passwords must match'),
});

export type UserAuthInfo = {
  username: string,
  password: string
};

export const UserAuthInfoSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'More than 8 characters')
    .max(100)
    .required('Password is required'),
  username: Yup.string()
    .email('Must be a valid email address')
    .max(100)
    .required('Email is required'),
});

export type UserResetCredentials = {
  email: string,
};

export const UserResetCredentialsSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Value is required"),
});

export type UserNewPasswordCredentials = {
  password: string,
  confirmPassword: string,
  token: string
};

export const UserNewPasswordCredentialsSchema = (email: string) => {
  if (!email) {
    return null;
  }
  const splitedEmail: array = email.split('@');
  return Yup.object().shape({
    password: Yup.string()
      .min(8, 'Must be no less than 8 characters')
      .test(
        'Should not contain your email',
        'Should not contain your email',
        (value) => new RegExp('^((?!' + splitedEmail[0] + ').)*$', "g").test(value)
      )
      .required('Required'),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('password'), null,], 'Passwords must match')
      .required('Required'),
  });
};

export type UserPermissionsInfo = {
  user: User,
  clients: Client[],
};

export type UserInvitesInfo = {
  email: any[],
  emailSelected: string,
  client: any[],
  clientSelected: any[],
};

export type UserEmailInfo = {
  email: string,
};

export const UserEmailInfoSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
});

export type UserChangePasswordInfo = {
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string,
};

// Password doesn't not containt username so we need to convert this schema to functionality
export const UserChangePasswordInfoSchema = (email: string) => {
  if (!email) {
    return null;
  }
  const splitedEmail: array = email.split('@');
  return Yup.object().shape({
    currentPassword: Yup.string()
      .min(8, 'Must be no less than 8 characters')
      .max(100)
      .required('Current password is required'),
    newPassword: Yup.string()
      .min(8, 'Must be no less than 8 characters')
      .max(100)
      .notOneOf([Yup.ref('currentPassword'), null,], "Must not be the same password")
      .test(
        'Should not contain your email',
        'Should not contain your email',
        (value) => new RegExp('^((?!' + splitedEmail[0] + ').)*$', "g").test(value)
      )
      .required('New password is required'),
    confirmNewPassword: Yup.string()
      .max(100)
      .oneOf([Yup.ref('newPassword'), null,], "Passwords must match")
      .required('Password confirm is required'),
  });
};

export type UserEmailNotiInfo = {
  invoiceNotificationsEnabled: boolean,
};

export const UserEmailNotiInfoSchema = Yup.object().shape({
  invoiceNotificationsEnabled: Yup.bool().required(),
});

export type RegisterCredentials = {
  password: string,
  firstName: string,
  lastName: string,
};

export const RegisterSchema = (email: string) => {
  if (!email) {
    return null;
  }
  const splitedEmail: array = email.split('@');
  return Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required'),
    lastName: Yup.string()
      .required('Last name is required'),
    password: Yup.string()
      .min(8, 'More than 8 characters')
      .test(
        'Should not contain your email',
        'Should not contain your email',
        (value) => new RegExp('^((?!' + splitedEmail[0] + ').)*$', "g").test(value)
      )
      .required('Password confirm is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null,], "Passwords must match")
      .required('Password confirm is required'),
  });
};
/* ------------- Repository ------------- */
export type WithCurrentUserToken = {
  currentUserToken: UserToken
};

export type UserRepository = {
  byAuthInfo: (authInfo: UserAuthInfo) => Promise<UserToken>,
  resetRequest: (cred: UserResetCredentials) => Promise<string>,
  resetPassword: (newPass: UserNewPasswordCredentials) => Promise<string>,
  add: (authInfo: UserAuthInfo) => Promise<User>,
  update: (editUser: EditingUser, tokens: WithCurrentUserToken) => Promise<string>,
  getByToken: (tokens: WithCurrentUserToken) => Promise<User>,
  register: (data: RegisterCredentials, tokens: WithCurrentUserToken) => Promise<string>,
};
