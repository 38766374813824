import { type PaymentRepository, type LinkToPayTemplate } from "domain/payment";
import { type WithCurrentUserToken } from 'domain/user';

type Callbacks = {
    onSuccess: (i: LinkToPayTemplate) => void,
    onError: (e: Error) => void,
};

type Dependencies = {
    paymentRepository: PaymentRepository
};

type Payload = {
    urlGuid: string
}

export type GetLinkToPayTemplateBehaviour = (
    Payload,
    WithCurrentUserToken,
    Callbacks
) => any

export default ({ paymentRepository }: Dependencies) => {
    /**
     * get list payments of a client or project behaviour
     * 
     * @param {Payload} payload - client id and project id
     * @param {WithCurrentUserToken} withCurrentUserToken - auth token
     * @param {Callbacks} callbacks - call back for each use case
     */
    const getLinkToPayTemplate: GetLinkToPayTemplateBehaviour = async (
        payload,
        withCurrentUserToken,
        { onSuccess, onError }) => {
        try {
            const { urlGuid } = payload;
            const linkToPayTemplate = await paymentRepository.getLinkToPayTemplate(urlGuid, withCurrentUserToken);
            return onSuccess(linkToPayTemplate);
        } catch (error) {
            return onError(error);
        }
    }

    return getLinkToPayTemplate;

}