// @flow
import { type ClientPortalSettings, PAYMENT_OPTIONS, } from 'domain/firm';
import { Colors, Images, } from 'assets/index';
import { Link, } from 'react-router-dom';
import React, { useCallback, } from 'react';

import { Button, Image, } from 'react-bootstrap';

import styled from 'styled-components';
import { MESSAGES, } from 'constants/index';

const StyledStripeLink = styled.a`
  font-size: .75rem;
  color: ${Colors.greyEmpress};

  :hover {
    text-decoration: none;
    color: ${Colors.greyEmpress};
  }
`;

const StyledButton = styled(Button)`
  background-color: ${Colors.blueButton} !important;
  border: none !important;

  :hover {
    background-color: ${Colors.blueButtonHover} !important;
  }
`;

type Props = {
  setErrors: Function,
  setMessage: Function,
  portalSettings: ClientPortalSettings,
  onStopPaymentClick: Function,
  onConnect: Function,
};

const StripeTab = ({ portalSettings, onStopPaymentClick, onConnect } : Props) => {
  // On connect
  const onSubmit = useCallback(() => {
    // Check if lawpay or custom payment integrated
    if(portalSettings.isLawpay) {
      const message = MESSAGES.setting.removeCurrentPayment(PAYMENT_OPTIONS.lawPay.label);
      onStopPaymentClick(PAYMENT_OPTIONS.lawPay.key, message, PAYMENT_OPTIONS.stripe.key);
      return;
    }
    if(portalSettings.isCustom) {
      const message = MESSAGES.setting.removeCurrentPayment(PAYMENT_OPTIONS.custom.label);
      onStopPaymentClick(PAYMENT_OPTIONS.custom.key, message, PAYMENT_OPTIONS.stripe.key);
      return;
    }
    onConnect();
  }, [ onConnect, onStopPaymentClick, portalSettings.isCustom, portalSettings.isLawpay, ]);

  // Open disconnect modal
  const onShowDisconnectModal = useCallback(() => {
    onStopPaymentClick(PAYMENT_OPTIONS.stripe.key);
  }, [ onStopPaymentClick, ]);

  return (
    <div>
      {
        portalSettings && portalSettings.isStripe ? (
          <StyledButton className='font-weight-bold' onClick={onShowDisconnectModal}>{`Disconnect From Stripe`}</StyledButton>
        ) : (
          <>
            <p>{`Connect with Stripe to Accept Credit Card Payments from Your Clients`}</p>
            <Link onClick={onSubmit}>
              <Image src={Images.common.stripe} />
            </Link>
            <div>
              <StyledStripeLink href="https://stripe.com/us/pricing">{`Stripe Pricing`}</StyledStripeLink>
            </div>
          </>
        )
      }
    </div>
  );
};

export default StripeTab;
