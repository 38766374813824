// @flow
import {Form, InputGroup,} from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';

const StyledFormLabel = styled(Form.Label)`
  font-weight: bold;
`;
const StyledFormControl = styled(Form.Control)`
  text-align: right;
  font-weight: 500;
`;

type Props = {
  amount: Number,
  setAmount: Function,
};

export default ({ amount, setAmount, } : Props) => {
  return (
    <React.Fragment>
      <h4>{`Make a payment to the balance on your account.`}</h4>
      <h4>{`Current Balance: $6,471.22`}</h4>
      <small>
        {`This payment will be applied to your account's balance in general.`}
      </small>
      <Form.Group>
        <StyledFormLabel>{`Payment Amount:`}</StyledFormLabel>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="inputGroupPrepend" >{`${amount}$`}</InputGroup.Text>
          </InputGroup.Prepend>
          <StyledFormControl type="text" value={amount} onChange={setAmount} />
        </InputGroup>
      </Form.Group>
      <StyledFormLabel>{`Payment Total $6,471.22`}</StyledFormLabel>
    </React.Fragment>
  );
};
