/* @flow */
import { type PaymentRepository, } from 'domain/payment';
import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (string) => void,
  onError: (Error) => void,
};

type Dependencies = {
  paymentRepository: PaymentRepository,
};

export type StripePaymentMethodsBehaviour = (
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { paymentRepository, }: Dependencies, ) => {
  /**
   * Stripe payment processors: get payment methods
   * 
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const stripePaymentMethodsBehaviour: StripePaymentMethodsBehaviour = async (
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      const message = await paymentRepository.stripePaymentMethods(withCurrentUserToken);
      
      return onSuccess(message);
    } catch(error) {
      return onError(error);
    }
  };

  return stripePaymentMethodsBehaviour;
};
