/* @flow */
import { type InvoiceRepository, type Invoice, } from 'domain/invoice';

import { type WithCurrentUserToken, } from 'domain/user';

type Callbacks = {
  onSuccess: (string) => void,
  onError: (Error) => void,
};

type Dependencies = {
  invoiceRepository: InvoiceRepository,
};

export type GetInvoiceTokenBehaviour = (
  Invoice,
  WithCurrentUserToken,
  Callbacks
) => any

export default ( { invoiceRepository, }: Dependencies, ) => {
  /**
   * get token of invoice behaviour
   * 
   * @param {Invoice} payload - get invoice body
   * @param {WithCurrentUserToken} withCurrentUserToken - auth token
   * @param {Callbacks} callbacks - call back for each use case
   */
  const getInvoiceTokenBehaviour: GetInvoiceTokenBehaviour = async (
    payload,
    withCurrentUserToken,
    { onSuccess, onError, },
  ) => {
    try {
      const token = await invoiceRepository.getInvoiceToken(payload, withCurrentUserToken);

      return onSuccess(token);
    } catch(error) {
      return onError(error);
    }
  };

  return getInvoiceTokenBehaviour;
};
